import PropTypes from "prop-types";
import React from "react";

import "./CheckboxLabel.css";

const CheckboxLabel = ({ name, value, text, error, elements, setElements }) => {
  const handleclick = (value, newValue, setValue) => {
    if (value?.includes(newValue)) {
      setValue(value?.filter((element) => element !== newValue));
      return;
    }

    if (value) {
      setValue([...value, newValue]);
    } else {
      setValue([newValue]);
    }
  };
  return (
    <>
      <div className="flex">
        <input
          value={value}
          id={value}
          onChange={() => handleclick(elements, value, setElements)}
          className="rounded-full mr-4 mt-1 checkbox-rounded"
          name={name}
          type="checkbox"
          checked={elements?.includes(value)}
          required
        />
        <label htmlFor={value} className="text-sm" style={{ cursor: "pointer", userSelect: "none" }}>
          {text}
        </label>
      </div>
      {error && <p className="text-red-600">{error}</p>}
    </>
  );
};

CheckboxLabel.propTypes = {
  name: PropTypes.string,
  text: PropTypes.string,
  value: PropTypes.string,
  error: PropTypes.string,
  setElements: PropTypes.func,
  elements: PropTypes.array,
};

export default CheckboxLabel;
