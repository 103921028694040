import propTypes from "prop-types";
import React, { useRef } from "react";

import { useModalBanner } from "../../../context/ModalBannerContext";
import useOnClickOutside from "../../../hooks/useOnClickOutside";
import ActionButton from "../ActionButton/ActionButton";

import "./ModalBanner.css";

function ModalBanner({ children, imageDesktop, imageMobile }) {
  const ref = useRef();

  const isDesktop = window.innerWidth >= 768;
  const image = isDesktop ? imageDesktop : imageMobile;

  const { handleHideBanner } = useModalBanner();

  useOnClickOutside(ref, () => handleHideBanner());

  return (
    <div className="backdrop">
      <div className="modal-banner" ref={ref}>
        <div className="modal-banner-container">
          <div className="modal-banner-jumbotron" style={{ backgroundImage: `url(${image})` }}>
            {!isDesktop && (
              <div className="modal-banner-close-button">
                <ActionButton icon={"back"} onClick={handleHideBanner} />
              </div>
            )}
          </div>
          <div className="modal-banner-content">
            <>
              {isDesktop && (
                <div className="modal-banner-close-button">
                  <ActionButton icon={"close"} onClick={handleHideBanner} />
                </div>
              )}

              {children}
            </>
          </div>
        </div>
      </div>
    </div>
  );
}

ModalBanner.propTypes = {
  children: propTypes.object,
  imageDesktop: propTypes.string,
  imageMobile: propTypes.string,
}.isRequired;

export default ModalBanner;
