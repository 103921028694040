import { Remove, Help } from "assets/svgs";
import PropTypes from "prop-types";
import React from "react";
import { useHistory } from "react-router-dom";

import * as S from "./styles.module.css";

const CardHeader = ({ unityName }) => {
  const history = useHistory();

  const handleRedirect = () => {
    history.push("/");
  };

  const handleOpenTawkTo = () => {
    const config = window.Tawk_API;
    config.toggle();
  };

  return (
    <div className={S.wrapper}>
      <button onClick={handleRedirect}>
        <Remove className={S.closeButton} />
      </button>

      <p className={S.unityLevel}>{unityName}</p>

      <button onClick={handleOpenTawkTo}>
        <Help />
      </button>
    </div>
  );
};

CardHeader.propTypes = {
  unityName: PropTypes.string.isRequired,
};

export default CardHeader;
