const testLevelQuestions = [
  {
    difficulty: "beginner",
    title: "I _____ from France.",
    correct_answer: "am",
    incorrect_answers: ["is", "are", "be"],
  },
  {
    difficulty: "beginner",
    title: "This is my friend. _____ name is Peter.",
    correct_answer: "His",
    incorrect_answers: ["Her", "Our", "Yours"],
  },
  {
    difficulty: "beginner",
    title: "Mike is __________.",
    correct_answer: "my sister's friend",
    incorrect_answers: ["friend my sister", "friend from my sister", "my sister friend's"],
  },
  {
    difficulty: "beginner",
    title: "My brother is _____ artist.",
    correct_answer: "an",
    incorrect_answers: ["the", "a", "-"],
  },
  {
    difficulty: "beginner",
    title: "_____ 20 desks in the classroom.",
    correct_answer: "There are",
    incorrect_answers: ["This is", "There is", "They are"],
  },
  {
    difficulty: "beginner",
    title: "Paul _____ romantic films.",
    correct_answer: "doesn't like",
    incorrect_answers: ["likes not", "don't like", "isn't likes"],
  },
  {
    difficulty: "beginner",
    title: "Sorry, I can't talk. I _____ right now.",
    correct_answer: "'m driving",
    incorrect_answers: ["driving", "drives", "drive"],
  },
  {
    difficulty: "beginner",
    title: "She _____ at school last week.",
    correct_answer: "wasn't",
    incorrect_answers: ["didn't be", "weren't", "isn't"],
  },
  {
    difficulty: "elementary",
    title: "I _____ the film last night.",
    correct_answer: "liked",
    incorrect_answers: ["like", "likes", "liking"],
  },
  {
    difficulty: "elementary",
    title: "_____ a piece of cake? No, thank you.",
    correct_answer: "Would you like",
    incorrect_answers: ["Do you like", "Want you", "Are you like"],
  },
  {
    difficulty: "elementary",
    title: "The living room is _____ than the bedroom.",
    correct_answer: "bigger",
    incorrect_answers: ["more big", "more bigger", "biggest"],
  },
  {
    difficulty: "elementary",
    title: "The car is very old. We're going _____ a  new car soon.",
    correct_answer: "to buy",
    incorrect_answers: ["buying", "to will buy", "buy"],
  },
  {
    difficulty: "elementary",
    title: "Jane is a vegetarian. She _____ meat.",
    correct_answer: "never eats",
    incorrect_answers: ["sometimes eats", "often eats", "usually eats"],
  },
  {
    difficulty: "elementary",
    title: "There aren't _____ buses late in the evening.",
    correct_answer: "any",
    incorrect_answers: ["some", "no", "a"],
  },
  {
    difficulty: "elementary",
    title: "The car park is _____ to the restaurant.",
    correct_answer: "next",
    incorrect_answers: ["opposite", "behind", "in front"],
  },
  {
    difficulty: "elementary",
    title: "Sue _____ shopping every day.",
    correct_answer: "goes",
    incorrect_answers: ["is going", "go", "going"],
  },
  {
    difficulty: "pre-intermediate",
    title: "They _____ in the park when it started to rain heavily.",
    correct_answer: "were walking",
    incorrect_answers: ["walked", "were walk", "are walking"],
  },
  {
    difficulty: "pre-intermediate",
    title: "_____ seen fireworks before?",
    correct_answer: "Have you ever",
    incorrect_answers: ["Did you ever", "Are you ever", "Do you ever"],
  },
  {
    difficulty: "pre-intermediate",
    title: "We've been friends _____ many years.",
    correct_answer: "for",
    incorrect_answers: ["since", "from", "during"],
  },
  {
    difficulty: "pre-intermediate",
    title: "You _____ pay for the tickets. They're free.",
    correct_answer: "don't need to",
    incorrect_answers: ["have to", "don't have", "doesn't have to"],
  },
  {
    difficulty: "pre-intermediate",
    title: "Jeff was ill last week and he _____ go out.",
    correct_answer: "couldn't",
    incorrect_answers: ["needn't", "can't", "mustn't"],
  },
  {
    difficulty: "pre-intermediate",
    title: "These are the photos _____ I took on holiday.",
    correct_answer: "which",
    incorrect_answers: ["who", "what", "where"],
  },
  {
    difficulty: "pre-intermediate",
    title: "We'll stay at home if it _____ this afternoon.",
    correct_answer: "rains",
    incorrect_answers: ["raining", "will rain", "rain"],
  },
  {
    difficulty: "pre-intermediate",
    title: "He doesn't smoke now, but he _____ a lot when he was young.",
    correct_answer: "used to smoke",
    incorrect_answers: ["has smoked", "smokes", "was smoked"],
  },
  {
    difficulty: "intermediate",
    title: "Mark plays football _____ anyone else I know.",
    correct_answer: "better than",
    incorrect_answers: ["more good than", "as better as", "best than"],
  },
  {
    difficulty: "intermediate",
    title: "I promise I _____ you as soon as I've finished this cleaning.",
    correct_answer: "will help",
    incorrect_answers: ["am helping", "going to help", "have helped"],
  },
  {
    difficulty: "intermediate",
    title: "This town _____ by lots of tourists during the summer.",
    correct_answer: "is visited",
    incorrect_answers: ["visits", "visited", "is visiting"],
  },
  {
    difficulty: "intermediate",
    title: "He said that his friends _____ to speak to him after they lost the football match.",
    correct_answer: "didn't want",
    incorrect_answers: ["not want", "weren't", "aren't wanting"],
  },
  {
    difficulty: "intermediate",
    title: "How about _____ to the cinema tonight?",
    correct_answer: "going",
    incorrect_answers: ["go", "to go", "for going"],
  },
  {
    difficulty: "intermediate",
    title: "Excuse me, can you _____ me the way to the station, please?",
    correct_answer: "tell",
    incorrect_answers: ["give", "take", "say"],
  },
  {
    difficulty: "intermediate",
    title: "- I wasn't interested in the performance very much. _____.",
    correct_answer: "- Neither was I.",
    incorrect_answers: ["- I didn't, too.", "- Nor I did.", "- So I wasn't."],
  },
  {
    difficulty: "intermediate",
    title: "Take a warm coat, _____ you might get very cold outside.",
    correct_answer: "otherwise",
    incorrect_answers: ["in case", "so that", "in order to"],
  },
  {
    difficulty: "upper-intermediate",
    title: "_____ this great book and I can't wait to see how it ends.",
    correct_answer: "I've been reading",
    incorrect_answers: ["I don't read", "I've read", "I read"],
  },
  {
    difficulty: "upper-intermediate",
    title: "What I like more than anything else _____ on weekends.",
    correct_answer: "is playing golf",
    incorrect_answers: ["playing golf", "to play golf", "is play golf"],
  },
  {
    difficulty: "upper-intermediate",
    title: "She ____ for her cat for two days when she finally found it in the garage.",
    correct_answer: "had been looking",
    incorrect_answers: ["looked", "had been looked", "were looking"],
  },
  {
    difficulty: "upper-intermediate",
    title: "We won't catch the plane _____ we leave home now! Please hurry up!",
    correct_answer: "unless",
    incorrect_answers: ["if", "providing that", "except"],
  },
  {
    difficulty: "upper-intermediate",
    title: "If I hadn't replied to your email, I _____ here with you now.",
    correct_answer: "wouldn't be",
    incorrect_answers: ["can't be", "won't be", "haven't been"],
  },
  {
    difficulty: "upper-intermediate",
    title: "Do you think you _____ with my mobile phone soon? I need to make a call.",
    correct_answer: "will have finished",
    incorrect_answers: ["finish", "are finishing", "are finished"],
  },
  {
    difficulty: "upper-intermediate",
    title: "I don't remember mentioning _____ dinner together tonight. ",
    correct_answer: "going for",
    incorrect_answers: ["go for", "you going to", "to go for"],
  },
  {
    difficulty: "upper-intermediate",
    title: "Was it Captain Cook _____ New Zealand?",
    correct_answer: "who discovered",
    incorrect_answers: ["discovered", "that discovered", "who was discovering"],
  },
  {
    difficulty: "advanced",
    title: "You may not like the cold weather here, but you'll have to _____, I'm afraid.",
    correct_answer: "put up with it",
    incorrect_answers: ["tell it off", "sort itself out", "put it off"],
  },
  {
    difficulty: "advanced",
    title: "It's cold so you should _____ on a warm jacket.",
    correct_answer: "put",
    incorrect_answers: ["wear", "dress", "take"],
  },
  {
    difficulty: "advanced",
    title: "Paul will look _____ our dogs while we're on holiday.",
    correct_answer: "after",
    incorrect_answers: ["at", "for", "into"],
  },
  {
    difficulty: "advanced",
    title: "She _____ a lot of her free time reading.",
    correct_answer: "spends",
    incorrect_answers: ["does", "has", "makes"],
  },
  {
    difficulty: "advanced",
    title: "Hello, this is Simon. Could I _____ to Jane, please?",
    correct_answer: "speak",
    incorrect_answers: ["say", "tell", "call"],
  },
  {
    difficulty: "advanced",
    title: "They're coming to our house _____ Saturday.",
    correct_answer: "on",
    incorrect_answers: ["in", "at", "with"],
  },
  {
    difficulty: "advanced",
    title: "I think it's very easy to _____ debt these days.",
    correct_answer: "get into",
    incorrect_answers: ["go into", "become", "go down to"],
  },
  {
    difficulty: "advanced",
    title: "Come on! Quick! Let's get _____!",
    correct_answer: "cracking",
    incorrect_answers: ["highlight", "massive", "with immediate effect"],
  },
  {
    difficulty: "advanced",
    title: "I phoned her _____ I heard the news.",
    correct_answer: "the moment",
    incorrect_answers: ["minute", "during", "by the time"],
  },
  {
    difficulty: "advanced",
    title: "I feel very _____. I'm going to go to bed!",
    correct_answer: "sleepy",
    incorrect_answers: ["nap", "asleep", "sleeper"],
  },
];

export default testLevelQuestions;
