import propTypes from "prop-types";
import React from "react";
import InputMask from "react-input-mask";

import "./Input.css";

function Input({ label, color, bg, border, width = "100%", mask, errorMessage, showErrorMessage, ...props }) {
  const s = {
    color,
    background: bg,
    border,
  };

  return (
    <label className="generic-input__label" style={{ width }}>
      {label}

      {(mask && (
        <InputMask {...props} mask={mask} maskChar="">
          {(inputProps) => (
            <input className={`generic-input ${showErrorMessage ? "border-error" : ""}`} {...inputProps} style={s} />
          )}
        </InputMask>
      )) || <input className="generic-input" {...props} style={s} />}

      {showErrorMessage && <small className="generic-input__error-message">{errorMessage}</small>}
    </label>
  );
}

export default Input;

Input.propTypes = {
  label: propTypes.string,
  color: propTypes.string,
  bg: propTypes.string,
  border: propTypes.string,
  width: propTypes.string,
  height: propTypes.string,
  mask: propTypes.string,
  errorMessage: propTypes.string,
  showErrorMessage: propTypes.bool,
  style: propTypes.object,
  labelBold: propTypes.string,
};
