import PropTypes from "prop-types";
import React from "react";

const CardPlan = ({
  name,
  value,
  setSelectedPlan,
  setPlanName,
  lessonsCount,
  price,
  recommended,
  selectedPlan,
  iconUrl,
  lessonType,
}) => {
  const handleSelected = () => {
    setPlanName(name);
    setSelectedPlan(value);
  };

  return (
    <div
      onClick={handleSelected}
      className={`border ${
        recommended ? "border-aqua-green" : "border border-gray-100"
      } rounded-lg py-3 mt-1 relative cursor-pointer`}>
      {recommended && (
        <p className="lg:left-[14.5rem] left-[6rem] md:left-[13.5rem] md:-top-5 lg:-top-5 -top-5 aqua-green text-md rounded-lg absolute text-white px-3 mt-2">
          Recomendado
        </p>
      )}
      <div className="flex gap-3 p-3">
        <img src={iconUrl} alt="icon" style={{ width: "94px", height: "94px" }} />
        <div>
          <div>
            <h2 className="text-lg">
              Plano{" "}
              <span>
                <strong> {name}</strong>
              </span>
            </h2>
            {lessonType === "CONVERSATION" && <p className="text-lg">2x por semana</p>}
            {lessonType !== "CONVERSATION" && (
              <p className="text-lg">
                {lessonsCount} {lessonsCount === 1 ? "aula" : "aulas"} por mês
              </p>
            )}
          </div>
          <span className="text-xl font-aqua-green font-bold">R$ {price}</span>
        </div>
        <div>
          <input checked={selectedPlan === value} type="radio" name="plan" value={value} required />
        </div>
      </div>
    </div>
  );
};

CardPlan.propTypes = {
  name: PropTypes.string,
  value: PropTypes.string,
  setSelectedPlan: PropTypes.func,
  lessonsCount: PropTypes.number,
  price: PropTypes.string,
  recommended: PropTypes.bool,
  setPlanName: PropTypes.func,
  selectedPlan: PropTypes.string,
  iconUrl: PropTypes.string,
  lessonType: PropTypes.string,
};

export default CardPlan;
