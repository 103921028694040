import GirlBanner6 from "assets/imgs/girl-banner-6.png";
import { useIsStudent } from "hooks/useIsStudent";
import React from "react";
import { AiFillStar } from "react-icons/ai";
import { HiOutlineArrowNarrowRight } from "react-icons/hi";
import "./BannerPremium.css";
import { logAnalyticsEvent } from "utils/firebase-analytics";

const BannerPremium = () => {
  const { handleRedirect } = useIsStudent();

  return (
    <div
      className="banner_premium"
      style={{ backgroundImage: `url(${GirlBanner6})` }}
      onClick={() => {
        logAnalyticsEvent("banner_click_premium");
        handleRedirect("premium");
      }}>
      <p>Plano</p>
      <p>Premium</p>
      <div className="flex items-center gap-1 mt-3">
        <AiFillStar color="#6a51b2" />
        <p>Aula ao vivo toma semana com teacher Julio</p>
      </div>

      <button>
        Seja premium
        <HiOutlineArrowNarrowRight />
      </button>
    </div>
  );
};

export default BannerPremium;
