import PropTypes from "prop-types";
import React, { useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";

import { WorkingGirl } from "../../../../../assets/svgs/index";
import { SlideContext } from "../../../../../context/SlideContext";
import { PurpleButton, Button } from "../../../Form/index";

const CreditCardPaymentSuccess = ({ response, discount, redirectUrl = "/" }) => {
  const { setEnableClickAway, setShowSlide } = useContext(SlideContext);
  const history = useHistory();

  setEnableClickAway(false);

  const calculatDiscount = (value, porcentage) => ((value * (porcentage || 0)) / 100).toFixed(2);

  useEffect(() => {
    window.history.replaceState(null, "Curso de Inglês online - EnglishBay", "/cartao/confirmado");
  }, []);

  const handleClose = (link) => {
    setShowSlide(false);
    history.push(link);
  };

  return (
    <div className="h-full flex md:w-[620px] flex-col">
      <div className="flex justify-between items-center">
        <h1 className="text-xl font-gray font-medium">Seu pagamento foi realizado com sucesso!</h1>
      </div>
      <div className="grid grid-cols-2 lg:grid-cols-4 flex-1">
        <div className="col-span-2 py-4">
          <p className="font-light-gray font-medium">Forma de pagamento</p>
          <div className="flex justify-between my-2">
            <p className="font-light-gray text-xs font-bold uppercase">Cartão de crédito</p>
            <p>....{response.bill.payment_profile.card_number_last_four}</p>
          </div>
          <div className="py-4">
            <p className="font-light-gray font-medium">Plano Adquirido</p>
            <div className="flex justify-between my-2">
              <p className="font-light-gray text-xs font-bold uppercase">{response.plan.name}</p>
              <p>{response.plan.price}</p>
            </div>
            <div className="flex justify-between">
              <p className="font-light-gray text-xs font-bold uppercase">Detalhes do Plano</p>
              {response.plan.lessonType !== "CONVERSATION" ? (
                <p>
                  {response.plan.lessonsCount} {response.plan.lessonsCount === 1 ? "aula" : "aulas"} por mês
                </p>
              ) : (
                <p>Conversação</p>
              )}
            </div>
          </div>
          <hr className="md:mt-10 mb-4" />
          <div className="flex justify-between my-2">
            <p className="font-light-gray text-xs font-bold uppercase">Subtotal</p>
            <p>{response.plan.price}</p>
          </div>
          <div className="flex justify-between my-2">
            <p className="font-light-gray text-xs font-bold uppercase">Taxas administrativas</p>
            <p className="font-aqua-green font-bold">Grátis</p>
          </div>

          {discount ? (
            <>
              <div className="flex justify-between my-2">
                <p className="font-light-gray text-xs font-bold uppercase">Desconto</p>
                <p>R$ {calculatDiscount(response.plan.price, discount?.discountPercentage || 0)}</p>
              </div>
              <div className="flex justify-between my-2">
                <p className="font-light-gray text-xs font-bold uppercase">Total a pagar</p>
                <p>
                  R${" "}
                  {(response.plan.price - calculatDiscount(response.plan.price, discount.discountPercentage)).toFixed(
                    2,
                  )}
                </p>
              </div>
            </>
          ) : (
            <div className="flex justify-between my-2">
              <p className="font-light-gray text-xs font-bold uppercase">Total a pagar</p>
              <p>{response.plan.price}</p>
            </div>
          )}
          <p className="text-sm font-medium" style={{ color: "#F59300" }}>
            As cobranças por cartão de crédito são automáticas, você pode cancelar quando quiser através do painel
            “Planos”.
          </p>
        </div>
        <WorkingGirl className="md:mx-5 hidden md:block" style={{ width: "200%" }} />
      </div>
      <div className="grid grid-cols-2 md:grid-cols-3 gap-2 md:mt-auto mt-8">
        {response.plan.lessonType !== "CONVERSATION" && (
          <PurpleButton onClick={() => handleClose("/professores")}>Agendar aula</PurpleButton>
        )}

        <Button onClick={() => handleClose(redirectUrl)} className="border border-purple-800 text-purple-800">
          Voltar para o início
        </Button>
        <hr className="mt-4 invisible" />
      </div>
    </div>
  );
};

CreditCardPaymentSuccess.propTypes = {
  response: PropTypes.object,
  discount: PropTypes.number,
  redirectUrl: PropTypes.string,
};

export default CreditCardPaymentSuccess;
