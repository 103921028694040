import { utcToZonedTime } from "date-fns-tz";
import PropTypes from "prop-types";
import React, { useEffect, useState, useContext } from "react";
import { useTranslation } from "react-i18next";

import { CheckMark, SpinnerPurple } from "../../../../assets/svgs/index";
import { SlideContext } from "../../../../context/SlideContext";
import { UserContext } from "../../../../context/UserContext";
import useRequest from "../../../../hooks/useRequest";
import { addMinutes, getHoursAndMinutes, getDayOfTheWeekDayMonth, formatIso } from "../../../../utils/dates";
import DrawerDetailsCancel from "./DrawerDetailsCancel";
import DrawerProfile from "./DrawerProfile.js";

import "./GoClassButton.css";

const Class = ({
  cottar,
  setCottar,
  uuid,
  uuidStudent,
  name,
  studentNote,
  datetime,
  imageUrl,
  minutesLesson,
  refresh,
  isLastRecord,
  teacherNote,
  isTestClass,
  isSoldTestClass,
  teacherMeetingUrl,
}) => {
  const { setShowSlide, setSlideComponent } = useContext(SlideContext);
  const { user } = useContext(UserContext);
  const { request } = useRequest();
  const { t } = useTranslation();

  const [start, setStart] = useState("");
  const [finish, setFinish] = useState("");
  const [border, setBorder] = useState("");
  const [feedback, setFeedback] = useState("");
  const [isFeedback, setIsFeedback] = useState(false);
  const [isInvalidFeedback, setIsInvalidFeedback] = useState(false);
  const [isLoadingSubmit, setIsLoadingSubmit] = useState(false);
  const [timezonedDatetime, setTimezonedDatetime] = useState(null);
  const [not, setNot] = useState(true); // Indica se o professor clicou em "Não"

  const handleComponent = async (component) => {
    setSlideComponent(component);
    setShowSlide(true);
  };

  const handleFinishLesson = async (event, answer) => {
    event.preventDefault();

    if (!feedback.length) {
      setIsInvalidFeedback(true);
      return;
    }

    setIsLoadingSubmit(true);

    const note = { teacherNote: feedback, status: answer ? "COMPLETED" : "MISSED" };

    await request("PATCH", `/appointment/finish/${uuid}`, note);

    setIsLoadingSubmit(false);
    setCottar(3);
    setIsFeedback(false);
  };

  useEffect(() => {
    if (!user?.timezone) {
      return;
    }

    datetime = formatIso(utcToZonedTime(datetime, user.timezone));
    setTimezonedDatetime(datetime);
    setStart(getHoursAndMinutes(datetime));
    setFinish(getHoursAndMinutes(addMinutes(datetime, minutesLesson)));
  }, [user?.timezone]);

  useEffect(() => {
    feedback.length && setIsInvalidFeedback(false);
  }, [feedback]);

  useEffect(() => {
    switch (cottar) {
      case 1:
        setBorder("#FFC300");
        break;
      case 2:
        setBorder("#C3A2F5");
        break;
      case 3:
        setBorder("#14C4BE");
        break;
    }
  }, [cottar]);

  return (
    <div
      className="grid rounded-2xl bg-white relative mt-4"
      style={{
        border: `2px solid ${border}`,
        gridTemplateColumns: "150px 4fr 1fr",
        marginBottom: isLastRecord ? "50px" : "0px",
        backgroundColor: isSoldTestClass ? "#c3a2f552" : "white",
      }}>
      {isTestClass && (
        <div
          className="mb-2 text-sm px-2"
          style={{
            background: "rgba(255, 231, 153)",
            borderRadius: "50px",
            color: "#F59300",
            position: "absolute",
            top: "-10px",
            left: "45%",
          }}>
          Test class
        </div>
      )}
      <div
        className={`absolute flex justify-between h-full w-full rounded-2xl pl-11 pr-7 ${isFeedback ? "" : "hidden"}`}
        style={{
          background: "#FFFFFF",
        }}>
        <form className="flex justify-between">
          <div className="mt-7">
            <h1
              className="text-xl"
              style={{
                color: "#383743",
              }}>
              Digite aqui um feedback sobre a aula
            </h1>
            <textarea
              className="resize-none mt-3 text-sm p-3 rounded-md"
              value={feedback}
              onChange={({ target }) => setFeedback(target.value)}
              style={{
                width: "645px",
                height: "96px",
                color: "#66647A",
                border: `1px solid ${isInvalidFeedback ? "#ca272757" : "#e5e7eb"}`,
              }}
              placeholder="Write here a feedback about the class..."
              required={true}
            />
          </div>

          <div className="self-center">
            <button
              className="w-48 h-12 rounded-lg font-semibold text-sm tracking-wider ml-24"
              style={{
                color: "#C3A2F5",
                border: "1px solid #C3A2F5",
              }}
              onClick={(event) => handleFinishLesson(event, not)}
              type="submit">
              {isLoadingSubmit ? (
                <SpinnerPurple className="m-auto animate-spin" style={{ width: "25px", height: "30px" }} />
              ) : (
                "Confirm"
              )}
            </button>
            <button
              className="w-48 h-12 rounded-lg font-semibold text-sm tracking-wider ml-24 mt-3"
              style={{
                color: "#C3A2F5",
                border: "1px solid #C3A2F5",
              }}
              onClick={() => {
                setNot(true);
                setIsInvalidFeedback(false);
                setIsFeedback(false);
                setFeedback("");
                // setCottar(2);
              }}
              type="button">
              Cancel
            </button>
          </div>
        </form>
      </div>
      <div
        className="flex flex-col justify-center sm:pl-8 pr-6 mb-7 mt-7 items-center"
        style={{
          borderRight: "1px solid #F0F0F0",
        }}>
        <button
          className="flex flex-col"
          onClick={() => handleComponent(<DrawerProfile setShowSlide={setShowSlide} uuid={uuidStudent} />)}>
          <img
            src={imageUrl ?? process.env.REACT_APP_PROFILE_AVATAR_URL}
            alt="foto do professor(a)"
            className="rounded-full self-center w-12 h-12 sm:w-20 sm:h-20"
          />
          <div className="mt-2 text-center" style={{ width: "97%" }}>
            <h1
              className="text-base"
              style={{
                color: "#1A1738",
              }}>
              {name}
            </h1>
          </div>
        </button>
      </div>
      <div className="sm:pl-11 pr-4 sm:pr-0 pl-4 mb-7 mt-7 flex flex-col justify-between">
        <p
          className="sm:text-base text-sm mt-1"
          style={{
            color: "#66647A",
          }}>
          {cottar === 1 && (studentNote ?? t("no_notes"))}
          {cottar === 2 && (studentNote ?? t("no_notes"))}
          {cottar === 3 && (teacherNote ?? t("no_notes"))}
        </p>
        {timezonedDatetime && (
          <div className="flex mt-3 sm:justify-self-end flex-col sm:flex-row">
            <p className="sm:text-base text:sm capitalize" style={{ color: "#1A1738" }}>
              {getDayOfTheWeekDayMonth(timezonedDatetime)}
            </p>
            <p className="sm:text-base text-sm sm:ml-16" style={{ color: "#1A1738" }}>
              Início {start} / Término {finish}
            </p>
          </div>
        )}
      </div>

      {cottar === 1 && (
        <div className="pr-6 flex flex-col justify-center items-center">
          <button
            id="go-class-button"
            className="w-52 h-12 rounded-lg font-semibold text-sm tracking-wider"
            disabled={!teacherMeetingUrl}
            onClick={() => window.open(teacherMeetingUrl, "_blank")}>
            Ir para aula
          </button>

          <button
            className="mt-4 text-sm p-2 px-5"
            style={{
              background: "rgba(255, 0, 0, 0.3)",
              borderRadius: "50px",
              color: "#B81212",
              fontWeight: 400,
            }}
            onClick={() =>
              handleComponent(
                <DrawerDetailsCancel
                  setShowSlide={setShowSlide}
                  uuid={uuid}
                  userUuid={uuidStudent}
                  dateTime={datetime}
                  refresh={refresh}
                  type={minutesLesson}
                />,
              )
            }>
            Cancel class
          </button>
        </div>
      )}

      {cottar === 2 && (
        <div className="pr-6 flex flex-col justify-center items-center text-center">
          <p
            className="font-semibold text-sm tracking-wider"
            style={{
              color: "#C3A2F5",
            }}>
            Essa aula foi concluída?
          </p>
          <div className="flex mt-3">
            <button
              className="w-20 h-12 rounded-lg font-semibold text-sm tracking-wider"
              style={{
                color: "#C3A2F5",
                border: "1px solid #C3A2F5",
              }}
              onClick={() => setIsFeedback(!isFeedback)}>
              Sim
            </button>

            <button
              className="w-20 h-12 rounded-lg font-semibold text-sm tracking-wider ml-3"
              style={{
                color: "#C3A2F5",
                border: "1px solid #C3A2F5",
              }}
              onClick={() => {
                setIsFeedback(!isFeedback);
                setNot(!not);
              }}>
              Não
            </button>
          </div>
        </div>
      )}

      {cottar === 3 && (
        <div className="pr-6 flex flex-col justify-center items-center text-center">
          <CheckMark className="w-7 sm:w-full" />
        </div>
      )}
    </div>
  );
};

Class.propTypes = {
  cottar: PropTypes.number,
  setCottar: PropTypes.func,
  refresh: PropTypes.func,
  uuid: PropTypes.string,
  uuidStudent: PropTypes.string,
  studentNote: PropTypes.string,
  teacherNote: PropTypes.string,
  datetime: PropTypes.string,
  name: PropTypes.string,
  imageUrl: PropTypes.string,
  minutesLesson: PropTypes.string,
  isLastRecord: PropTypes.bool,
  isTestClass: PropTypes.bool,
  isSoldTestClass: PropTypes.bool,
  teacherMeetingUrl: PropTypes.string,
};

export default Class;
