import * as url from "url";

const allowedUtms = ["utm_source", "utm_medium", "utm_campaign", "utm_content", "utm_term", "utm_id"];

const filterValidUtms = (utms) =>
  Object.keys(utms)
    .filter((k) => allowedUtms.includes(k))
    .reduce((o, k) => {
      o[k] = utms[k];
      return o;
    }, {});

export const utm = (link, sep) => {
  const parsed = url.parse(unescape(link));
  const query = parsed.query || "";
  sep = sep || "&";
  const utmRegx = new RegExp("((utm_[^=]+)=([^\\" + sep + "]+))", "gi");
  const matched = query.match(utmRegx) || [];

  return (
    matched.reduce((o, u) => {
      const kV = u.split("=");
      o[kV[0]] = kV[1];
      return o;
    }, {}) || {}
  );
};

export const strict = (link, sep) => {
  try {
    const utms = utm(link, sep);
    return filterValidUtms(utms);
  } catch (error) {
    return null;
  }
};

export const build = (link, utms, isStrict) => {
  const parsed = url.parse(unescape(link));
  parsed.search = undefined;
  parsed.query = isStrict ? filterValidUtms(utms) : utms;

  return url.format(parsed);
};
