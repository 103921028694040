/* eslint-disable jsx-a11y/no-onchange */
import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";

import { CalendarClock } from "../../../assets/svgs/index";
import useRequest from "../../../hooks/useRequest";
import months from "../../../utils/months";
import { monthNumberToName } from "../../../utils/utils";
import ButtonSvg from "../ButtonSvg/ButtonSvg";

const OurLessons = ({ lessonType }) => {
  const [showPeriodFilter, setShowPeriodFilter] = useState(false);
  const [appointmentsCount, setAppointmentsCount] = useState(0);
  const [year] = useState(new Date().getFullYear());
  const [month, setMonth] = useState(new Date().getMonth() + 1);

  const { request } = useRequest();

  const getAppointmentsCount = async () => {
    const response = await request("GET", `/teacher/getStatistics?month=${month}&year=${year}`);
    setAppointmentsCount(lessonType === "50" ? response.FIFTY : response.TWENTY_FIVE);
  };

  const refreshTime = 180000; // 3 minutos
  useEffect(() => {
    getAppointmentsCount();

    const intervalId = setInterval(getAppointmentsCount, refreshTime);
    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    getAppointmentsCount();
  }, [month]);

  return (
    <>
      <div className="flex">
        <div className="flex justify-between flex-1 bg-white rounded-xl shadow-md">
          <div className="flex flex-col m-5 justify-between">
            <h2>Aulas de {lessonType} min</h2>
            <h2 className="text-sm font-green font-semibold mr-2 break-words">
              Em {monthNumberToName(month)} de {year} você fez{" "}
              <span className="text-xl font-medium text-gray-500">{appointmentsCount}</span> aulas.
            </h2>
          </div>

          <div className="flex flex-col m-5 items-center">
            {!showPeriodFilter && (
              <ButtonSvg
                clickHandler={() => setShowPeriodFilter((current) => !current)}
                className="p-3 bg-white border border-gray-300 max-h-12 mb-5">
                <CalendarClock className="w-5" />
              </ButtonSvg>
            )}
            {showPeriodFilter && (
              <div className="relative">
                <select
                  className="cursor-pointer bg-transparent"
                  onChange={({ target }) => {
                    setShowPeriodFilter(false);
                    setMonth(target.value);
                  }}>
                  <option selected disabled value="">
                    Month
                  </option>
                  {months.map(({ label, value }, index) => (
                    <option key={index} value={value}>
                      {label}
                    </option>
                  ))}
                </select>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

OurLessons.propTypes = {
  lessonType: PropTypes.string,
};

export default OurLessons;
