import { Speaker, SpinnerWhite } from "assets/svgs";
import useRequest from "hooks/useRequest";
import PropTypes from "prop-types";
import React, { useState, useRef } from "react";
import { urltoFile } from "utils/file";

import * as S from "./styles.module.css";

const TTSButton = ({ phrase }) => {
  const audioRef = useRef();

  const [isLoading, setLoading] = useState(false);
  const { request } = useRequest();
  const [audio, setAudio] = useState();

  const handleClick = async () => {
    // Prevenir multiplas requisições
    if (isLoading) {
      return;
    }

    setLoading(true);

    const text = {
      text: phrase,
    };

    const { result } = await request("POST", "/tts/textToSpeech", text);
    setLoading(false);

    const file = await urltoFile("data:audio/ogg;base64, " + result, "audio.ogg", "audio/mp3");

    const fileUrl = URL.createObjectURL(file);

    setAudio(fileUrl);

    audioRef.current.play();
  };

  return (
    <div className={S.wrapper}>
      <button onClick={handleClick} className={S.button} type="button">
        {isLoading && <SpinnerWhite className="w-6 m-auto animate-spin" />}

        {!isLoading && <Speaker />}
      </button>

      <span className={S.text}>{phrase}</span>

      <audio className="hidden" ref={audioRef} id="beep" src={audio}>
        <track kind="captions" />
      </audio>
    </div>
  );
};

TTSButton.propTypes = {
  phrase: PropTypes.string.isRequired,
};

export default TTSButton;
