import PropTypes from "prop-types";
import React from "react";
import InputMask from "react-input-mask";

import "./InputTwo.css";

const InputTwo = ({
  className,
  label,
  name,
  placeholder,
  type,
  value,
  onChange,
  width = "100%",
  mask,
  error,
  ...props
}) => {
  return (
    <div style={{ width: "100%" }}>
      <div className={`${className} flex flex-col`}>
        <label className="generic-input__label" style={{ width }} htmlFor={name}>
          {label}
        </label>
        <div className="flex flex-row justify-between">
          {!mask ? (
            <input
              className="generic-input"
              style={{ color: "#95939d" }}
              name={name}
              placeholder={placeholder}
              type={type}
              value={value}
              onChange={({ target }) => onChange(target.value)}
              {...props}
            />
          ) : (
            <InputMask mask={mask} value={value} onChange={({ target }) => onChange(target.value)} {...props}>
              {(inputProps) => (
                <input
                  {...inputProps}
                  className="w-full generic-input"
                  style={{ color: "#95939d" }}
                  name={name}
                  placeholder={placeholder}
                  type={type}
                  {...props}
                />
              )}
            </InputMask>
          )}
        </div>
      </div>
      {error && <p className="text-red-500 text-sm ml-1">{error}</p>}
    </div>
  );
};

InputTwo.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string.isRequired,
  name: PropTypes.string,
  mask: PropTypes.string,
  placeholder: PropTypes.string,
  error: PropTypes.string,
  type: PropTypes.string,
  value: PropTypes.string,
  min: PropTypes.number,
  max: PropTypes.number,
  onChange: PropTypes.func.isRequired,
  width: PropTypes.string,
};

export default InputTwo;
