const prefix = {
  STUDENT: "[Aluno]",
  TEACHER: "[Professor]",
  LEAD: "[Lead]",
};

module.exports.tawkTo = function (user) {
  try {
    if (!window) {
      throw new Error("DOM is unavailable");
    }

    window.Tawk_API = window.Tawk_API || {};
    window.Tawk_LoadStart = new Date();

    window.Tawk_API.onChatMessageAgent = function () {
      window.Tawk_API.maximize();
    };

    window.Tawk_API.onChatMessageSystem = function () {
      window.Tawk_API.maximize();
    };

    // Verifica se mudou nome ou email
    if (user && (window.Tawk_API.visitor?.name !== user?.name || window.Tawk_API.visitor?.email !== user?.email)) {
      window.Tawk_API.visitor = {
        name: prefix[user.role] + user.name,
        email: user.email,
      };
    }

    const tawk = document.getElementById("tawkId");
    if (tawk) {
      // Prevent TawkTo to create root script if it already exists
      return window.Tawk_API;
    }

    const script = document.createElement("script");
    const tawkToId =
      user?.role === "STUDENT" ? "608317d25eb20e09cf360216/1f401a3pd" : "608317d25eb20e09cf360216/1f401a3pd";

    console.log("TAWKTO: ", user?.role === "STUDENT" ? "ALUNO" : "LEAD");

    script.id = "tawkId";
    script.async = true;
    script.src = "https://embed.tawk.to/" + tawkToId;
    script.setAttribute("crossorigin", "*");

    const first_script_tag = document.getElementsByTagName("script")[0];
    if (!first_script_tag || !first_script_tag.parentNode) {
      throw new Error("DOM is unavailable");
    }

    first_script_tag.parentNode.insertBefore(script, first_script_tag);
  } catch (error) {
    console.error("TAWKTO_ERROR", error);
  }
};

module.exports.setAttributes = function (user) {
  try {
    window.Tawk_API.setAttributes({
      name: prefix[user.role] + user.name,
      email: user.email,
    });
  } catch (error) {
    console.error("TAWKTO_ERROR", error);
  }
};
