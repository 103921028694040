import GirlBanner3 from "assets/imgs/girl-banner-3.png";
import "./BannerPrivateClassesRect.css";
import { useIsStudent } from "hooks/useIsStudent";
import React from "react";
import { HiOutlineArrowNarrowRight } from "react-icons/hi";
import { logAnalyticsEvent } from "utils/firebase-analytics";

const BannerPrivateClassesRect = () => {
  const { handleRedirect } = useIsStudent();

  return (
    <div
      className="banner-private-classes"
      style={{ backgroundImage: `url(${GirlBanner3})` }}
      onClick={() => {
        logAnalyticsEvent("banner_click_aulas_particulares");
        handleRedirect("privateClasses", "/professores");
      }}>
      <span>
        Faça aulas <span>particulares</span> online
      </span>

      <button>
        Ver professores
        <HiOutlineArrowNarrowRight />
      </button>
    </div>
  );
};

export default BannerPrivateClassesRect;
