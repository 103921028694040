import { utcToZonedTime } from "date-fns-tz";
import PropTypes from "prop-types";
import React, { useContext, useState, useEffect } from "react";

import { UserContext } from "../../../../context/UserContext";
import {
  getHoursAndMinutes,
  getDayOfWeek,
  getDayAbrvMonthYear,
  getDayOfWeekNumber,
  formatIso,
} from "../../../../utils/dates";

import "./MyLessons.css";

const HistoryClassRow = ({ className, onClick, nameTeacher, imageTeacher, date, uuid, itemUuid }) => {
  console.log("HistoryClassRow -> date", date);
  const { user } = useContext(UserContext);
  const [zonedDate, setZonedDate] = useState(date);

  useEffect(() => {
    if (user?.timezone) {
      const zonedDate = formatIso(utcToZonedTime(date, user.timezone));
      setZonedDate(zonedDate);
    }
  }, [user?.timezone]);

  return (
    <div
      onClick={onClick}
      className={`${className} ${
        uuid === itemUuid ? "bg-white" : "bg-transparent"
      } py-6 grid grid-cols-2 border-b-2 border-gray-200 items-center ${
        uuid === itemUuid ? "rounded-xl" : ""
      } cursor-pointer items-center`}
      style={{
        boxShadow: `${
          uuid === itemUuid ? "0px 2px 4px -2px rgba(24, 39, 75, 0.12), 0px 4px 4px -2px rgba(24, 39, 75, 0.08)" : ""
        }`,
      }}>
      <div className="flex items-center">
        <img
          src={imageTeacher ?? process.env.REACT_APP_PROFILE_AVATAR_URL}
          alt="professora"
          className="rounded-3xl w-12 h-12 ml-6"
        />
        <div className="ml-2">
          <p className="text-base">{nameTeacher}</p>
        </div>
      </div>
      <div className="justify-self-end xl:justify-self-start mr-4">
        <p
          className="md:text-sm 2xl:text-base"
          style={{
            color: "#1A1738",
          }}>
          <span className="capitalize">
            {getDayOfWeek(zonedDate)}
            <span className="lowercase">{getDayOfWeekNumber(zonedDate) < 6 ? "-feira" : ""}, </span>
          </span>
          <span className="capitalize">{getDayAbrvMonthYear(zonedDate)}</span>
        </p>
        <p className="text-xs text-gray-500 font-bold uppercase">{getHoursAndMinutes(zonedDate)}</p>
      </div>
    </div>
  );
};

HistoryClassRow.propTypes = {
  className: PropTypes.bool,
  onClick: PropTypes.func,
  nameTeacher: PropTypes.string,
  imageTeacher: PropTypes.string,
  date: PropTypes.string,
  itemUuid: PropTypes.string,
  uuid: PropTypes.string,
};

export default HistoryClassRow;
