import propTypes from "prop-types";
import React from "react";

import "./CourseProgressBar.css";

const CourseProgressBar = (props) => {
  const {
    showDetails,
    showPercentage,
    fillColor,
    barColor,
    barHeight,
    totalSteps,
    doneSteps = 0,
    detailsColor,
  } = props;

  const percentage = totalSteps ? (doneSteps / totalSteps) * 100 : 0;

  return (
    <div className="progress__container">
      {showDetails && (
        <span className="progress__details" style={{ color: detailsColor }}>
          {doneSteps}/{totalSteps}
        </span>
      )}

      <div className="progress__bar" style={{ background: barColor, height: barHeight }}>
        {percentage > 0 && (
          <div className="progress__fill" style={{ width: `${percentage}%`, background: fillColor }} />
        )}
      </div>

      {showPercentage && (
        <span className="progress__percentage" style={{ color: detailsColor }}>
          {percentage.toFixed(0)}%
        </span>
      )}
    </div>
  );
};

CourseProgressBar.propTypes = {
  showDetails: propTypes.bool,
  showPercentage: propTypes.bool,
  fillColor: propTypes.string,
  barColor: propTypes.string,
  barHeight: propTypes.string,
  totalSteps: propTypes.number,
  doneSteps: propTypes.number,
  detailsColor: propTypes.string,
};

export default CourseProgressBar;
