import propTypes from "prop-types";
import React from "react";

import icon from "../../../assets/imgs/chat-information.png";

import "./Warning.css";

function Warning({ children }) {
  return (
    <section className="warning my-2">
      <img src={icon} alt="warning icon" />
      <p>{children}</p>
    </section>
  );
}

export default Warning;

Warning.propTypes = {
  children: propTypes.any,
};
