import PropTypes from "prop-types";
import React from "react";
import { Link } from "react-router-dom";

import { Button } from "../Form";

const Teacher = ({ name, description, photoUrl, bannerUrl, isSchedule, uuid, isTestClass }) => {
  const teacherLink = `professores/perfil/${uuid}${isTestClass ? "?aulaTeste=true" : ""}`;

  return (
    <div className="text-center rounded-2xl shadow bg-white">
      <div>
        <Link to={teacherLink} className="cursor-pointer">
          <img
            style={{ height: "81px", borderRadius: "16px 16px 0 0" }}
            src={bannerUrl ?? process.env.REACT_APP_PROFILE_BANNER_URL}
            alt="professora"
            className="w-full"
          />

          <div className="flex flex-1 justify-center">
            <img
              style={{ width: "110px", height: "110px" }}
              src={photoUrl ?? process.env.REACT_APP_PROFILE_AVATAR_URL}
              alt="professora"
              className="-mt-12 rounded-full border-4 border-white"
            />
          </div>
        </Link>
        <div className="p-1">
          <Link to={teacherLink}>
            <p className="text-xl hover-aqua-green line-clamp-1">{name}</p>
          </Link>

          <div style={{ minHeight: "104px" }} className="px-5 text-center">
            <div>
              <p className="text-gray-500 line-clamp-4 text-sm mt-4">
                {description ?? "Atualmente esse professor não tem descrição"}
              </p>
              <Link to={teacherLink} className="font-green text-bold text-sm">
                Saiba mais
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="px-8 py-4">
        {isSchedule ? (
          <Link to={`professores/perfil/${uuid}?agenda=true${isTestClass ? "&aulaTeste=true" : ""}`}>
            <Button className="aqua-green text-sm p-4 text-white" style={{ alignSelf: "flex-end" }}>
              Agendar aula
            </Button>
          </Link>
        ) : (
          <Link to={teacherLink}>
            <Button className="aqua-green text-sm p-4 text-white" style={{ alignSelf: "flex-end" }}>
              ver perfil
            </Button>
          </Link>
        )}
      </div>
    </div>
  );
};

Teacher.propTypes = {
  name: PropTypes.string,
  description: PropTypes.string,
  photoUrl: PropTypes.string,
  uuid: PropTypes.string,
  bannerUrl: PropTypes.string,
  isSchedule: PropTypes.bool,
  isTestClass: PropTypes.bool,
};

export default Teacher;
