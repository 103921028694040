import { Remove, Help } from "assets/svgs";
import React from "react";
import { useHistory } from "react-router-dom";

import * as S from "./styles.module.css";

const CardHeaderTransparent = () => {
  const history = useHistory();

  const handleRedirect = () => {
    history.push("/");
  };

  const handleOpenTawkTo = () => {
    const config = window.Tawk_API;
    config.toggle();
  };

  return (
    <div className={S.wrapper}>
      <button className={S.headerButton} onClick={handleRedirect}>
        <Remove className={S.closeButton} />
      </button>

      <button className={S.headerButton} onClick={handleOpenTawkTo}>
        <Help />
      </button>
    </div>
  );
};

export default CardHeaderTransparent;
