import React, { useState, useContext } from "react";
import { Link } from "react-router-dom";

import { Input, PurpleButton } from "../../_Shared/Form/index";
import { PurpleArrow } from "../../../assets/svgs/index";
import { ToastContext } from "../../../context/ToastContext";
import "./PasswordRecovery.css";
import useForm from "../../../hooks/useForm";
import useRequest from "../../../hooks/useRequest";
import PasswordRecoverySuccess from "../PasswordRecoverySuccess/PasswordRecoverySuccess";

const PasswordRecovery = () => {
  const { showToast } = useContext(ToastContext);
  const [success, setSuccess] = useState(false);
  const { request, loading } = useRequest();

  const email = useForm("email");

  const validateForm = () => {
    email.validate();
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    validateForm();

    if (email.validate()) {
      const user = {
        email: email.value,
      };

      const { error } = await request("POST", "/user/requestPasswordReset", user, false, false);

      if (error && error.statusCode === 404) {
        return showToast({ message: "E-mail não encontrado", type: "error" });
      }

      if (error) {
        console.error(error.message);
        showToast({ message: error.message, type: "error" });
        return;
      }

      setSuccess(true);
    }
  };

  if (success) {
    return <PasswordRecoverySuccess />;
  }

  return (
    <div className="recoveryPassword py-5">
      <div className="flex items-center mb-5">
        <Link to="/">
          <PurpleArrow className="mr-2" />
        </Link>
        <Link to="/">
          <div className="text-left font-low text-purple-500">Voltar</div>
        </Link>
      </div>

      <h6 className="my-5">Recuperar senha</h6>

      <p className="my-5">Digite seu e-mail para recuperar sua conta</p>

      <form onSubmit={handleSubmit}>
        <Input className="my-2" label="E-mail" name="email" {...email} />
        <PurpleButton type="submit" isLoading={loading}>
          Continuar
        </PurpleButton>
      </form>
    </div>
  );
};

export default PasswordRecovery;
