import React, { useContext } from "react";
import { Link } from "react-router-dom";

import { MovieVideo } from "../../../assets/svgs/index";
import { MovieVideoRed } from "../../../assets/svgs/index";
import { UserContext } from "../../../context/UserContext";

import "./RemainingClasses.css";

const RemainingClasses = () => {
  const { credits } = useContext(UserContext);

  return (
    <>
      {credits?.planType && credits?.planType !== "CONVERSATION" && (
        <div
          className={`relative border shadown-lg text-black rounded-2xl
         ${credits?.available > 0 ? "remainingClasses light-purple-2" : "bgExpiredPlan"}`}>
          <div
            className={
              credits?.available > 0 ? "borderExpiredPlanPurple rounded" : "borderExpiredPlanRed rounded"
            }></div>
          <div
            style={{ width: "69px", height: "58px" }}
            className={credits?.available > 0 ? "ml-4 light-purple rounded-full" : "ml-4 bgPlaySVG rounded-full"}>
            {credits?.available > 0 ? (
              <MovieVideo className="w-8 mt-3 mx-auto" />
            ) : (
              <MovieVideoRed className="w-8 mt-3 mx-auto" />
            )}
          </div>
          <div className="px-2 sm:w-96">
            <div className="text-sm sm:text-base">Você possui {credits?.available} aulas restantes</div>
            <p className="text-xs font-gray mt-1">
              Plano de <span className="font-bold">{credits?.planLessons} aulas</span> de{" "}
              <span className="font-bold">{credits?.planType == "FIFTY" ? "50 min" : "25 min"}</span> por mês,{" "}
              {credits?.available > 0 ? (
                <Link to="/assinatura">
                  <button className="text-purple-800 font-bold">Saiba mais</button>
                </Link>
              ) : (
                <Link to="/assinatura">
                  <button className="text-red-600 font-bold">Saiba mais</button>
                </Link>
              )}
            </p>
          </div>
        </div>
      )}

      {credits?.planType && credits?.planType === "CONVERSATION" && (
        <div className="relative border shadown-lg text-black rounded-2xl remainingClasses light-purple-2">
          <div className="borderExpiredPlanPurple rounded"></div>
          <div style={{ width: "69px", height: "58px" }} className="ml-4 light-purple rounded-full">
            <MovieVideo className="w-8 mt-3 mx-auto" />
          </div>
          <div className="px-2 sm:w-96">
            <div className="text-sm sm:text-base">Plano Live Class ativo!</div>
            <p className="text-xs font-gray mt-1">Plano de aulas ao vivo</p>
          </div>
        </div>
      )}
    </>
  );
};

export default RemainingClasses;
