import React from "react";

import {
  HeaderLevelBeginner,
  HeaderLevelElementary,
  HeaderLevelPreIntermediate,
  HeaderLevelIntermediate,
  HeaderLevelAdvanced,
  HeaderLevelUpperIntermediate,
} from "../assets/svgs";

export const getLevelIcon = (currentLevel) =>
  ({
    "A1 - Beginner": <HeaderLevelBeginner />,
    "A2 - Elementary": <HeaderLevelElementary />,
    "B1 - Pre-Intermediate": <HeaderLevelPreIntermediate />,
    "B2 - Intermediate": <HeaderLevelIntermediate />,
    "C1 - Upper-Intermediate": <HeaderLevelUpperIntermediate />,
    "C2 - Advanced": <HeaderLevelAdvanced />,
  }[currentLevel]);

export const getLevelBackgroundColor = (currentLevel) =>
  ({
    "A1 - Beginner": "#733EB1",
    "A2 - Elementary": "#6AAFDD",
    "B1 - Pre-Intermediate": "#EB5757",
    "B2 - Intermediate": "#EC9900",
    "C1 - Upper-Intermediate": "#2D9974",
    "C2 - Advanced": "#CF8BFB",
  }[currentLevel]);

export const getLevelBadgeBackgroundColor = (currentLevel) =>
  ({
    "A1 - Beginner": "#7C49B6",
    "A2 - Elementary": "#55A6DD",
    "B1 - Pre-Intermediate": "#E52929",
    "B2 - Intermediate": "#B87700",
    "C1 - Upper-Intermediate": "#227257",
    "C2 - Advanced": "#BA57F9",
  }[currentLevel]);

export const getNextLevel = (currentLevel) =>
  ({
    "A1 - Beginner": "A2 - Elementary",
    "A2 - Elementary": "B1 - Pre-Intermediate",
    "B1 - Pre-Intermediate": "B2 - Intermediate",
    "B2 - Intermediate": "C1 - Upper-Intermediate",
    "C1 - Upper-Intermediate": "C2 - Advanced",
    "C2 - Advanced": "FINALIZADO", // TODO: Ver esse fallback
  }[currentLevel]);

export const numberToLevel = (number) => {
  switch (number) {
    case 0:
      return "A1 - Beginner";
    case 1:
      return "A2 - Elementary";
    case 2:
      return "B1 - Pre-Intermediate";
    case 3:
      return "B2 - Intermediate";
    case 4:
      return "C1 - Upper-Intermediate";
    case 5:
      return "C2 - Advanced";
    case 6:
      return "C2 - Advanced";
    default:
      return "número inválido";
  }
};

export const levelToNumber = (level) => {
  switch (level) {
    case "A1 - Beginner":
      return 0;
    case "A2 - Elementary":
      return 1;
    case "B1 - Pre-Intermediate":
      return 2;
    case "B2 - Intermediate":
      return 3;
    case "C1 - Upper-Intermediate":
      return 4;
    case "C2 - Advanced":
      return 5;
    default:
      return 0; // cuidado
  }
};

const levels = [
  "A1 - Beginner",
  "A2 - Elementary",
  "B1 - Pre-Intermediate",
  "B2 - Intermediate",
  "C1 - Upper-Intermediate",
  "C2 - Advanced",
];

export const getLevelIndexByLevelName = (level) => {
  if (!level) {
    return;
  }

  return levels.indexOf(level);
};

export const getLevelNameByLevelIndex = (index) => {
  if (index === null || index === undefined) {
    throw new Error("index is null");
  }

  if (index < 0 || index >= levels.length) {
    throw new Error("Level index is out of range");
  }

  return levels[index];
};
