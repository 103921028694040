import PropTypes from "prop-types";
import React from "react";
import PhoneInput from "react-phone-number-input";

import "./InputPhone.css";

const InputPhone = ({ error, placeholder, onChange, value, onBlur, errorClass = "", style }) => {
  return (
    <>
      <PhoneInput
        className={`${
          error && "border-red-300 focus:border-red-300"
        } rounded-lg phone-input px-3 py-4 my-2 border sm:text-sm`}
        defaultCountry="BR"
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        style={style}
      />
      {error && <p className={`text-red-600 ${errorClass}`}>{error}</p>}
    </>
  );
};

InputPhone.propTypes = {
  error: PropTypes.string,
  value: PropTypes.string,
  placeholder: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  onBlur: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  rounded: PropTypes.string,
  errorClass: PropTypes.string,
  style: PropTypes.object,
};

export default InputPhone;
