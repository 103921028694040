import React from "react";
import { Switch, Route } from "react-router-dom";

import Lesson from "../_SharedPages/Lesson/Lesson";
import LessonsList from "../_SharedPages/LessonsList/LessonsList";
import Materials from "../_SharedPages/Materials/Materials";
import MyEvents from "../_SharedPages/MyEvents/MyEvents";
import PrivacyPolices from "../_SharedPages/PrivacyPolices/PrivacyPolices";
import Profile from "../_SharedPages/Profile/Profile";
import TermsOfUse from "../_SharedPages/TermsOfUse/TermsOfUse";
import Dashboard from "../../Dashboard/Dashboard";
import NotFound from "../../NotFound/NotFound";
import TeacherSchedule from "../Teacher/TeacherSchedule/TeacherSchedule";
import Classes from "./Classes/Classes";
import IndexTeacher from "./IndexTeacher/IndexTeacher";
import { TeacherSidebarLinks } from "./TeacherSidebarLinks";

const Student = () => {
  return (
    <Dashboard userSidebarLinks={TeacherSidebarLinks}>
      <Switch>
        <Route path="/" exact component={IndexTeacher} />

        <Route path="/perfil/*" component={Profile} />
        <Route path="/termodeuso" component={TermsOfUse} />
        <Route path="/politicaPrivacidade" component={PrivacyPolices} />

        <Route path="/cronograma" component={TeacherSchedule} />

        <Route path="/material" exact component={Materials} />
        <Route path="/material/:uuid" component={LessonsList} />
        <Route path="/lesson/:uuid" component={Lesson} />

        <Route path="/eventos" component={MyEvents} />
        <Route path="/aulas" component={Classes} />
        <Route path="*" component={NotFound} />
      </Switch>
    </Dashboard>
  );
};

export default Student;
