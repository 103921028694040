import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import { useParams, useLocation } from "react-router-dom";

import { SpinnerPurple } from "../../../../assets/svgs";
import useRequest from "../../../../hooks/useRequest";
import "./TeacherProfile.css";
import Schedule from "../Schedule/Schedule";
import TeacherIntroduction from "./TeacherIntroduction";

const TeacherProfile = ({ setSuccesse, setAppointmentsSuccesseArray }) => {
  const [key, setKey] = useState(2);
  const [loading, setLoading] = useState(true);
  const [isTestClass, setIsTestClass] = useState(false);
  const [teacher, setTeacher] = useState({});
  const { uuid } = useParams();
  const { request } = useRequest();
  const { search } = useLocation();

  const handleKey = (key) => {
    setKey(key);
  };

  const fetchTeacher = async () => {
    setLoading(true);
    const response = await request("GET", "/teacher/read/" + uuid);
    setTeacher(response);
    setLoading(false);
  };

  // Se abriu passando ?agenda=true abre direito na agenda do professor.
  const handleQuery = () => {
    const openSchedule = new URLSearchParams(search).get("agenda");
    openSchedule && handleKey(3);

    setIsTestClass(!!new URLSearchParams(search).get("aulaTeste"));
  };

  useEffect(() => {
    fetchTeacher();
    handleQuery();
  }, []);

  return (
    <>
      {loading ? (
        <SpinnerPurple className="w-10 m-auto mt-5 animate-spin" />
      ) : (
        <div>
          <div className="professorCard text-center rounded-3xl shadow bg-white text-black">
            <img
              src={teacher?.user?.bannerUrl ?? process.env.REACT_APP_PROFILE_BANNER_URL}
              style={{ maxHeight: "170px" }}
              alt="professora"
              className="w-full rounded-t-2xl teacher-banner"
            />
            <div className="flex flex-1 justify-center">
              <img
                src={teacher?.user?.profilePictureUrl ?? process.env.REACT_APP_PROFILE_AVATAR_URL}
                alt="professora"
                className="w-36 h-36 rounded-full"
                style={{
                  border: "11px solid #FFFFFF",
                  marginTop: "-6.5rem",
                  backgroundColor: "white",
                }}
              />
            </div>
            <div className="pb-4">
              <p className="text-xl">{teacher?.user?.name}</p>
            </div>
            <div className="flex justify-center items-center pt-4">
              <button
                className={`borderBotton pb-6 w-[140px] md:w-64 ${key == 2 ? "selectedBotton" : ""}`}
                value={2}
                onClick={() => handleKey(2)}>
                Introdução
              </button>
              <button
                className={`borderBotton pb-6 w-[140px] md:w-64 ${key == 3 ? "selectedBotton" : ""}`}
                value={3}
                onClick={() => handleKey(3)}>
                Agenda
              </button>
            </div>
          </div>
          <div className="mt-10">
            {key === 2 && <TeacherIntroduction teacher={teacher} uuid={uuid} />}
            {key === 3 && (
              <Schedule
                showRemainingClasses={true}
                setSuccesse={setSuccesse}
                setAppointment={setAppointmentsSuccesseArray}
                isTestClass={isTestClass}
              />
            )}
          </div>
        </div>
      )}
    </>
  );
};

TeacherProfile.propTypes = {
  name: PropTypes.string,
  description: PropTypes.string,
  setSuccesse: PropTypes.func,
  setAppointmentsSuccesseArray: PropTypes.func,
};

export default TeacherProfile;
