import PropTypes from "prop-types";
import React from "react";

import { BrasilFlag, RussianFlag, SerbianFlag, SpainFlag, UnitedStatesFlag } from "../../../../assets/svgs/index";
import "./LanguageCheckbox.css";

const LanguageCheckbox = ({ value, flag, text, error, elements, setElements }) => {
  const flagNation = (flag) => {
    if (flag === "brasil") {
      return <BrasilFlag className="m-1" />;
    }
    if (flag === "russian") {
      return <RussianFlag className="m-1" />;
    }
    if (flag === "serbian") {
      return <SerbianFlag className="m-1" />;
    }
    if (flag === "spain") {
      return <SpainFlag className="m-1" />;
    }
    if (flag === "eua") {
      return <UnitedStatesFlag className="m-1" />;
    }
  };

  const handleclick = (value, newValue, setValue) => {
    if (value?.includes(newValue)) {
      setValue(value?.filter((element) => element !== newValue));
      return;
    }

    if (value) {
      setValue([...value, newValue]);
    } else {
      setValue([newValue]);
    }
  };

  return (
    <>
      <div className="flex m-1">
        <button
          type="button"
          className={elements?.includes(value) ? "Button-change rounded-lg flex" : "flex"}
          onClick={() => handleclick(elements, value, setElements)}>
          {flagNation(flag)}
          <div className="ml-1 mt-2 text-xs pr-3" style={{ width: "70px" }}>
            {text}
          </div>
        </button>
      </div>
      {error && <p className="text-red-600">{error}</p>}
    </>
  );
};

LanguageCheckbox.propTypes = {
  name: PropTypes.string,
  text: PropTypes.string,
  error: PropTypes.string,
  flag: PropTypes.string,
  value: PropTypes.string,
  setElements: PropTypes.func,
  elements: PropTypes.array,
};

export default LanguageCheckbox;
