import React, { useContext } from "react";

import { WorkingGirl, CloseX } from "../../../../assets/svgs/index";
import { SlideContext } from "../../../../context/SlideContext";
import { PurpleButton } from "../../Form/index";

const ChangePlanSuccess = () => {
  const { setShowSlide } = useContext(SlideContext);

  return (
    <div style={{ width: "700px" }} className="h-full flex flex-col">
      <div className="flex items-center justify-between">
        <h1 className="text-xl font-gray font-medium">Seu plano foi alterado com sucesso!</h1>
        <button>
          <CloseX onClick={() => setShowSlide(false)} />
        </button>
      </div>
      <div className="flex-1">
        <p className="text-xl font-gray font-medium">Bons estudos!</p>
        <WorkingGirl className="w-full h-full" />
      </div>
      <div className="grid grid-cols-3">
        <PurpleButton onClick={() => setShowSlide(false)}>Fechar</PurpleButton>
      </div>
    </div>
  );
};

export default ChangePlanSuccess;
