import PropTypes from "prop-types";
import React, { useEffect, useRef } from "react";
import { AiOutlineClose as CloseIcon } from "react-icons/ai";
import { logAnalyticsEvent } from "utils/firebase-analytics";

import BannerImage from "../../../../assets/imgs/banners/NotebookMobile.png";
import { ReactComponent as WhatsAppIcon } from "../../../../assets/svgs/WhatsAppIcon.svg";
import useOnClickOutside from "../../../../hooks/useOnClickOutside";
import * as S from "./styles.module.css";

const TestConversationMobile = ({ handleHideBanner }) => {
  const ref = useRef();

  useOnClickOutside(ref, () => handleHideBanner());

  const onClick = () => {
    logAnalyticsEvent("CLICK_BANNER_TEST_CONVERSATION_MOBILE");

    const message = "Olá, gostaria de agendar minha aula grátis do grupo de conversação!";
    const messageEncoded = encodeURI(message);

    window.open(`https://api.whatsapp.com/send?phone=5511958565055&text=${messageEncoded}`, "_blank");
  };

  useEffect(() => {
    logAnalyticsEvent("SHOW_BANNER_TEST_CONVERSATION_MOBILE");
  }, []);

  return (
    <div className={S.Backdrop}>
      <div className={S.Container}>
        <div ref={ref}>
          <div style={{ position: "relative", width: "100%" }}>
            <img className={S.Image} src={BannerImage} alt="bannerImage" />

            <div className={S.WrapperButtonClose}>
              <button className={S.ButtonClose} onClick={handleHideBanner}>
                <CloseIcon size={20} color="#6A51B2" />
              </button>
            </div>
          </div>

          <div className={S.Wrapper}>
            <p className={S.Title}>
              Participe do grupo de conversação <span className={S.Bold}>GRÁTIS</span>
            </p>

            <p className={S.Description}>
              Agende e participe do grupo de conversação grátis para testar a plataforma da EnglishBay e comece a sua
              jornada de aprendizado de inglês hoje mesmo.
            </p>

            <button onClick={onClick} className={S.Button}>
              <WhatsAppIcon width={24} height={24} />
              Agendar agora
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

TestConversationMobile.propTypes = {
  handleHideBanner: PropTypes.func.isRequired,
  variant: PropTypes.number.isRequired,
};

export default TestConversationMobile;
