import React from "react";
import { HiOutlineArrowNarrowRight } from "react-icons/hi";
import { useHistory } from "react-router-dom";
import { logAnalyticsEvent } from "utils/firebase-analytics";

import * as S from "./styles.module.css";

const Banner7DaysExpired = () => {
  const isMobile = window.innerWidth < 768;
  const history = useHistory();

  const handleClick = () => {
    history.push("/planos");
    logAnalyticsEvent("banner_click_7days_expired");
  };

  return isMobile ? (
    <div className={S.Wrapper} onClick={handleClick}>
      <div className={S.TextWrapper}>
        <p>
          O período de teste grátis <span style={{ color: "#FF826D" }}>acabou :(</span>
        </p>

        <button>
          Escolha seu plano
          <HiOutlineArrowNarrowRight size="14px" />
        </button>
      </div>
    </div>
  ) : (
    <div className={S.WrapperDesktop} onClick={handleClick}>
      <p>
        O período de teste grátis <span style={{ color: "#FF826D" }}>acabou :(</span>
      </p>

      <button>
        Escolha seu plano
        <HiOutlineArrowNarrowRight size="20px" />
      </button>
    </div>
  );
};

export default Banner7DaysExpired;
