import PropTypes from "prop-types";
import React from "react";

const Button = ({ className, children, ...props }) => {
  return (
    <button {...props} className={`w-full py-3 text-sm font-medium rounded-md ${className}`}>
      {children}
    </button>
  );
};

Button.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
};

export default Button;
