import PropTypes from "prop-types";
import React from "react";

const Photos = ({ participants, participantsCount }) => {
  return (
    <div className="flex items-center gap-3">
      <ul className="flex items-center gap-1 ml-4">
        {participants &&
          participants.map((participant, index) => (
            <li className="-ml-4" key={participant.uuid}>
              <img
                style={{ zIndex: index }}
                src={
                  participant.user.profilePictureUrl
                    ? participant.user.profilePictureUrl
                    : process.env.REACT_APP_PROFILE_AVATAR_URL
                }
                alt="professora"
                className="rounded-full w-8 h-8 object-cover"
                onError={({ currentTarget }) => {
                  currentTarget.src = process.env.REACT_APP_PROFILE_AVATAR_URL;
                }}
              />
            </li>
          ))}
      </ul>
      {participantsCount >= 5 && <span>+{participantsCount - 4}</span>}
    </div>
  );
};

Photos.propTypes = {
  participants: PropTypes.array,
  participantsCount: PropTypes.number,
};
export default Photos;
