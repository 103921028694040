export const getStatusName = (status) => {
  const statusDic = {
    pending: "Pagamento pendente",
    paid: "Pagamento concluído",
    canceled: "Pagamento não realizado",
    processing: "Processando",
  };
  return statusDic[status] ?? status;
};

export const getStatusPlanName = (status) => {
  const statusDic = {
    pending: "Pagamento pendente",
    paid: "Pagamento aprovado",
    canceled: "Plano cancelado",
    processing: "Processando",
  };
  return statusDic[status] ?? status;
};

export const getColorByStatus = (status) => {
  const dic = {
    pending: "yellow",
    paid: "green",
    canceled: "red",
    processing: "yellow",
  };

  return dic[status] ?? "yellow";
};
