import propTypes from "prop-types";
import React, { useState, useEffect, useContext } from "react";

import { SlideContext } from "../../../context/SlideContext";
import { UserContext } from "../../../context/UserContext";
import { formatDate } from "../../../utils/dates";
import { capitalize } from "../../../utils/utils";
import EventDetails from "../EventDetails/EventDetails";
import EventParticipants from "../EventParticipants/EventParticipants";
import "./EventCard.css";

const EventCard = ({ eventData = {}, setUpdateCards }) => {
  const [date, setEventDate] = useState("");
  const [schedule, setSchedule] = useState("");

  const { setShowSlide, setSlideComponent } = useContext(SlideContext);
  const { title, startDate, endDate, participants = [], participantsCount } = eventData;
  const { user } = useContext(UserContext);

  useEffect(() => {
    if (!user?.timezone) {
      return;
    }

    const formattedDate = formatDate(startDate, user.timezone);

    setEventDate(formattedDate.date);

    if (endDate) {
      const formattedEndDate = formatDate(endDate, user.timezone);
      setSchedule(`${formattedDate.hour} às ${formattedEndDate.hour}`);
      return;
    }

    setSchedule(formattedDate.hour);
  }, [user?.timezone]);

  const renderEventDetails = (event) => {
    setShowSlide(true);

    setSlideComponent(
      <EventDetails
        data={event}
        closeSlider={() => setShowSlide(false)}
        renderEventDetails={(event) => renderEventDetails(event)}
        setUpdateCards={setUpdateCards}
      />,
    );
  };

  return (
    <section
      className="event-card"
      onClick={() => {
        if (window.innerWidth >= 1024) {
          renderEventDetails(eventData);
        }
      }}>
      <div className="event-card__header">
        <span>{title}</span>
        <EventParticipants participants={participants} participantsCount={participantsCount} />
      </div>

      <div className="flex gap-8">
        <div className="event-card__info">
          <span className="event-card__info--title">Quando</span>
          <span className="event-card__info--value">{capitalize(date)}</span>
        </div>

        <div className="event-card__info">
          <span className="event-card__info--title">Horário</span>
          <span className="event-card__info--value">{schedule}</span>
        </div>
      </div>

      <button type="button" className="event-card__button" onClick={() => renderEventDetails(eventData)}>
        Ver evento
      </button>
    </section>
  );
};

export default EventCard;

EventCard.propTypes = {
  eventData: propTypes.object,
  setUpdateCards: propTypes.func,
}.isRequired;
