import GuyBanner1 from "assets/imgs/guy-banner-1.png";
import { useIsStudent } from "hooks/useIsStudent";
import React from "react";
import { HiOutlineArrowNarrowRight } from "react-icons/hi";
import "./BannerTeacher.css";
import { logAnalyticsEvent } from "utils/firebase-analytics";

const BannerTeacher = () => {
  const { handleRedirect } = useIsStudent();

  return (
    <div
      className="banner-teacher"
      style={{ backgroundImage: `url(${GuyBanner1})` }}
      onClick={() => {
        logAnalyticsEvent("banner_click_tire_suas_duvidas");
        handleRedirect(
          "whatsapp",
          "https://api.whatsapp.com/send?phone=5511958565055&text=Gostaria%20de%20tirar%20duvida%20com%20professor",
        );
      }}>
      <span>
        Tire suas <span>dúvidas</span> pelo WhatsApp
      </span>

      <button>
        Fale com um professor
        <HiOutlineArrowNarrowRight />
      </button>
    </div>
  );
};

export default BannerTeacher;
