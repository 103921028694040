import propTypes from "prop-types";
import React from "react";
import "./QuestionCard.css";
import { logAnalyticsEvent } from "utils/firebase-analytics";

function QuestionCard({ data, setter }) {
  const { title, answers } = data;

  const handleClick = (answerTitle) => {
    logAnalyticsEvent(data.analyticsEventName);
    setter(answerTitle);
  };

  return (
    <div className="question-card">
      <span className="question-title">{title}</span>

      <div className="answers">
        {answers.map(({ title: answerTitle, image, recommended }, index) => (
          <>
            {recommended && <div className="recommended-flag-mobile sm:hidden">Recomendado</div>}
            <div className="answer-card" key={index} onClick={() => handleClick(answerTitle)}>
              {recommended && <div className="recommended-flag hidden sm:block">Recomendado</div>}
              <img src={`/images/${image}`} alt="icone" />
              <span className="answer-title">{answerTitle}</span>
            </div>
          </>
        ))}
      </div>
    </div>
  );
}

export default QuestionCard;

QuestionCard.propTypes = {
  data: propTypes.object,
  onClick: propTypes.func,
}.isRequired;
