import React, { useEffect } from "react";
import { Route, Redirect } from "react-router-dom";
import { useLocation } from "react-router-dom";

import Lead from "../components/Pages/Lead/Lead";
import Student, { StudentPremium } from "../components/Pages/Student/Student";
import Teacher from "../components/Pages/Teacher/Teacher";
import { useChatbot } from "../context/ChatbotContext";
import { getCurrentToken, getUser } from "../utils/localStorage";

const locationsToShowToolTip = ["/", "/materialCards"];

const ProtectedRoute = ({ ...props }) => {
  const location = useLocation();
  const { setShowTooltip, setShowTutor } = useChatbot();

  useEffect(() => {
    window.scrollTo(0, 0);

    if (locationsToShowToolTip.includes(location.pathname)) {
      setShowTooltip(true);
      setShowTutor(true);
    } else {
      setShowTooltip(false);
    }
  }, [location]);

  if (getCurrentToken()) {
    const user = getUser();

    const isPremium = user.role === "STUDENT" && user.student?.activeSubscription?.plan?.lessonType === "CONVERSATION";

    if (user.role === "STUDENT" && !isPremium) {
      return <Route {...props} component={Student} />;
    }

    if (user.role === "STUDENT" && isPremium) {
      return <Route {...props} component={StudentPremium} />;
    }

    if (user.role === "TEACHER") {
      return <Route {...props} component={Teacher} />;
    }

    return <Route {...props} component={Lead} />;
  }

  const returnUrl = props.location.pathname + props.location.search;
  const queryReturnUrl = returnUrl && returnUrl !== "/" ? `?returnUrl=${returnUrl}` : "";

  return <Redirect to={`/auth/login${queryReturnUrl}`} />;
};

export default ProtectedRoute;
