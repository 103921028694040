import React from "react";
import { Link } from "react-router-dom";

import { LogoPurple } from "../../../assets/svgs/index";
import SwitchLanguageButton from "../SwitchLanguageButton/SwitchLanguageButton";

const AuthNavbar = () => {
  const isSmallMonitor = window.innerWidth < 1366;

  return (
    <header className={`bg-white border-b-2 border-purple-500 shadow-md ${isSmallMonitor ? "mb-2" : "mb-16"}`}>
      <div className="container mx-auto px-5 py-3">
        <div className="flex justify-between items-center">
          <div className="flex justify-start items-center">
            <Link to="/auth/login">
              <LogoPurple />
            </Link>
            <span className="ml-8 text-sm">Acessar conta</span>
          </div>
          <SwitchLanguageButton />
        </div>
      </div>
    </header>
  );
};
export default AuthNavbar;
