import propTypes from "prop-types";
import React from "react";
import "./EventParticipants.css";

const defaultPicture = process.env.REACT_APP_PROFILE_AVATAR_URL;

const EventParticipants = ({ participants, participantsCount }) => {
  return (
    <div className="event-participants">
      {participants.map((participant) => (
        <img
          key={participant.uuid}
          alt="User profile"
          className={`event-participants__image ${participants.length > 1 && "margin"}`}
          src={participant.user.profilePictureUrl || defaultPicture}
          onError={({ currentTarget }) => {
            currentTarget.src = process.env.REACT_APP_PROFILE_AVATAR_URL;
          }}
        />
      ))}

      {participantsCount > 4 && <div className="event-participants__quantity">+{participantsCount - 4}</div>}
    </div>
  );
};

export default EventParticipants;

EventParticipants.propTypes = {
  participants: propTypes.array,
}.isRequired;
