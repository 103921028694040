import React, { useState, useEffect } from "react";
import { Switch, Route, Redirect, useLocation } from "react-router-dom";

import AuthNavbar from "../_Shared/AuthNavbar/AuthNavbar";
import CardAuth from "../_Shared/Cards/CardAuth/CardAuth";
import { Banner } from "../../assets/svgs";
import BannerTeacherWithLabel from "../../components/_Shared/Teacher/BannerTeacher/BannerTeacher";
import useRequest from "../../hooks/useRequest";
import ChangePasswordRecovery from "./ChangePasswordRecovery/ChangePasswordRecovery";
import Login from "./Login/Login";
import PasswordRecovery from "./PasswordRecovery/PasswordRecovery";
import Register from "./Register/Register";

const Auth = () => {
  const location = useLocation();
  const isSmallMonitor = window.innerWidth < 768;
  const { request } = useRequest();

  const [terms, setTerms] = useState("");
  const [privacy, setPrivacy] = useState("");
  const [termsVersion, setTermsVersion] = useState(0);
  const [privacyVersion, setPrivacyVersion] = useState(0);

  useEffect(() => {
    const userType = location?.pathname.includes("/teacher/") ? "TEACHER" : "USER";

    const fetchTerms = async () => {
      const { text, version } = await request("GET", `/terms/read?kind=TERM&userType=${userType}`, null, false, false);

      if (text && version) {
        setTerms(text);
        setTermsVersion(version);
      }
    };

    const fetchPrivacy = async () => {
      const { text, version } = await request(
        "GET",
        `/terms/read?kind=PRIVACY&userType=${userType}`,
        null,
        false,
        false,
      );

      if (text && version) {
        setPrivacy(text);
        setPrivacyVersion(version);
      }
    };

    fetchTerms();
    fetchPrivacy();
  }, []);

  return (
    <>
      <AuthNavbar />
      <div className="container mx-auto sm:px-4">
        <div className="grid grid-cols-1 md:grid-cols-2">
          {!isSmallMonitor && (
            <div>
              {location?.pathname.includes("/teacher/") ? (
                <BannerTeacherWithLabel />
              ) : (
                <Banner className="w-full max-w-2xl flex-initial" />
              )}
            </div>
          )}
          <CardAuth privacy={privacy} terms={terms}>
            <Switch>
              <Route path="*/login" component={Login} />
              <Route
                path="*/register"
                render={() => (
                  <Register
                    privacy={privacy}
                    terms={terms}
                    privacyVersion={privacyVersion}
                    termsVersion={termsVersion}
                  />
                )}
              />
              <Route path="*/passwordRecovery" component={PasswordRecovery} />
              <Route path="*/changePasswordRecovery" component={ChangePasswordRecovery} />
              <Redirect to="/auth/login" />
            </Switch>
          </CardAuth>
        </div>
      </div>
    </>
  );
};
export default Auth;
