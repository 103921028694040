import React from "react";
import { Link } from "react-router-dom";

import { Password, PurpleArrow } from "../../../assets/svgs/index";

const PasswordRecoverySuccess = () => {
  return (
    <>
      <div className="max-w-md w-full mx-auto ">
        <div className="flex items-center">
          <Link to="/">
            <PurpleArrow className="mr-2" />
          </Link>
          <Link to="/">
            <div className="text-left font-low text-purple-500 justify-end">Voltar</div>
          </Link>
        </div>
        <div className="text-2xl font-low text-gray-900 mt-2 mb-2 text-left flex flex-col items-center">
          Enviamos um e-mail com detalhes de como recuperar sua senha
          <Password className="mt-4" />
        </div>
      </div>
    </>
  );
};

export default PasswordRecoverySuccess;
