import PropTypes from "prop-types";
import React from "react";

const Card = ({ children, style }) => {
  return (
    <div style={style} className="flex flex-col flex-1 bg-white rounded-xl shadow-md mr-2">
      {children}
    </div>
  );
};

Card.propTypes = {
  children: PropTypes.node.isRequired,
  style: PropTypes.object,
};

export default Card;
