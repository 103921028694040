import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import { Announcement, SpinnerPurple } from "../../../assets/svgs";
import { usePagination } from "../../../hooks/usePagination";
import useRequest from "../../../hooks/useRequest";
import EventCard from "../EventCard/EventCard";
import "./Events.css";

function Events() {
  const [events, setEvents] = useState([]);
  const [totalResults, setTotalResults] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const [isMobile, setIsMobile] = useState(false);
  const [updateCards, setUpdateCards] = useState(true);

  const { request } = useRequest();

  const paginationRange = usePagination({
    currentPage,
    totalCount: totalResults,
    pageSize: 4,
  });

  useEffect(() => {
    if (updateCards) {
      setIsLoading(true);

      request("GET", "/calendarEvent/list?perPage=4").then((response) => {
        // Pega apenas os 4 primeiros eventos
        setEvents(response.results);
        setTotalResults(response.totalResults);
        setIsLoading(false);
        setUpdateCards(false);
      });
    }
  }, []);

  useEffect(() => {
    if (window.innerWidth >= 1024) {
      setIsMobile(false);
      return;
    }

    setIsMobile(true);
  }, [window.innerWidth]);

  const changePage = (pageNumber) => {
    setIsLoading(true);

    request("GET", `/calendarEvent/list?page=${pageNumber}&perPage=4`).then((response) => {
      setEvents(response.results);
      setCurrentPage(pageNumber);
      setIsLoading(false);
    });
  };

  return (
    <section className="section__events">
      <div className="events__header">
        <div>
          <span>Agenda</span> <Link to="/eventos">Ver tudo</Link>
        </div>

        <span>
          <Announcement /> Eventos EnglishBay
        </span>
      </div>

      {(isLoading && <SpinnerPurple className="w-5 m-auto mt-6 mb-8 animate-spin" />) ||
        (isMobile && <EventCard eventData={events[0]} setUpdateCards={setUpdateCards} />) ||
        events.map((event) => <EventCard key={event.id} eventData={event} setUpdateCards={setUpdateCards} />)}

      {!isMobile && (
        <section className="flex gap-5 items-center justify-center mt-6 mb-4">
          {paginationRange.map((button) => {
            // Se o pageItem for um PONTO (DOT), renderize o caractere unicode DOTS
            if (button === "...") {
              return <div className="pagination-item dots">&#8230;</div>;
            }

            return (
              <button
                type="button"
                key={button}
                className={`events__btn-page ${currentPage === button && "events__btn-page--active"}`}
                onClick={() => changePage(button)}>
                {button}
              </button>
            );
          })}
        </section>
      )}
    </section>
  );
}

export default Events;
