import PropTypes from "prop-types";
import React from "react";

import * as S from "./styles.module.css";

const Message = ({ children, ...rest }) => {
  return (
    <div className={S.Message} {...rest}>
      {children}
    </div>
  );
};

Message.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Message;
