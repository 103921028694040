import PropTypes from "prop-types";
import React from "react";

import * as S from "./styles.module.css";

const TextArea = ({ showErrorFeedback, isCorrect, onChange, value, disabled }) => {
  const modifiers = isCorrect ? S.correct : S.incorrect;

  const classNames = `${showErrorFeedback ? modifiers : ""} ${S.textArea}`;

  return (
    <textarea
      onChange={onChange}
      value={value}
      className={classNames}
      disabled={disabled}
      placeholder="Escreva aqui..."
      spellCheck="false"
    />
  );
};

TextArea.propTypes = {
  isCorrect: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  showErrorFeedback: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default TextArea;
