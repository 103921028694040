import A1Disabled from "../assets/imgs/A1-Disabled.png";
import A1 from "../assets/imgs/A1.png";
import A2Disabled from "../assets/imgs/A2-Disabled.png";
import A2 from "../assets/imgs/A2.png";
import B1Disabled from "../assets/imgs/B1-Disabled.png";
import B1 from "../assets/imgs/B1.png";
import B2Disabled from "../assets/imgs/B2-Disabled.png";
import B2 from "../assets/imgs/B2.png";
import C1Disabled from "../assets/imgs/C1-Disabled.png";
import C1 from "../assets/imgs/C1.png";
import C2Disabled from "../assets/imgs/C2-Disabled.png";
import C2 from "../assets/imgs/C2.png";

const certificates = [
  {
    image: A1,
    imageDisabled: A1Disabled,
    title: "Beginner",
    description: "Ideal para quem quer ter o primeiro contato com o idioma",
    color: "#723FB1",
  },
  {
    image: A2,
    imageDisabled: A2Disabled,
    title: "Elementary",
    description: "Para você que já tem uma base, mas quer evoluir mais.",
    color: "#4098D3",
  },
  {
    image: B1,
    imageDisabled: B1Disabled,
    title: "Pre-Intermediate",
    description: "Você já tem um bom conhecimento da língua. Continue avançando!",
    color: "#EB5757",
  },
  {
    image: B2,
    imageDisabled: B2Disabled,
    title: "Intermediate",
    description: "You're halfway to the top. Keep up!",
    color: "#E99802",
  },
  {
    image: C1,
    imageDisabled: C1Disabled,
    title: "Upper-Intermediate",
    description: "Let's take it up one more notch!",
    color: "#47A685",
  },
  {
    image: C2,
    imageDisabled: C2Disabled,
    title: "Advanced",
    description: "Last stop: Proficiency!",
    color: "#CF8BFB",
  },
];

export default certificates;
