import PropTypes from "prop-types";
import React, { createContext } from "react";
import { ToastContainer as Toast, toast } from "react-toastify";

export const ToastContext = createContext();

export const ToastStorage = ({ children }) => {
  toast.configure({ hideProgressBar: true, style: { backgroundColor: "purple" } });
  const showToast = ({ message, type = "info" }) => toast[type](message);

  return <ToastContext.Provider value={{ showToast, Toast }}>{children}</ToastContext.Provider>;
};

ToastStorage.propTypes = {
  children: PropTypes.node.isRequired,
};
