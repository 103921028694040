import PropTypes from "prop-types";
import React from "react";

import * as S from "./styles.module.css";

const ProgressBar = ({ progress = 0 }) => {
  return (
    <div className={S.ProgressBar}>
      <div className={`${S.Progress} ${S.Completed}`} style={{ width: `${progress}%` }}>
        <span className={S.ProgressText} style={{ right: progress < 5 ? -30 : 0 }}>
          {progress}%
        </span>
      </div>
    </div>
  );
};

ProgressBar.propTypes = {
  progress: PropTypes.number,
};

export default ProgressBar;
