import PropTypes from "prop-types";
import React, { useState } from "react";

import Terms from "../../../Terms/Terms";

const RadioButton = ({ terms, privacy, onChange, error }) => {
  const [showModal, setShowModal] = useState(false);
  const [targetText, setTargetText] = useState(terms);
  const [targetTitle, setTargetTitle] = useState("Termos de Uso - EnglishBay");

  const handleClick = () => {
    setShowModal(true);
  };

  const openTerm = (event) => {
    event.preventDefault();
    event.stopPropagation();

    setTargetTitle("Termos de Uso - EnglishBay");
    setTargetText(terms);
    handleClick();
  };

  const openPrivacy = (event) => {
    event.preventDefault();
    event.stopPropagation();

    setTargetTitle("Política de Privacidade - EnglishBay");
    setTargetText(privacy);
    handleClick();
  };

  return (
    <>
      <div className="w-full flex pl-1 pr-1 items-center space-x-2">
        <input
          id="radio"
          onChange={({ target }) => onChange(target.value)}
          className="cursor-pointer form-checkbox focus:ring-transparent"
          style={{ minHeight: "1rem", minWidth: "1rem" }}
          name="radio"
          type="checkbox"
          required
        />
        <label htmlFor="radio" className="select-none text-left">
          Li e aceito as{" "}
          <span
            onClick={openPrivacy}
            onKeyDown={openPrivacy}
            role="button"
            tabIndex={0}
            className="font-bold text-purple-500">
            Políticas de Privacidade{" "}
          </span>
          e os{" "}
          <span
            onClick={openTerm}
            onKeyDown={openTerm}
            role="button"
            tabIndex={0}
            className="font-bold text-purple-500">
            Termos de Serviço{" "}
          </span>
        </label>
        <Terms title={targetTitle} show={showModal} setShowModal={setShowModal} termo={targetText} />
      </div>
      {error && <p className="text-red-600">{error}</p>}
    </>
  );
};

RadioButton.propTypes = {
  terms: PropTypes.string,
  privacy: PropTypes.string,
  error: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

export default RadioButton;
