import { localStorage } from "globalthis/implementation";

export const getCurrentToken = () => localStorage.getItem("token");

export const saveToken = (token) => localStorage.setItem("token", token);

export const saveUser = (user) => {
  const userStringify = JSON.stringify(user);
  localStorage.setItem("user", userStringify);
};

export const getUser = () => {
  const user = localStorage.getItem("user");
  return JSON.parse(user);
};

export const getPlan = () => {
  const plan = localStorage.getItem("plan");
  return JSON.parse(plan);
};

export const deleteToken = () => localStorage.removeItem("token");
export const deleteUser = () => localStorage.removeItem("user");
export const deleteTestClassInformation = () => localStorage.removeItem("testClass");
export const deleteTimeAccessed = () => localStorage.removeItem("timesAcessed");

export const deleteOnBoardingInformation = () => {
  localStorage.removeItem("userData");
  sessionStorage.removeItem("externalLogin");
};

export const clearStorage = () => {
  deleteToken();
  deleteUser();
  deleteTestClassInformation();
  deleteOnBoardingInformation();
  deleteTimeAccessed();
};
