import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

import "./LevelTest.css";
import a1Icon from "../../../assets/imgs/A1.png";
import a2Icon from "../../../assets/imgs/A2.png";
import b1Icon from "../../../assets/imgs/B1.png";
import b2Icon from "../../../assets/imgs/B2.png";
import c1Icon from "../../../assets/imgs/C1.png";
import c2Icon from "../../../assets/imgs/C2.png";
import { numberToLevel } from "../../../utils/level";
import shuffleArray from "../../../utils/shuffleArray";
import questions from "../../../utils/testLevelQuestions";

const LevelTest = ({ handleTestFinished, handleCancel }) => {
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [answers, setAnswers] = useState([]);
  const [response, setResponse] = useState(null);

  const [counter, setCounter] = useState(0);
  const [chances, setChances] = useState(2);
  const [currentLevel, setCurrentLevel] = useState(0);

  const [endTest, setEndTest] = useState(false);

  const { title, correct_answer, incorrect_answers } = questions[currentQuestion];

  const history = useHistory();

  useEffect(() => {
    setTimeout(() => window.scroll(0, 55), 300);
  }, []);

  useEffect(() => {
    if (chances === 0 || currentQuestion === 50) {
      setEndTest(true);

      const userData = JSON.parse(localStorage.getItem("userData"));

      const levelTest = numberToLevel(currentLevel);

      localStorage.setItem("userData", JSON.stringify({ ...userData, levelTest }));
    }

    setAnswers(shuffleArray([correct_answer, ...incorrect_answers]));
    setCounter(counter + 1);
  }, [currentQuestion]);

  useEffect(() => {
    if (counter === 9) {
      setCounter(1);
      setChances(4);
      setCurrentLevel(currentLevel + 1);
    }
  }, [counter]);

  const numberToLevelImage = (number) => {
    switch (number) {
      case 0:
        return a1Icon;
      case 1:
        return a2Icon;
      case 2:
        return b1Icon;
      case 3:
        return b2Icon;
      case 4:
        return c1Icon;
      case 5:
        return c2Icon;
      case 6:
        return c2Icon;
      default:
        throw new Error("número inválido");
    }
  };

  const disableButtons = () => {
    const answers = document.querySelectorAll(".answers__item");
    answers.forEach((answer) => answer.setAttribute("disabled", ""));
  };

  const checkAnswer = ({ target }) => {
    disableButtons();

    if (target.innerHTML === correct_answer) {
      target.classList.add("answer--correct");
      setResponse("correct");
      return;
    }

    target.classList.add("answer--incorrect");
    setResponse("incorrect");
    setChances(chances - 1);
  };

  const nextQuestion = () => {
    setResponse(null);

    if (currentQuestion !== 49) {
      setCurrentQuestion(currentQuestion + 1);
      return;
    }

    setCurrentQuestion(0);
    setChances(0);
  };

  return (
    <div className="main-wrapper" style={{ justifyContent: "space-between" }}>
      {(endTest && (
        <div className="result-wrapper">
          <img src={numberToLevelImage(currentLevel)} alt="level icon" style={{ width: "95px" }} />
          <span>{numberToLevel(currentLevel)}</span>

          {!handleTestFinished && (
            <button className="btn-start" onClick={() => history.push("/onboarding/signup")}>
              Comece aqui!
            </button>
          )}

          {handleTestFinished && (
            <button className="btn-start" onClick={() => handleTestFinished(numberToLevel(currentLevel))}>
              Entendi!
            </button>
          )}
        </div>
      )) || (
        <>
          <div className="test-wrapper">
            <div className="test-wrapper__question">
              <span className="question__title">{title}</span>

              <div className="question__answers">
                {answers.map((answer) => (
                  <button key={answer} className="answers__item" onClick={checkAnswer}>
                    {answer}
                  </button>
                ))}
              </div>
            </div>
          </div>

          <div className={`feedback bg-${response}`}>
            {response && (
              <span className={`response-${response}`}>
                {response === "correct" ? "Certa resposta! :D" : response === "incorrect" ? "Ops.. Você errou :C" : ""}
              </span>
            )}

            {!response && (
              <button className={"button-next button-incorrect"} onClick={handleCancel}>
                Cancelar
              </button>
            )}

            <button className={`button-next button-${response}`} disabled={!response} onClick={nextQuestion}>
              Próxima
            </button>
          </div>
        </>
      )}
    </div>
  );
};

LevelTest.propTypes = {
  handleTestFinished: PropTypes.func,
  handleCancel: PropTypes.func,
};

export default LevelTest;
