import { utcToZonedTime } from "date-fns-tz";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { SpinnerPurple } from "../../../assets/svgs/index";
import { SlideContext } from "../../../context/SlideContext";
import { UserContext } from "../../../context/UserContext";
import useRequest from "../../../hooks/useRequest";
import useWindown from "../../../hooks/useWindowSize";
import { formatIso } from "../../../utils/dates";
import CardAvailableClass from "../Cards/CardAvailableClass/CardAvailableClass";
import CardLesson from "../Cards/CardLesson/CardLesson";
import CardNextClass from "../Cards/CardNextClass/CardNextClass";
import DrawerLessonTeacher from "./DrawerLessonTeacher";

const LessonsTeacherCardsGrid = () => {
  const { user } = useContext(UserContext);
  const { setShowSlide, setSlideComponent } = useContext(SlideContext);
  const { request } = useRequest();
  const { t } = useTranslation();

  const width = useWindown();
  const [appointments, setAppointments] = useState();
  const [loading, setLoading] = useState();
  const [appointmentsReq, setAppointmentsReq] = useState();
  const [appointmentsToday, setAppointmentsToday] = useState(0); // Aulas marcadas para o dia atual

  useEffect(() => {
    if (!appointmentsReq) {
      return;
    }
    const isSmallMonitor = window.innerWidth <= 1600;
    const appointmentsArray = !isSmallMonitor ? appointmentsReq.slice(0, 3) : appointmentsReq.slice(0, 2);

    appointmentsArray.forEach((appointment) => {
      appointment.schedule.dateTime = formatIso(utcToZonedTime(appointment.schedule.dateTime, user.timezone));
    });

    setAppointments(appointmentsArray);
  }, [width, appointmentsReq]);

  const getAppointments = async () => {
    setLoading(true);
    const { results, todayScheduledClasses } = await request(
      "GET",
      "/appointment/teacher/list?filter=RESERVED&perPage=4",
    );
    setAppointmentsReq(results);
    setAppointmentsToday(todayScheduledClasses);
    setLoading(false);
  };

  const handleClick = (appointment) => {
    setSlideComponent(
      <DrawerLessonTeacher
        setShowSlide={setShowSlide}
        setSlideComponent={setSlideComponent}
        appointment={appointment}
        refresh={getAppointments}
      />,
    );
    setShowSlide(true);
  };

  const refreshTime = 180000; // 3 minutos
  useEffect(() => {
    getAppointments();

    const intervalId = setInterval(getAppointments, refreshTime);
    return () => clearInterval(intervalId);
  }, []);

  return (
    <div>
      {loading && <SpinnerPurple className="w-10 m-auto mt-5 animate-spin" />}
      {!loading && (
        <>
          <div className="flex justify-between">
            <h2 className="text-2xl mb-6 ">
              {t("you_have")} {appointmentsReq && appointmentsToday}{" "}
              {appointmentsToday != 0 ? t("class.classes") : t("class.class")} {t("today")}
            </h2>
          </div>
          <div className="flex justify-between">
            <div className="flex-grow-0">
              <CardLesson isTeacher={true} />
            </div>
            {appointments &&
              appointments.map((appointment, index) => {
                return (
                  <div key={appointment.uuid} className="flex-grow-0">
                    <CardNextClass
                      isNext={index === 0}
                      teacher={{ user: appointment.user }}
                      type={appointment.type}
                      dateTime={appointment.schedule.dateTime}
                      clickHandler={() => handleClick(appointment)}
                    />
                  </div>
                );
              })}
            {appointmentsReq && appointmentsReq.length < 1 && <CardAvailableClass isTeacher={true} />}
            {appointmentsReq && appointmentsReq.length < 2 && <CardAvailableClass isTeacher={true} />}
            {appointmentsReq && appointmentsReq.length < 3 && window.innerWidth > 1600 && (
              <CardAvailableClass isTeacher={true} />
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default LessonsTeacherCardsGrid;
