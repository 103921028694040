import propTypes from "prop-types";
import React from "react";

import "./SmallPlanCard.css";

const SmallPlanCard = ({ data }) => {
  return (
    <section className="plan-card--small">
      <span style={{ backgroundImage: `url(${data?.iconUrl})` }} />

      <div>
        <span className="plan__title--small">
          Plano <span>{data?.name}</span>
        </span>
        <span className="plan__description--small">{data?.description}</span>
        <span className="plan__price--small">R$ {data?.price}</span>
      </div>
    </section>
  );
};

export default SmallPlanCard;

SmallPlanCard.propTypes = {
  data: propTypes.object.isRequired,
};
