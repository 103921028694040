import propTypes from "prop-types";
import React from "react";
import { useHistory } from "react-router-dom";

import { LessonBook, LessonDone, LessonPadlock, LessonPlay, LessonRepeat } from "../../../../assets/svgs";
import "./LessonItem.css";

function LessonItem({ data = {} }) {
  const { uuid, title, viewed, readable } = data;

  const history = useHistory();

  const handleStartLesson = () => {
    if (!readable) {
      return;
    }

    history.push(`/lesson/${uuid}`);
  };

  return (
    <section onClick={handleStartLesson} className="lesson-item cursor-pointer">
      <div className="w-full">
        <div className={`lesson-item__icon ${viewed ? "icon--done" : !readable ? "icon--block" : ""}`}>
          {viewed ? <LessonDone /> : <LessonBook />}
        </div>

        <div className="lesson-item__datails">
          <span className="lesson-item__title">{title}</span>
          <span className={`lesson-item__legend ${viewed ? "legend--done" : ""}`}>Lição Concluída</span>
        </div>
      </div>

      <button
        className={`btn-see-lesson ${viewed ? "button--done" : !readable ? "button--block" : ""}`}
        disabled={!readable}>
        {(!readable && <LessonPadlock />) || (viewed ? <LessonRepeat /> : <LessonPlay />)}
      </button>
    </section>
  );
}

export default LessonItem;

LessonItem.propTypes = {
  data: propTypes.object,
}.isRequired;
