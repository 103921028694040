import React from "react";
import { Route, Redirect, useLocation } from "react-router-dom";

import { getCurrentToken } from "../utils/localStorage";

const AuthRoute = ({ ...props }) => {
  const location = useLocation();

  if (getCurrentToken() && !location.search.includes("?token=")) {
    return <Redirect to="/" />;
  }

  return <Route {...props} />;
};

export default AuthRoute;
