import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";

import { PurpleButton, RadioButton, Input } from "../../../_Shared/Form/index";
import { LargeArrow, Visa, MasterCard } from "../../../../assets/svgs/index";
import { SlideContext } from "../../../../context/SlideContext";
import { ToastContext } from "../../../../context/ToastContext";
import { UserContext } from "../../../../context/UserContext";
import useForm from "../../../../hooks/useForm";
import useRequest from "../../../../hooks/useRequest";
import InfoPlan from "../InfoPlan/InfoPlan";

import "./ChangePaymentMethod.css";

const ChangePaymentMethod = () => {
  const { request } = useRequest();
  const history = useHistory();
  const { setSlideComponent } = useContext(SlideContext);
  const { showToast } = useContext(ToastContext);
  const { user } = useContext(UserContext);

  const cpfForm = useForm("cpf");
  const cardholderNameForm = useForm();
  const cardExpirationForm = useForm();
  const cardNumberForm = useForm();
  const cardCVVForm = useForm();
  const acceptedTermsForm = useForm();
  const birthDate = useForm();
  const cellForm = useForm();

  const [terms, setTerms] = useState("");
  const [privacy, setPrivacy] = useState("");
  const [loading, setloading] = useState();

  const validateForm = () => {
    cpfForm.validate();
    cardholderNameForm.validate();
    cardExpirationForm.validate();
    cardNumberForm.validate();
    cardCVVForm.validate();
    acceptedTermsForm.validate();
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const paymentRequest = {
      paymentMethodCode: "credit_card",
      holderName: cardholderNameForm.value,
      card: {
        cardExpiration: cardExpirationForm.value,
        cardNumber: cardNumberForm.value.split(" ").join(""),
        cardCVV: cardCVVForm.value,
      },
    };

    validateForm();

    if (cpfForm.validate()) {
      setloading(true);
      const response = await request("PATCH", "/subscription/setPaymentProfile", paymentRequest, true, false);
      setloading(false);

      if (!response.error) {
        showToast({ message: "Metodo de pagamento modificado com sucesso", type: "success" });
        return history.push("/");
      }

      if (response.error?.statusCode !== 201) {
        if (response.error?.message === "INVALID_CPF") {
          return showToast({ message: "CPF Inválido!", type: "error" });
        }

        if (response.error?.statusCode === 500) {
          return showToast({ message: "Erro interno ao processar compra!", type: "error" });
        }

        return showToast({ message: "Preencha todos os dados corretamente!", type: "error" });
      }
    }
  };

  const handleBackButton = async () => {
    const activeBill = await request("GET", "/subscription/activeSubscription");
    setSlideComponent(<InfoPlan activeBill={activeBill} />);
  };

  useEffect(() => {
    const fetchTerms = async () => {
      const userType = user.role === "TEACHER" ? "TEACHER" : "USER";

      const [term, privacy] = await Promise.all([
        request("GET", `/terms/read?kind=TERM&userType=${userType}`),
        request("GET", `/terms/read?kind=PRIVACY&userType=${userType}`),
      ]);

      setTerms(term.text);
      setPrivacy(privacy.text);
    };

    fetchTerms();
  }, []);

  return (
    <div
      style={{
        width: "663px",
      }}>
      <header className="flex items-center">
        <LargeArrow
          className="w-6 h-6 ront mr-5 cursor-pointer"
          style={{
            fill: "#14C4BE",
            color: "#14C4BE",
          }}
          onClick={handleBackButton}
        />

        <h1
          className="text-2xl"
          style={{
            color: "#252426",
          }}>
          Cadastrar cartão de crédito
        </h1>
      </header>
      <div className="flex items-center mt-4 pl-11">
        <h2>Bandeiras aceitas: </h2>
        <Visa className="ml-5" />
        <MasterCard className="ml-5" />
      </div>

      <form className="mt-5" onSubmit={handleSubmit}>
        <Input label="Número do cartão" name="numCard" mask="9999 9999 9999 9999" {...cardNumberForm} />
        <Input label="Nome impresso no cartão" name="holderName" {...cardholderNameForm} />
        <div className="grid grid-cols-2 gap-5">
          <Input label="Data de vencimento" name="expiration" mask="99/9999" {...cardExpirationForm} />
          <Input label="CVV" name="cvv" mask="999" {...cardCVVForm} />
        </div>

        <div className="grid grid-cols-2 gap-5">
          <Input label="Data de nascimento" name="birthDate" mask="99/99/9999" {...birthDate} />
          <Input label="CPF" name="cpf" mask="999.999.999-99" {...cpfForm} />
        </div>

        <Input label="Celular" name="cell" mask="(99) 99999-9999" {...cellForm} />

        <div className="mt-2">
          <RadioButton terms={terms} privacy={privacy} {...acceptedTermsForm} />
        </div>

        <PurpleButton className="absolute bottom-6 btn-assign" type="submit" isLoading={loading}>
          Ativar pagamento
        </PurpleButton>
      </form>
    </div>
  );
};

export default ChangePaymentMethod;
