import useRequest from "hooks/useRequest";
import PropTypes from "prop-types";
import React, { useEffect, useState, useRef, useContext } from "react";
import { AiOutlineClose as CloseIcon } from "react-icons/ai";

import LevelProgress from "../../../_Shared/LevelProgress/LevelProgress";
import A1DisabledImage from "../../../../assets/imgs/A1-Disabled.png";
import A1Image from "../../../../assets/imgs/A1.png";
import A2DisabledImage from "../../../../assets/imgs/A2-Disabled.png";
import A2Image from "../../../../assets/imgs/A2.png";
import B1DisabledImage from "../../../../assets/imgs/B1-Disabled.png";
import B1Image from "../../../../assets/imgs/B1.png";
import B2DisabledImage from "../../../../assets/imgs/B2-Disabled.png";
import B2Image from "../../../../assets/imgs/B2.png";
import C1DisabledImage from "../../../../assets/imgs/C1-Disabled.png";
import C1Image from "../../../../assets/imgs/C1.png";
import C2DisabledImage from "../../../../assets/imgs/C2-Disabled.png";
import C2Image from "../../../../assets/imgs/C2.png";
import { UserContext } from "../../../../context/UserContext";
import useOnClickOutside from "../../../../hooks/useOnClickOutside";
import { getLevelIndexByLevelName, getLevelNameByLevelIndex } from "../../../../utils/level";
import * as S from "./styles.module.css";

const levels = [
  {
    title: "Beginner",
    imageEnabledUrl: A1Image,
    imageDisabledUrl: A1DisabledImage,
  },

  {
    title: "Elementary",
    imageEnabledUrl: A2Image,
    imageDisabledUrl: A2DisabledImage,
  },

  {
    title: "Pre-Intermediate",
    imageEnabledUrl: B1Image,
    imageDisabledUrl: B1DisabledImage,
  },
  {
    title: "Intermediate",
    imageEnabledUrl: B2Image,
    imageDisabledUrl: B2DisabledImage,
  },
  {
    title: "Upper-Intermediate",
    imageEnabledUrl: C1Image,
    imageDisabledUrl: C1DisabledImage,
  },
  {
    title: "Advanced",
    imageEnabledUrl: C2Image,
    imageDisabledUrl: C2DisabledImage,
  },
];

const SelectLevel = ({ handleHideBanner, changeLevel }) => {
  const { user } = useContext(UserContext);
  const ref = useRef();
  const { request } = useRequest();

  const [progress, setProgress] = useState({
    done: 0,
    total: 0,
  });

  const levelIndex = getLevelIndexByLevelName(user?.levelTest);

  const getCurrentState = (index) => {
    if (index < levelIndex) {
      return "COMPLETED";
    }

    if (index === levelIndex) {
      return "DOING";
    }

    return "BLOCKED";
  };

  const handleLevelPress = (index) => {
    const level = getLevelNameByLevelIndex(index);

    changeLevel(level);
    handleHideBanner(false);
  };

  const hadleGetCurrentProgress = async () => {
    if (!user?.levelTest) {
      return;
    }

    const response = await request("GET", "/unity/currentProgress?currentCategory=" + encodeURI(user?.levelTest));

    const firstItemWithProgressNull = response.unities.findIndex((unity) => unity.progress.stars === null);
    setProgress({ done: firstItemWithProgressNull, total: response.unities.length });
  };

  useEffect(() => {
    hadleGetCurrentProgress();
  }, []);

  useOnClickOutside(ref, () => handleHideBanner());

  return (
    <div className={S.Backdrop}>
      <div ref={ref} className={`${S.Container} ${S.slideDown}`}>
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-lg font-bold">Escolha seu nível</h2>

          <CloseIcon className="cursor-pointer" size={20} onClick={handleHideBanner} />
        </div>

        {levels.map((level, index) => (
          <LevelProgress
            onClick={() => handleLevelPress(index)}
            state={getCurrentState(index)}
            doneUnity={progress.done}
            totalUnity={progress.total}
            {...level}
            key={index}
          />
        ))}
      </div>
    </div>
  );
};

SelectLevel.propTypes = {
  changeLevel: PropTypes.func.isRequired,
  handleHideBanner: PropTypes.func.isRequired,
};

export default SelectLevel;
