import PropTypes from "prop-types";
import React from "react";
import { useHistory } from "react-router-dom";

import BannerWhatsappImageMobile from "../../../../../assets/imgs/banners/banner_certificates_mobile.png";
import BannerWhatsappImage from "../../../../../assets/imgs/banners/banner_certificates.png";
import { ChatFavourite } from "../../../../../assets/svgs";
import ModalBanner from "../../ModalBanner";

import "./ModalBannerCertificates.css";

const ModalBannerCertificates = ({ handleHideBanner }) => {
  const history = useHistory();

  const handleClick = () => {
    handleHideBanner();
    history.push("/planos");
  };

  return (
    <ModalBanner imageDesktop={BannerWhatsappImage} imageMobile={BannerWhatsappImageMobile}>
      <div className="modal-banner-certificate">
        <div className="modal-banner-certificate-text-wrapper">
          <ChatFavourite />

          <p className="modal-banner-certificate-title">
            Baixe seus <span>certificados</span>
          </p>
          <p className="modal-banner-certificate-description">
            Assinando o plano premium por apenas R$49,87 ao mês, você pode baixar seus certificados sempre que avançar
            um nível
          </p>
        </div>

        <div className="modal-banner-certificate-button-wrapper">
          <button onClick={handleClick} className="modal-banner-certificate-button-primary">
            Assine agora
          </button>
        </div>
      </div>
    </ModalBanner>
  );
};

ModalBannerCertificates.propTypes = {
  handleHideBanner: PropTypes.func.isRequired,
};

export default ModalBannerCertificates;
