import { parsePhoneNumberFromString } from "libphonenumber-js";

export const getFirstName = (fullName) => fullName?.split(" ")[0]?.slice(0, 17) || "";

export const formatPhoneNumber = (str) => parsePhoneNumberFromString(str).formatInternational();

export const parseFilterQuery = (arr, initialChar = "&") => {
  let filterQuery = "";

  if (!arr?.length) {
    return filterQuery;
  }

  arr.forEach((filterValue, i) => {
    filterQuery = `${filterQuery}${i === 0 ? initialChar : "&"}${filterValue}=true`;
  });

  return filterQuery;
};

export const monthNumberToName = (monthNumber) => {
  const dic = {
    1: "January",
    2: "February",
    3: "March",
    4: "April",
    5: "May",
    6: "June",
    7: "July",
    8: "August",
    9: "September",
    10: "October",
    11: "November",
    12: "December",
  };

  return dic[monthNumber] || "Invalid";
};

export const downloadFile = (base64, fileName) => {
  const source = `data:application/pdf;base64,${base64}`;
  const downloadLink = document.createElement("a");
  const name = `${fileName}.pdf`;

  downloadLink.href = source;
  downloadLink.download = name;
  downloadLink.click();
};

export const capitalize = (string) => {
  if (string) {
    const firstLetter = string[0].toUpperCase();
    const stringRest = string.slice(1, string.length);

    return firstLetter + stringRest;
  }
};

export const getRandomItemFromArray = (arr) => arr[Math.floor(Math.random() * arr.length)];

export const getRandomItemsFromArray = (quantity, array) => {
  const arrayCopy = [...array];
  const randomItems = [];

  for (let i = 0; i < quantity; i++) {
    const randomBanner = arrayCopy[Math.floor(Math.random() * arrayCopy.length)];

    if (randomItems.includes(randomBanner)) {
      i--;
      continue;
    }

    randomItems.push(randomBanner);
  }

  return randomItems;
};

export const getInstallments = (planValue, maxInstallments) => {
  let installments = [];

  for (let i = 1; i <= Math.min(planValue, maxInstallments); i++) {
    let installmentValue = (planValue / i).toFixed(2);
    installments.push(i + "x de R$ " + installmentValue + " sem juros");
  }

  return installments;
};

export const TooltipDic = {
  neutral: {
    url: "https://media.englishbay.com.br/bay-neutral-web.gif",
  },
  sad: {
    url: "https://media.englishbay.com.br/bay-sad-web.gif",
  },
  happy: {
    url: "https://media.englishbay.com.br/bay-happy-web.gif",
  },
};

export const getDefaultPlan = () => {
  if (process.env.NODE_ENV === "prod") {
    return {
      vindiProductId: 1499981,
      vindiPlanId: 443610,
      uuid: "c97f364b-1fbb-488e-bbd7-4d5449066f00",
      price: "49.99",
      name: "Basic",
      iconUrl: "https://media.englishbay.com.br/d721bc45-8817-4adf-b126-773b1cbbd8b6.jpeg",
      interval: "months",
      intervalCount: 1,
      billingTriggerType: "beginning_of_period",
      billingTriggerDay: 0,
      billingCycles: null,
      code: null,
      description: "Plano premium com 4 aulas por mês",
      installments: null,
      invoiceSplit: null,
      status: "active",
      metadata: null,
      lessonType: "CONVERSATION",
      lessonsCount: 0,
      recommended: false,
      platform: "WEB",
    };
  }

  // dev/testes
  return {
    vindiProductId: 233141,
    vindiPlanId: 88895,
    uuid: "ba0ccc56-9ae1-416f-a8d5-70625505b2cb",
    price: "49.99",
    name: "Basic",
    iconUrl: "https://storage.googleapis.com/englishbay-dev-public/d721bc45-8817-4adf-b126-773b1cbbd8b6.jpeg",
    interval: "months",
    intervalCount: 1,
    billingTriggerType: "beginning_of_period",
    billingTriggerDay: 0,
    billingCycles: null,
    code: null,
    description: "Plano premium com 4 aulas por mês",
    installments: null,
    invoiceSplit: null,
    status: "active",
    metadata: null,
    lessonType: "CONVERSATION",
    lessonsCount: 4,
    recommended: false,
    platform: "WEB",
  };
};
