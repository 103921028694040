import PropTypes from "prop-types";
import React, { useEffect, useRef } from "react";
import { AiOutlineClose as CloseIcon } from "react-icons/ai";
import { AiFillYoutube } from "react-icons/ai";
import { logAnalyticsEvent } from "utils/firebase-analytics";

import BannerBackground from "../../../../assets/imgs/banners/YoutubeBackground.png";
import BannerImage from "../../../../assets/imgs/banners/YoutubeImage.png";
import useOnClickOutside from "../../../../hooks/useOnClickOutside";
import * as S from "./styles.module.css";

const Youtube = ({ handleHideBanner }) => {
  const ref = useRef();

  useOnClickOutside(ref, () => handleHideBanner());

  const image = BannerBackground;

  const handleClick = () => {
    handleHideBanner();
    logAnalyticsEvent("CLICK_BANNER_YOUTUBE");
    window.open("https://www.youtube.com/c/englishbay?sub_confirmation=1").focus();
  };

  useEffect(() => {
    logAnalyticsEvent("SHOW_BANNER_YOUTUBE");
  }, []);

  return (
    <div className={S.Backdrop}>
      <div ref={ref} className={S.Container}>
        <div className={S.Wrapper}>
          <div className={S.WrapperContent}>
            <p className={S.Title}>
              Aulas <span>GRÁTIS</span> no Youtube <AiFillYoutube size={56} color="red" />
            </p>

            <p className={S.Description}>
              Participe das nossas lives, de segunda a quinta às 20HS de graça em nosso canal do Youtube com o professor
              Julio
            </p>

            <button onClick={handleClick} className={S.Button}>
              <AiFillYoutube size={32} color="#fff" />
              Seguir canal
            </button>
          </div>
        </div>

        <div style={{ position: "relative" }}>
          <img className={S.Image} src={image} alt="BannerBackground" />

          <img className={S.BannerImage} src={BannerImage} alt="BannerImage" />

          <div className={S.WrapperButtonClose}>
            <button className={S.ButtonClose} onClick={handleHideBanner}>
              <CloseIcon size={20} color="#6A51B2" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

Youtube.propTypes = {
  handleHideBanner: PropTypes.func.isRequired,
};

export default Youtube;
