import PropTypes from "prop-types";
import React from "react";

import { BrasilFlag, RussianFlag, SerbianFlag, SpainFlag, UnitedStatesFlag } from "../../../assets/svgs/index";

const ButtonSwitch = ({ option, toggleShowHide }) => {
  const isSmallMonitor = window.innerWidth <= 1600;

  switch (option) {
    case "pt-BR":
      return (
        <button
          className="buttonSwitch flex flex-row items-center text-sm p-2.5 rounded-xl bg-gray border"
          style={{ border: "1px solid #F3F4F6" }}
          onClick={toggleShowHide}>
          <BrasilFlag />
          {!isSmallMonitor && <span className="ml-2">Português</span>}
        </button>
      );
    case "en":
      return (
        <button
          className="buttonSwitch flex flex-row items-center text-sm p-2.5 rounded-xl bg-gray border"
          style={{ border: "1px solid #F3F4F6" }}
          onClick={toggleShowHide}>
          <UnitedStatesFlag />
          {!isSmallMonitor && <span className="ml-2">English</span>}
        </button>
      );
    case "sp-sp":
      return (
        <button
          className="buttonSwitch flex flex-row items-center text-sm p-2.5 rounded-xl bg-gray border"
          style={{ border: "1px solid #F3F4F6" }}
          onClick={toggleShowHide}>
          <SpainFlag />
          {!isSmallMonitor && <span className="ml-2">Español</span>}
        </button>
      );
    case "sb-sb":
      return (
        <button
          className="buttonSwitch flex flex-row items-center text-sm p-2.5 rounded-xl bg-gray border"
          style={{ border: "1px solid #F3F4F6" }}
          onClick={toggleShowHide}>
          <SerbianFlag />
          {!isSmallMonitor && <span className="ml-2">Serbian</span>}
        </button>
      );
    case "ru-ru":
      return (
        <button
          className="buttonSwitch flex flex-row items-center text-sm p-2.5 rounded-xl bg-gray border"
          style={{ border: "1px solid #F3F4F6" }}
          onClick={toggleShowHide}>
          <RussianFlag />
          {!isSmallMonitor && <span className="ml-2">Russian</span>}
        </button>
      );
  }
};

ButtonSwitch.propTypes = {
  option: PropTypes.string,
  toggleShowHide: PropTypes.func,
};

export default ButtonSwitch;
