import PropTypes from "prop-types";
import React, { useEffect, useContext, useState } from "react";
import { Link } from "react-router-dom";

import { Input, PurpleButton, Button, InputPhone, SelectTimezone } from "../../../../_Shared/Form/index";
import DrawerConfirmDelete from "../../../../_Shared/Slide/DrawerDeleteAccount/DrawerConfirmDelete";
import SkypeIcon from "../../../../../assets/imgs/skypeIcon.jpg";
import { SpinnerPurple } from "../../../../../assets/svgs/index";
import { SlideContext } from "../../../../../context/SlideContext";
import { ToastContext } from "../../../../../context/ToastContext";
import { UserContext } from "../../../../../context/UserContext";
import useForm from "../../../../../hooks/useForm";
import useRequest from "../../../../../hooks/useRequest";

const InformationUser = ({ loading, setLoading }) => {
  const { showToast } = useContext(ToastContext);
  const { setUserContext } = useContext(UserContext);
  const nameForm = useForm();
  const cardForm = useForm();

  const setUserFormInfo = (user) => {
    nameForm.setValue(user.name);
    cardForm.setValue(user.card);
    emailForm.setValue(user.email);
    birthDateForm.setValue(user.birthDate);
    skypeIdForm.setValue(user.skypeId);
    phoneNumberForm.setValue(user.phoneNumber);
    timezone.setValue(user.timezone);
  };

  const validateForm = () => {
    nameForm.validate();
    emailForm.validate();
    phoneNumberForm.validate();
  };

  const emailForm = useForm("email");
  const phoneNumberForm = useForm("phoneNumber");
  const skypeIdForm = useForm("optional");
  const birthDateForm = useForm("optional");
  const timezone = useForm("optional");

  const getUserInfos = async () => {
    setLoading(true);
    const user = await request("GET", "/user/read");
    setUserFormInfo(user);
    setUserForm(user);
    await setUserContext(user);
    setLoading(false);
    return user;
  };

  useEffect(() => {
    getUserInfos();
  }, []);

  const { setShowSlide, setSlideComponent } = useContext(SlideContext);

  const [userForm, setUserForm] = useState();
  const { request } = useRequest();

  const handleSubmit = async (event) => {
    setLoading(true);
    event.preventDefault();
    validateForm();

    const formIsValid = nameForm.validate() && emailForm.validate() && phoneNumberForm.validate();
    if (formIsValid) {
      const userReq = {
        name: nameForm.value,
        email: emailForm.value,
        birthDate: birthDateForm.value,
        skypeId: skypeIdForm.value,
        phoneNumber: phoneNumberForm.value,
        timezone: timezone.value,
      };

      if (cardForm.value) {
        userReq.currentCardPosition = cardForm.value;
      }

      const user = await request("PATCH", "/user", userReq);
      if (!user.error) {
        showToast({ type: "success", message: "Informações atualizadas com sucesso!!" });
        getUserInfos();
      }
    }

    setLoading(false);
  };

  const handleResetClick = () => {
    setUserFormInfo(userForm);
    validateForm();
  };

  const handleClick = (component) => {
    setShowSlide((active) => (active = !active));
    setSlideComponent(component);
  };

  return (
    <div className="bg-white p-4 px-7 mt-5 mb-10">
      <h2>Sobre você</h2>

      {loading ? (
        <SpinnerPurple className="w-10 m-auto mt-5 animate-spin" />
      ) : (
        <form onSubmit={handleSubmit}>
          <>
            <div className="grid sm:grid-cols-2 gap-5">
              {(process.env.REACT_APP_NODE_ENV !== "prod" ||
                userForm?.email === "aluno@gmail.com" ||
                userForm?.email === "juliocbohn2@gmail.com") && (
                <Input name="Card" label="Posição do card" {...cardForm} />
              )}
              <Input name="Nome completo" label="Nome Completo" {...nameForm} />
              <Input
                name="Data de nascimento"
                label="Data de nascimento"
                type="date"
                required={false}
                {...birthDateForm}
              />
            </div>

            <div className="grid sm:grid-cols-2 gap-5">
              <div>
                <InputPhone label="Telefone" placeholder="Número de celular" {...phoneNumberForm} />
              </div>
              <SelectTimezone value="teste" multiple={false} disabled={false} {...timezone} />
            </div>
            <div className="grid sm:grid-cols-2 gap-5">
              <div className="flex items-center gap-2">
                <img src={SkypeIcon} className="w-5 h-5 sm:w-7 sm:h-7" alt="Skype icone" />
                <Input className="w-full" name="ID SKYPE" label="ID SKYPE" {...skypeIdForm} />
              </div>
              <Input name="E-mail" label="E-mail" {...emailForm} />
            </div>

            <div className="grid grid-cols-2 gap-5"></div>
            <div className="grid sm:grid-cols-1 sm:grid-rows-3 md:grid-cols-3 md:grid-rows-1 gap-3 mt-4">
              <PurpleButton onClick={handleSubmit} type="submit" className=" dark-purple text-white">
                Salvar alterações
              </PurpleButton>

              <Button onClick={handleResetClick} type="reset" className="border border-dark-purple font-dark-purple">
                Descartar alterações
              </Button>

              <Button
                onClick={() => handleClick(<DrawerConfirmDelete handleClick={() => setShowSlide(false)} />)}
                type="button"
                className="border border-dark-purple font-dark-purple">
                Deletar conta
              </Button>
            </div>

            <div className="grid sm:grid-cols-2 gap-5">
              <div className="flex mt-5 ">
                <Link to="/termodeuso">
                  <p className="mr-5 text-gray-400 text-sm">Termos de Uso</p>
                </Link>
                <Link to="/politicaPrivacidade">
                  <p className="text-gray-400 text-sm">Politicas de privacidade</p>
                </Link>
              </div>
            </div>
          </>
        </form>
      )}
    </div>
  );
};

InformationUser.propTypes = {
  loading: PropTypes.bool,
  setLoading: PropTypes.func,
};

export default InformationUser;
