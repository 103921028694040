import PropTypes from "prop-types";
import React from "react";

import * as S from "./styles.module.css";

const variants = {
  default: {
    fill: S.progressBarDefault,
    glow: false,
  },

  question: {
    fill: S.progressBarExercise,
    glow: true,
  },
};

const ProgressBar = ({ width, variant = "default" }) => {
  const variantStyle = variants[variant];

  const progressClasses = `${S.progressBar} ${variantStyle.fill}`;

  return (
    <div className={progressClasses}>
      <div className={S.progressFill} style={{ width: `${width}%` }}>
        {variantStyle.glow && <div className={S.fillGlow}></div>}
      </div>
    </div>
  );
};

ProgressBar.propTypes = {
  width: PropTypes.number.isRequired,
  variant: PropTypes.oneOf(["default", "question"]),
};

export default ProgressBar;
