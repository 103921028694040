import propTypes from "prop-types";
import React from "react";

import "./SquareButton.css";

const SquareButton = ({ children, className, ...props }) => {
  return (
    <button {...props} className={`square-button ${className}`}>
      {children}
    </button>
  );
};

export default SquareButton;

SquareButton.propTypes = {
  className: propTypes.string,
  children: propTypes.any,
};
