import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";

import {
  FavoriteStar,
  BrasilFlag,
  RussianFlag,
  SerbianFlag,
  SpainFlag,
  UnitedStatesFlag,
} from "../../../../assets/svgs/index";
import useRequest from "../../../../hooks/useRequest";
import { getDayMonthNameYear, getYear } from "../../../../utils/dates";
import Age from "./CoursesExperience/Age";
import Teach from "./CoursesExperience/Teach";

import "./TeacherProfile.css";

const TeacherIntroduction = ({ teacher, uuid }) => {
  if (teacher?.languages?.length) {
    teacher.languages = teacher.languages.filter((lang) => lang !== "Sérvio");
  }

  const [key, setKey] = useState(1);
  const [isFavorite, setIsFavorite] = useState(false);

  const { request } = useRequest();

  const handleKey = (key) => {
    setKey(key);
  };

  const flagNation = (flag) => {
    if (flag === "Português") {
      return <BrasilFlag className="m-1" />;
    }
    if (flag === "Russo") {
      return <RussianFlag className="m-1" />;
    }
    if (flag === "Sérvio") {
      return <SerbianFlag className="m-1" />;
    }
    if (flag === "Espanhol") {
      return <SpainFlag className="m-1" />;
    }
    if (flag === "Inglês") {
      return <UnitedStatesFlag className="m-1" />;
    }
  };

  const handleFavorite = async () => {
    if (!isFavorite) {
      await request("POST", "/teacher/favorite/" + uuid);

      setIsFavorite(!isFavorite);
    } else {
      await request("DELETE", "/teacher/favorite/" + uuid);

      setIsFavorite(!isFavorite);
    }
  };

  const fetchFavorite = async () => {
    const response = await request("GET", "/teacher/read/" + uuid);

    setIsFavorite(response.favorite);
  };

  useEffect(() => {
    fetchFavorite();
  }, []);

  return (
    <div className="professorCard grid md:grid-cols-3 grid-cols-1">
      <div className="md:mb-32 mb-4 md:pr-4 col-span-2">
        <div className="bg-white p-4 rounded-xl">
          <div className="flex justify-between items-center">
            <h2>Sobre mim</h2>
            <button className="favoriteButton flex items-center text-center text-sm bg-white" onClick={handleFavorite}>
              <FavoriteStar className="mr-2" />
              {isFavorite ? "Remover dos favoritos" : "Adicionar aos favoritos"}
            </button>
          </div>
          {teacher.description && <p className="mt-5 text-gray-500">{teacher.description}</p>}
          {teacher.videoUrl && (
            <iframe
              className="rounded-2xl m-auto mt-3 w-full h-[220px] md:w-[432px] md:h-[256px]"
              title="video"
              src={teacher.videoUrl}
              frameBorder="0"
              allowFullScreen
            />
          )}
        </div>
        <div className="mt-5 bg-white p-4 rounded-xl">
          <h2>Experiência de ensino</h2>
          <div className="w-full justify-center flex pt-4 text-center ">
            <button
              className={`borderEnsino pb-6 ${key == 1 ? "selectedIntroduction" : ""}`}
              value={1}
              onClick={() => handleKey(1)}>
              Ensina
            </button>
            <button
              className={`borderEnsino pb-6 ${key == 2 ? "selectedIntroduction" : ""}`}
              value={2}
              onClick={() => handleKey(2)}>
              Faixa Etária
            </button>
          </div>
          <div>
            {key === 1 && <Teach teach={teacher?.teach} />}
            {key === 2 && <Age ageRange={teacher?.ageRange} />}
          </div>
        </div>
      </div>
      <div>
        <div className="bg-white p-4 w-full rounded-xl">
          <p className="mb-4">Detalhes</p>
          <div className="flex justify-between mb-4 text-sm">
            <p className="text-gray-600">Ingressou</p>
            {teacher.createdDate && <p>{getDayMonthNameYear(teacher?.createdDate)}</p>}
          </div>
          <div className="flex justify-between mb-4 text-sm">
            <p className="text-gray-600">Idade</p>
            <p>{teacher.createdDate && new Date().getFullYear() - getYear(teacher.user.birthDate)} anos</p>
          </div>
          {teacher?.languages && (
            <div className="flex justify-between mb-2 text-sm">
              <p className="text-gray-600">Idiomas</p>
            </div>
          )}

          <div
            className="grid grid-cols-2 sm:grid-cols-1 xl:grid-cols-2 gap-x-5 md:gap-x-1 2xl:gap-x-16 mb-1 text-sm"
            style={{ textAlign: "-webkit-center" }}>
            {teacher?.languages?.map((item, index) => {
              return (
                !!flagNation(item) && (
                  <div key={index} className="m-1">
                    <button type="button" className="Button-change rounded-lg flex p-1.5">
                      {flagNation(item)}
                      <div className="mt-2 text-2xs pr-3" style={{ width: "100px" }}>
                        {item}
                      </div>
                    </button>
                  </div>
                )
              );
            })}
          </div>
        </div>
        {teacher?.hobbies && (
          <div className="bg-white mt-4 p-4 rounded-xl">
            <p className="mb-4 mt-4">Hobbies</p>
            <div className="mb-4 text-sm">
              <p className="break-normal">{teacher?.hobbies}</p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

TeacherIntroduction.propTypes = {
  teacher: PropTypes.object,
  uuid: PropTypes.string,
};

export default TeacherIntroduction;
