import PropTypes from "prop-types";
import React from "react";

import "./PurpleButton.css";

const PurpleButton = ({ className, children, type, isLoading, disabled, ...props }) => {
  return (
    <button
      {...props}
      disabled={disabled}
      className={`purpleButton ${className} ${disabled ? "button-disabled" : ""}`}
      type={type}>
      {isLoading ? <div className="loader"></div> : children}
    </button>
  );
};

PurpleButton.propTypes = {
  className: PropTypes.string,
  type: PropTypes.string,
  isLoading: PropTypes.any,
  disabled: PropTypes.bool,
  children: PropTypes.node,
};

export default PurpleButton;
