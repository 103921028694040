import A1 from "assets/imgs/A1.png";
import A2 from "assets/imgs/A2.png";
import Achievement from "assets/imgs/Achievement.png";
import B1 from "assets/imgs/B1.png";
import B2 from "assets/imgs/B2.png";
import C1 from "assets/imgs/C1.png";
import C2 from "assets/imgs/C2.png";
import Linkedin from "assets/imgs/linkedin.png";
// import Share from "assets/imgs/Share.png";
import Button from "components/MaterialCards/Button/Button";
import ProgressCircular from "components/MaterialCards/ProgressCircular/ProgressCircular";
import { useMaterialCards } from "context/MaterialCardsContext";
import { UserContext } from "context/UserContext";
import PropTypes from "prop-types";
import React, { useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { getLevelBackgroundColor } from "utils/level";

const imageLevel = {
  "A1 - Beginner": A1,
  "A2 - Elementary": A2,
  "B1 - Pre-Intermediate": B1,
  "B2 - Intermediate": B2,
  "C1 - Upper-Intermediate": C1,
  "C2 - Advanced": C2,
};

import * as S from "./styles.module.css";

const FinishedCategory = () => {
  const { user } = useContext(UserContext);
  const history = useHistory();
  const { categoryProgress } = useMaterialCards();
  const level = user.levelTest;

  const [linkedinLink, setLinkedinLink] = React.useState("");

  const color = getLevelBackgroundColor(level);
  const icon = imageLevel[level];

  const generateLinkedInLink = () => {
    let baseLink = "https://www.linkedin.com/profile/add?";

    const params = {
      startTask: "CERTIFICATION_NAME",
      name: `Conclusão do módulo ${level}`,
      organizationId: 11446089,
      issueYear: new Date().getFullYear(),
      issueMonth: new Date().getMonth() + 1,
      certUrl: "https://media.englishbay.com.br/e66604cf-d1be-45b7-8f88-5d80824fe453.jpg",
    };

    Object.keys(params).forEach((key) => {
      baseLink += `${key}=${params[key]}&`;
    });

    setLinkedinLink(baseLink);
  };

  const handleNextCard = () => {
    history.push("/");
  };

  useEffect(() => {
    generateLinkedInLink();
  }, []);

  return (
    <div className={S.wrapper}>
      <div className={S.wrapperContent}>
        <div className={S.brandingWrapper}>
          <div className={S.wrapperFlex}>
            <ProgressCircular percentage={categoryProgress} image={icon} color={color} />
            <p className={S.percentage} style={{ color }}>
              Você acertou {categoryProgress}% do nível
            </p>
          </div>

          <p className={S.title}>Parabéns {user.name} você terminou um nível!</p>

          <button onClick={() => history.push("/certificados")} className={S.certificatesButton}>
            <img src={Achievement} alt="solicitar certificado" />
            Solicite seu certificado
          </button>
        </div>

        <div>
          <div>
            <div className={S.LinkedinButton}>
              <img src={Linkedin} alt="Share" />
              <a href={linkedinLink} target="_blank" rel="noopener noreferrer" className={S.shareText}>
                Compartilhe sua conquista no LinkedIn
              </a>
            </div>
          </div>

          <div className={S.wrapperButton}>
            {/* <button className={S.shareButton}>
              <img src={Share} alt="Share" />
            </button> */}

            <Button onClick={handleNextCard} type="callToAction" variant="checking" text="Continuar" />
          </div>
        </div>
      </div>
    </div>
  );
};

FinishedCategory.propTypes = {
  restart: PropTypes.func.isRequired,
};

export default FinishedCategory;
