import propTypes from "prop-types";
import React from "react";
import { HiOutlineArrowNarrowRight } from "react-icons/hi";
import { useHistory } from "react-router-dom";

import CourseProgressBar from "../CourseProgressBar/CourseProgressBar";

import "./SecondaryCategoryCard.css";

const SecondaryCategoryCard = ({ data, color }) => {
  const { uuid, title, description, iconUrl, level, totalMaterials, viwedMaterials, children } = data;

  const history = useHistory();

  const subCategoriesLink = `material?parentUuid=${uuid}`;
  const hasSubCategories = children?.length > 0;

  const getLevelName = (level) =>
    ({
      BEGINNER: "Iniciante",
      BASIC: "Básico",
      PRE_INTERMEDIATE: "Pré-Intermediário",
      INTERMEDIATE: "Intermediário",
      UPPER_INTERMEDIATE: "Intermediário++",
      ADVANCED: "Avançado",
    }[level]);

  return (
    <section
      onClick={() => history.push(hasSubCategories ? subCategoriesLink : `material/${uuid}`)}
      className="secondary-category-card cursor-pointer"
      style={{ background: color }}>
      <div className="secondary__main">
        <img src={iconUrl} alt="category" draggable="false" className="secondary-category__image" />

        <div>
          <div className="secondary-category__header">
            <span className="secondary-category__title" style={{ height: "30px" }}>
              {title}
            </span>
            <span className="secondary-category__level">{getLevelName(level)}</span>
          </div>

          <span className="secondary-category__description" style={{ height: "70px" }}>
            {description}
          </span>
        </div>
      </div>

      <CourseProgressBar
        barHeight="8px"
        totalSteps={totalMaterials}
        doneSteps={viwedMaterials}
        detailsColor="#FFF"
        showDetails
        showPercentage
      />

      <button type="button">
        {viwedMaterials === 0
          ? "Conhecer módulo"
          : viwedMaterials === totalMaterials
          ? "Módulo concluído"
          : "Continuar módulo"}

        <HiOutlineArrowNarrowRight />
      </button>
    </section>
  );
};

export default SecondaryCategoryCard;

SecondaryCategoryCard.propTypes = {
  data: propTypes.object,
  color: propTypes.string,
}.isRequired;
