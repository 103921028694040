import { useState } from "react";

const types = {
  email: {
    regex: /^(?!.{51})([a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?)$/i,
    message: "Preencha um email válido",
    optional: false,
  },
  password: {
    regex: /^.{6,25}$/,
    message: "Preencha uma senha válida",
    optional: false,
  },
  cpfOptional: {
    regex: /^((\d{3}).(\d{3}).(\d{3})-(\d{2}))*$/,
    message: "Preencha um CPF válido",
    optional: true,
  },
  cpf: {
    regex: /^((\d{3}).(\d{3}).(\d{3})-(\d{2}))*$/,
    message: "Preencha um CPF válido",
    optional: false,
  },
  cep: {
    regex: /^[0-9]{8}$/,
    message: "Preencha um CEP válido",
    optional: false,
  },
};

const useForm = (type) => {
  const [value, setValue] = useState("");
  const [error, setError] = useState("");

  function validate(value) {
    if (type === false) {
      return true;
    }

    if (type === "optional") {
      return false;
    }

    if (type === "phoneNumber" && !/^\+[1-9]\d{10,14}$/.test(value)) {
      setError("Esse número não é válido");
      return false;
    }

    if (!value && !types[type]?.optional) {
      setError("Esse campo é necessário");
      return false;
    }

    if (value && types[type] && !types[type].regex.test(value)) {
      setError(types[type].message);
      return false;
    }

    setError(null);
    return true;
  }

  const onChange = (value) => {
    if (error) {
      validate(value);
    }

    setValue(value);
  };
  const setValueValidate = (value) => {
    if (!value) {
      setValue("");
      return;
    }
    setValue(value);
  };

  return {
    value,
    setValue: (value) => setValueValidate(value),
    onChange,
    error,
    validate: () => validate(value),
    onBlur: () => validate(value),
  };
};

export default useForm;
