import React from "react";
import { Link } from "react-router-dom";

import { Person } from "../../../assets/svgs/index";
import "./ChangePasswordRecoverySuccess.css";

const ChangePasswordRecoverySuccess = () => {
  return (
    <div className="accountRecoverySucess">
      <h6>Conta recuperada com sucesso. Vamos continuar seus estudos?</h6>

      <Person className="personIcon" />

      <Link to="/">
        <button>Acessar minha conta</button>
      </Link>
    </div>
  );
};

export default ChangePasswordRecoverySuccess;
