import PropTypes from "prop-types";
import React, { useEffect, useRef } from "react";
import { AiOutlineClose as CloseIcon } from "react-icons/ai";
import { logAnalyticsEvent } from "utils/firebase-analytics";

import FreeClassesBanners from "../../../../assets/imgs/banners/FreeClassesBanners.png";
import TestConversationImage from "../../../../assets/imgs/banners/TestConversation.png";
import EmojiBlur from "../../../../assets/imgs/EmojiBlur.png";
import WaveImage from "../../../../assets/imgs/Wave.png";
import { ReactComponent as WhatsaAppIcon } from "../../../../assets/svgs/WhatsAppIcon.svg";
import useOnClickOutside from "../../../../hooks/useOnClickOutside";
import * as S from "./styles.module.css";

const TestConversation = ({ handleHideBanner }) => {
  const ref = useRef();

  useOnClickOutside(ref, () => handleHideBanner());

  const onClick = () => {
    logAnalyticsEvent("CLICK_BANNER_TEST_CONVERSATION");

    const message = "Olá, gostaria de agendar minha aula grátis do grupo de conversação!";
    const messageEncoded = encodeURI(message);

    window.open(`https://api.whatsapp.com/send?phone=5511958565055&text=${messageEncoded}`, "_blank");
  };

  useEffect(() => {
    logAnalyticsEvent("SHOW_BANNER_TEST_CONVERSATION");
  }, []);

  return (
    <div className={S.Backdrop}>
      <div ref={ref} className={S.Container}>
        <div className={S.Wrapper}>
          <div className={S.WrapperContent}>
            <p className={S.SubTitle}>COMECE SUA JORNADA</p>

            <p className={S.Title}>
              <p>
                Participe do grupo de conversação <span className={S.Bold}>GRÁTIS</span>
              </p>
            </p>

            <p className={S.Description}>
              Agende e participe do grupo de conversação grátis para testar a plataforma da EnglishBay e comece a sua
              jornada de aprendizado de inglês hoje mesmo.
            </p>

            <button onClick={onClick} className={S.Button}>
              <WhatsaAppIcon width={22} height={22} />
              Agendar agora
            </button>
          </div>
        </div>

        <div className={S.ImageWrapper}>
          <img className={S.Image} src={FreeClassesBanners} alt="bannerImage" />

          <div className={S.WrapperButtonClose}>
            <button className={S.ButtonClose} onClick={handleHideBanner}>
              <CloseIcon size={20} color="#6A51B2" />
            </button>
          </div>
        </div>

        <img
          style={{ width: 120, height: 120, position: "absolute", zIndex: 10, top: 0, left: 0, borderRadius: 20 }}
          src={EmojiBlur}
          alt="bannerImage"
        />

        <img
          style={{ width: 120, height: 120, position: "absolute", zIndex: 10, bottom: 20 }}
          src={WaveImage}
          alt="bannerImage"
        />

        <img className={S.NotebookImage} src={TestConversationImage} alt="bannerImage" />
      </div>
    </div>
  );
};

TestConversation.propTypes = {
  handleHideBanner: PropTypes.func.isRequired,
  variant: PropTypes.number.isRequired,
};

export default TestConversation;
