import React from "react";
import { Switch, Route } from "react-router-dom";
import { Redirect } from "react-router-dom/cjs/react-router-dom.min";

import Certificates from "../_SharedPages/Certificates/Certificates";
import Checkout from "../_SharedPages/Checkout/Checkout";
import Lesson from "../_SharedPages/Lesson/Lesson";
import LessonsList from "../_SharedPages/LessonsList/LessonsList";
import Materials from "../_SharedPages/Materials/Materials";
import MyEvents from "../_SharedPages/MyEvents/MyEvents";
import MyPlans from "../_SharedPages/MyPlans/MyPlans";
import MyTeachers from "../_SharedPages/MyTeachers/MyTeachers";
import ProfessorProfile from "../_SharedPages/MyTeachers/ProfessorProfile/ProfessorProfile";
import PrivacyPolices from "../_SharedPages/PrivacyPolices/PrivacyPolices";
import Profile from "../_SharedPages/Profile/Profile";
import TermsOfUse from "../_SharedPages/TermsOfUse/TermsOfUse";
import ChatBot from "../../_Shared/Chatbot/Chatbot";
import Version from "../../../Version";
import Dashboard from "../../Dashboard/Dashboard";
import NotFound from "../../NotFound/NotFound";
import IndexLeads from "../Lead/IndexLead/IndexLeads";
import Plans from "../Lead/Plans/Plans";
import MyLessons from "./MyLessons/MyLessons";
import { StudentSidebarLinks } from "./StudentSidebarLinks";

const Student = () => {
  return (
    <>
      <Dashboard userSidebarLinks={StudentSidebarLinks}>
        <Switch>
          <Route path="/" exact component={IndexLeads} />
          <Route path="/open">
            <Redirect to="/" />
          </Route>

          <Route path="/perfil/*" component={Profile} />
          <Route path="/termodeuso" component={TermsOfUse} />
          <Route path="/politicaPrivacidade" component={PrivacyPolices} />

          <Route path="/minhasAulas" exact component={MyLessons} />

          <Route path="/assinatura" exact component={MyPlans} />

          <Route path="/planos/checkout" component={Checkout} />
          <Route path="/planos" component={Plans} />

          <Route path="/professores" exact component={MyTeachers} />
          <Route path="/professores/perfil/:uuid" exact component={ProfessorProfile} />

          <Route path="/material" exact component={Materials} />
          <Route path="/material/:uuid" component={LessonsList} />
          <Route path="/lesson/:uuid" component={Lesson} />

          <Route path="/eventos" component={MyEvents} />

          <Route path="/certificados" component={Certificates} />

          <Route path="/version" component={Version} />
          <Route path="*" component={NotFound} />
        </Switch>
      </Dashboard>

      <ChatBot />
    </>
  );
};

export const StudentPremium = () => {
  return (
    <>
      <Dashboard userSidebarLinks={StudentSidebarLinks}>
        <Switch>
          <Route path="/" exact component={IndexLeads} />
          <Route path="/perfil/*" component={Profile} />
          <Route path="/termodeuso" component={TermsOfUse} />
          <Route path="/politicaPrivacidade" component={PrivacyPolices} />

          <Route path="/minhasAulas" exact component={MyLessons} />

          <Route path="/assinatura" exact component={MyPlans} />

          <Route path="/planos/checkout" component={Checkout} />
          <Route path="/planos" component={Plans} />

          <Route path="/professores" exact component={MyTeachers} />
          <Route path="/professores/perfil/:uuid" exact component={ProfessorProfile} />

          <Route path="/material" exact component={Materials} />
          <Route path="/material/:uuid" component={LessonsList} />
          <Route path="/lesson/:uuid" component={Lesson} />

          <Route path="/eventos" component={MyEvents} />

          <Route path="/certificados" component={Certificates} />

          <Route path="/version" component={Version} />
          <Route path="*" component={NotFound} />
        </Switch>
      </Dashboard>

      <ChatBot />
    </>
  );
};

export default Student;
