import { useGoogleLogin } from "@react-oauth/google";
import React, { useState, useEffect, useContext } from "react";
import AppleLogin from "react-apple-login";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import { Link, useHistory } from "react-router-dom";
import Turnstile from "react-turnstile";
import { logAnalyticsEvent } from "utils/firebase-analytics";

import { RadioButton, InputPhone } from "../../_Shared/Form/index";
import { SpinnerWhite } from "../../../assets/svgs/index";
import { ToastContext } from "../../../context/ToastContext";
import { UserContext } from "../../../context/UserContext";
import useForm from "../../../hooks/useForm";
import useRequest from "../../../hooks/useRequest";
import { saveToken, saveUser } from "../../../utils/localStorage";

import "./SignUpForm.css";

const isMacLike = /(Mac|iPhone|iPod|iPad)/i.test(navigator.platform);

function SignUpForm() {
  const { request } = useRequest();
  const { setUserContext, setCaptchaToken, getOnBoardingPayload } = useContext(UserContext);
  const { showToast } = useContext(ToastContext);
  const history = useHistory();

  const acceptedTermsForm = useForm();

  const [isLoading, setIsLoading] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [whatsapp, setWhatsapp] = useState();
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [terms, setTerms] = useState("");
  const [termsVersion, setTermsVersion] = useState();
  const [privacy, setPrivacy] = useState("");
  const [privacyVersion, setPrivacyVersion] = useState();
  const [errorMessage, setErrorMessage] = useState(null);
  const [cData, setCData] = useState(Date.now().toString()); // usado para resetar o captcha

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (password !== confirmPassword) {
      setErrorMessage("As senhas não correspondem!");
      return;
    }

    const signupPayload = {
      name,
      email,
      password,
      phoneNumber: whatsapp,
      lastTermsAcceptedVersion: termsVersion,
      lastPrivacyAcceptedVersion: privacyVersion,
      onBoarding: getOnBoardingPayload(),
      os: "web",
    };

    if (!signupPayload.onBoarding.wantTestClass) {
      signupPayload.onBoarding.wantTestClass = "Não";
    }

    const originCode = localStorage.getItem("originCode");
    if (originCode) {
      signupPayload.originCode = originCode;
    }

    setIsLoading(true);
    const { token, user, error } = await request("POST", "/auth/signup", signupPayload, true, false, true);
    setIsLoading(false);
    setCData(Date.now().toString()); // reseta o captcha

    if (error && error.statusCode === 409) {
      setErrorMessage("Já existe uma conta com esse email!");
      return;
    }

    if (error && error.properties?.find((property) => property.name === "phoneNumber")) {
      showToast({ message: "Preencha corretamente seu telefone", type: "error" });
      return;
    }

    if (error && error.statusCode === 400) {
      showToast({ message: "Preencha corretamente seus dados", type: "error" });
      return;
    }

    // Salva evento no GTM
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: "sign_up",
      method: "email", // Método de cadastro (fb, gmail ou email)
      userId: user.uuid,
    });

    logAnalyticsEvent("af_complete_registration", {
      af_registration_method: "EMAIL",
    });

    localStorage.removeItem("timesAlreadyShowedFreeClasses");
    saveToken(token);
    saveUser(user);
    setUserContext(user);
    history.push(originCode === "lp-39" ? "/planos" : "/");
  };

  const validateOauth = async (accessToken, origin, onBoarding, givenName) => {
    setIsLoading(true);

    const body = {
      origin,
      accessToken,
      givenName,
      onBoarding,
      os: "web",
    };

    if (onBoarding.userPhoneNumber) {
      body.phoneNumber = onBoarding.userPhoneNumber;
    }

    const originCode = localStorage.getItem("originCode");
    if (originCode) {
      body.originCode = originCode;
    }

    const { token, user, isSignUp } = await request("POST", "/auth/externalLogin", body, true, false, true);

    if (user && token) {
      if (isSignUp) {
        logAnalyticsEvent("af_complete_registration", {
          af_registration_method: origin,
        });
      } else {
        logAnalyticsEvent("af_login", {
          af_login_method: origin,
        });
      }

      localStorage.removeItem("timesAlreadyShowedFreeClasses");
      saveToken(token);
      saveUser(user);
      setUserContext(user);
      history.push(originCode === "lp-39" ? "/planos" : "/");

      return true;
    }
  };

  const loginGoogle = useGoogleLogin({
    onSuccess: async ({ access_token }) => {
      await validateOauth(access_token, "GOOGLE", getOnBoardingPayload());
    },
  });

  const loginFacebook = async (response) => {
    await validateOauth(response.accessToken, "FACEBOOK", getOnBoardingPayload(), response.name);
  };

  const loginApple = async (response) => {
    await validateOauth(
      response.authorization.id_token,
      "APPLE",
      getOnBoardingPayload(),
      response.user?.name?.firstName,
    );
  };

  useEffect(() => {
    const fetchTerms = async () => {
      const userType = "USER";

      const [term, privacy] = await Promise.all([
        request("GET", `/terms/read?kind=TERM&userType=${userType}`),
        request("GET", `/terms/read?kind=PRIVACY&userType=${userType}`),
      ]);

      setTerms(term.text);
      setTermsVersion(term.version);
      setPrivacy(privacy.text);
      setPrivacyVersion(privacy.version);
    };

    const checkIfHasOnBoarding = () => {
      const payload = JSON.parse(localStorage.getItem("userData"));
      const hasOnBoarding = payload && payload.reason && payload.age && payload.interest;

      if (!hasOnBoarding) {
        history.push("/onboarding");
      }

      if (payload.userPhoneNumber) {
        setWhatsapp(payload.userPhoneNumber);
      }

      const hasExternalLogin = sessionStorage.getItem("externalLogin");

      if (hasExternalLogin) {
        const { accessToken, origin, givenName } = JSON.parse(hasExternalLogin);
        validateOauth(accessToken, origin, getOnBoardingPayload(), givenName);
      }
    };

    logAnalyticsEvent("OB_signupForm");

    checkIfHasOnBoarding();
    fetchTerms();
  }, []);

  return (
    <div className="signup-wrapper">
      <div className="signup__header">
        <span className="header__title">{"Falta pouco! 🥳"}</span>
        <span className="header__description">Complete seu cadastro para começar os estudos!</span>
      </div>

      <div className="flex items-center justify-between mb-4 mt-4">
        <button
          onClick={loginGoogle}
          className="cursor-pointer border borderRadius font-semibold border-gray-100 hover:bg-purple-700 hover:text-white py-2 px-4 inline-flex items-center mr-2">
          <img className="w-5 mr-2" alt="google" src="https://img.icons8.com/fluent/48/000000/google-logo.png" />
          Entrar com Google
        </button>
        {isMacLike && (
          <AppleLogin
            clientId="com.br.englishbay.web"
            callback={loginApple}
            redirectURI="https://app.englishbay.com.br"
            scope="name email"
            responseType="id_token"
            usePopup={true}
            render={(renderProps) => (
              <button
                onClick={renderProps.onClick}
                className="cursor-pointer border borderRadius font-semibold border-gray-100 hover:bg-purple-700 hover:text-white py-2 px-4 inline-flex items-center mr-2">
                <img
                  style={{ width: "22px", height: "22px" }}
                  className="mr-2"
                  alt="apple"
                  src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjwhRE9DVFlQRSBzdmcgIFBVQkxJQyAnLS8vVzNDLy9EVEQgU1ZHIDEuMS8vRU4nICAnaHR0cDovL3d3dy53My5vcmcvR3JhcGhpY3MvU1ZHLzEuMS9EVEQvc3ZnMTEuZHRkJz48c3ZnIGVuYWJsZS1iYWNrZ3JvdW5kPSJuZXcgMCAwIDU2LjY5MyA1Ni42OTMiIGhlaWdodD0iNTYuNjkzcHgiIGlkPSJMYXllcl8xIiB2ZXJzaW9uPSIxLjEiIHZpZXdCb3g9IjAgMCA1Ni42OTMgNTYuNjkzIiB3aWR0aD0iNTYuNjkzcHgiIHhtbDpzcGFjZT0icHJlc2VydmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPjxnPjxwYXRoIGQ9Ik00MS43NzcsMzAuNTE3Yy0wLjA2Mi02LjIzMiw1LjA4Mi05LjIyMSw1LjMxMi05LjM3MmMtMi44OTEtNC4yMjctNy4zOTUtNC44MDctOC45OTgtNC44NzMgICBjLTMuODMtMC4zODktNy40NzcsMi4yNTYtOS40MiwyLjI1NmMtMS45MzksMC00Ljk0MS0yLjE5OS04LjExNy0yLjE0M2MtNC4xNzgsMC4wNjItOC4wMjksMi40My0xMC4xNzksNi4xNyAgIGMtNC4zMzksNy41MjctMS4xMSwxOC42ODIsMy4xMTgsMjQuNzkxYzIuMDY3LDIuOTg2LDQuNTMyLDYuMzQ2LDcuNzY2LDYuMjIzYzMuMTE3LTAuMTIzLDQuMjkzLTIuMDE2LDguMDYxLTIuMDE2ICAgczQuODI2LDIuMDE2LDguMTIzLDEuOTUzYzMuMzUyLTAuMDYxLDUuNDc3LTMuMDQzLDcuNTI3LTYuMDQxYzIuMzczLTMuNDY5LDMuMzUtNi44MjgsMy40MDgtNi45OTggICBDNDguMzA1LDQwLjQzMyw0MS44NDQsMzcuOTU4LDQxLjc3NywzMC41MTd6Ii8+PHBhdGggZD0iTTM1LjU4MiwxMi4yMjljMS43MTUtMi4wODIsMi44NzctNC45NzUsMi41NjEtNy44NTVjLTIuNDc1LDAuMS01LjQ3MSwxLjY0NS03LjI0OCwzLjcyNSAgIGMtMS41OTIsMS44NDYtMi45ODQsNC43ODUtMi42MTEsNy42MTNDMzEuMDQ1LDE1LjkyNiwzMy44NjEsMTQuMzA3LDM1LjU4MiwxMi4yMjl6Ii8+PC9nPjwvc3ZnPg=="
                />
                Entrar com Apple
              </button>
            )}
          />
        )}

        {!isMacLike && (
          <FacebookLogin
            responseType="token"
            fields="name,email,picture"
            appId="223054333877393"
            callback={loginFacebook}
            render={(renderProps) => (
              <button
                onClick={renderProps.onClick}
                className="cursor-pointer border borderRadius font-semibold border-gray-100 hover:bg-purple-700 hover:text-white py-2 px-4 inline-flex items-center">
                <img
                  className="w-5 mr-2"
                  alt="facebook"
                  src="https://img.icons8.com/fluent/48/000000/facebook-new.png"
                />
                Entrar com Facebook
              </button>
            )}
          />
        )}
      </div>
      <div className="text-center font-low text-sm mb-4 mt-2">
        <div>ou entre por e-mail</div>
      </div>

      <form className="signup__form" onSubmit={handleSubmit}>
        <input
          minLength={4}
          type="text"
          name="name"
          placeholder="Nome"
          onChange={({ target }) => setName(target.value)}
          required
        />

        <input
          type="email"
          name="email"
          placeholder="Email"
          onChange={({ target }) => setEmail(target.value)}
          required
        />

        <InputPhone
          label="Telefone"
          placeholder="Número do Whatsapp"
          onChange={setWhatsapp}
          value={JSON.parse(localStorage.getItem("userData")).userPhoneNumber}
          style={{ marginTop: "-5px", border: "1px solid #ddd;" }}
        />

        <input
          type="password"
          name="password"
          placeholder="Senha"
          onChange={({ target }) => setPassword(target.value)}
          required
        />

        <input
          type="password"
          name="confirm-password"
          placeholder="Confirmar senha"
          onChange={({ target }) => setConfirmPassword(target.value)}
          required
        />

        {errorMessage && <small className="password-message ml-1">{errorMessage}</small>}

        <div className="d-flex" style={{ display: "flex", justifyContent: "left", marginBottom: "15px" }}>
          <Turnstile
            className="mb-3"
            sitekey={process.env.REACT_APP_TURNSTILE_SITE_KEY}
            theme="light"
            onVerify={setCaptchaToken}
            cData={cData}
          />
        </div>

        <div>
          <RadioButton terms={terms} privacy={privacy} {...acceptedTermsForm} />
        </div>

        <button className="btn-signup" disabled={isLoading}>
          {isLoading ? <SpinnerWhite className="w-7 mx-auto animate-spin" /> : "Começar a estudar!"}
        </button>

        <Link to="/auth/login" className="have-account-link">
          Já possui uma conta?
        </Link>
      </form>
    </div>
  );
}

export default SignUpForm;
