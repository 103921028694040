import PropTypes from "prop-types";
import React from "react";

import { Remove } from "../../../assets/svgs";
import { ArrowLeftPurple } from "../../../assets/svgs";

import "./ActionButton.css";

const ActionButtonsSvgs = {
  close: <Remove />,
  back: <ArrowLeftPurple />,
};
const ActionButton = ({ onClick, icon }) => {
  return (
    <button className="action-button" onClick={onClick}>
      {ActionButtonsSvgs[icon]}
    </button>
  );
};

ActionButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  icon: PropTypes.string.isRequired,
};

export default ActionButton;
