import PropTypes from "prop-types";
import React from "react";

const AnswersCheckboxLabel = ({ name, value, text, elements, setElements }) => {
  const handleclick = (value, newValue, setValue) => {
    if (value?.includes(newValue)) {
      setValue(value?.filter((element) => element !== newValue));
      return;
    }

    if (value) {
      setValue([...value, newValue]);
    } else {
      setValue([newValue]);
    }
  };
  return (
    <>
      <div className="flex items-center">
        <input
          value={value}
          id={value}
          onChange={() => handleclick(elements, value, setElements)}
          className="w-7 h-7 cursor-pointer"
          name={name}
          type="checkbox"
          checked={elements?.includes(value)}
          required
        />
        <label htmlFor={value} className="ml-4 text-base cursor-pointer" style={{ userSelect: "none" }}>
          {text}
        </label>
      </div>
    </>
  );
};

AnswersCheckboxLabel.propTypes = {
  name: PropTypes.string,
  text: PropTypes.string,
  value: PropTypes.string,
  setElements: PropTypes.func,
  elements: PropTypes.array,
};

export default AnswersCheckboxLabel;
