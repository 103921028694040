import PropTypes from "prop-types";
import React from "react";
import { Link } from "react-router-dom";

import { LargeArrow, Videos } from "../../../../assets/svgs/index";
import CardDashed from "../Card/CardDashed";

const CardAvailableClass = ({ isLarger, credits, isTeacher = false }) => {
  return (
    <CardDashed style={isLarger ? { width: "378px", height: "263px" } : { width: "255px", height: "263px" }}>
      <div className="flex-1 w-56 sm:w-auto items-center justify-between">
        <div className="borderGradient-light-gray m-auto rounded-2xl p-2 w-16 mt-10 lg:mb-8 mb:mb-0">
          <Videos className="w-10 stroke-current text-gray-300 m-auto" />
        </div>
        <p className="text-sm text-gray-500 m-9 md:m-4 lg:m-9 text-center items-center">
          {isTeacher ? (
            <span>Nenhuma aula próxima</span>
          ) : (
            <span>Você tem {credits?.available ?? "0"} aulas disponiveis</span>
          )}
        </p>
      </div>
      <div className="flex justify-between px-4 lg:mt-[10px] md:mt-0">
        <Link to={isTeacher ? "cronograma" : "/professores"}>
          <button className="text-sm text-gray-400 p-2 font-bold mr-2" href="/">
            {isTeacher ? "Ver agenda" : "Agendar agora"}
          </button>
        </Link>
        <LargeArrow fill="#14c4be" className="w-4 stroke-current text-gray-400 mr-2" />
      </div>
    </CardDashed>
  );
};

CardAvailableClass.propTypes = {
  isLarger: PropTypes.bool,
  isTeacher: PropTypes.bool,
  credits: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
};

export default CardAvailableClass;
