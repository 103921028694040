import { utcToZonedTime } from "date-fns-tz";
import React, { useContext, useEffect, useState, useRef } from "react";
import Slider from "react-slick";

import { SpinnerPurple, ArrowLeft, ArrowRigth } from "../../../assets/svgs/index";
import { SlideContext } from "../../../context/SlideContext";
import { UserContext } from "../../../context/UserContext";
import useRequest from "../../../hooks/useRequest";
import { formatIso } from "../../../utils/dates";
import CardAvailableClass from "../Cards/CardAvailableClass/CardAvailableClass";
import CardLesson from "../Cards/CardLesson/CardLesson";
import CardNextClass from "../Cards/CardNextClass/CardNextClass";
import DrawerDetailsClass from "../Slide/DrawerDetailsClass/DrawerDetailsClass";

const LessonsCardsCarousel = () => {
  const { credits, user } = useContext(UserContext);
  const refCarousel = useRef();
  const { request } = useRequest();
  const [appointments, setAppointments] = useState();
  const [loading, setLoading] = useState();

  const [activeSlide, setActiveSlide] = useState(0);
  const [disablePrev, setDisablePrev] = useState(true);
  const [disableNext, setDisableNext] = useState(false);

  const settings = {
    arrows: false,
    infinite: false,
    variableWidth: true,
    slidesToScroll: 1,
    beforeChange: (current, next) => setActiveSlide(next),
  };

  const getAppointments = async () => {
    setLoading(true);
    if (!user?.timezone) {
      return;
    }

    const appointmentsReq = await request("GET", "/appointment/listNext");

    appointmentsReq.forEach((appointment) => {
      appointment.schedule.dateTime = formatIso(utcToZonedTime(appointment.schedule.dateTime, user.timezone));
    });

    setAppointments(appointmentsReq);
    setLoading(false);
  };

  useEffect(() => {
    getAppointments();
  }, [user?.timezone]);

  const { setShowSlide, setSlideComponent } = useContext(SlideContext);

  const handleClick = (appointment) => {
    setShowSlide(true);
    setSlideComponent(<DrawerDetailsClass getAppointments={getAppointments} appointment={appointment} />);
  };

  useEffect(() => {
    if (!appointments) {
      return;
    }

    if (activeSlide === 0) {
      setDisablePrev(true);
    } else {
      setDisablePrev(false);
    }

    const totalLength = appointments.length > 6 ? appointments.length : 6;

    if (activeSlide >= totalLength) {
      setDisableNext(true);
    } else {
      setDisableNext(false);
    }
  }, [activeSlide]);

  const handleClickNext = () => {
    refCarousel.current.slickNext();
  };

  const handleClickPrev = () => {
    refCarousel.current.slickPrev();
  };

  return (
    <div>
      {loading && <SpinnerPurple className="w-10 m-auto mt-5 animate-spin" />}
      {!loading && (
        <>
          <div className="flex justify-between items-center">
            <h2 className="text-2xl">
              Você possui {appointments && appointments.length}{" "}
              {appointments?.length !== 1 ? "aulas agendadas" : "aula agendada"}
            </h2>
            <div className="flex gap-1">
              <button onClick={handleClickPrev}>
                <ArrowLeft className={`w-2 fill-current ${disablePrev ? "text-gray-400" : "font-aqua-green"} mr-3`} />
              </button>
              <button onClick={handleClickNext}>
                <ArrowRigth
                  id="next"
                  className={`w-2 fill-current ${disableNext ? "text-gray-400" : "font-aqua-green"} mr-3`}
                />
              </button>
            </div>
          </div>
          <div className="p-2">
            <Slider ref={refCarousel} {...settings}>
              <CardLesson credits={credits} />
              {appointments &&
                appointments.map((card, index) => (
                  <CardNextClass
                    isNext={index === 0}
                    key={card.uuid}
                    name={card.schedule.teacher.user.name}
                    type={card.type}
                    dateTime={card.schedule.dateTime}
                    teacher={card.schedule.teacher}
                    clickHandler={() => handleClick(card)}
                  />
                ))}
              {appointments && appointments.length < 2 && <CardAvailableClass credits={credits} />}
              {appointments && appointments.length < 3 && <CardAvailableClass credits={credits} />}
              {appointments && appointments.length < 4 && <CardAvailableClass credits={credits} />}
              {appointments && appointments.length < 5 && <CardAvailableClass credits={credits} />}
              {appointments && appointments.length < 6 && <CardAvailableClass credits={credits} />}
            </Slider>
          </div>
        </>
      )}
    </div>
  );
};

export default LessonsCardsCarousel;
