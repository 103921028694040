import BannerTexture from "assets/imgs/banner_texture_rect.png";
import SpatialChat from "assets/imgs/SpatialChat.gif";
import { useIsStudent } from "hooks/useIsStudent";
import React from "react";
import { HiOutlineArrowNarrowRight } from "react-icons/hi";
import "./BannerConversationRect.css";
import { logAnalyticsEvent } from "utils/firebase-analytics";

const BannerConversationRect = () => {
  const { handleRedirect } = useIsStudent();

  return (
    <div
      className="banner_conversation_rect"
      style={{ backgroundImage: `url(${BannerTexture})` }}
      onClick={() => {
        logAnalyticsEvent("banner_click_conversacao_01");
        handleRedirect("conversation", "/eventos");
      }}>
      <div>
        <p>
          Pratique <span>conversação</span> com outras pessoas
        </p>

        <button className="flex items-center gap-2 mt-3">
          PARTICIPE AGORA <HiOutlineArrowNarrowRight />
        </button>
      </div>

      <img src={SpatialChat} alt="Conversation group" />
    </div>
  );
};

export default BannerConversationRect;
