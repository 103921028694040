import React from "react";

// import Notification from "./Notification";

const Notifications = () => {
  return (
    <div>
      <h1 className="font-medium text-lg">Essa funcionalidade estará disponível em breve</h1>
      {/* <h1 className="font-medium text-lg">Suas Notificações</h1> */}
      {/* <p className="font-medium text-sm text-gray-500 mb-2">Todas as atualizações em nossa plataforma aparecerá aqui</p>
      <Notification />
      <Notification />
      <Notification />
      <Notification />
      <Notification />
      <Notification /> */}
    </div>
  );
};

export default Notifications;
