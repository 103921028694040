import PropTypes from "prop-types";
import React, { useState, useContext } from "react";

import { CloseX, PersonBad, SpinnerWhite } from "../../../../assets/svgs/index";
import { ToastContext } from "../../../../context/ToastContext";
import useRequest from "../../../../hooks/useRequest";
import { PurpleButton } from "../../Form/index";
import AnswerCheckboxLabel from "./AnswersCheckboxLabel";

const SuccessDeletePlan = ({ handleClick, setEnableClickAway }) => {
  const [answers, setAnswers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [checkedOther, setCheckedOther] = useState(false);
  const [otherValue, setOtherValue] = useState("");
  const { showToast } = useContext(ToastContext);
  const { request } = useRequest();

  const handleSubmit = async () => {
    if (!answers.length && !checkedOther) {
      showToast({ message: "Selecione pelo menos uma opção", type: "error" });
      return;
    }

    setIsLoading(true);
    const payload = [...answers];

    if (checkedOther && otherValue?.length) {
      payload.push("Outro: " + otherValue);
    }

    await request("POST", "/feedback/create", { answers: payload });
    setIsLoading(false);
    setEnableClickAway(true);
    handleClick();
  };

  return (
    <div className="flex h-full flex-col flex-1 overflow-x-hidden md:overflow-auto relative md:w-full w-[55rem]">
      <header className="flex justify-between items-center">
        <h1
          className="text-xl"
          style={{
            color: "#383743",
          }}>
          Ficamos tristes que você já vai. Pode nos ajudar a melhorar?
        </h1>
        <CloseX className="cursor-pointer mr-4 md:mr-4" onClick={handleClick} />
      </header>

      <h2
        className="text-base mt-2"
        style={{
          color: "#66647A",
        }}>
        Ficamos tristes que tenha cancelado seu plano. Você poderá utilizar seus créditos de aula até sua expiração e
        continuará tendo acesso ao site.
      </h2>

      <div className="self-center md:mt-6 mt-10">
        <PersonBad className="h-[280px] md:w-[310px]" />
      </div>

      <h2
        className="mt-8 text-base"
        style={{
          color: "#66647A",
        }}>
        Queríamos entender melhor o motivo do seu cancelamento. Buscamos sempre melhorar nosso serviço e seus
        comentários são muito importantes!
        <br />
        <span className="font-bold">Por que você cancelou o seu plano? </span> (Selecione quantos quiser)
      </h2>

      <div className="mt-5 grid grid-cols-1 md:grid-cols-2 gap-y-3 gap-x-8">
        <div>
          <AnswerCheckboxLabel
            elements={answers}
            setElements={setAnswers}
            name="answer1"
            value="Estou ou ficarei sem tempo para fazer as aulas"
            text="Estou ou ficarei sem tempo para fazer as aulas"
          />
        </div>
        <div>
          <AnswerCheckboxLabel
            elements={answers}
            setElements={setAnswers}
            name="answer2"
            value="O valor não cabe mais em meu orçamento"
            text="O valor não cabe mais em meu orçamento"
          />
        </div>
        <div>
          <AnswerCheckboxLabel
            elements={answers}
            setElements={setAnswers}
            name="answer3"
            value="Não gostei do material didático"
            text="Não gostei do material didático"
          />
        </div>

        <div>
          <AnswerCheckboxLabel
            elements={answers}
            setElements={setAnswers}
            name="answer4"
            value="Não gostei/me adaptei ao professor"
            text="Não gostei/me adaptei ao professor"
          />
        </div>
        <div>
          <AnswerCheckboxLabel
            elements={answers}
            setElements={setAnswers}
            name="answer5"
            value="Já atingi meus objetivos e metas com o inglês"
            text="Já atingi meus objetivos e metas com o inglês"
          />
        </div>
        <div>
          <AnswerCheckboxLabel
            elements={answers}
            setElements={setAnswers}
            name="answer6"
            value="Não tenho mais interesse nas aulas"
            text="Não tenho mais interesse nas aulas"
          />
        </div>

        <div>
          <div className="flex items-center">
            <input
              className="w-7 h-7 cursor-pointer"
              type="checkbox"
              id="answer7"
              style={{ borderRadius: "2px" }}
              onChange={() => setCheckedOther(!checkedOther)}
            />
            <label htmlFor="answer7" className="ml-3 text-base cursor-pointer">
              Outro
            </label>

            <input
              className="ml-3 pl-2 text-base"
              placeholder="Conte-nos"
              onChange={({ target }) => setOtherValue(target.value)}
              style={{
                border: "1px solid #000000",
                borderRadius: "2px",
                color: "#66647A",
                width: "70%",
              }}
            />
          </div>
        </div>
      </div>

      <h1
        className="text-sm md:text-base font-bold mt-6"
        style={{
          color: "#66647A",
        }}>
        Obrigado. Nós gostamos de tê-lo como aluno e esperamos que você volte em breve.
      </h1>

      <div className="grid grid-cols-3 gap-5 p-2 flex-1 h-1/2"></div>

      <div
        className="flex pt-5"
        style={{
          borderTop: "1px solid #F0F0F0",
        }}>
        <PurpleButton
          style={{
            width: "229px",
          }}
          onClick={handleSubmit}>
          {isLoading ? <SpinnerWhite className="animate-spin" style={{ width: "30px" }} /> : "Enviar feedback"}
        </PurpleButton>
      </div>
    </div>
  );
};

SuccessDeletePlan.propTypes = {
  handleClick: PropTypes.func,
  setEnableClickAway: PropTypes.func,
};

export default SuccessDeletePlan;
