import React, { useContext } from "react";

import { CloseX, LessonCancelSuccess } from "../../../../assets/svgs/index";
import { SlideContext } from "../../../../context/SlideContext";

const DrawerDetailsCancelSuccess = () => {
  const { setShowSlide } = useContext(SlideContext);

  return (
    <div className="w-96 h-full flex flex-col">
      <div>
        <header className="flex flex-row justify-between items-center">
          <h1 className="text-2xl">Aula cancelada com sucesso</h1>

          <CloseX className="cursor-pointer" onClick={() => setShowSlide(false)} />
        </header>
      </div>
      <div className="mt-2 flex-1">
        <LessonCancelSuccess className="w-full" />
      </div>
      <div className="borderButtons pt-4 flex">
        <button
          onClick={() => setShowSlide(false)}
          className="cancelLesson pt-4 pb-4 pr-18 pl-18 rounded-lg tracking-wider text-sm font-semibold w-60 h-14">
          Voltar para o início
        </button>
      </div>
    </div>
  );
};

export default DrawerDetailsCancelSuccess;
