import React, { useState, useEffect, useContext } from "react";
import { HiChevronDown } from "react-icons/hi";
import { Link } from "react-router-dom";

import { UserContext } from "../../../context/UserContext";
import { getFirstName } from "../../../utils/utils";

import "./ProfileDropdown.css";

function ProfileDropdown() {
  const { user } = useContext(UserContext);

  const [isOptionVisible, setIsOptionVisible] = useState(false);

  const handleClickOut = ({ target }) => {
    const targetClass = typeof target.className !== "string" ? target.className.baseVal : target.className;

    const isClickedOut = !targetClass.includes("dropdown");

    if (isClickedOut) {
      setIsOptionVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOut);
  }, []);

  return (
    <div className="header__dropdown">
      <img
        className="dropdown__image"
        src={user?.profilePictureUrl ?? process.env.REACT_APP_PROFILE_AVATAR_URL}
        alt="profile pic"
        onError={({ currentTarget }) => {
          currentTarget.src = process.env.REACT_APP_PROFILE_AVATAR_URL;
        }}
      />

      <span className="dropdown__username" onClick={() => setIsOptionVisible(!isOptionVisible)}>
        {getFirstName(user?.name)}{" "}
        <HiChevronDown className={`dropdown__chevron-down ${isOptionVisible && "chevron-up"}`} />
      </span>

      {isOptionVisible && (
        <div className="dropdown__options">
          <Link to="/perfil/informacoes" className="dropdown__link" onClick={() => setIsOptionVisible(false)}>
            Informações da conta
          </Link>

          <Link to="/perfil/alterarSenha" className="dropdown__link" onClick={() => setIsOptionVisible(false)}>
            Alterar senha
          </Link>

          <Link to="/perfil/notificacoes" className="dropdown__link" onClick={() => setIsOptionVisible(false)}>
            Notificações
          </Link>
        </div>
      )}
    </div>
  );
}

export default ProfileDropdown;
