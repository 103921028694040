import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { HiOutlineDotsHorizontal } from "react-icons/hi";

import Message from "../Message/Message";
import * as S from "./styles.module.css";

const BotResponse = ({ message, isLoading, loadingDuration, handleScrollToBottom }) => {
  const [showMessage, setShowMessage] = useState(false);
  const [showLoading, setShowLoading] = useState(isLoading);

  useEffect(() => {
    handleScrollToBottom();

    if (loadingDuration) {
      setTimeout(() => {
        setShowLoading(false);
        setShowMessage(true);
        handleScrollToBottom();
      }, loadingDuration);

      return;
    }

    setShowLoading(isLoading);
  }, [isLoading, loadingDuration]);

  return (
    <Message>
      <div
        className={S.BotResponse}
        style={{
          borderTopLeftRadius: 5,
          borderBottomLeftRadius: 20,
        }}>
        {showLoading && !showMessage ? <HiOutlineDotsHorizontal /> : message}
      </div>
    </Message>
  );
};

BotResponse.propTypes = {
  message: PropTypes.string.isRequired,
  isLoading: PropTypes.bool,
  loadingDuration: PropTypes.number,
  handleScrollToBottom: PropTypes.func.isRequired,
};

export default BotResponse;
