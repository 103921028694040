import PropTypes from "prop-types";
import React from "react";

import * as S from "./styles.module.css";

const callToActionVariant = {
  checking: S.callToActionChecking,
  correct: S.callToActionCorrect,
  incorrect: S.callToActionIncorrect,
  disabled: S.disabled,
};

const answerVariant = {
  checking: S.answerChecking,
  correct: S.answerCorrect,
  incorrect: S.answerIncorrect,
  disabled: S.disabled,
};

const Button = ({ type = "answer", variant, text, image, onClick, disabled }, props) => {
  const buttonType = type === "answer" ? S.answer : S.callToAction;

  const buttonVariant = type === "answer" ? answerVariant[variant] : callToActionVariant[variant];

  const buttonClass = `${buttonVariant} ${buttonType} ${S.wrapper}`;

  return (
    <button onClick={onClick} className={buttonClass} disabled={disabled} {...props}>
      {image && <img className={S.image} src={image} alt={text} />}

      {!image && text && <span className={S.text}>{text}</span>}
    </button>
  );
};

Button.propTypes = {
  type: PropTypes.oneOf(["answer", "callToAction"]),
  variant: PropTypes.oneOf(["checking", "correct", "incorrect"]),
  text: PropTypes.string,
  onClick: PropTypes.func,
  image: PropTypes.string.optional,
  disabled: PropTypes.bool,
};

export default Button;
