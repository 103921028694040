import PropTypes from "prop-types";
import React from "react";
import ReactPaginate from "react-paginate";

import { PaginationChevronRight, PaginationChevronLeft } from "../../../assets/svgs";

import "./Pagination.css";

const Pagination = ({ handlePageChange, totalPages, forcePage }) => {
  return (
    <ReactPaginate
      pageRangeDisplayed={2}
      marginPagesDisplayed={1}
      nextLabel={<PaginationChevronRight />}
      previousLabel={<PaginationChevronLeft />}
      onPageChange={handlePageChange}
      pageCount={totalPages}
      containerClassName="flex items-center justify-center gap-1"
      pageLinkClassName="px-3"
      activeClassName="bg-purple-800 text-white rounded-md"
      disabledClassName="disabledPagination"
      forcePage={forcePage}
    />
  );
};

Pagination.propTypes = {
  handlePageChange: PropTypes.func.isRequired,
  totalPages: PropTypes.number.isRequired,
  forcePage: PropTypes.number,
};

export default Pagination;
