import { addMinutes as fnsAddMinutes, parseISO } from "date-fns";
import { googleCalendarEventUrl } from "google-calendar-url";
import PropTypes from "prop-types";
import React, { useState } from "react";

import { SpinnerPurple, GoogleCalendarIcon } from "../../../../../assets/svgs";
import useRequest from "../../../../../hooks/useRequest";
import { getDayOfTheWeekDayMonth, getHoursAndMinutes, addMinutes } from "../../../../../utils/dates";

const ScheduleRow = ({ appointment, planType }) => {
  const [key, setKey] = useState(1);
  const [note, setNote] = useState("");
  const [isLoadingNote, setIsLoadingNote] = useState(false);
  const { request } = useRequest();

  const saveNote = async () => {
    setIsLoadingNote(true);
    await request("POST", "/appointment/addNote/" + appointment.uuid, { note });
    setIsLoadingNote(false);
    setKey(1);
  };

  const formatISOStringToCalendar = (dateString) =>
    dateString.replaceAll("-", "").replaceAll(":", "").replaceAll(".000", "");

  const openCalendar = () => {
    try {
      const url = googleCalendarEventUrl({
        start: formatISOStringToCalendar(appointment.schedule.dateTime),
        end: formatISOStringToCalendar(
          fnsAddMinutes(parseISO(appointment.schedule.dateTime), appointment.type === "FIFTY" ? 50 : 25).toISOString(),
        ),
        title: "EnglishBay Class",
        details: "EnglishBay Class",
      });
      window.open(url, "_blank");
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      {key === 1 && (
        <div
          className="grid grid-cols-1 mb-24 xl:mb-16 xl:mb-0 xl:grid-cols-3 xl:border-b p-3 border-gray-300"
          style={{ height: "80px" }}>
          <div className="flex items-center">
            <img
              className="w-11 h-11"
              style={{ borderRadius: "3.125rem" }}
              src={appointment.schedule.teacher.user.profilePictureUrl ?? process.env.REACT_APP_PROFILE_AVATAR_URL}
              alt="Teacher"
            />
            <div className="ml-2">
              <h1 style={{ color: "#1A1738" }}>{appointment.schedule.teacher.user.name}</h1>
            </div>
          </div>
          <div className="ml-[3.2rem] xl:ml-0">
            <h1 className="font-nunito capitalize" style={{ color: "#1A1738" }}>
              {getDayOfTheWeekDayMonth(appointment.schedule.dateTime)}
            </h1>
            <h2
              className="text-xs tracking-wider uppercase font-bold font-nunito uppercase"
              style={{ color: "#66647A" }}>
              das {getHoursAndMinutes(appointment.schedule.dateTime)} ás{" "}
              {getHoursAndMinutes(addMinutes(appointment.schedule.dateTime, planType ?? "TWENTY_FIVE"))}
            </h2>
          </div>
          <div className="flex space-x-4 items-end items-center">
            <button
              onClick={() => setKey(2)}
              className="rounded-lg text-sm font-semibold mt-5 xl:mt-0 w-[80%]"
              style={{ background: "#14C4BE", color: "white", height: "44px" }}>
              Deixar observação
            </button>
            <GoogleCalendarIcon className="h-[44px] cursor-pointer" onClick={openCalendar} />
          </div>
        </div>
      )}

      {key === 2 && (
        <div className="grid grid-cols-1 grid-rows-2 xl:grid-rows-1 xl:grid-cols-3 border-b p-4 border-gray-300">
          <div className="flex items-center mr-1 col-start-1 col-end-1 xl:col-start-1 xl:col-end-3">
            <textarea
              // eslint-disable-next-line jsx-a11y/no-autofocus
              autoFocus
              value={note}
              placeholder="Escreva aqui uma observação para seu professor ver..."
              className="resize-none text-md"
              onChange={({ target }) => setNote(target.value)}
              style={{
                color: "#66647A",
                width: "100%",
              }}
            />
          </div>
          <button
            onClick={saveNote}
            className="xl:col-start-3 xl:col-end-3 rounded-lg text-sm font-semibold tracking-wider mt-5 xl:mt-0"
            style={{ background: "#14C4BE", color: "white", height: "44px" }}>
            {isLoadingNote ? (
              <div>
                <SpinnerPurple className="w-6 h-6 m-auto animate-spin" />
              </div>
            ) : (
              <span>Salvar observação</span>
            )}
          </button>
        </div>
      )}
    </>
  );
};

ScheduleRow.propTypes = {
  appointment: PropTypes.object,
  planType: PropTypes.string,
};

export default ScheduleRow;
