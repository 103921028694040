import React, { useContext } from "react";
import { useHistory } from "react-router-dom";

import "./LessonCoverNoVideo.css";
import cover from "../../../assets/imgs/CoverNoVideo.png";
import { UserContext } from "../../../context/UserContext";
import Button from "../Button/Button";

const LessonCoverNoVideo = () => {
  const { user } = useContext(UserContext);
  const history = useHistory();

  const handleClick = () => {
    if (user?.student?.status === "ACTIVE") {
      window.Tawk_API.maximize();
      return;
    }

    const isCanceledStudent = user?.student?.status === "CANCELED";
    const isLead = user?.role === "LEAD";

    history.push(isLead || isCanceledStudent ? "/planos" : "/assinatura");
  };

  return (
    <section className="lesson-cover__container" style={{ backgroundImage: `url(${cover})` }} onClick={handleClick}>
      <div>
        <span className="lesson-cover__title">
          Tire suas <span>dúvidas</span> com professores!
        </span>

        <span className="lesson-cover__description">
          Quando precisar de ajuda conte com nosso suporte com professores.
        </span>

        <Button
          color="#47D5DC"
          border="1px solid #47D5DC"
          width="100%"
          style={{ paddingRight: "4px", paddingLeft: "4px", fontSize: "0.83em", marginTop: "20px" }}>
          Falar com professor
        </Button>
      </div>
    </section>
  );
};

export default LessonCoverNoVideo;
