import GirlBanner1 from "assets/imgs/girl-banner-2.png";
import { useIsStudent } from "hooks/useIsStudent";
import React from "react";
import { HiOutlineArrowNarrowRight } from "react-icons/hi";
import "./BannerCertificate.css";
import { logAnalyticsEvent } from "utils/firebase-analytics";

const BannerCertificate = () => {
  const { handleRedirect } = useIsStudent();

  return (
    <div
      className="banner-certificate"
      style={{ backgroundImage: `url(${GirlBanner1})` }}
      onClick={() => {
        logAnalyticsEvent("banner_click_certificados");
        handleRedirect("certificates", "/certificados");
      }}>
      <span>
        Solicite o seu <br /> <span>certificado</span>
      </span>

      <button>
        Solicitar
        <HiOutlineArrowNarrowRight />
      </button>
    </div>
  );
};

export default BannerCertificate;
