export const commonQuestions = [
  {
    title: "Como você conheceu a EnglishBay?",
    analyticsEventName: "OB_origin",
    answers: [
      {
        image: "friends.png",
        title: "Amigos ou Indicação",
      },
      {
        image: "google.png",
        title: "Pesquisa no google",
      },
      {
        image: "youtube.png",
        title: "YouTube",
      },
      {
        image: "fb_ig.png",
        title: "Facebook ou Instagram",
      },
      {
        image: "tiktok.png",
        title: "TikTok",
      },
      {
        image: "dots.png",
        title: "Outros",
      },
    ],
  },
  {
    title: "Por que você quer aprender inglês?",
    analyticsEventName: "OB_reason",
    answers: [
      {
        image: "reason_1.png",
        title: "Profissional",
      },
      {
        image: "reason_2.png",
        title: "Viagem",
      },
      {
        image: "reason_3.png",
        title: "Pessoal",
      },
    ],
  },
  {
    title: "Qual sua idade?",
    analyticsEventName: "OB_age",
    answers: [
      {
        image: "age_1.png",
        title: "Menor de 18",
      },
      {
        image: "age_2.png",
        title: "18 - 24",
      },
      {
        image: "age_3.png",
        title: "25 - 36",
      },
      {
        image: "age_4.png",
        title: "37 - 50",
      },
      {
        image: "age_5.png",
        title: "+50",
      },
    ],
  },
  {
    title: "Na nossa plataforma você está mais interessado em:",
    analyticsEventName: "OB_interest",
    answers: [
      {
        image: "interest_1.png",
        title: "Aprender sozinho",
      },
      {
        image: "interest_2.png",
        title: "Professor particular",
      },
    ],
  },
  {
    title: "Qual opção te descreve melhor?",
    analyticsEventName: "OB_level",
    answers: [
      {
        image: "baby.png",
        title: "Nunca estudei",
      },
      {
        image: "mouth-zipper.png",
        title: "Entendo mas não falo",
      },
      {
        image: "speaking.png",
        title: "Consigo falar",
      },
    ],
  },
];

export const learnAloneQuestion = {
  analyticsEventName: "OB_preference",
  title: "O que você prefere?",
  answers: [
    {
      image: "age_1.png",
      title: "Começar do zero",
      recommended: true,
    },
    {
      image: "level.png",
      title: "Teste de nível",
    },
  ],
};

export const scheduleClass = {
  title: "Gostaria de agendar uma aula grátis?",
  analyticsEventName: "OB_wantClass",
  answers: [
    {
      image: "yes.png",
      title: "Sim",
    },
    {
      image: "no.png",
      title: "Não",
    },
  ],
};
