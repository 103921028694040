import PropTypes from "prop-types";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { Book, LargeArrow } from "../../../../assets/svgs/index";
import CardDashed from "../Card/CardDashed";

const CardLesson = ({ isTeacher = false }) => {
  const { t } = useTranslation();

  return (
    <>
      <CardDashed style={{ width: "255px", height: "263px" }}>
        <Link to={isTeacher ? "/aulas" : "/professores"}>
          <div className="p-2">
            <div className="bg-white rounded-2xl p-3">
              <div className="px-2">
                <div className="flex mt-7">
                  <Book className="w-9" />
                </div>
                <p className="text-2xl mt-7">
                  {isTeacher ? t("class.see_your_next_classes") : "Agende sua próxima aula"}
                </p>
              </div>
              <div className="flex justify-between mt-10 px-2">
                <button className="text-sm font-green font-semibold mr-2">
                  {isTeacher ? "Veja mais" : "Agendar agora"}
                </button>
                <LargeArrow fill="#14c4be" className="w-4 stroke-current font-green" />
              </div>
            </div>
          </div>
        </Link>
      </CardDashed>
    </>
  );
};

CardLesson.propTypes = { isTeacher: PropTypes.bool };

export default CardLesson;
