import PropTypes from "prop-types";
import React, { useState, useContext, useEffect } from "react";

import { SpinnerPurple } from "../../../../assets/svgs/index";
import { ToastContext } from "../../../../context/ToastContext";
import useRequest from "../../../../hooks/useRequest";
import { Input } from "../index";

const FormCouponButton = ({ planUuid, couponForm, setDiscount, discount }) => {
  const { request } = useRequest();
  const { showToast } = useContext(ToastContext);
  const [discountLoading, setDiscountLoading] = useState(false);
  const [loadingPlan, setLoadingPlan] = useState(true);
  const [price, setPrice] = useState();

  const handleCoupon = async () => {
    if (!couponForm.value) {
      couponForm.value = "";
      return setDiscount(null);
    }

    setDiscountLoading(true);

    const cuponReq = {
      code: couponForm.value,
      planUuid,
    };

    const cupon = await request("POST", "/coupon/check", cuponReq, true, false);
    setDiscountLoading(false);

    if (cupon.error) {
      return showToast({ type: "error", message: "Cupom inválido" });
    }

    setDiscount(cupon);
  };

  useEffect(() => {
    const getPlan = async () => {
      const selectedPlanReq = await request("GET", `/plan/read/${planUuid}`);
      setPrice(selectedPlanReq.price);
      setLoadingPlan(false);
    };

    getPlan();
  }, []);

  const calculatDiscount = (value, porcentage) => ((value * porcentage) / 100).toFixed(2);

  return (
    <>
      <div>
        <div className="grid grid-cols-3 items-center gap-5">
          <div className="col-span-2">
            <Input label="Adicione aqui seu cupom" required={false} {...couponForm} />
          </div>
          {discount ? (
            <button onClick={handleCoupon} type="button" className="h-14 rounded bg-purple-200 text-purple-700">
              Aplicado
            </button>
          ) : (
            <button onClick={handleCoupon} type="button" className="h-14 rounded bg-purple-200 text-purple-700">
              {discountLoading ? <SpinnerPurple className="w-8 m-auto animate-spin" /> : "Aplicar"}
            </button>
          )}
        </div>
      </div>
      {loadingPlan ? (
        <p>
          <SpinnerPurple className="w-4 m-auto animate-spin" />
        </p>
      ) : price && discount ? (
        <>
          <div className="flex justify-between my-2 items-center">
            <p className="font-light-gray text-xs font-bold uppercase">Total</p>
            <p>R${price}</p>
          </div>
          <div className="flex justify-between my-2 items-center">
            <p className="font-light-gray text-xs font-bold uppercase">Desconto</p>
            <p>R$ {calculatDiscount(price, discount.discountPercentage)}</p>
          </div>
          <div className="flex justify-between my-2 items-center">
            <p className="font-light-gray text-xs font-bold uppercase">Total a pagar</p>
            <p>R$ {(price - calculatDiscount(price, discount.discountPercentage)).toFixed(2)}</p>
          </div>
        </>
      ) : (
        <div className="flex justify-between my-2 items-center">
          <p className="font-light-gray text-xs font-bold uppercase ">Total a pagar</p>
          <p>{price}</p>
        </div>
      )}
    </>
  );
};

FormCouponButton.propTypes = {
  planUuid: PropTypes.string,
  couponForm: PropTypes.object,
  setDiscount: PropTypes.object,
  discount: PropTypes.string,
};

export default FormCouponButton;
