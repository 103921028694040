import { Line09 } from "assets/svgs";
import { SpinnerPurple } from "assets/svgs/index";
import Banner7Days from "components/_Shared/Banners/Banner7Days/Banner7Days";
import Banner7DaysExpired from "components/_Shared/Banners/Banner7DaysExpired/Banner7DaysExpired";
import LessonItem from "components/_Shared/LessonItem/LessonItem";
import SelectLevel from "components/_Shared/NewBanners/SelectLevel";
import PaymentWarningStudent from "components/_Shared/PaymentWarningStudent/PaymentWarningStudent";
import useRequest from "hooks/useRequest";
import React, { useContext, useState, useEffect, useRef } from "react";
import { BiChevronDown } from "react-icons/bi";
import { FaCrown, FaChevronUp } from "react-icons/fa";
import { useHistory } from "react-router-dom";
import { getLevelIndexByLevelName } from "utils/level";

import Events from "../../../_Shared/Events/Events";
import { useChatbot } from "../../../../context/ChatbotContext";
import { UserContext } from "../../../../context/UserContext";
import { getFirstName } from "../../../../utils/utils";
import Certificate from "./components/Certificados/Certificados";
import ProgressBar from "./components/ProgressBar";

import "./IndexLeads.css";

export const IndexLeads = () => {
  const { user, isUserExpired } = useContext(UserContext);
  const history = useHistory();

  const { request } = useRequest();

  const [unities, setUnities] = useState([]);
  const [openLevelSelect, setOpenLevelSelect] = useState(false);
  const [showBanner, setShowBannerCards] = useState(false);
  const [categoryProgress, setCategoryProgress] = useState(0);
  const [selectedLevel, setSelectedLevel] = useState();
  const [loading, setLoading] = useState(true);
  const [isActiveStudent, setIsActiveStudent] = useState(false);
  const { handleChatbot } = useChatbot();

  const lessonItemRefs = useRef([]); // Change to array of refs

  const isLevelCompleted = getLevelIndexByLevelName(user?.levelTest) > getLevelIndexByLevelName(selectedLevel);

  const handleScrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const handleProgress = (index, currentPosition, progress = 0) => {
    if (isLevelCompleted) {
      return 100;
    }

    if (index > currentPosition) {
      return 0;
    }

    if (index === currentPosition) {
      return progress;
    }

    return 100;
  };

  const handleState = (index, currentPosition) => {
    if (isLevelCompleted) {
      return "completed";
    }

    if (index > currentPosition) {
      return "locked";
    }

    if (index === currentPosition) {
      return "unlocked";
    }

    return "completed";
  };

  const handleOpenLevelSelect = () => {
    setOpenLevelSelect(true);
  };

  const getUnityProgress = async () => {
    setLoading(true);

    const data = await request("GET", `/unity/currentProgress?currentCategory=${encodeURI(selectedLevel)}`);

    setLoading(false);

    if (isLevelCompleted) {
      setCategoryProgress(100);
    } else {
      setCategoryProgress(data.levelProgress ?? 0);
    }

    const firstItemWithProgressNull = data.unities.findIndex((unity) => unity.progress.stars === null);

    const unitiesWithProgress = data.unities.map((unity, index) => {
      return {
        ...unity,
        progress: handleProgress(index, firstItemWithProgressNull, unity.progress.progress),
        state: handleState(index, firstItemWithProgressNull),
        stars: unity.progress.stars || 0,
      };
    });

    setUnities(unitiesWithProgress);

    // Scroll to the specific LessonItem with an offset if conditions are met
    if (!isLevelCompleted && firstItemWithProgressNull >= 5 && lessonItemRefs.current[firstItemWithProgressNull]) {
      const item = lessonItemRefs.current[firstItemWithProgressNull];
      const offset = -100; // Adjust this value as needed
      const topPosition = item.getBoundingClientRect().top + window.scrollY + offset;
      window.scrollTo({ top: topPosition, behavior: "smooth" });
    }
  };

  const handleShowBanner = () => {
    history.push("/planos");
  };

  useEffect(() => {
    if (!user) {
      return;
    }

    // const timesAcessed = user.sessionsCount;
    const isActiveStudent = user?.student?.status === "ACTIVE";

    setSelectedLevel(user?.levelTest);
    setIsActiveStudent(isActiveStudent);

    // eslint-disable-next-line
    const msg = isActiveStudent ? "Que bom que você voltou! Vamos praticar?" : "<p><span style=\"color: green; font-weight: bold\">Promoção: </span>Assine agora com nosso <strong>menor preço</strong> histórico!</p>";
    handleChatbot("neutral", msg);
    // if (timesAcessed === 1) {
    //   handleChatbot("neutral", "Bem vindo a plataforma da EnglishBay! Sou sua professora virtual criada com IA");
    //   return;
    // }

    // if (timesAcessed >= 2) {
    //   /* eslint-disable */
    //   const msg = isActiveStudent ? "Que bom que você voltou! Vamos praticar?" : "Use ferramentas de IA ilimitadas com cupom de <span style=\"color: green; font-weight: bold\">15% OFF</span>";
    //   handleChatbot("neutral", msg);
    //   return;
    // }
  }, [user]);

  useEffect(() => {
    if (!selectedLevel) {
      return;
    }

    getUnityProgress();
  }, [selectedLevel]);

  useEffect(() => {
    const onScroll = () => {
      if (window.scrollY > 200) {
        setShowBannerCards(true);
      }

      if (window.scrollY < 200) {
        setShowBannerCards(false);
      }
    };

    window.addEventListener("scroll", onScroll);

    return () => window.removeEventListener("scroll", onScroll);
  }, [setShowBannerCards]);

  const IS_MOBILE = window.innerWidth < 768;

  return (
    <>
      {["PROCESSING", "PENDING", "OVERDUE"].includes(user?.student?.status) && (
        <PaymentWarningStudent
          color="yellow"
          message="Seu pagamento ainda está pendente. Faça o pagamento para liberar a plataforma!"
          link="/assinatura"
          buttonMessage="Ver fatura"
          buttonColor="#494a2736"
        />
      )}

      {user?.student?.status === "CANCELED" && (
        <PaymentWarningStudent
          color="red"
          buttonMessage="Renovar agora"
          message="Você cancelou o seu plano. Renove a qualquer momento para voltar com seus estudos!"
          link="/planos"
        />
      )}

      <main className="index__container">
        <div className="w-full" style={{ maxWidth: 750 }}>
          <section className="welcome__message">
            {!IS_MOBILE && (
              <span className="profile__welcome ">
                Welcome,{" "}
                <span>
                  {getFirstName(user?.name)} {"👋"}
                </span>
              </span>
            )}
          </section>

          <div className="flex items-center gap-2 my-5">
            <p>Nível</p>
            <button
              onClick={() => handleOpenLevelSelect(true)}
              style={{ backgroundColor: "#733eb1" }}
              className="flex items-center gap-2 rounded-full text-white px-4 py-1 font-bold">
              {selectedLevel}
              <BiChevronDown />
            </button>
          </div>

          <ProgressBar progress={categoryProgress} />

          {!isActiveStudent && <div className="my-4">{isUserExpired ? <Banner7DaysExpired /> : <Banner7Days />}</div>}

          <div className="flex justify-between my-4 font-bold">
            <h2>Unidades</h2>
            {unities.filter((unity) => unity.state === "completed").length}/{unities.length}
          </div>

          {!isActiveStudent && showBanner && (
            <div onClick={handleShowBanner} className="promotion_banner">
              <FaCrown color="orange" size="2rem" />
              <p>SE TORNE MEMBRO PREMIUM </p>
            </div>
          )}

          {showBanner && (
            <div onClick={handleScrollToTop} className="goBack_banner">
              <FaChevronUp color="white" size={20} />
            </div>
          )}

          {loading ? (
            <SpinnerPurple className="w-10 m-auto animate-spin" />
          ) : (
            <section
              className="bg-white border border-gray-300 rounded-3xl p-2"
              style={{
                minHeight: "30vh",
              }}>
              {unities.map((item, index) => (
                <div key={index} className="flex flex-col" ref={(el) => (lessonItemRefs.current[index] = el)}>
                  <LessonItem {...item} index={index} />

                  {index <= unities.length - 1 && (
                    <div className="dotted-divider my-2">
                      <Line09 />
                    </div>
                  )}
                </div>
              ))}

              {selectedLevel && (
                <Certificate
                  active={isLevelCompleted}
                  selectedLevel={selectedLevel}
                  description="Certificado de conclusão do curso"
                />
              )}
            </section>
          )}
        </div>

        <section className="index__sidebar mt-10">
          <Events />
        </section>
      </main>

      {openLevelSelect && (
        <SelectLevel handleHideBanner={() => setOpenLevelSelect(false)} changeLevel={setSelectedLevel} />
      )}
    </>
  );
};

export default IndexLeads;
