/* eslint-disable jsx-a11y/interactive-supports-focus */
import PropTypes from "prop-types";
import React from "react";

import { LargeArrow } from "../../../../assets/svgs/index";
import {
  getDayOfTheWeekDayMonthYear,
  getTimeBetweemToday,
  getHoursAndMinutes,
  addMinutes,
} from "../../../../utils/dates";
import { getFirstName } from "../../../../utils/utils";
import Card from "../Card/Card";

const CardNextClass = ({ isNext, clickHandler, dateTime, type, teacher }) => {
  return (
    <Card style={isNext ? { width: "376px", height: "260px" } : { width: "243px", height: "260px" }}>
      <div role="button" className="cursor-pointer" onClick={clickHandler}>
        <div>
          <div className="flex items-center justify-between p-4 mt-2 text-sm font-black-pb">
            <p>{isNext ? "Próxima aula" : "Faltam"}</p>
            {!isNext ? <p>{getTimeBetweemToday(dateTime)}</p> : <p>Começa em {getTimeBetweemToday(dateTime)}</p>}
          </div>
          <div className="flex items-center px-4">
            <img
              src={teacher.user.profilePictureUrl ?? process.env.REACT_APP_PROFILE_AVATAR_URL}
              alt="professora"
              style={{ height: "44px", width: "44px" }}
              className="rounded-3xl mr-2"
            />
            <div>
              <p className="text-md font-black-pb capitalize">{getFirstName(teacher.user.name)}</p>
            </div>
          </div>
          <div className="pt-9 px-4">
            <p className="text-sm font-black-pb capitalize font-medium">{getDayOfTheWeekDayMonthYear(dateTime)}</p>
            <p className="font-gray font-bold uppercase text-xs font-serif">
              das {getHoursAndMinutes(dateTime)} ás {getHoursAndMinutes(addMinutes(dateTime, type))}
            </p>
          </div>
        </div>
        <div className="flex justify-between mt-9 px-2">
          <button className={`${isNext ? "font-aqua-green" : "text-gray-400"} text-sm font-semibold p-2 mr-2`} href="/">
            {isNext ? "Ir para aula" : "Ver detalhes"}
          </button>
          <LargeArrow
            fill="#14c4be"
            stroke="#14c4be !important"
            className={`${isNext ? "font-aqua-green" : "text-gray-400"} w-4 stroke-current mr-2`}
          />
        </div>
      </div>
    </Card>
  );
};

CardNextClass.propTypes = {
  isNext: PropTypes.bool,
  clickHandler: PropTypes.func,
  dateTime: PropTypes.string,
  name: PropTypes.string,
  type: PropTypes.string,
  teacher: PropTypes.object.isRequired,
};

export default CardNextClass;
