import PropTypes from "prop-types";
import React, { createContext, useState } from "react";

export const NavbarContext = createContext();

export const NavbarStorage = ({ children }) => {
  const [navigationLinks, setNavigationLinks] = useState([]);

  const value = {
    navigationLinks,
    setNavigationLinks,
  };

  return <NavbarContext.Provider value={value}>{children}</NavbarContext.Provider>;
};

NavbarStorage.propTypes = {
  children: PropTypes.node.isRequired,
};
