import { differenceInMinutes, addMinutes, isEqual } from "date-fns";
import { utcToZonedTime } from "date-fns-tz";
import PropTypes from "prop-types";
import React, { useEffect, useState, useContext } from "react";
import { BsFillLockFill } from "react-icons/bs";
import { useHistory } from "react-router-dom";
import { useParams, Link } from "react-router-dom";

import { RightArrow, Calendar, Watch, LeftArrow, LeftArrowGrey, BasicClock } from "../../../../assets/svgs/index";
import { ToastContext } from "../../../../context/ToastContext";
import { UserContext } from "../../../../context/UserContext";
import useRequest from "../../../../hooks/useRequest";
import {
  addDays,
  subDays,
  formatIso,
  getStartOfWeek,
  getHoursAndMinutes,
  getDayAbrvMonthYear,
  getDay,
} from "../../../../utils/dates";
import { pushGTMEvent } from "../../../../utils/GTM";
import RemainingClasses from "../../RemainingClasses/RemainingClasses";
import HourCard from "./HourCard";
import "./BookClasses.css";

const Schedule = ({ setSuccesse, setAppointment, showRemainingClasses, isTestClass }) => {
  const { request } = useRequest();
  const params = useParams();
  const { getCredits, user, credits } = useContext(UserContext);
  const { showToast } = useContext(ToastContext);
  const history = useHistory();

  const [dateToday] = useState(getStartOfWeek());
  const [date, setDate] = useState(getStartOfWeek());
  const [weekAppoitments, setWeekAppoitments] = useState([]);
  const [teacher, setTeacher] = useState({});
  const [datesSelected, setDatesSelected] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [planType, setPlanType] = useState("TWENTY_FIVE");

  const openPlans = () => {
    history.push("/planos");
  };

  const saveGTMEvents = () => {
    pushGTMEvent({
      event: "aula_teste",
    });
  };

  const handleSubmitClasses = async () => {
    if (datesSelected.length <= 0) {
      return;
    }

    setLoading(true);

    if (!isTestClass && credits?.available < datesSelected.length) {
      showToast({ type: "error", message: "Você não possui todos os créditos necessários!" });
      return setLoading(false);
    }

    if (isTestClass && datesSelected.length > 1) {
      showToast({ type: "error", message: "Você só pode escolher um horário para a aula teste!" });
      return setLoading(false);
    }

    const slotsUuidArray = datesSelected.map((dates) => dates.uuid);
    const slotsSelected = { schedulesUuids: slotsUuidArray };

    if (isTestClass) {
      slotsSelected.isTestClass = true;
    }

    const response = await request("POST", "appointment/create", slotsSelected, true, false);

    if (!response.error) {
      setAppointment(response);

      if (!isTestClass) {
        getCredits();
      }

      if (isTestClass) {
        saveGTMEvents();
      }
    }

    if (response.error?.message === "USER_HAS_NO_LESSON_CREDIT_AVAILABLE") {
      showToast({ type: "error", message: "Você não possui todos os créditos necessários!" });
      return setLoading(false);
    }

    setLoading(false);
    setSuccesse(true);
  };

  const handleCancel = () => {
    setShowConfirmation(false);
  };

  useEffect(() => {
    const getTeacherSchedule = async () => {
      const teacherReq = await request("GET", `/teacher/read/${params.uuid}`);
      setTeacher(teacherReq);
    };
    getTeacherSchedule();
  }, []);

  useEffect(() => {
    if (!credits?.planType) {
      return;
    }

    const currentPlanType = credits?.nextCredit ? credits?.nextCredit.lessonType : credits?.planType;
    setPlanType(currentPlanType);
  }, [credits]);

  useEffect(() => {
    if (!user?.timezone) {
      return;
    }

    const getTeacherSchedule = async () => {
      const scheduleReq = await request(
        "GET",
        `/teacherSchedule/list?teacherUuid=${params.uuid}&initialDate=${formatIso(date)}`,
      );

      // Aplica o timezone nas schedules
      scheduleReq.forEach((schedule) => {
        schedule._utcISOString = schedule.dateTime;
        schedule.dateTime = formatIso(utcToZonedTime(schedule.dateTime, user.timezone));
        schedule.hidden = false;
      });

      // Remove slots do passado
      const scheduleFilterDate = scheduleReq.filter((schedule) => {
        return differenceInMinutes(new Date(schedule._utcISOString), new Date()) >= 60;
      });

      // Pega hoje e os próximos 6 dias
      const nextDays = [
        getDay(date),
        getDay(addDays(date, 1)),
        getDay(addDays(date, 2)),
        getDay(addDays(date, 3)),
        getDay(addDays(date, 4)),
        getDay(addDays(date, 5)),
        getDay(addDays(date, 6)),
      ];

      const week = [];

      for (const day of nextDays) {
        const dayResults = scheduleFilterDate.filter((schedule) => {
          const isoString = new Date(schedule.dateTime).toISOString();
          const scheduleDay = getDay(isoString);
          return scheduleDay == day;
        });

        week.push(dayResults);
      }

      setWeekAppoitments(week);
    };
    getTeacherSchedule();
  }, [date, user?.timezone]);

  const handleSubDay = () => {
    const nextDate = subDays(date, 7);

    if (dateToday <= nextDate) {
      setDate(() => nextDate);
    }
  };

  const addDate = (date) => {
    if (planType === "FIFTY") {
      const nextSlotDate = addMinutes(new Date(date.dateTime), 30);
      const nextSlot = weekAppoitments.flat().find((slot) => isEqual(nextSlotDate, new Date(slot.dateTime)));

      if (!nextSlot) {
        setDatesSelected((dates) => [...dates, date]);
        return;
      }

      let filteredWeekAppointments = [...weekAppoitments];
      date._nextSlot = nextSlot;

      filteredWeekAppointments.forEach((week) => {
        const targetIndex = week.findIndex((slot) => slot.uuid === nextSlot.uuid);

        if (targetIndex !== -1) {
          week[targetIndex].hidden = true;
        }
      });

      setWeekAppoitments(filteredWeekAppointments);
    }

    setDatesSelected((dates) => [...dates.filter((date) => !date.hidden), date]);
  };

  const removeDate = (dateSelected) => {
    const newDates = datesSelected.filter((date) => date.uuid !== dateSelected.uuid);
    setDatesSelected(newDates);

    if (planType === "FIFTY" && dateSelected._nextSlot) {
      let filteredWeekAppointments = [...weekAppoitments];

      filteredWeekAppointments.forEach((week) => {
        const targetIndex = week.findIndex((slot) => slot.uuid === dateSelected._nextSlot.uuid);

        if (targetIndex !== -1) {
          week[targetIndex].hidden = false;
        }
      });

      setWeekAppoitments(filteredWeekAppointments);
    }

    if (newDates.length === 0) {
      setShowConfirmation(false);
    }
  };

  const isLead = user.role === "LEAD" && !teacher.doTestLessons;

  return (
    <div className="2xl:col-span-3" style={{ marginBottom: "100px" }}>
      <div className="flex flex-wrap md:flex-nowrap justify-between items-center">
        <div className="px-4">
          <div className="text-2xl">Marcar aulas</div>
          <div className="flex">
            <div className="mt-2 mb-4 text-sm">
              Lembre-se de consultar seus créditos disponíveis.
              <span className=""> Cada aula custará 1 crédito </span>
              na plataforma.
            </div>
          </div>
        </div>
        {showRemainingClasses && (
          <div className="px-4 md:px-0">
            <RemainingClasses />
          </div>
        )}
      </div>

      {weekAppoitments && (
        <div className={`grid grid-cols-1 lg:grid-cols-6 ${(isLead && "mt-0") || "mt-10"}`}>
          <div
            className={`col-span-4 px-4 relative flex flex-col justify-center ${isLead && "mt-12"}`}
            style={{ marginBottom: "100px" }}>
            {!isLead && (
              <div className="flex mt-3 justify-between items-center">
                <div className="inline-flex items-center justify-center gap-2 mb-3" style={{ width: "100%" }}>
                  <button
                    onClick={handleSubDay}
                    className={`button-action flex items-center justify-center relative ${
                      (dateToday <= subDays(date, 7) && "previous-week-active") || "cursor-default previous-week"
                    }`}>
                    {(dateToday <= subDays(date, 7) && (
                      <LeftArrow className="absolute left-0" style={{ left: "0" }} />
                    )) || <LeftArrowGrey className="absolute left-0" style={{ left: "0" }} />}
                    Semana anterior
                  </button>
                  <button
                    onClick={() => setDate((date) => addDays(date, 7))}
                    className="button-action flex items-center justify-center relative next-week">
                    Próxima semana
                    <RightArrow className="absolute right-0" />
                  </button>
                </div>
              </div>
            )}

            {isLead && (
              <div className="flex flex-col items-center absolute z-10 self-center p-6 rounded-lg bg-white">
                <BsFillLockFill style={{ color: "#623cea", fontSize: "50px" }} />
                <span className="font-semibold mb-5 mt-3 max-w-xs text-center text-md text-gray-500">
                  Adquira um plano para ter <br></br> acesso aos horários
                </span>
                <button
                  type="button"
                  style={{ backgroundColor: "#623cea" }}
                  className="py-2 px-5 text-md rounded-lg text-white font-semibold"
                  onClick={openPlans}>
                  Assine agora!
                </button>
              </div>
            )}

            <div style={{ filter: `${isLead ? "blur(6px)" : "none"}` }}>
              {weekAppoitments.map((schedule, i) => {
                return (
                  <HourCard
                    key={Math.random()}
                    schedule={schedule}
                    date={addDays(date, i)}
                    addDate={addDate}
                    removeDate={removeDate}
                    datesSelected={datesSelected}
                  />
                );
              })}
            </div>
          </div>

          <div className="col-span-2 py-4">
            <div className="flex items-center justify-end mt-4">
              <BasicClock fill="#625E66" style={{ width: "20px" }} />
              <span className="ml-1 text-xs md:text-sm" style={{ color: "#14c4be" }}>
                <span style={{ color: "#000000" }}>Horário local:</span>{" "}
                <Link to="/perfil/informacoes">
                  <span className="text-purple-800 font-bold">({user?.timezone})</span>
                </Link>
              </span>
            </div>

            <div
              className="relative p-8 shadow-xl borderBehind text-white mt-3"
              style={{ height: "475px", marginBottom: "70px" }}>
              {teacher && (
                <div
                  style={{ borderRadius: "24px 8px" }}
                  className="-mr-4 bg-white p-5 rounded-lg flex items-center gap-2">
                  <img
                    alt="foto do professor"
                    src={teacher?.user?.profilePictureUrl ?? process.env.REACT_APP_PROFILE_AVATAR_URL}
                    className="w-14 h-14 -ml-9 rounded-full"
                  />
                  <div className="text-2xl profileNameColor">{teacher?.user?.name}</div>
                </div>
              )}
              <div className="flex mt-2">
                <Watch className="mt-3" />
                <div className="ml-5 mb-1">
                  <p className="text-white">Duração</p>
                  <div className="text-white font-semibold text-xl">{planType === "FIFTY" ? "50" : "25"} min</div>
                </div>
              </div>
              <div className="flex mt-2 ">
                <Calendar />
                <div className="ml-5 mb-1 w-full">
                  <p>Data</p>
                  <div style={{ height: "100px" }}>
                    {datesSelected.length === 0 && <p>Nenhuma aula selecionada</p>}
                    <ul style={{ height: "100px" }} className="w-full px-4 overflow-auto list-disc">
                      {datesSelected.length > 0 &&
                        datesSelected.map((date, index) => (
                          <li key={index}>
                            <span className="capitalize">{getDayAbrvMonthYear(date.dateTime)}</span> ás{" "}
                            {getHoursAndMinutes(date.dateTime)}
                          </li>
                        ))}
                    </ul>
                  </div>
                </div>
              </div>
              {!showConfirmation && datesSelected.length > 0 && (
                <button
                  onClick={() => setShowConfirmation(true)}
                  style={{ marginTop: "3.5rem" }}
                  className="relative flex items-center justify-center w-full h-14 bg-purple-700 text-white p-2 px-5 rounded-lg">
                  <div className="w-full flex justify-between items-center">
                    <span>Agendar aulas</span>
                    <div className="rounded-full bg-purple-900 h-10 w-10 flex justify-center items-center">
                      <p className="mb-1">{datesSelected.length}</p>
                    </div>
                  </div>
                </button>
              )}

              {!showConfirmation && datesSelected.length === 0 && (
                <button
                  style={{ marginTop: "3.5rem", backgroundColor: "gray", cursor: "default" }}
                  className="relative flex items-center justify-center w-full h-14 bg-purple-700 text-white p-2 px-5 rounded-lg">
                  <div className="w-full flex justify-between items-center">
                    <span>Agendar aulas</span>
                    <div
                      className="rounded-full bg-purple-900 h-10 w-10 flex justify-center items-center"
                      style={{ backgroundColor: "#6e6c6c" }}>
                      <p className="mb-1">{datesSelected.length}</p>
                    </div>
                  </div>
                </button>
              )}

              {showConfirmation && datesSelected.length > 0 && (
                <div
                  style={{ marginTop: "3.5rem" }}
                  className="relative flex items-center justify-center w-full h-14 bg-purple-700 text-white p-2 px-5 rounded-lg">
                  {!loading && (
                    <div className="p-10">
                      <button onClick={handleCancel} className="hover:text-gray-300">
                        Cancelar
                      </button>
                    </div>
                  )}
                  {!loading && (
                    <div className="p-10">
                      <button onClick={handleSubmitClasses} className="hover:text-gray-300">
                        Confirmar
                      </button>
                    </div>
                  )}

                  {loading && <div className="loader"></div>}
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

Schedule.propTypes = {
  setSuccesse: PropTypes.func,
  setAppointment: PropTypes.func,
  showRemainingClasses: PropTypes.bool,
  isTestClass: PropTypes.bool,
};

export default Schedule;
