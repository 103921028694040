import React, { useContext } from "react";
import { useTranslation } from "react-i18next";

import CardSchedule from "../../../_Shared/Cards/CardSchedule/CardSchedule";
import LessonsTeacherCardsGrid from "../../../_Shared/LessonsCardsTeacher/LessonsTeacherCardsGrid";
import OurLessons from "../../../_Shared/Teacher/OurLessons";
import { UserContext } from "../../../../context/UserContext";

import "./IndexTeacher.css";

const IndexTeacher = () => {
  const { t } = useTranslation();
  const { user } = useContext(UserContext);

  return (
    <>
      <div className="index-jumbotron-teacher grid grid-cols-2 py-7 px-5">
        <div>
          <div className="flex items-center gap-5 mt-3">
            <div>
              <h2 className="text-3xl text-white font-medium">
                {t("hi")}, <span className="capitalize">{user && user.name}</span>!
              </h2>
              <p className="text-sm text-white">{t("good_to_see_you")}</p>
            </div>
          </div>
        </div>
      </div>
      <div className="grid grid-cols-1 2xl:grid-cols-4 px-5 mb-10 mt-6">
        <div className="2xl:col-span-3">
          <div className="mb-5 overflow-x-auto">
            <LessonsTeacherCardsGrid />
          </div>
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-8  mb-5">
            <OurLessons className="mr-5" lessonType="25" />
            <OurLessons lessonType="50" />
          </div>
          <CardSchedule />
        </div>
      </div>
    </>
  );
};

export default IndexTeacher;
