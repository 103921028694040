import PropTypes from "prop-types";
import React, { useState } from "react";

import { Content, ArrownDownPurple, ArrowUp } from "../../../../assets/svgs/index";

const AnnotationRow = ({ title, note, className }) => {
  const [open, setOpen] = useState(false);
  const [read, setRead] = useState(false);

  const handleClick = () => {
    setOpen((state) => !state);
    setRead(true);
  };
  return (
    <details>
      <summary
        onClick={handleClick}
        className={`${className} flex rounded-2xl p-2 w-full md:pl-6 md:pr-6 justify-between items-center relative cursor-pointer`}
        style={{
          border: "1px solid rgb(0 0 0 / 7%)",
        }}>
        <div className="col-span-10 w-full">
          <div className="flex items-center gap-5">
            <div
              className="p-4 rounded-xl"
              style={{
                background: "#FBF7FF",
              }}>
              <Content className={`${read ? "stroke-current text-gray-600" : "stroke-current text-purple-600"}`} />
            </div>
            <div>
              <p
                className="uppercase text-xs tracking-wider"
                style={{
                  color: "#676482",
                }}>
                ANOTAÇÔES
              </p>
              <div className="flex justify-between">
                <p
                  style={{
                    color: "#36344B",
                  }}>
                  {title}
                </p>
              </div>
            </div>
          </div>
          {open && (
            <div className="mt-3 pt-7 pl-20 pr-20 mb-5" style={{ borderTop: "1px solid #0000001c" }}>
              <p
                className=""
                style={{
                  color: "#625E66",
                }}>
                {note}
              </p>
            </div>
          )}
        </div>
        {open ? (
          <ArrowUp className="cursor-pointer absolute right-5 md:right-11 top-10" />
        ) : (
          <ArrownDownPurple className="cursor-pointer absolute right-5 md:right-11 top-10" />
        )}
      </summary>
    </details>
  );
};

AnnotationRow.propTypes = {
  title: PropTypes.string,
  note: PropTypes.string,
  className: PropTypes.string,
};

export default AnnotationRow;
