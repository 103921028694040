import PropTypes from "prop-types";
import React, { useEffect, useRef } from "react";
import { AiOutlineClose as CloseIcon } from "react-icons/ai";
import { logAnalyticsEvent } from "utils/firebase-analytics";

import BannerImage2 from "../../../../assets/imgs/banners/banner_download_mobile_2.png";
import BannerImage from "../../../../assets/imgs/banners/banner_download_mobile.png";
import { ReactComponent as Underline01 } from "../../../../assets/svgs/Underline_01.svg";
import useOnClickOutside from "../../../../hooks/useOnClickOutside";
import * as S from "./styles.module.css";

const variantImage = {
  0: BannerImage,
  1: BannerImage2,
};

const variantColor = {
  0: "#6A51B2",
  1: "#58CC02",
};

const DownloadAppMobile = ({ handleHideBanner, variant }) => {
  const ref = useRef();

  useOnClickOutside(ref, () => handleHideBanner());

  const image = variantImage[variant];

  const color = variantColor[variant];

  const handleRedirect = () => {
    const eventName = "CLICK_BANNER_DOWNLOAD_APP_MOBILE_" + variant?.toString();
    logAnalyticsEvent(eventName);

    window.open("https://englishbay.onelink.me/nHSW/cbkv3f1y");
  };

  useEffect(() => {
    const eventName = "SHOW_BANNER_DOWNLOAD_APP_MOBILE_" + variant?.toString();
    logAnalyticsEvent(eventName);
  }, []);

  return (
    <div className={S.Backdrop}>
      <div className={S.Container}>
        <div ref={ref}>
          <div style={{ position: "relative", width: "100%" }}>
            <img className={S.Image} src={image} alt="bannerImage" />

            <div className={S.WrapperButtonClose}>
              <button className={S.ButtonClose} onClick={handleHideBanner}>
                <CloseIcon size={20} color="#6A51B2" />
              </button>
            </div>
          </div>

          <div className={S.Wrapper}>
            <div>
              <div>
                <p className={S.Title}>
                  <p>Aprimore sua</p>
                  Experiência com o App!
                </p>
                <Underline01 />
              </div>

              <p className={S.Description}>
                Nosso aplicativo oferece uma trilha de aprendizado personalizada, lições envolventes e notificações para
                mantê-lo motivado.
              </p>
            </div>
            <div>
              <button onClick={handleRedirect} className={S.ButtonPrimary} style={{ backgroundColor: color }}>
                <span>Obter app</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

DownloadAppMobile.propTypes = {
  handleHideBanner: PropTypes.func.isRequired,
  variant: PropTypes.number.isRequired,
};

export default DownloadAppMobile;
