import PropTypes from "prop-types";
import React, { useContext, useEffect, useState } from "react";

import { CloseX, Warning } from "../../../../assets/svgs/index";
import { SlideContext } from "../../../../context/SlideContext";
import { UserContext } from "../../../../context/UserContext";
import useRequest from "../../../../hooks/useRequest";
import { deleteToken, deleteUser } from "../../../../utils/localStorage";
import { PurpleButton } from "../../Form/index";
import DrawerDeleteSuccess from "./DrawerDeleteSuccess";
import "./DrawerConfirmDelete.css";

const DrawerConfirmDelete = ({ handleClick }) => {
  const { setShowSlide, setSlideComponent } = useContext(SlideContext);
  const { setUser, user } = useContext(UserContext);

  const { request, loading } = useRequest();

  const [activeSubscription, setActiveSubscription] = useState(undefined);

  const handleComponent = (component) => {
    setSlideComponent(component);
  };

  const handleDeleteRegister = async () => {
    await request("DELETE", "/user");
    deleteToken();
    deleteUser();
    setUser(null);
  };

  useEffect(() => {
    const getPlan = async () => {
      if (user.role === "STUDENT") {
        const activeSubscription = await request("GET", "/subscription/activeSubscription", null, true, false);

        setActiveSubscription(activeSubscription);
      }
    };
    getPlan();
  }, []);

  return (
    <div className="flex h-full flex-col flex-1">
      <header className="flex justify-between items-center">
        <h2
          className="text-2xl 31.5rem w-96"
          style={{
            color: "#383743",
          }}>
          Você tem certeza que deseja deletar sua conta?
        </h2>
        <CloseX className="cursor-pointer" onClick={handleClick} />
      </header>

      <div
        className="mt-12 pb-6"
        style={{
          borderBottom: "0.6px solid #9D96A3",
        }}>
        <h1
          className="text-xs tracking-wider uppercase font-bold"
          style={{
            color: "#625E66",
          }}>
          Plano adquirido
        </h1>
        <h2
          className="mt-2"
          style={{
            color: "#252426",
          }}>
          {activeSubscription?.plan?.name || "Nenhum"}
        </h2>
      </div>

      <div className="flex mt-8 items-center">
        <Warning fill="#F59300" />

        <p
          className="ml-4 flex-grow-0 text-sm"
          style={{
            width: "29rem",
            color: "#F59300",
          }}>
          Ao deletar sua conta, automáticamente seu plano é cancelado, dessa forma perdendo acesso a todas as aulas e
          materiais contratados.
        </p>
      </div>

      <div className="grid grid-cols-3 gap-5 p-2 flex-1 h-1/2"></div>
      <footer className="flex col-span-2">
        <PurpleButton
          className="load-red"
          style={{
            height: "3.25rem",
            width: "14.375rem",
            background: "#B81212",
          }}
          isLoading={loading}
          onClick={async () => {
            await handleDeleteRegister();
            handleComponent(
              <DrawerDeleteSuccess
                handleClick={() => {
                  setShowSlide(false);
                }}
              />,
            );
          }}>
          Sim, quero deletar
        </PurpleButton>
        <button
          className="ml-4 rounded-lg text-sm font-semibold tracking-wider pt-4 pb-4 pl-12 pr-12 bg-white"
          style={{
            color: "#B81212",
            border: "1px solid #B81212",
          }}
          onClick={handleClick}>
          Não
        </button>{" "}
      </footer>
    </div>
  );
};

DrawerConfirmDelete.propTypes = {
  handleClick: PropTypes.func,
};

export default DrawerConfirmDelete;
