import React from "react";

import { Home, Lessons, Schedule, Notebook, Calendar } from "../../../assets/svgs/index";

export const TeacherSidebarLinks = [
  { name: "sidebar.home", path: "/", svg: <Home style={{ width: "32px", height: "32px" }} /> },
  {
    name: "sidebar.schedule",
    path: "/cronograma",
    svg: <Schedule style={{ width: "32px", height: "32px" }} />,
    isNotExact: true,
  },
  {
    name: "sidebar.classes",
    path: "/aulas",
    svg: <Lessons style={{ width: "32px", height: "32px" }} />,
  },
  {
    name: "sidebar.material",
    path: "/material",
    svg: <Notebook style={{ width: "32px", height: "32px" }} />,
    isNotExact: true,
  },
  { name: "sidebar.events", path: "/eventos", svg: <Calendar style={{ width: "32px", height: "32px" }} /> },
];
