import PropTypes from "prop-types";
import React, { useContext, useEffect, useState } from "react";

import ChangePaymentMethod from "../../../_Shared/Slide/ChangePaymentMethod/ChangePaymentMethod";
import { IconV, CloseX, SpinnerPurple } from "../../../../assets/svgs/index";
import { SlideContext } from "../../../../context/SlideContext";
import useRequest from "../../../../hooks/useRequest";
import { getDayMonthYear } from "../../../../utils/dates";
import ConfirmDeletePlan from "./ConfirmDeletePlan";

const InfoPlan = ({ activeBill /* getPlans */ }) => {
  const { setSlideComponent, setShowSlide } = useContext(SlideContext);
  const { request } = useRequest();
  const [loading, setLoading] = useState(true);
  const [activeBillState, setActiveBillState] = useState((activeBill = null));

  useEffect(() => {
    const getActivePlan = async () => {
      const activeBillReq = await request("GET", "/subscription/activeSubscription");
      setActiveBillState(activeBillReq);
      setLoading(false);
    };

    if (!activeBill) {
      getActivePlan();
      return;
    }

    setLoading(false);
  }, []);

  const handleChangePlan = () => {
    // const isReactivatePlan = activeBillState.status !== "paid";

    // setSlideComponent(
    //   <SelectPlan changePlan={!isReactivatePlan} isReactivatePlan={isReactivatePlan} getPlans={getPlans} />,
    // );

    // Abre o tawkto
    const config = window.Tawk_API;
    config.toggle();
  };

  return (
    <div className="h-full flex flex-col w-full" style={{ maxWidth: "700px" }}>
      <div className="flex justify-between items-center">
        <h2 className="text-xl font-medium">Detalhes do plano</h2>
        <CloseX className="cursor-pointer" onClick={() => setShowSlide(false)} />
      </div>
      {loading ? (
        <SpinnerPurple className="w-10 m-auto mt-5 animate-spin" />
      ) : (
        <>
          <div className="mt-6 flex-1">
            <div className="grid grid-cols-2 sm:grid-cols-3 gap-4 sm:gap-20 border-b border-gray-300 mb-5 py-4">
              <div className="col-span-2">
                <h2 className="font-medium">Plano Adquirido</h2>
                <p>{activeBillState?.plan?.name}</p>
              </div>
              <button
                onClick={handleChangePlan}
                className="rounded bg-purple-200 text-purple-700 text-sm font-bold h-10">
                Alterar
              </button>
            </div>
            {true && (
              <div
                className="grid sm:grid-cols-3 grid-cols-2 gap-4 sm:gap-20 border-b border-gray-300 mb-5 py-4"
                style={{ paddingTop: "0rem" }}>
                <div className="col-span-2">
                  <h2 className="font-medium">Cobrança</h2>
                  <p>
                    {["pix_bank_slip", "bank_slip", "cash"].includes(
                      activeBillState?.bill?.charges[activeBillState?.bill?.charges.length - 1]?.payment_method?.code,
                    )
                      ? "Pix/Boleto"
                      : `Cartão de crédito final •••• ${
                          activeBillState?.bill?.charges[activeBillState?.bill?.charges.length - 1]?.last_transaction
                            ?.payment_profile?.card_number_last_four
                        }`}
                  </p>
                </div>
                {activeBillState?.bill?.charges[activeBillState?.bill?.charges.length - 1]?.payment_method?.code ===
                  "credit_card" && (
                  <button
                    className="rounded bg-purple-200 text-purple-700 text-sm font-bold  h-10"
                    onClick={() => setSlideComponent(<ChangePaymentMethod />)}>
                    Alterar
                  </button>
                )}
              </div>
            )}
            <div className="grid grid-cols-3 gap-5 mb-3">
              <div>
                <p className="font-medium text-xs sm:text-base">Próxima cobrança</p>
                <p>{getDayMonthYear(activeBillState?.nextBillingAt)}</p>
              </div>
              {activeBillState.plan.lessonType !== "CONVERSATION" && (
                <div>
                  <p className="font-medium text-xs sm:text-base">Aulas disponíveis</p>
                  <p>Restam {activeBillState.creditsCount.available} aulas</p>
                </div>
              )}
              <div>
                <p className="font-medium text-xs sm:text-base">Assinante desde</p>
                <p>{getDayMonthYear(activeBillState?.startAt)}</p>
              </div>
            </div>
            <div className="grid sm:grid-cols-3">
              <div>
                <h2 className="text-md font-medium py-2">Características do Plano</h2>
                {activeBillState.creditsCount.planType !== "CONVERSATION" && (
                  <div className="flex gap-1 text-sm">
                    <IconV />
                    <p>Aula individual de {activeBillState.creditsCount.planType === "FIFTY" ? 50 : 25} minutos</p>
                  </div>
                )}
                <div className="flex gap-1 text-sm">
                  <IconV />
                  <p>Material gratuito</p>
                </div>
                <div className="flex gap-1 text-sm">
                  <IconV />
                  <p>Grupo de conversação</p>
                </div>
                <div className="flex gap-1 text-sm">
                  <IconV />
                  <p>Live classes</p>
                </div>
                <div className="flex gap-1 text-sm">
                  <IconV />
                  <p>Certificado</p>
                </div>
                <div className="flex gap-1 text-sm">
                  <IconV />
                  <p>Cancele sem multa</p>
                </div>
                <p className="py-4 text-sm" style={{ color: "#F59300" }}>
                  Lembre-se: caso não faça todas as aulas disponíveis no seu plano, elas não irão se acumular.
                </p>
              </div>
            </div>
          </div>
          {activeBillState?.status !== "canceled" && (
            <div className="border-t border-gray-300">
              <div className="flex justify-between items-center py-4">
                <p className="text-gray-400">Deseja cancelar seu plano?</p>
                <button
                  className="text-red-600 font-bold text-sm"
                  onClick={() => setSlideComponent(<ConfirmDeletePlan planName={activeBillState?.plan?.name} />)}>
                  Cancelar
                </button>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

InfoPlan.propTypes = {
  activeBill: PropTypes.object,
  getPlans: PropTypes.func,
};

export default InfoPlan;
