export const fluxoBanners = {
  wantLiveClasses: {
    mobile: [
      "freeClassesMobile",
      "freeClassesMobile",
      "downloadAppMobile",
      "youtubeMobile",
      "recommendationMobile",
      "vendasMobile",
    ],
    desktop: ["freeClasses", "freeClasses", "downloadApp", "youtube", "recommendation", "vendas"],
    repeat: false,
  },

  dontWantLiveClasses: {
    mobile: ["downloadAppMobile", "youtubeMobile", "conversationNewMobile", "recommendationMobile", "vendasMobile"],
    desktop: ["downloadApp", "youtube", "conversationNew", "recommendation", "vendas"],
    repeat: false,
  },

  under18: {
    mobile: ["youtubeMobile", "downloadAppMobile"],
    desktop: ["youtube", "downloadApp"],
    repeat: true,
  },
};
