import PropTypes from "prop-types";
import React from "react";
import { useHistory } from "react-router-dom";

import BannerWhatsappImageMobile from "../../../../../assets/imgs/banners/banner_whatsapp_mobile.png";
import BannerWhatsappImage from "../../../../../assets/imgs/banners/banner_whatsapp.png";
import { ChatFavourite } from "../../../../../assets/svgs";
import ModalBanner from "../../ModalBanner";

import "./ModalBannerWhatsapp.css";

const ModalBannerWhatsapp = ({ handleHideBanner }) => {
  const history = useHistory();

  const handleRedirect = () => {
    handleHideBanner();
    history.push("/planos");
  };
  return (
    <ModalBanner imageDesktop={BannerWhatsappImage} imageMobile={BannerWhatsappImageMobile}>
      <div className="banner-whatsapp-wrapper">
        <div className="text-wrapper">
          <ChatFavourite />

          <p className="title">
            Tire suas <span>dúvidas</span> com um professor pelo WhatsApp{" "}
          </p>

          <p className="description">
            Assinando o plano premium, por apenas R$49,87 ao mês, você pode tirar suas dúvidas pelo WhatsApp com um
            professor.
          </p>
        </div>

        <div className="button-wrapper">
          <button onClick={handleRedirect} className="button-primary">
            Assine agora
          </button>
        </div>
      </div>
    </ModalBanner>
  );
};

ModalBannerWhatsapp.propTypes = {
  handleHideBanner: PropTypes.func.isRequired,
};

export default ModalBannerWhatsapp;
