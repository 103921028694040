/* eslint-disable jsx-a11y/accessible-emoji */
import useRequest from "hooks/useRequest";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { logAnalyticsEvent } from "utils/firebase-analytics";

import Message from "../Message/Message";
import * as S from "./styles.module.css";

const dictCallToAction = {
  revisao: {
    title: "Receba sua revisão personalizada",
    analyticsEvent: "BAY_opt_revisao_cta",
    description: "Assine o premium por R$ 49 ao mês para ter acesso a sua revisões personalizadas",
    options: [
      {
        title: "✨ Assinar agora",
        url: "/checkout",
      },
      {
        title: "💲Planos",
        url: "/planos",
      },
    ],
  },

  duvida: {
    title: "Tire suas dúvidas de inglês",
    analyticsEvent: "BAY_opt_duvida_cta",
    description: "Assine o premium por R$ 49 ao mês para conseguir tirar suas dúvidas de inglês com a Bay",
    options: [
      {
        title: "✨ Assinar agora",
        url: "/checkout",
      },
      {
        title: "💲Planos",
        url: "/planos",
      },
    ],
  },

  whatsapp: {
    title: "Fale com nosso time",
    analyticsEvent: "BAY_opt_suporte_cta",
    description: "Clique no botão abaixo para falar com o nosso suporte",
    options: [
      {
        title: "Falar com o suporte",
        url: "https://api.whatsapp.com/send?phone=5511958565055&text=Tenho%20uma%20duvida!",
      },
    ],
  },

  cupom: {
    title: "Use o cupom                  ",
    description:
      // eslint-disable-next-line quotes
      '<p style="font-size: 22px; font-weight: bold; letter-spacing: 3px; text-align: center">BAY20</p>',
    analyticsEvent: "BAY_opt_cupom_cta",
    options: [
      {
        title: "Assinar",
        url:
          "https://api.whatsapp.com/send?phone=5511958565055&text=Gostaria%20de%20receber%20meu%20cupom%20de%20desconto",
      },
    ],
  },
};

const CallToAction = ({ handleScrollToBottom, type, setShowChat, triggerWebView }) => {
  const { request } = useRequest();
  const history = useHistory();

  const [plans, setPlans] = useState([]);

  const fetchPlans = () => request("GET", "/plan/list");

  const { title, description, options, analyticsEvent } = dictCallToAction[type];

  useEffect(() => {
    handleScrollToBottom();
    triggerWebView(description);
  }, [type]);

  const handleClick = (type, index) => {
    setShowChat(false);
    logAnalyticsEvent(analyticsEvent);

    if (index === 1) {
      history.push("/planos");
      return;
    }

    if (type === "whatsapp") {
      window.open(
        "https://api.whatsapp.com/send?phone=5511958565055&text=Quero%20tirar%20uma%20d%C3%BAvida ",
        "_blank",
      );
      return;
    }

    if (type === "cupom") {
      sessionStorage.setItem("cupom", "BAY20");
      history.push("/planos");
      return;
    }

    if (type === "revisao" || type === "duvida") {
      handleChoosePlan(
        plans.find(
          (p) => p.intervalCount === 1 && p.lessonType == "CONVERSATION" && p.name === "Basic" && p.platform === "WEB",
        ),
      );
    }
  };

  const handleChoosePlan = (plan) => {
    localStorage.setItem("plan", JSON.stringify(plan));
    history.push("/planos/checkout");
  };

  useEffect(() => {
    handleScrollToBottom();
    fetchPlans().then(setPlans);
  }, []);

  return (
    <Message>
      <div className={S.Wrapper}>
        <div>
          <h3 style={{ userSelect: "none" }}>{title}</h3>
          <p dangerouslySetInnerHTML={{ __html: description }}></p>
        </div>

        {options.map((option, index) => (
          <button key={option.title} onClick={() => handleClick(type, index)}>
            {option.title}
          </button>
        ))}
      </div>
    </Message>
  );
};

CallToAction.propTypes = {
  type: PropTypes.string.isRequired,
  handleScrollToBottom: PropTypes.func.isRequired,
  isIaResponse: PropTypes.bool,
  setShowChat: PropTypes.func,
  triggerWebView: PropTypes.func,
};

export default CallToAction;
