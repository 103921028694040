import React from "react";
import { useTranslation } from "react-i18next";

import { BannerTeacher } from "../../../../assets/svgs";

const BannerTeacherWithLabel = () => {
  const { t } = useTranslation();

  return (
    <div className="w-full max-w-2xl flex-initial items-centers">
      <h2 className="2xl:text-4xl text-4xl text-gray-600 ml-7">
        <span dangerouslySetInnerHTML={{ __html: t("signup.teacher.welcome_text") }}></span>
      </h2>
      <BannerTeacher />
    </div>
  );
};

export default BannerTeacherWithLabel;
