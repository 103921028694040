import PropTypes from "prop-types";
import React from "react";

import Message from "../Message/Message";
import * as S from "./styles.module.css";

const UserResponse = ({ message }) => {
  return (
    <Message
      style={{
        alignItems: "flex-end",
      }}>
      <div className={S.UserMessage}>{message}</div>
    </Message>
  );
};

UserResponse.propTypes = {
  message: PropTypes.string.isRequired,
};

export default UserResponse;
