import PropTypes from "prop-types";
import React, { useContext, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";
import { logAnalyticsEvent } from "utils/firebase-analytics";

import { Input, InputPhone, RadioButton, PurpleButton } from "../../_Shared/Form/index";
import { PurpleArrow } from "../../../assets/svgs";
import { ToastContext } from "../../../context/ToastContext";
import { UserContext } from "../../../context/UserContext";
import useForm from "../../../hooks/useForm";
import useRequest from "../../../hooks/useRequest";
import { saveToken, saveUser } from "../../../utils/localStorage";
import { strict } from "../../../utils/UTM";
import AccountCreatedSuccess from "../AccountCreatedSuccess/AccountCreatedSuccess";

const Register = ({ terms, privacy, termsVersion, privacyVersion }) => {
  const { setUserContext } = useContext(UserContext);
  const { showToast } = useContext(ToastContext);
  const { request } = useRequest();
  const location = useLocation();
  const { t } = useTranslation();

  const [passwordError, setPassowrdError] = useState();
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [backUrl, setBackUrl] = useState("/auth/login");
  const [utm, setUTM] = useState(null);

  const name = useForm();
  const email = useForm("email");
  const { error: passwordFormError, ...password } = useForm("password");
  const { error: passowordConfirmationError, ...confirmPassword } = useForm("password");
  const phoneNumber = useForm("phoneNumber");
  const acceptedTerms = useForm("acceptedTerms");

  const validateForm = () => {
    name.validate();
    email.validate();
    phoneNumber.validate();
    password.validate();
    confirmPassword.validate();
    acceptedTerms.validate();
  };

  const passwordValidate = () => {
    if (password.value === confirmPassword.value) {
      return true;
    }
    setPassowrdError("As senhas não são iguais");
    return false;
  };

  const handleSubmit = async (event) => {
    setLoading(true);
    event.preventDefault();
    validateForm();

    const formIsValid =
      name.validate() &&
      phoneNumber.validate() &&
      password.validate() &&
      confirmPassword.validate() &&
      email.validate() &&
      acceptedTerms.validate() &&
      passwordValidate();

    if (formIsValid) {
      const userForm = {
        name: name.value,
        email: email.value,
        password: password.value,
        phoneNumber: phoneNumber.value,
        lastTermsAcceptedVersion: termsVersion,
        lastPrivacyAcceptedVersion: privacyVersion,
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      };

      if (location?.pathname.includes("/teacher/")) {
        userForm.teacher = true;
      }

      if (utm) {
        userForm.utm = utm;
      }

      const referrer = document.referrer;

      if (referrer) {
        userForm.referrer = referrer;
      }

      const { token, user, error } = await request("POST", "/auth/signup", userForm, true, false);
      setLoading(false);

      if (error && error.statusCode === 409) {
        return showToast({ message: "Esse E-mail já está em uso", type: "error" });
      }

      if (error) {
        console.error(error);
        return showToast({ message: error.message, type: "error" });
      }

      // Salva evento no GTM
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: "sign_up",
        method: "email", // Método de cadastro (fb, gmail ou email)
        userId: user.uuid,
      });

      logAnalyticsEvent("af_complete_registration", {
        af_registration_method: "EMAIL",
      });

      saveToken(token);
      saveUser(user);
      setUserContext(user);
      setSuccess(true);
    }

    setLoading(false);
  };

  const persistUTM = (parsedUTM) => {
    setUTM(parsedUTM);
    localStorage.setItem("utm", JSON.stringify(parsedUTM));
  };

  const tryLoadUTM = () => {
    const storedUTM = localStorage.getItem("utm");

    if (storedUTM) {
      setUTM(JSON.parse(storedUTM));
    }
  };

  useEffect(() => {
    const parsedUTM = strict(location.search);
    const hasUTMInUrl = parsedUTM && Object.keys(parsedUTM)?.length > 0;

    // Se tiver um UTM na url, seta e salva ele, senão tenta carregar um utm de uma visita anterior no storage
    if (hasUTMInUrl) {
      persistUTM(parsedUTM);
    } else {
      tryLoadUTM();
    }

    if (location.search.includes("?returnUrl=")) {
      const returnUrl = location.search.split("=")[1];

      if (returnUrl) {
        setBackUrl("/auth/login?returnUrl=" + returnUrl);
      }
    }
  }, []);

  return (
    <>
      {success && <AccountCreatedSuccess />}

      {!success && (
        <>
          <div>
            {!location?.pathname.includes("/teacher/") && (
              <div className="flex items-center">
                <Link to="/">
                  <PurpleArrow className="mr-2" />
                </Link>
                <Link to={backUrl}>
                  <div className="text-left font-low text-purple-500">Voltar</div>
                </Link>
              </div>
            )}
            <div className="text-2xl font-low text-gray-900 mt-2 mb-2 text-left">{t("signup.create_account")}</div>

            {/* Deixar comentado enquanto não for implementado */}
            {/* <div className="text-left font-low text-sm mb-4">Selecione a melhor opção para criar sua conta</div>
          <a
            href="/"
            className="cursor-pointer border borderRadius font-semibold border-gray-100 hover:bg-purple-700 hover:text-white py-2 px-4 mr-2 inline-flex items-center">
            <img className="w-5 mr-2" alt="google" src="https://img.icons8.com/fluent/48/000000/google-logo.png" />
            <span>Entrar com Google</span>
          </a>
          <a
            href="/"
            className="cursor-pointer border borderRadius font-semibold border-gray-100 hover:bg-purple-700 hover:text-white py-2 px-4 inline-flex items-center">
            <img className="w-5 mr-2" alt="facebook" src="https://img.icons8.com/fluent/48/000000/facebook-new.png" />
            <span>Entrar com Facebook</span>
          </a> */}
          </div>
          {/* <div className="text-center font-low text-sm mb-4 mt-2">
          <div>ou crie por e-mail</div>
        </div> */}
          <form className="space-y-2" onSubmit={handleSubmit}>
            <Input label="Nome" name="Nome" {...name} />
            <Input label="E-mail" name="email" {...email} />
            <InputPhone label="Telefone" placeholder="Número do celular" {...phoneNumber} />
            <Input
              label="Senha"
              name="senha"
              type="password"
              {...password}
              error={passwordError || passwordFormError}
            />
            <Input
              label="Confirmar senha"
              name="confirmarSenha"
              type="password"
              {...confirmPassword}
              error={passwordError || passowordConfirmationError}
            />
            <div className="text-center font-extralight text-sm py-2">
              <RadioButton terms={terms} privacy={privacy} {...acceptedTerms} />
            </div>
            <div>
              <PurpleButton isLoading={loading}>Criar conta</PurpleButton>
            </div>
          </form>
        </>
      )}
    </>
  );
};

Register.propTypes = {
  terms: PropTypes.string,
  privacy: PropTypes.string,
  privacyVersion: PropTypes.number,
  termsVersion: PropTypes.number,
};

export default Register;
