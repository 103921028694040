import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";

import { SpinnerPurple } from "../../../assets/svgs/index";
import useRequest from "../../../hooks/useRequest";
import { parseFilterQuery } from "../../../utils/utils";
import Teacher from "./Teacher";

import "./OurTeachers.css";

const OurTeachers = ({
  isSchedule,
  limit,
  setTotalTeacher,
  query,
  favorite,
  setPaginate,
  pageNumber,
  currentFilters,
}) => {
  const { request, loading } = useRequest();
  const [teachers, setTeachers] = useState([]);
  const { search } = useLocation();
  const isTestClass = new URLSearchParams(search).get("aulaTeste");

  const fetchTeachers = async () => {
    if (isTestClass && !currentFilters?.includes("isTestClass")) {
      currentFilters.push("isTestClass");
    }

    const filterQuery = parseFilterQuery(currentFilters);

    const { results, totalPages, totalResults } = await request(
      "GET",
      `/teacher/list?page=${pageNumber + 1}&perPage=${limit}${favorite && !isTestClass ? "&isFavorite=true" : ""}${
        query ? "&searchQuery=" + query : ""
      }${filterQuery ? filterQuery : ""}`,
    );

    setPaginate({ totalPages, totalResults });
    setTotalTeacher && setTotalTeacher(totalResults);
    setTeachers(limit ? results?.slice(0, limit) : results);
  };

  useEffect(() => {
    fetchTeachers();
  }, [query, favorite, pageNumber, currentFilters]);

  return (
    <>
      {loading ? (
        <SpinnerPurple className="w-10 mx-auto animate-spin" />
      ) : (
        teachers.map((teacher) => {
          return (
            <Teacher
              key={teacher.uuid}
              name={teacher.user.name}
              description={teacher.description}
              bannerUrl={teacher.user.bannerUrl}
              photoUrl={teacher.user.profilePictureUrl}
              isSchedule={isSchedule}
              uuid={teacher.uuid}
              isTestClass={isTestClass}
            />
          );
        })
      )}
    </>
  );
};

OurTeachers.propTypes = {
  isSchedule: PropTypes.bool,
  limit: PropTypes.number,
  setTotalTeacher: PropTypes.func,
  query: PropTypes.string,
  favorite: PropTypes.bool,
  setPaginate: PropTypes.func,
  pageNumber: PropTypes.number,
  currentFilters: PropTypes.array,
};

export default OurTeachers;
