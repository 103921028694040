import { Speaker, SpinnerWhite, Turtle } from "assets/svgs";
import Button from "components/MaterialCards/Button/Button";
import { useMaterialCards } from "context/MaterialCardsContext";
import useRequest from "hooks/useRequest";
import PropTypes from "prop-types";
import React, { useState, useRef, useEffect } from "react";
import { urltoFile } from "utils/file";

import * as S from "./styles.module.css";

const AudioComponent = ({ nextCard }) => {
  const { question } = useMaterialCards();
  const audioRef = useRef();

  const handleNextQuestion = () => {
    setIsLoading(false);
    setIsChecking(false);
    nextCard({ answer: "_audio", isCorrect: true });
  };

  const { request } = useRequest();

  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingSlow, setIsLoadingSlow] = useState(false);
  const [isChecking, setIsChecking] = useState(false);
  const [audio, setAudio] = useState();

  const handleClick = async () => {
    // Prevenir multiplas requisições
    if (isLoading) {
      return;
    }

    setIsLoading(true);

    const { result } = await request("POST", "/tts/textToSpeech", { text: question.text });

    const file = await urltoFile("data:audio/ogg;base64, " + result, "audio.ogg", "audio/mp3");

    const fileUrl = URL.createObjectURL(file);

    setAudio(fileUrl);

    setIsLoading(false);
    setIsChecking(true);

    audioRef.current.play();
  };

  const handleClickSlow = async () => {
    // Prevenir multiplas requisições
    if (isLoadingSlow) {
      return;
    }

    setIsLoadingSlow(true);

    const { result } = await request("POST", "/tts/textToSpeech", {
      text: question.text,
      speakingRate: 0.44,
    });

    const file = await urltoFile("data:audio/ogg;base64, " + result, "audio.ogg", "audio/mp3");

    const fileUrl = URL.createObjectURL(file);

    setAudio(fileUrl);

    setIsLoadingSlow(false);
    setIsChecking(true);

    audioRef.current.play();
  };

  useEffect(() => {
    setIsLoading(false);
    setIsChecking(false);
  }, []);

  return (
    <div className={S.wrapper}>
      <div>
        <h1 className={S.title}>{question?.title}</h1>
      </div>

      <div className={S.wrapperButton}>
        {question?.imageUrl && (
          <div className={S.wrapperImage}>
            <img className={S.image} src={question.imageUrl} alt="card imagem" />
          </div>
        )}

        <div className={S.slowButtonWrapper}>
          <button onClick={handleClick} className={S.button} type="button">
            {isLoading && <SpinnerWhite className="w-6 m-auto animate-spin" />}

            {!isLoading && <Speaker />}
          </button>

          <button onClick={handleClickSlow} className={S.slowButton} type="button">
            {isLoadingSlow && <SpinnerWhite className="w-6 m-auto animate-spin" />}

            {!isLoadingSlow && <Turtle style={{ marginLeft: "3px" }} />}
          </button>
        </div>

        <div>
          {question?.showTranslation && <p className={S.text}>{question?.text}</p>}
          <p className={S.translation}>{question?.subText}</p>
        </div>
      </div>

      {!isChecking && <Button type="callToAction" variant="disabled" text="Próximo" disabled={true} />}

      {isChecking && <Button onClick={handleNextQuestion} type="callToAction" variant="checking" text="Continuar" />}

      <audio className="hidden" ref={audioRef} id="beep" src={audio}>
        <track kind="captions" />
      </audio>
    </div>
  );
};

AudioComponent.propTypes = {
  nextCard: PropTypes.func.isRequired,
};

export default AudioComponent;
