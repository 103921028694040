import PropTypes from "prop-types";
import React from "react";
import SelectSearch from "react-select-search";

import "./SelectTimezone.css";
import { timezones } from "../../../../utils/timezones";
import fuzzySearch from "./fuzzySearch";

const SelectTimezone = ({ onChange, value }) => {
  return <SelectSearch search onChange={onChange} value={value} options={timezones} filterOptions={fuzzySearch} />;
};

SelectTimezone.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.string,
};

export default SelectTimezone;
