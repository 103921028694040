import PropTypes from "prop-types";
import React, { useState, useContext, useEffect } from "react";
import Cards from "react-credit-cards";
import "react-credit-cards/es/styles-compiled.css";

import { Warning } from "../../../../../assets/svgs/index";
import { SlideContext } from "../../../../../context/SlideContext";
import { ToastContext } from "../../../../../context/ToastContext";
import { UserContext } from "../../../../../context/UserContext";
import useForm from "../../../../../hooks/useForm";
import useRequest from "../../../../../hooks/useRequest";
import { pushGTMEvent } from "../../../../../utils/GTM";
import PaymentUnsuccessful from "../../../../Auth/PaymentUnsuccessful/PaymentUnsuccessful";
import { InputTwo, RadioButton, PurpleButton, Button, FormCouponButton } from "../../../Form/index";
import CreditCardPaymentSuccess from "../CreditCardPaymentSuccess/CreditCardPaymentSuccess";

const CreditCardPayment = ({ planUuid, setShowSlide, isReactivatePlan, planType }) => {
  const { request } = useRequest();
  const cpfForm = useForm("cpf");
  const couponForm = useForm("optional");
  const cardholderNameForm = useForm();
  const cardExpirationForm = useForm();
  const cardNumberForm = useForm();
  const cardCVVForm = useForm();
  const acceptedTermsForm = useForm();
  const { setSlideComponent } = useContext(SlideContext);
  const { updateUserContext, getCredits, user } = useContext(UserContext);
  const { showToast } = useContext(ToastContext);

  const [focus, setFocus] = useState();
  const [loading, setloading] = useState();
  const [terms, setTerms] = useState("");
  const [privacy, setPrivacy] = useState("");
  const [discount, setDiscount] = useState();

  useEffect(() => {
    const fetchTerms = async () => {
      const userType = user.role === "TEACHER" ? "TEACHER" : "USER";

      const [term, privacy] = await Promise.all([
        request("GET", `/terms/read?kind=TERM&userType=${userType}`),
        request("GET", `/terms/read?kind=PRIVACY&userType=${userType}`),
      ]);

      setTerms(term.text);
      setPrivacy(privacy.text);
    };

    fetchTerms();
  }, []);

  const saveGTMEvents = (purchase) => {
    pushGTMEvent({
      event: "purchase",
      ecommerce: {
        purchase: {
          actionField: {
            id: purchase.billId, // Transaction ID. Required for purchases and refunds. (Vindi Bill Id)
            affiliation: "EnglishBay",
            revenue: purchase.bill.amount, // Total transaction value (incl. tax and shipping)
            tax: null,
            shipping: null,
            coupon: purchase?.coupon?.code ? purchase.coupon.code : null, //Retorna o cupom caso for utilizado na compra
          },
          products: [
            {
              // List of productFieldObjects.
              name: purchase?.plan?.name, // Name or ID is required. Nome da assinatura
              id: purchase?.plan?.uuid,
              price: purchase?.plan?.price,
              brand: null,
              category: purchase?.plan?.lessonType,
              variant: "null",
              quantity: 1,
              coupon: purchase?.coupon?.code ? purchase.coupon.code : null,
            },
          ],
        },
      },
    });
  };

  const validateForm = () => {
    cpfForm.validate();
    cardholderNameForm.validate();
    cardExpirationForm.validate();
    cardNumberForm.validate();
    cardCVVForm.validate();
    acceptedTermsForm.validate();
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (loading) {
      return;
    }

    const paymentRequest = {
      cpf: cpfForm.value,
      planUuid,
      paymentMethodCode: "credit_card",
      card: {
        holderName: cardholderNameForm.value,
        cardExpiration: cardExpirationForm.value,
        cardNumber: cardNumberForm.value.split(" ").join(""),
        cardCVV: cardCVVForm.value,
      },
    };

    if (discount) {
      paymentRequest.couponCode = discount.code;
    }

    validateForm();

    const isValidForm =
      cpfForm.validate() &&
      cardholderNameForm.validate() &&
      cardExpirationForm.validate() &&
      cardNumberForm.validate() &&
      cardCVVForm.validate() &&
      acceptedTermsForm.validate();

    if (isValidForm) {
      setloading(true);
      const response = await request(
        "POST",
        `/subscription/${isReactivatePlan ? "subscribe" : "new"}`,
        paymentRequest,
        true,
        false,
      );

      if (!response.error) {
        await updateUserContext();
        await getCredits();
        saveGTMEvents(response);
        setloading(false);

        setSlideComponent(<CreditCardPaymentSuccess response={response} discount={discount} />);
        return;
      }

      setloading(false);

      if (response.error?.statusCode !== 201) {
        if (response.error?.message === "INVALID_CPF") {
          return showToast({ message: "CPF Inválido!", type: "error" });
        }

        if (response.error?.message === "CARD_REJECTED") {
          return setSlideComponent(<PaymentUnsuccessful isReactivatePlan={isReactivatePlan} planUuid={planUuid} />);
        }

        if (response.error?.statusCode === 500) {
          return showToast({ message: "Erro interno ao processar compra!", type: "error" });
        }

        return showToast({ message: "Preencha todos os dados corretamente!", type: "error" });
      }
    }

    setloading(false);
  };

  return (
    <div onSubmit={handleSubmit}>
      <form>
        <h1 className="text-xl font-gray">Insira os dados do cartão de crédito</h1>

        <div className="flex items-center gap-2 py-3">
          <Warning style={{ fill: "#F59300", width: "23px", height: "23px" }} className="w-10 md:w-5 mr-3 md:mr-1" />
          <p style={{ color: "#F59300" }}>
            Lembre-se: A cobrança é mensal! {planType !== "group" && <span>Suas aulas tem validade de 45 dias.</span>}
          </p>
        </div>

        <div className="grid sm:grid-cols-1 md:grid-cols-2">
          <div>
            <InputTwo
              mask="9999 9999 9999 9999"
              label="numero do cartão"
              placeholder="Insira o numero"
              name="number"
              onFocus={() => setFocus("number")}
              {...cardNumberForm}
            />
            <InputTwo
              placeholder="Digite o nome do responsável"
              label="nome impresso no cartão"
              name="name"
              onFocus={() => setFocus("name")}
              {...cardholderNameForm}
            />
            <InputTwo
              mask="999.999.999-99"
              placeholder="Digite o CPF do responsável"
              label="digite seu CPF"
              name="cpf"
              {...cpfForm}
            />
            <div className="grid grid-cols-2 gap-20">
              <InputTwo
                mask="99/9999"
                placeholder="Insira a validade"
                label="validade"
                name="expiry"
                onFocus={() => setFocus("number")}
                {...cardExpirationForm}
              />
              <InputTwo
                mask="999"
                placeholder="Insira o código"
                label="cvv"
                name="cvc"
                onFocus={() => setFocus("cvc")}
                {...cardCVVForm}
              />
            </div>
            <div>
              <FormCouponButton
                couponForm={couponForm}
                planUuid={planUuid}
                setDiscount={setDiscount}
                discount={discount}
              />
            </div>
          </div>
          <Cards
            cvc={cardCVVForm.value}
            expiry={cardExpirationForm.value}
            name={cardholderNameForm.value}
            number={cardNumberForm.value}
            focused={focus}
          />
        </div>
        <div style={{ fontSize: "0.8em" }} className="grid md:grid-cols-2 mt-3 text-xs">
          <RadioButton terms={terms} privacy={privacy} {...acceptedTermsForm} />
        </div>
        <div className="grid md:grid-cols-2 mt-4">
          <div className="col-span-1 grid grid-cols-2 gap-5">
            <PurpleButton isLoading={loading} type="submit">
              Finalizar compra
            </PurpleButton>
            <Button
              type="button"
              className="border border-purple-800 text-purple-800"
              onClick={() => setShowSlide(false)}>
              Cancelar
            </Button>
            <hr className="mt-2 invisible" />
          </div>
        </div>
      </form>
    </div>
  );
};

CreditCardPayment.propTypes = {
  planUuid: PropTypes.string,
  setShowSlide: PropTypes.func,
  isReactivatePlan: PropTypes.bool,
  planType: PropTypes.string,
};

export default CreditCardPayment;
