import propTypes from "prop-types";
import React from "react";

import "./Select.css";

function Select({ options = [], label, className, ...props }) {
  return (
    <label className="generic-select__label">
      {label}

      <select {...props} className={`generic-select ${className}`} required>
        <option value="" disabled hidden>
          Selecione...
        </option>

        {options.map((option) => (
          <option key={option} value={option}>
            {option}
          </option>
        ))}
      </select>
    </label>
  );
}

export default Select;

Select.propTypes = {
  options: propTypes.array.isRequired,
  label: propTypes.string,
  className: propTypes.string,
};
