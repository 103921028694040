import propTypes from "prop-types";
import React from "react";
import { useHistory } from "react-router-dom";
import { MATERIALS_CARDS_FEATURE_ENABLED } from "utils/features";

import "./MaterialCard.css";

const MaterialCard = ({ data, color, isDisabled, isCompleted }) => {
  const { uuid, title, description, iconUrl, totalMaterials, children } = data;

  color = isDisabled ? "rgba(0, 0, 0, 0.35)" : color;
  color = isCompleted ? " #58CC02" : color;

  const borderStyle =
    !isDisabled && !isCompleted ? { boxShadow: `0px 4px 0px ${color}`, border: `2px solid ${color}` } : {};

  const imageStyle = isDisabled ? { filter: "grayscale(1)" } : {};

  const backgroundStyle = isCompleted ? { background: "#F5FFED" } : {};

  const wrapperStyle = { ...borderStyle, ...backgroundStyle };

  const history = useHistory();

  const subCategoriesLink = `material?parentUuid=${uuid}`;
  const hasSubCategories = children?.length > 0;
  const isCard = MATERIALS_CARDS_FEATURE_ENABLED.includes(title);
  const handleRedirect = () => {
    if (isDisabled || (isCompleted && isCard)) {
      return;
    }

    if (MATERIALS_CARDS_FEATURE_ENABLED.includes(title)) {
      history.push("/materialCards");
      return;
    }

    history.push(hasSubCategories ? subCategoriesLink : `material/${uuid}`);
  };

  return (
    <section
      className={`material-card ${!isCompleted && "cursor-pointer"}`}
      style={wrapperStyle}
      onClick={handleRedirect}>
      <img src={iconUrl} alt="category icon" className="material-card__image" style={imageStyle} draggable="false" />

      <span className="material-card__title">{title}</span>
      <span className="material-card__description">{description}</span>

      {!isCompleted ? (
        <span className="material-card__details" style={{ color }}>
          {totalMaterials} unidades neste módulo
        </span>
      ) : (
        <span className="material-card__details" style={{ color }}>
          Todas as unidades concluídas
        </span>
      )}

      <button
        type="button"
        className="material-card__button cursor-pointer"
        style={{ background: color }}
        onClick={(event) => {
          event.stopPropagation();
          if (isCompleted && isCard) {
            history.push("/certificados");
            return;
          }

          handleRedirect();
        }}>
        {isCompleted ? "Ver certificados" : !isDisabled ? "Continuar" : "Termine módulo anterior"}
      </button>
    </section>
  );
};

export default MaterialCard;

MaterialCard.propTypes = {
  data: propTypes.object,
  color: propTypes.string,
}.isRequired;
