import PropTypes from "prop-types";
import React from "react";

import * as S from "./styles.module.css";

export const HalfScreenScroll = ({ children }) => {
  return <div className={`${S.box} ${S.animation}`}>{children}</div>;
};

HalfScreenScroll.propTypes = {
  children: PropTypes.node,
};
