import React from "react";
import { useHistory } from "react-router-dom";

import { PurpleButton } from "../_Shared/Form/index";
import { PeoplesNotFound } from "../../assets/svgs/index";

const NotFound = () => {
  const history = useHistory();

  return (
    <div>
      <header className="myEvents-bg shadow-md">
        <div className="px-6">
          <p className="text-sm pt-2">Início / Eventos</p>
          <h1 className="text-2xl font-semibold my-3">Eventos</h1>
        </div>
      </header>

      <div
        className="-mt-14 h-full bg-white rounded-2xl ml-5 mr-5"
        style={{
          boxShadow: "0px 6px 12px -6px rgba(24, 39, 75, 0.12), 0px 8px 24px -4px rgba(24, 39, 75, 0.08)",
        }}>
        <div className="flex flex-col items-center justify-center">
          <PeoplesNotFound className="mt-10" />

          <div className="text-center mt-6">
            <h1 className="text-xl" style={{}}>
              Puxa, esta pagina não existe
            </h1>

            <h2
              className="mt-3 text-lg"
              style={{
                color: "#625E66",
              }}>
              Talvez o endereço não esteja mais disponivel na web
            </h2>

            <PurpleButton className="w-80 mt-6 mb-5" onClick={() => history.push("/")}>
              Ir para o início
            </PurpleButton>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotFound;
