import axios from "axios";
import { useState, useCallback } from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";

import { deleteToken, getCurrentToken } from "../utils/localStorage";

toast.configure({ hideProgressBar: true, style: { backgroundColor: "purple" } });

const axiosInstace = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

const showToast = ({ message, type = "info" }) => {
  toast[type](message);
};

const errorHandler = (statusError) => {
  switch (statusError) {
    case 400:
      return "Preencha corretamente os seus dados!";
    case 401:
      return "Sua sessão expirou, faça login novamente";
    case 403:
      return "Não autorizado";
    case 429:
      return "Muitas requisições! Aguarde um pouco";
    case 404:
      return "Recurso não encontrado";
    case 500:
      return "Erro no servidor interno";
    default:
      return "Default error";
  }
};

const useRequest = () => {
  const history = useHistory();
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(null);

  const request = useCallback(
    async (method, url, data = {}, sendToken = true, useDefaultErrorHandler = true, sendCaptchaToken = false) => {
      setError(null);

      try {
        const options = {
          method,
          url,
          data,
          headers: { "x-eb-platform": "WEB" },
        };

        if (sendToken) {
          options.headers = { ...options.headers, Authorization: "Bearer " + getCurrentToken() };
        }

        if (sendCaptchaToken && !!localStorage.getItem("x-captcha-token")) {
          options.headers = {
            ...options.headers,
            "x-captcha-token": localStorage.getItem("x-captcha-token"),
          };
        }

        setLoading(true);

        const response = await axiosInstace(options);
        return response.data;
      } catch (error) {
        console.error(error);

        const errorMessage = errorHandler(error.response.status);

        if (error.response.status === 401) {
          deleteToken();
          history.push("/");
        }

        if (useDefaultErrorHandler && error?.response?.status !== 401) {
          showToast({ message: errorMessage, type: "error" });
        }

        setError(errorMessage);

        const errorResponse = {
          error: {
            statusCode: error.response.data.statusCode,
            message: error.response.data.message,
            properties: error.response.data.properties,
          },
        };

        return errorResponse;
      } finally {
        setLoading(false);
      }
    },
    [],
  );
  return { error, loading, request, setLoading };
};

export default useRequest;
