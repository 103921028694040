import React from "react";

const Version = () => {
  return (
    <div>
      <div className="p-8">
        <h1 className="text-lg font-bold">Enviroment information</h1>

        <div>
          {Object.keys(process.env)
            .filter((key) => key.startsWith("REACT_APP"))
            .map((envKey, index) => {
              return (
                <p key={index}>
                  {envKey}={process.env[envKey]}
                </p>
              );
            })}
        </div>
      </div>
    </div>
  );
};

export default Version;
