import React, { useState } from "react";

import TeacherProfile from "../../../../_Shared/Teacher/TeacherProfile/TeacherProfile";
import SchedulingSuccess from "../SchedulingSuccess/SchedulingSuccess";

import "./ProfessorProfile.css";

const ProfessorProfile = () => {
  const [appointmentsSuccesse, setSuccesse] = useState(false);
  const [appointmentsSuccesseArray, setAppointmentsSuccesseArray] = useState([]);

  if (appointmentsSuccesse) {
    return <SchedulingSuccess appointmentsSuccesseArray={appointmentsSuccesseArray} />;
  }

  return (
    <div>
      <div className="professor-jumbotron shadow-md px-4 text-black">
        <div className="grid grid-cols-1 2xl:grid-cols-4">
          <div className="col-span-3 py-4" style={{ marginBottom: "100px" }}>
            <h1 className="text-3xl ">Perfil do professor</h1>
            <div className="mt-5 text-black">
              <TeacherProfile setSuccesse={setSuccesse} setAppointmentsSuccesseArray={setAppointmentsSuccesseArray} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfessorProfile;
