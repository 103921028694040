import PropTypes from "prop-types";
import React, { useContext } from "react";

import { CloseX, Warning } from "../../../../assets/svgs/index";
import { SlideContext } from "../../../../context/SlideContext";
import { UserContext } from "../../../../context/UserContext";
import useRequest from "../../../../hooks/useRequest";
import { getDayOfTheWeekDayMonthYear, getHoursAndMinutes, addMinutes } from "../../../../utils/dates";
import DrawerDetailsCancelSuccess from "../DrawerDetailsCancelSuccess/DrawerDetailsCancelSuccess";

import "./DrawerDetailsCancel.css";

const DrawerDetailsCancel = ({ getAppointments, appointment }) => {
  const { request } = useRequest();
  const { setShowSlide, setSlideComponent } = useContext(SlideContext);
  const { getCredits, user } = useContext(UserContext);

  const handleCancelClass = async () => {
    const response = await request("DELETE", `/appointment/cancel/${appointment.uuid}`);
    if (!response.error) {
      setSlideComponent(<DrawerDetailsCancelSuccess />);
      getAppointments();

      if (user?.role === "STUDENT") {
        getCredits();
      }
    }
  };

  return (
    <div className="w-96 h-full flex flex-col">
      <div>
        <header className="flex flex-row justify-between items-center">
          <h1 className="text-xl">Tem certeza que deseja cancelar essa aula?</h1>

          <CloseX className="cursor-pointer" onClick={() => setShowSlide(false)} />
        </header>
        <div className="flex flex-row mt-8 justify-between">
          <div className="flex flex-row">
            <img
              className="w-11 h-11 rounded-3xl"
              src={appointment.schedule.teacher.user.profilePictureUrl ?? process.env.REACT_APP_PROFILE_AVATAR_URL}
              alt="foto do professor"
            />

            <div className="ml-4 items-center">
              <h2 className="text-base mt-2">{appointment.schedule.teacher.user.name}</h2>
            </div>
          </div>
        </div>
        <div className="mt-5">
          <p className="text-sm capitalize">{getDayOfTheWeekDayMonthYear(appointment.schedule.dateTime)}</p>
          <p className="font-nunito text-xs font-bold tracking-wider uppercase text-gray-600">
            das {getHoursAndMinutes(appointment.schedule.dateTime)} ás{" "}
            {getHoursAndMinutes(addMinutes(appointment.schedule.dateTime, "FIFTY"))}
          </p>
        </div>
      </div>
      <div className="mt-8 flex-1">
        <div className="flex items-center">
          <div>
            <Warning style={{ fill: "#F59300" }} />
          </div>
          <p className="warningTextColorCancel ml-4 text-sm">Os créditos não serão descontados de você</p>
        </div>
      </div>
      <div className="borderButtonsTop pt-4 flex">
        <button
          onClick={() => handleCancelClass()}
          className="bg-red-700 text-white mr-4 pt-4 pb-4 rounded-lg tracking-wider text-sm font-semibold w-60 h-14">
          Sim, quero cancelar
        </button>
        <button
          onClick={() => setShowSlide(false)}
          className="cancelLessonCancel pt-4 pb-4 rounded-lg tracking-wider text-sm font-semibold w-60 h-14">
          Não
        </button>
      </div>
    </div>
  );
};

DrawerDetailsCancel.propTypes = {
  appointment: PropTypes.object.isRequired,
  getAppointments: PropTypes.func,
};

export default DrawerDetailsCancel;
