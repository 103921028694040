import { SpinnerPurple } from "assets/svgs";
import Banner from "components/_Shared/Banners/Banner/Banner";
import CourseProgressBar from "components/_Shared/CourseProgressBar/CourseProgressBar";
import { NavbarContext } from "context/NavbarContext";
import { ToastContext } from "context/ToastContext";
import useRequest from "hooks/useRequest";
import React, { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";

import LessonItem from "../LessonItem/LessonItem";

import "./LessonsList.css";

function LessonsList() {
  const [lessons, setLessons] = useState([]);
  const [category, setCategory] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  const { request } = useRequest();
  const { uuid } = useParams();
  const { showToast } = useContext(ToastContext);
  const { setNavigationLinks } = useContext(NavbarContext);

  const getNumberOfDoneLessons = (lessons) => {
    return lessons.reduce((quantity, { viewed }) => (viewed ? quantity + 1 : quantity), 0);
  };

  const fetchLessons = async () => {
    const response = await request("GET", `/material/list/${uuid}`);
    setLessons(response);
    setIsLoading(false);
  };

  const fetchCategoryData = async () => {
    const response = await request("GET", `/category/read/${uuid}`, null, true, false);

    if (response.error?.statusCode === 403) {
      showToast({ message: "Você não tem permissão!", type: "error" });
      return history.push("/material");
    }

    return setCategory(response);
  };

  useEffect(() => {
    fetchLessons();
    fetchCategoryData();
  }, []);

  useEffect(() => {
    if (category.title) {
      const arrLinks = [
        { title: "Início", path: "/" },
        { title: "Material", path: "/material" },
        { title: `${category?.title || ""}`, path: `/material/${category?.uuid}` },
      ];

      setNavigationLinks(arrLinks);
    }
  }, [category]);

  return (
    <section className="lessons-list__container">
      <div className="container__header">
        <div className="header__col">
          <section className="lessons__progress-details">
            <img src={category?.iconUrl} alt="category" className="category__image--lg" />

            <div>
              <div className="progress-details__header">
                <img src={category?.iconUrl} alt="category" />
                <span>{category?.title}</span>
              </div>

              <CourseProgressBar
                barColor="rgba(0, 0, 0, 0.05)"
                fillColor="#733EB1"
                totalSteps={lessons.length}
                doneSteps={getNumberOfDoneLessons(lessons)}
                barHeight="12px"
              />

              <div className="progress-details__footer">
                <span>Lições concluídas</span>
                <span>
                  {getNumberOfDoneLessons(lessons)} <span className="footer__total-materials">/ {lessons.length}</span>
                </span>
              </div>
            </div>
          </section>
        </div>

        <div className="header__col">
          <Banner format="rect" name="certificate" />
        </div>
      </div>

      <div className="flex flex-col gap-3 mt-8">
        {(isLoading && <SpinnerPurple className="w-5 m-auto mt-5 animate-spin" />) ||
          lessons.map((lesson) => <LessonItem data={lesson} key={lesson.uuid} />)}
      </div>
    </section>
  );
}

export default LessonsList;
