import React, { useState, useContext } from "react";
import { useLocation } from "react-router-dom";

import { Input, PurpleButton } from "../../_Shared/Form/index";
import "./ChangePasswordRecovery.css";
import { ToastContext } from "../../../context/ToastContext";
import useForm from "../../../hooks/useForm";
import useRequest from "../../../hooks/useRequest";
import ChangePasswordRecoverySuccess from "../ChangePasswordRecoverySuccess/ChangePasswordRecoverySuccess";

const ChangePasswordRecovery = () => {
  const { request, loading } = useRequest();
  const { showToast } = useContext(ToastContext);
  const [passwordError, setPasswordError] = useState();
  const [success, setSuccess] = useState(false);

  const token = new URLSearchParams(useLocation().search).get("token");

  const { error: passwordFormError, ...password } = useForm("password");
  const { error: passowordConfirmationError, ...confirmPassword } = useForm("password");

  const validateForm = () => {
    passwordValidate();
  };

  const passwordValidate = () => {
    if (password.value === confirmPassword.value) {
      return true;
    }

    setPasswordError("As senhas não são iguais");
    return false;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    validateForm();

    if (!passwordValidate()) {
      return;
    }

    const recover = {
      code: token,
      password: password.value,
    };

    const { error } = await request("POST", "/user/recoverPassword", recover);

    if (error) {
      console.error(error.message);
      showToast({ message: error.message, type: "error" });
      return;
    }

    setSuccess(!error);
  };

  if (success) {
    return <ChangePasswordRecoverySuccess />;
  }

  return (
    <div className="changeRecoveryPassword">
      <h6>Recuperação de senha solicitada</h6>

      <p>Tente escolher uma senha que você irá lembrar e que contenha letras, números e caracteres especiais.</p>

      <form onSubmit={handleSubmit}>
        <Input label="Senha" name="senha" type="password" {...password} error={passwordError || passwordFormError} />
        <Input
          label="Confirmar senha"
          name="confirmarSenha"
          type="password"
          {...confirmPassword}
          error={passwordError || passowordConfirmationError}
        />
        <PurpleButton type="submit" isLoading={loading}>
          Recuperar Conta
        </PurpleButton>
      </form>
    </div>
  );
};

export default ChangePasswordRecovery;
