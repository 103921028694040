import React, { useEffect, useContext, useState } from "react";

import { UserContext } from "../../../../context/UserContext";
import useRequest from "../../../../hooks/useRequest";

const PrivacyPolices = () => {
  const { user } = useContext(UserContext);
  const { request } = useRequest();

  const [privacy, setPrivacy] = useState("");

  useEffect(() => {
    if (!user?.role) {
      return;
    }

    const fetchPrivacy = async () => {
      const userType = user.role === "TEACHER" ? "TEACHER" : "USER";

      const { text } = await request("GET", `/terms/read?kind=PRIVACY&userType=${userType}`, null, false, false);

      if (text) {
        setPrivacy(text);
      }
    };

    fetchPrivacy();
  }, [user?.role]);

  return (
    <>
      <h2 className="text-3xl font-medium m-5">Política de Privacidade</h2>

      <div className="p-5">
        <iframe className="w-full h-[70vh]" title="Terms" srcDoc={privacy}></iframe>
      </div>
    </>
  );
};

export default PrivacyPolices;
