// import PropTypes from "prop-types";
import React from "react";
import { useHistory } from "react-router-dom";

import { ArrowLeftPurple } from "../../../assets/svgs";

import "./BackButton.css";

const BackButton = () => {
  const history = useHistory();

  return (
    <div className="back-button__container">
      <button onClick={() => history.goBack()}>
        <ArrowLeftPurple />
      </button>

      <span>Voltar</span>
    </div>
  );
};

// BackButton.propTypes = {
//   color: PropTypes.string,
// };

export default BackButton;
