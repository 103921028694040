import GuyBanner2 from "assets/imgs/guy-banner-2.png";
import "./BannerPraticeConversationRect.css";
import { useIsStudent } from "hooks/useIsStudent";
import React from "react";
import { HiOutlineArrowNarrowRight } from "react-icons/hi";
import { logAnalyticsEvent } from "utils/firebase-analytics";

const BannerPraticeConversationRect = () => {
  const { handleRedirect } = useIsStudent();

  return (
    <div
      className="banner_pratice_conversation_rect"
      style={{ backgroundImage: `url(${GuyBanner2})` }}
      onClick={() => {
        logAnalyticsEvent("banner_click_conversacao_02");
        handleRedirect("conversation", "/eventos");
      }}>
      <span>
        Pratique <span>conversação</span> com outras pessoas
      </span>

      <button>
        Participe agora
        <HiOutlineArrowNarrowRight />
      </button>
    </div>
  );
};

export default BannerPraticeConversationRect;
