import { subDays, addDays, format } from "date-fns";
import { utcToZonedTime } from "date-fns-tz";
import React, { useEffect, useState, useContext } from "react";
import { useTranslation } from "react-i18next";

import { SpinnerPurple, BasicClock } from "../../../../assets/svgs/index";
import { UserContext } from "../../../../context/UserContext";
import useRequest from "../../../../hooks/useRequest";
import {
  getStartOfWeek,
  formatIso,
  getHoursAndMinutes,
  getDayMonthNameYear,
  getNameOfDay,
} from "../../../../utils/dates";
import Schedule from "./Schedule";
import "./TeacherSchedule.css";

const TeacherSchedule = () => {
  const [date, setDate] = useState(getStartOfWeek());
  const [schedulesArray, setSchedulesArray] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { user } = useContext(UserContext);
  const { request } = useRequest();
  const { t } = useTranslation();

  const weekDays = ["dom", "seg", "ter", "qua", "qui", "sex", "sáb"];

  const addDaysByName = (dayName) => {
    return format(addDays(date, weekDays.indexOf(dayName)), "yyyy-MM-dd");
  };

  const getSchedules = async () => {
    if (!user?.timezone) {
      return;
    }

    setIsLoading(true);

    const result = await request("GET", `/teacherSchedule/schedules?initialDate=${formatIso(date)}`);

    // Aplica o timezone nas schedules
    result.forEach((schedule) => {
      schedule.dateTime = formatIso(utcToZonedTime(schedule.dateTime, user.timezone));
    });

    // Filtra os schedules que estão dentro da semana atual
    const startDate = date;
    const endDate = addDays(startDate, 7);

    const schedules = result.filter((schedule) => {
      return (
        new Date(schedule.dateTime).getTime() >= startDate.getTime() &&
        new Date(schedule.dateTime).getTime() <= endDate.getTime()
      );
    });

    // Cuidado ao mutar essa var.
    const hoursArray = [
      { hour: "00:00" },
      { hour: "00:30" },

      { hour: "01:00" },
      { hour: "01:30" },

      { hour: "02:00" },
      { hour: "02:30" },

      { hour: "03:00" },
      { hour: "03:30" },

      { hour: "04:00" },
      { hour: "04:30" },

      { hour: "05:00" },
      { hour: "05:30" },

      { hour: "06:00" },
      { hour: "06:30" },

      { hour: "07:00" },
      { hour: "07:30" },

      { hour: "08:00" },
      { hour: "08:30" },

      { hour: "09:00" },
      { hour: "09:30" },

      { hour: "10:00" },
      { hour: "10:30" },

      { hour: "11:00" },
      { hour: "11:30" },

      { hour: "12:00" },
      { hour: "12:30" },

      { hour: "13:00" },
      { hour: "13:30" },

      { hour: "14:00" },
      { hour: "14:30" },

      { hour: "15:00" },
      { hour: "15:30" },

      { hour: "16:00" },
      { hour: "16:30" },

      { hour: "17:00" },
      { hour: "17:30" },

      { hour: "18:00" },
      { hour: "18:30" },

      { hour: "19:00" },
      { hour: "19:30" },

      { hour: "20:00" },
      { hour: "20:30" },

      { hour: "21:00" },
      { hour: "21:30" },

      { hour: "22:00" },
      { hour: "22:30" },

      { hour: "23:00" },
      { hour: "23:30" },
    ];

    // Adiciona os dias da semana em cada row de horário.
    hoursArray.forEach((obj) => {
      weekDays.forEach((day) => (obj[day] = []));
    });

    // Itera nas rows
    for (const row of hoursArray) {
      const rowTime = row.hour;

      // Itera nas colunas
      Object.keys(row).forEach((day) => {
        if (!weekDays.includes(day)) {
          return;
        }

        // Pega todos os registros da row (hora) e coluna(dia da semana) atual
        const targetSchedule = schedules.find((schedule) => {
          const targetDate = format(addDays(date, weekDays.indexOf(day)), "dd");

          return (
            getHoursAndMinutes(schedule.dateTime) === rowTime &&
            getNameOfDay(schedule.dateTime) === day &&
            targetDate == format(new Date(schedule.dateTime), "dd")
          );
        });

        // Objeto vazio = pilula vazia
        const pill = targetSchedule || { _dateTime: `${addDaysByName(day)} ${rowTime}` };
        row[day].push(pill);
      });
    }

    setSchedulesArray(hoursArray);
    setIsLoading(false);
  };

  const refreshTime = 180000; // 3 minutos
  useEffect(() => {
    getSchedules();

    const intervalId = setInterval(getSchedules, refreshTime);
    return () => clearInterval(intervalId);
  }, [date, user?.timezone]);

  return (
    <>
      <div className="teacherSchedule-bg shadow-md">
        <div className="grid grid-cols-1 2xl:grid-cols-4 lg:grid-cols-5 px-4">
          <div className="font-dark-purple font-medium py-4">
            <p className="text-xs">Início / Minhas Aulas</p>
            <h1 className="text-3xl mt-5">{t("calendar")}</h1>
          </div>
        </div>
        <div className="grid grid-cols-1 2xl:grid-cols-3 px-4 mt-16">
          <div className="2xl:col-span-3 flex justify-between items-center py-3">
            <h1 className="text-2xl font-bold ml-1">{t("schedule")}</h1>
            <div className="flex gap-5">
              <div className="flex items-center gap-2">
                <div className="squared-filled-green" />
                <span>Available</span>
              </div>

              <div className="flex items-center gap-2">
                <div className="squared-filled-purple" />
                <span>Schedule</span>
              </div>

              <div className="flex items-center gap-2">
                <div className="squared" />
                <span>Not Available</span>
              </div>
            </div>
          </div>
        </div>
        <div className="grid grid-cols-1 2xl:grid-cols-3 px-4 py-2">
          <div className="bg-white border border-gray-100 2xl:col-span-3 shadow-md px-10 pb-5">
            <div className="flex py-2 justify-between items-center">
              <div className="flex items-center gap-10">
                <button disabled={isLoading} onClick={() => setDate((date) => subDays(date, 7))}>
                  <span className="text-sm">&lt;&lt;</span>
                </button>
                <h1 className="text-xl font-bold py-2">{getDayMonthNameYear(date)}</h1>
                <button disabled={isLoading} onClick={() => setDate((date) => addDays(date, 7))}>
                  <span className="text-sm">&gt;&gt;</span>
                </button>
              </div>
              <div className="flex">
                <BasicClock fill="#625E66" style={{ width: "20px" }} />
                <span className="ml-1" style={{ color: "#FF5C5C" }}>
                  {t("use_your_timezone")}
                  {"  "}({user?.timezone})
                </span>
              </div>
            </div>
            <div className="schedule-grid py-2 text-center font-gray text-sm">
              <span>{t("time")}</span>
              <span>
                {t("days.sunday")} {format(date, "dd/MM")}
              </span>
              <span>
                {t("days.monday")} {format(addDays(date, 1), "dd/MM")}
              </span>
              <span>
                {t("days.tuesday")} {format(addDays(date, 2), "dd/MM")}
              </span>
              <span>
                {t("days.wednesday")} {format(addDays(date, 3), "dd/MM")}
              </span>
              <span>
                {t("days.thursday")} {format(addDays(date, 4), "dd/MM")}
              </span>
              <span>
                {t("days.friday")} {format(addDays(date, 5), "dd/MM")}
              </span>
              <span>
                {t("days.saturday")} {format(addDays(date, 6), "dd/MM")}
              </span>
            </div>

            {isLoading ? (
              <SpinnerPurple className="w-10 m-auto mt-5 animate-spin" />
            ) : (
              schedulesArray?.map((obj, index) => {
                // Junta todos os dias da semana da row
                const flat = [...obj.dom, ...obj.seg, ...obj.ter, ...obj.qua, ...obj.qui, ...obj.sex, ...obj.sáb];

                return <Schedule key={index} time={obj.hour} schedules={flat} refresh={getSchedules} />;
              })
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default TeacherSchedule;
