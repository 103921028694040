import PropTypes from "prop-types";
import React, { useEffect, useRef } from "react";
import { AiOutlineClose as CloseIcon } from "react-icons/ai";
import { useHistory } from "react-router-dom";
import { logAnalyticsEvent } from "utils/firebase-analytics";

import BannerButton from "../../../../assets/imgs/banners/BannerPremiumButton.png";
import BannerImage from "../../../../assets/imgs/banners/BannerPremiumTexture.png";
import useOnClickOutside from "../../../../hooks/useOnClickOutside";
import * as S from "./styles.module.css";

const Premium = ({ handleHideBanner }) => {
  const history = useHistory();

  const ref = useRef();

  useOnClickOutside(ref, () => handleHideBanner());

  const image = BannerImage;

  const handleClick = () => {
    handleHideBanner();
    logAnalyticsEvent("CLICK_BANNER_PREMIUM");
    history.push("/planos");
  };

  useEffect(() => {
    logAnalyticsEvent("SHOW_BANNER_PREMIUM");
  }, []);

  return (
    <div className={S.Backdrop}>
      <div ref={ref} className={S.Container}>
        <div className={S.Wrapper}>
          <div className={S.WrapperContent}>
            <div style={{ position: "relative" }}>
              <>
                <p className={S.Title}>
                  Assine o Premium com
                  <span> 67% de desconto</span>
                </p>
              </>
            </div>

            <p className={S.Description}>
              Destrave todo potencial da plataforma com o plano premium, participe dos grupos e aulas de segunda a
              sexta!
            </p>

            <button onClick={handleClick} className={S.Button}>
              Quero ser Premium
            </button>
          </div>
        </div>

        <div style={{ position: "relative", width: "100%" }}>
          <img className={S.Image} src={image} alt="bannerImage" />

          <img
            style={{
              position: "absolute",
              top: 80,
              left: -60,
            }}
            src={BannerButton}
            alt="bannerButton"
          />

          <div className={S.WrapperButtonClose}>
            <button className={S.ButtonClose} onClick={handleHideBanner}>
              <CloseIcon size={20} color="#6A51B2" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

Premium.propTypes = {
  handleHideBanner: PropTypes.func.isRequired,
};

export default Premium;
