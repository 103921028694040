import PropTypes from "prop-types";
import React from "react";
import { useHistory } from "react-router-dom";

import { CloseX, PersonStudying } from "../../../../assets/svgs/index";

const DrawerDeleteSuccess = ({ handleClick }) => {
  const history = useHistory();
  const redirectTimeout = setTimeout(() => {
    history.push("/auth/login");
  }, 4000);

  return (
    <div className="flex h-full flex-col flex-1">
      <header className="flex justify-between items-center">
        <h2
          className="text-2xl 31.5rem w-96"
          style={{
            color: "#383743",
          }}>
          Esperamos que você volte em breve. Sua conta foi cancelada!
        </h2>
        <CloseX className="cursor-pointer" onClick={handleClick} />
      </header>

      <PersonStudying />

      <div className="grid grid-cols-3 gap-5 p-2 flex-1 h-1/2"></div>
      <footer className="col-span-2">
        <button
          className="ml-4 rounded-lg text-sm font-semibold tracking-wider pt-4 pb-4 pl-12 pr-12 text-white"
          style={{
            background: "#5807B8",
            width: "14.3125rem",
          }}
          onClick={() => {
            clearTimeout(redirectTimeout);
            history.push("/auth/login");
          }}>
          Sair
        </button>
      </footer>
    </div>
  );
};

DrawerDeleteSuccess.propTypes = {
  handleClick: PropTypes.func,
};

export default DrawerDeleteSuccess;
