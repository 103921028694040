import PropTypes from "prop-types";
import React, { useState } from "react";
import InputMask from "react-input-mask";

import { EyeClose, EyeOpen } from "../../../../assets/svgs/index";

import "./Input.css";

const Input = ({ className, label, name, type, error, value, onChange, onBlur, required = true, mask, ...props }) => {
  const cleanedInputProps = { ...props };
  delete cleanedInputProps.setValue;
  delete cleanedInputProps.validate;

  const [typ, setTyp] = useState(type);
  const [toggle, setToggle] = useState(false);

  const showHide = () => {
    setToggle(!toggle);

    if (toggle) {
      return setTyp("password");
    }

    setTyp("text");
  };
  const ButtonShowHide = () => {
    return (
      <div role="button" tabIndex={0} onClick={showHide} onKeyDown={showHide} className="showHide">
        <div>{toggle ? <EyeOpen /> : <EyeClose />}</div>
      </div>
    );
  };

  return (
    <div className={`${className ? className + " " : ""}input my-2`}>
      {!mask ? (
        <input
          className={`${error && "border-red-300 focus:border-red-300"} border rounded-lg border-gray-200 rounded-sm`}
          required={required}
          id={name}
          name={name}
          value={value}
          type={typ}
          onChange={({ target }) => onChange(target.value)}
          onBlur={onBlur}
          {...cleanedInputProps}
        />
      ) : (
        <InputMask
          mask={mask}
          value={value}
          onChange={({ target }) => onChange(target.value)}
          onBlur={onBlur}
          {...props}>
          {(inputProps) => (
            <input
              {...inputProps}
              className={`${
                error && "border-red-300 focus:border-red-300"
              } border rounded-lg border-gray-200 rounded-sm`}
              required={required}
              id={name}
              name={name}
              value={value}
              type={typ}
            />
          )}
        </InputMask>
      )}

      {type === "password" && <ButtonShowHide />}

      <label className="inputLabel">{label}</label>
      {error && <p className="text-red-600">{error}</p>}
    </div>
  );
};

Input.propTypes = {
  className: PropTypes.string,
  type: PropTypes.string,
  mask: PropTypes.string,
  value: PropTypes.string,
  error: PropTypes.string,
  onBlur: PropTypes.func,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  required: PropTypes.bool,
};

export default Input;
