import BannerExpired from "assets/imgs/modal-7-dias-expirado.png";
import ScribbleExpired from "assets/imgs/scribble-banner-expired.png";
import { UserContext } from "context/UserContext";
import { format } from "date-fns";
import PropTypes from "prop-types";
import React, { createContext, useContext, useState } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { logAnalyticsEvent } from "utils/firebase-analytics";

import * as S from "./styles.module.css";

const StateContext = createContext();

export const ExpiredProvider = ({ children }) => {
  const [show, setShow] = useState(false);
  const { user } = useContext(UserContext);
  const history = useHistory();

  const hideModal = () => {
    document.documentElement.style.overflow = "auto";
    setShow(false);
  };

  const showModal = () => {
    logAnalyticsEvent("show_modal_7days_expired");
    document.documentElement.style.overflow = "hidden";
    setShow(true);
  };

  const value = {
    hideModal,
    showModal,
  };

  return (
    <StateContext.Provider value={value}>
      {show && (
        <div className={S.Wrapper}>
          <div className={S.Bg}>
            <div className={S.Container}>
              <hr className={S.Separator} />
              <div
                className={S.BannerWrapper}
                style={{
                  background: `url(${BannerExpired})`,
                  backgroundRepeat: "round",
                  backgroundSize: "cover",
                }}>
                <div className={S.TextWrapper}>
                  <p className={S.BannerTitle}>Finaliza em</p>

                  <p className={S.SubTitle}>
                    0d <span>:</span> 00h <span>:</span> 00m
                  </p>

                  <img src={ScribbleExpired} alt="Scribble" />
                </div>
              </div>

              <p className={S.Title}>{"Seu teste grátis acabou :("}</p>
              <p className={S.Description}>
                O prazo de validade do seu teste{" "}
                <strong>finalizou em {format(new Date(user?.expiresAt), "dd/MM")}</strong>. Para continuar utilizando o
                app, escolha um dos nossos planos de adesão.
              </p>

              <button
                onClick={() => {
                  hideModal();
                  logAnalyticsEvent("modal_7days_expired_click");
                  history.push("/planos");
                }}
                className="px-5 mt-1 py-3 rounded-lg text-white font-bold w-full "
                style={{ background: "rgba(115, 62, 177, 1)" }}>
                Ver planos
              </button>

              <button
                className="px-5 mt-1 py-3 rounded-lg font-bold w-full"
                style={{ color: "#939393" }}
                onClick={hideModal}>
                Fechar
              </button>
            </div>
          </div>
        </div>
      )}
      {children}
    </StateContext.Provider>
  );
};

ExpiredProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const useExpired = () => useContext(StateContext);
