import React, { useState, useEffect, useContext } from "react";
import { FaCheck, FaFacebook, FaInstagram, FaLinkedin, FaWhatsapp, FaYoutube } from "react-icons/fa";
import { MdClose } from "react-icons/md";
import { useHistory } from "react-router-dom";
import Slider from "react-slick";

import { Accordion } from "../../../_Shared/Accordion/Accordion";
import SquareButton from "../../../_Shared/SquareButton/SquareButton";
import Switcher from "../../../_Shared/Switcher/Switcher";
import AppStoreImage from "../../../../assets/imgs/googleplay_review.png";
import PlayStoreImage from "../../../../assets/imgs/playstore_review.png";
import { NavbarContext } from "../../../../context/NavbarContext";
import useRequest from "../../../../hooks/useRequest";
import PlanCard from "../PlanCard/PlanCard";
import SmallPlanCard from "../SmallPlanCard/SmallPlanCard";

import "./Plans.css";

const PlansItem = [
  {
    name: "Pro",
    price: "R$ 89,99",
    typeOfCharge: "Grupos de conversação ilimitados",
    badge: "Recomendado",
  },

  {
    name: "Basic",
    price: "R$ 49,99",
    typeOfCharge: "4 grupos de conversação/mês",
  },
];

const SocialMedias = [
  {
    Icon: FaFacebook,
    link: "https://www.facebook.com/SchoolEnglishBay",
    name: "Facebook",
  },
  {
    Icon: FaWhatsapp,
    link: "https://api.whatsapp.com/send?phone=5511958565055",
    name: "WhatsApp",
  },
  {
    Icon: FaInstagram,
    link: "https://www.instagram.com/english.bay",
    name: "Instagram",
  },
  {
    Icon: FaLinkedin,
    link: "https://www.linkedin.com/company/englishbay",
    name: "LinkedIn",
  },
  {
    Icon: FaYoutube,
    link: "https://www.youtube.com/englishbay",
    name: "YouTube",
  },
];

const Feedbacks = [
  {
    text:
      "Como viajante frequente, ter habilidades em inglês é essencial. A EnglishBay me permitiu melhorar meu inglês de forma significativa, facilitando minhas viagens ao redor do mundo.",
    name: "Rafaela, 28 anos",
  },
  {
    text:
      "Eu tô me surpreendendo com a EnglishBay! Não só melhorou meu inglês, mas também achei os videos divertidos! Eu aprendi mais rápido do que pensei que conseguiria.",
    name: "Elias, 20 anos",
  },
  {
    text:
      "A EnglishBay faz a diferença, gente. Deu uma turbinada no meu inglês! Até piada em inglês eu tô pegando agora. Tô no ritmo e não quero parar de aprender.",
    name: "Guilherme, 26 anos",
  },
  {
    text:
      "Esse aplicativo é demais! Os exercícios são bem pensados e têm me ajudado um bocado a enriquecer e expandir meu vocabulário. Quem quer se jogar no inglês, eu sugiro sem pensar duas vezes!",
    name: "Enzo, 16 anos",
  },
  {
    text:
      "Eu gostei muito porque as lições são intuitivas e me permitem aprender ao meu próprio ritmo. Eu recomendaria para qualquer pessoa.",
    name: "Paulo, 34 anos",
  },
  {
    text:
      "Desde que comecei com o curso, não apenas meu inglês melhorou, mas também minha confiança ao falar inglês. Muito obrigado à equipe da EnglishBay",
    name: "Beatriz, 22 anos",
  },
  {
    text:
      "As vezes não acredito em quanto meu inglês melhorou desde que comecei a treinar com as lições do curso. Vale a pena tentar.",
    name: "Mici, 27 anos",
  },
];

const Accordions = [
  {
    title: "O curso tem certificado?",
    text:
      "Sim, ao concluir todas as lições de cada nível do curso, você receberá um certificado de conclusão, reconhecido a nível nacional, para destacar sua conquista.",
  },
  {
    title: "Vocês tem materiais preparatórios para exames?",
    text:
      "Sim, oferecemos uma série de materiais preparatórios específicos para uma variedade de exames de proficiência em inglês, como TOEFL. Estes podem ser encontrados na seção de materiais do curso.",
  },
  {
    title: "Qual nível de inglês eu preciso ter para começar o curso?",
    text:
      "Não é preciso ter nenhum conhecimento prévio. Nosso curso é projetado para acomodar todos os níveis de habilidade, desde iniciantes completos à alunos avançados. Quando você se inscreve, realizamos um teste de nivelamento para garantir que você comece no nível correto para seu conhecimento atual da língua.",
  },
  {
    title: "Posso cancelar a qualquer momento?",
    text:
      "Sim, você pode cancelar seu plano a qualquer momento direto pela plataforma. \nEm caso de estorno, os descontos que oferecemos para assinaturas de longo prazo, como o anual, serão desconsiderados e será cobrado o valor original, proporcional ao período em que plano esteve ativo. Calcularemos o valor do estorno com base no preço mensal do plano, e o aluno recebe de volta o valor proporcional ao número de dias restantes do plano.",
  },
  {
    title: "Quanto tempo eu vou levar para falar inglês?",
    text:
      "O tempo que leva para se tornar fluente em inglês varia muito de pessoa para pessoa e depende de vários fatores, como sua dedicação, frequência de estudo e exposição ao idioma. No entanto, muitos de nossos alunos relatam um progresso notável após 3-6 meses de estudo consistente.",
  },
];

const settings = {
  dots: false,
  speed: 500,
  slidesToShow: 4,
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 760,
      settings: {
        slidesToShow: 1.5,
        slidesToScroll: 1.5,
      },
    },
  ],
};

const conversationPlanBenefits = [
  {
    benefit: "Cursos de inglês",
    isIncluded: true,
  },
  {
    benefit: "Aulas ao vivo de segunda à sexta",
    isIncluded: true,
  },
  {
    benefit: "Grupos de conversação diários",
    isIncluded: true,
  },
  {
    benefit: "Suporte com professores",
    isIncluded: true,
  },
  {
    benefit: "Certificados",
    isIncluded: true,
  },
  {
    benefit: "Sem anúncios",
    isIncluded: true,
  },
  {
    benefit: "Aulas particulares",
    isIncluded: false,
  },
];

const lessonsPlanBenefits = [
  {
    benefit: "Cursos de inglês",
    isIncluded: true,
  },
  {
    benefit: "Aulas ao vivo de segunda à sexta",
    isIncluded: true,
  },
  {
    benefit: "Grupos de conversação diários",
    isIncluded: true,
  },
  {
    benefit: "Suporte com professores",
    isIncluded: true,
  },
  {
    benefit: "Certificados",
    isIncluded: true,
  },
  {
    benefit: "Sem anúncios",
    isIncluded: true,
  },
  {
    benefit: "Aulas particulares",
    isIncluded: true,
  },
];

const Plans = () => {
  const { request } = useRequest();
  const [plans, setPlans] = useState([]);

  const [isCustomizingPlan, setCustomizingPlan] = useState(false);
  const [isSelectingPlan, setSelectingPlan] = useState(false);

  const [lessonDuration, setLessonDuration] = useState("25");
  const [selectedPlan, setSelectedPlan] = useState(null);
  const { setNavigationLinks } = useContext(NavbarContext);

  const [planIndex, setPlanIndex] = useState(0);

  const history = useHistory();

  useEffect(() => {
    const arrLinks = [
      { title: "Início", path: "/" },
      { title: "Planos", path: "/planos" },
    ];

    setNavigationLinks(arrLinks);
    fetchPlans().then(setPlans);
  }, []);

  useEffect(() => {
    if (isCustomizingPlan) {
      document.documentElement.style.overflow = "hidden";
      return;
    }

    document.documentElement.style.overflow = "auto";
    setSelectedPlan(null);
  }, [isCustomizingPlan]);

  useEffect(() => {
    setSelectedPlan(null);
  }, [lessonDuration]);

  const fetchPlans = () => request("GET", "/plan/list");

  const handleChoosePlan = (plan) => {
    localStorage.setItem("plan", JSON.stringify(plan));
    document.documentElement.style.overflow = "auto";
    history.push("/planos/checkout");
  };

  const handlePlan = (planIndex) => {
    if (planIndex === 0) {
      handleChoosePlan(
        plans.find(
          (p) => p.intervalCount === 1 && p.lessonType === "CONVERSATION" && p.name === "Pro" && p.platform === "WEB",
        ),
      );
      return;
    }

    handleChoosePlan(
      plans.find(
        (p) => p.intervalCount === 1 && p.lessonType == "CONVERSATION" && p.name === "Basic" && p.platform === "WEB",
      ),
    );
  };

  const handleOpenWhats = () => {
    window.open(
      "https://api.whatsapp.com/send?phone=5511958565055&text=Gostaria%20de%20tirar%20uma%20d%C3%BAvida%20sobre%20os%20planos",
    );
  };

  return (
    <main className="plans__section p-4 pb-20">
      <section className="plans">
        <div className="md:flex justify-between">
          <div>
            <p
              className="section__title"
              style={{
                fontSize: 24,
              }}>
              Todos os planos
            </p>

            <p
              className="section__subtitle"
              style={{
                fontSize: 16,
              }}>
              Sua jornada de aprendizado começa aqui, escolha o plano que melhor se encaixa com você.
            </p>
          </div>
        </div>

        <div className="plans__container">
          <PlanCard
            data={{
              name: "Premium",
              price: "49.99",
              price2: "189.99",
              iconUrl: "https://media.englishbay.com.br/3eec2c7c-b7b8-45b7-92d5-267d4e186862.jpeg",
              benefits: conversationPlanBenefits,
            }}
            plans={plans}
            onClick={() => setSelectingPlan(true)}
          />

          <PlanCard
            data={{
              name: "Aulas Particulares",
              price: "159.99",
              fromPrice: "A partir de",
              iconUrl: "https://media.englishbay.com.br/3eec2c7c-b7b8-45b7-92d5-267d4e186862.jpeg",
              benefits: lessonsPlanBenefits,
            }}
            plans={plans}
            onClick={() => setCustomizingPlan(true)}
          />
        </div>

        <div className="mt-5">
          <div className="flex flex-col justify-center items-center gap-4">
            <p
              className="section__title"
              style={{
                fontSize: 24,
                textAlign: "center",
              }}>
              Junte-se a mais de 100 mil alunos
            </p>

            <div className="flex justify-center ">
              <img src={AppStoreImage} alt="students" />
              <img src={PlayStoreImage} alt="students" />
            </div>
          </div>

          <p
            className="section__title hidden md:block mt-5"
            style={{
              fontSize: 16,
            }}>
            O que alguns dos nossos alunos estão falando...
          </p>
        </div>

        <div className="flex gap-10">
          <Slider {...settings}>
            {Feedbacks.map(({ text, name }) => (
              <div key={name} className="feedback-item">
                <p>{text}</p>

                <p>— {name}</p>
              </div>
            ))}
          </Slider>
        </div>
      </section>

      <section className="grid md:grid-cols-2 gap-20 mt-8">
        <div className="col-span-1">
          <div>
            <p className="uppercase text-pink-500 font-bold">Perguntas frequentes</p>
            <p
              className="section__title"
              style={{
                fontSize: 24,
              }}>
              Ficou com dúvida?
            </p>
            <p
              className="section__subtitle mb-4"
              style={{
                color: "#8C8A97",
              }}>
              Se tiver qualquer outra pergunta, por favor. Entre em contato atráves de nossos canais.
            </p>

            <button
              style={{
                maxWidth: 200,
              }}
              onClick={handleOpenWhats}
              className="btn-choose-plan justify-center">
              <div className="flex justify-center items-center gap-2">
                <FaWhatsapp size={24} color="white" />
                Fale conosco
              </div>
            </button>
          </div>

          <div className="mt-8">
            <p className="section__subtitle">Acompanha nas redes sociais</p>

            <div className="flex md:block flex-wrap gap-2 max-w-xs">
              {SocialMedias.map(({ Icon, link, name }) => (
                <a key={name} className="section__title flex gap-4 items-center mt-2" href={link}>
                  <Icon size={24} color="#8C8A97" />
                  {name}
                </a>
              ))}
            </div>
          </div>
        </div>

        <div>
          {Accordions.map((accordion, index) => (
            <Accordion key={index} {...accordion} />
          ))}
        </div>
      </section>

      {isCustomizingPlan && (
        <div className="plans__bg">
          <div className="plans__options">
            <div className="left-column">
              <SquareButton className="mb-5 mt-2 btn-close--mobile" onClick={() => setCustomizingPlan(false)}>
                <MdClose size={22} />
              </SquareButton>

              <span className="options__title">Escolha seu plano de aulas particulares</span>
              <span className="options__legend">Qual a duração das aulas?</span>

              <Switcher
                option1="25 minutos"
                option2="50 minutos"
                onClick1={() => setLessonDuration("25")}
                onClick2={() => setLessonDuration("50")}
              />

              <ul>
                {lessonsPlanBenefits.map(({ benefit, isIncluded }) => (
                  <li className="flex items-center gap-2 mt-2" key={benefit}>
                    {isIncluded ? <FaCheck color="#623cea" /> : <MdClose color="#33303e" size={24} />}
                    <p>{benefit}</p>
                  </li>
                ))}
              </ul>
            </div>

            <div className="right-column">
              <div className="flex justify-end">
                <SquareButton
                  className="btn-close--desktop"
                  onClick={() => {
                    setCustomizingPlan(false);
                  }}>
                  <MdClose size={22} />
                </SquareButton>
              </div>

              <div className="overflow-auto">
                {plans.map((plan) => {
                  const selectedPlanDuration = lessonDuration === "25" ? "TWENTY_FIVE" : "FIFTY";

                  if (plan.lessonType === selectedPlanDuration) {
                    return (
                      <label className="plan__label" key={plan.uuid}>
                        <input type="radio" name="selected-plan" onChange={() => setSelectedPlan(plan)} />
                        <SmallPlanCard data={plan} />
                      </label>
                    );
                  }
                })}
              </div>

              <button
                className="btn-choose-plan"
                disabled={!selectedPlan}
                onClick={() => handleChoosePlan(selectedPlan)}>
                Escolher plano
              </button>
            </div>
          </div>
        </div>
      )}

      {isSelectingPlan && (
        <div className="plans__bg">
          <div className="plans__options">
            <div className="left-column mb-6">
              <SquareButton
                style={{ fontSize: "2em" }}
                className="mb-5 mt-2 btn-close--mobile"
                onClick={() => setSelectingPlan(false)}>
                <MdClose />
              </SquareButton>

              <div className="flex gap-2 items-center">
                <p className="options__title">Premium</p>
                <div
                  style={{
                    backgroundColor: "rgba(255, 155, 227, 0.2)",
                  }}
                  className="p-1 px-3 rounded-lg">
                  <p
                    style={{
                      color: "#E564BC",
                    }}>
                    Mais popular
                  </p>
                </div>
              </div>
              <span className="options__legend">Eleve sua experiencia de aprendizado</span>

              <ul>
                {conversationPlanBenefits
                  .filter((benefit) => benefit.isIncluded)
                  .map(({ benefit, isIncluded }) => (
                    <li className="flex items-center gap-2 mt-2" key={benefit}>
                      {isIncluded ? <FaCheck color="#623cea" /> : <MdClose color="#33303e" size={24} />}
                      <p>{benefit}</p>
                    </li>
                  ))}
              </ul>
            </div>

            <div className="right-column sm:mt-4">
              <div>
                <div className="flex justify-end">
                  <SquareButton
                    className="mt-6 mb-6 btn-close--desktop"
                    onClick={() => {
                      setSelectingPlan(false);
                    }}>
                    <MdClose size={22} />
                  </SquareButton>
                </div>

                <div className="flex flex-col gap-3">
                  {PlansItem.map((plan, index) => (
                    <div
                      onClick={() => setPlanIndex(index)}
                      key={index}
                      className={`plan-item ${index === planIndex && "plan-item-active"} rounded-lg cursor-pointer`}>
                      {plan.badge && (
                        <div
                          style={{
                            borderTopRightRadius: 7,
                            borderTopLeftRadius: 7,
                          }}
                          className="w-full bg-yellow-400 text-center rounded-t-md p-1 font-bold">
                          <p
                            style={{
                              fontSize: 14,
                            }}>
                            {plan.badge}
                          </p>
                        </div>
                      )}

                      <div className="flex justify-between p-4">
                        <p className="plan__title--small">{plan.name}</p>
                        <div className="flex flex-col">
                          <p
                            className="plan__title--small"
                            style={{
                              alignSelf: "flex-end",
                            }}>
                            {plan.price}/mês
                          </p>

                          <p className="plan__description--small mt-2">
                            <span>{plan.typeOfCharge}</span>
                          </p>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>

                <p
                  className="section__subtitle text-center px-4 mt-6"
                  style={{
                    fontSize: 14,
                  }}>
                  Sem multas. Experimente por 7 dias. Se não gostar, devolvemos seu dinheiro.
                </p>
              </div>

              <div>
                <div className="flex justify-around mb-8 mt-6">
                  <a
                    className="section__subtitle"
                    style={{
                      color: "#8C8A97",
                    }}
                    href="https://englishbay.com.br/termos-de-uso/"
                    target="_blank"
                    rel="noreferrer">
                    Termos de uso
                  </a>
                  <a
                    className="section__subtitle"
                    style={{
                      color: "#8C8A97",
                    }}
                    href="https://englishbay.com.br/politica-de-privacidade/"
                    target="_blank"
                    rel="noreferrer">
                    Política de privacidade
                  </a>
                </div>

                <button className="btn-choose-plan" onClick={() => handlePlan(planIndex)}>
                  Continuar
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </main>
  );
};

export default Plans;
