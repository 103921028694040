import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";

import Pagination from "../../../_Shared/Pagination/Pagination";
import Skype from "../../../../assets/imgs/Skype.png";
import { WhatsAppNew, CloseX, SpinnerPurple } from "../../../../assets/svgs/index";
import useRequest from "../../../../hooks/useRequest";
import { getDayMonthNameYear } from "../../../../utils/dates";
import FeedbackRow from "./FeedbackRow";

const DrawerProfile = ({ setShowSlide, uuid }) => {
  const { request } = useRequest();

  const [history, setHistory] = useState({});
  const [isLoadingHistory, setIsLoadingHistory] = useState(true);
  const [paginate, setPaginate] = useState([]);
  const [pageNumber, setPageNumber] = useState(0);
  const [user, setUser] = useState(null);

  const fetchProfile = async () => {
    const response = await request("GET", `/teacher/readUser/${uuid}`);
    setUser(response);
  };

  const fetchHistory = async (page = 0) => {
    setIsLoadingHistory(true);
    const response = await request("GET", `/teacher/getStudentHistory/${uuid}?page=${page + 1}&perPage=3`);

    setHistory(response);
    setPaginate({ totalPages: response.totalPages, totalResults: response.totalResults });
    setIsLoadingHistory(false);
  };

  const handlePageChange = ({ selected }) => {
    fetchHistory(selected);
    setPageNumber(selected);
  };

  useEffect(() => {
    fetchHistory();
    fetchProfile();
  }, []);

  return (
    <div className="md:w-[700px]">
      <header className="flex items-center justify-between ">
        <div className="flex items-center">
          <img
            src={user?.profilePictureUrl ?? process.env.REACT_APP_PROFILE_AVATAR_URL}
            alt="foto do aluno(a)"
            className="rounded-full self-center"
            style={{
              width: "4.4375rem",
              height: "4.4375rem",
            }}
          />
          <div className="ml-8">
            <h1
              className="text-xl"
              style={{
                color: "#383743",
              }}>
              {user?.name}
            </h1>
          </div>
        </div>

        <CloseX className="cursor-pointer" onClick={() => setShowSlide(false)} />
      </header>
      <div className="mt-8">
        <p
          style={{
            color: "#383743",
          }}>
          Perfil
        </p>
      </div>
      <div
        className="rounded-2xl pt-4 pb-4 pl-6 mt-4"
        style={{
          border: "1px solid #F3F4F6",
        }}>
        <div className="flex flex-col sm:flex-row">
          <div>
            <h1
              className="text-xs tracking-wider uppercase"
              style={{
                color: "#676482",
              }}>
              Data de nascimento
            </h1>
            <h2
              style={{
                color: "#36344B",
              }}>
              {user?.birthDate ? getDayMonthNameYear(user?.birthDate) : ""}
            </h2>
          </div>

          <div className="sm:ml-14 mt-4">
            <h1
              className="text-xs tracking-wider uppercase"
              style={{
                color: "#676482",
              }}>
              Telefone
            </h1>
            <h2
              style={{
                color: "#36344B",
              }}>
              {user?.phoneNumber}
            </h2>
          </div>

          <div className="sm:ml-14 mt-4">
            <h1
              className="text-xs tracking-wider uppercase"
              style={{
                color: "#676482",
              }}>
              Email
            </h1>
            <h2
              style={{
                color: "#36344B",
              }}>
              {user?.email}
            </h2>
          </div>
        </div>

        <div className="flex sm:items-center mt-8 flex flex-col sm:flex-row">
          <img src={Skype} alt="skype" className="w-7 h-7" />
          <div className="ml-4">
            <h1
              className="text-xs tracking-wider uppercase"
              style={{
                color: "#676482",
              }}>
              Skype
            </h1>
            <h2
              style={{
                color: "#36344B",
              }}>
              {user?.skypeId}
            </h2>
          </div>

          <button className="flex items-center" onClick={() => window.open(`https://wa.me/${user?.phoneNumber}`)}>
            <WhatsAppNew />
            <div className="ml-4">
              <h1
                className="text-xs tracking-wider uppercase"
                style={{
                  color: "#676482",
                  float: "left",
                }}>
                whatsapp
              </h1>
              <h2
                style={{
                  color: "#36344B",
                }}>
                {user?.phoneNumber}
              </h2>
            </div>
          </button>
        </div>
      </div>
      <h1
        className="mt-11 text-2xl"
        style={{
          color: "#252426",
        }}>
        Histórico de aulas
      </h1>
      <h2 className="text-sm">
        {" "}
        <span
          className="font-bold"
          style={{
            color: "#14C4BE",
          }}>
          {history?.totalResults}
        </span>{" "}
        aulas concluídas
      </h2>
      <div className="mt-4">
        {isLoadingHistory ? (
          <SpinnerPurple className="w-10 m-auto mt-5 animate-spin" />
        ) : (
          history?.results?.map((hist) => {
            return <FeedbackRow key={hist.uuid} className="mt-2" history={hist} />;
          })
        )}

        {!isLoadingHistory && paginate.totalPages > 1 && (
          <div className="mt-4 mb-4">
            <Pagination forcePage={pageNumber} totalPages={paginate.totalPages} handlePageChange={handlePageChange} />
          </div>
        )}
      </div>
    </div>
  );
};

DrawerProfile.propTypes = {
  setShowSlide: PropTypes.func,
  uuid: PropTypes.string,
};

export default DrawerProfile;
