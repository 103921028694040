import PlanCard from "components/Pages/Lead/PlanCard/PlanCard";
import useRequest from "hooks/useRequest";
import PropTypes from "prop-types";
import React, { useEffect, useRef } from "react";
import { useState } from "react";
import { AiOutlineClose as CloseIcon } from "react-icons/ai";
import { useHistory } from "react-router-dom";
import { logAnalyticsEvent } from "utils/firebase-analytics";

import BannerImage from "../../../../assets/imgs/banners/VendaMobile.png";
import useOnClickOutside from "../../../../hooks/useOnClickOutside";
import * as S from "./styles.module.css";

const conversationPlanBenefits = [
  {
    benefit: "Aulas ao vivo de segunda a sexta",
    isIncluded: true,
  },
  {
    benefit: "Grupo de conversação diário",
    isIncluded: true,
  },
  {
    benefit: "Suporte com Professores",
    isIncluded: true,
  },
];

const VendasMobile = ({ handleHideBanner }) => {
  const { request } = useRequest();
  const history = useHistory();
  const [plans, setPlans] = useState([]);

  const ref = useRef();

  useOnClickOutside(ref, () => handleHideBanner());

  const image = BannerImage;

  const handleChoosePlan = (plan) => {
    logAnalyticsEvent("CLICK_BANNER_SALES_MOBILE");
    handleHideBanner();

    localStorage.setItem("plan", JSON.stringify(plan));
    document.documentElement.style.overflow = "auto";
    history.push("/planos/checkout");
  };

  const fetchPlans = () => request("GET", "/plan/list");

  useEffect(() => {
    logAnalyticsEvent("SHOW_BANNER_SALES_MOBILE");
    fetchPlans().then(setPlans);
  }, []);

  return (
    <div className={S.Backdrop}>
      <div className={S.Container}>
        <div ref={ref}>
          <div style={{ position: "relative", width: "100%" }}>
            <img className={S.Image} src={image} alt="bannerImage" />

            <div className={S.WrapperButtonClose}>
              <button className={S.ButtonClose} onClick={handleHideBanner}>
                <CloseIcon size={20} color="#6A51B2" />
              </button>
            </div>
          </div>

          <div className={S.Wrapper}>
            <PlanCard
              data={{
                name: "Premium",
                price: "49.99",
                price2: "189.99",
                iconUrl: "https://media.englishbay.com.br/3eec2c7c-b7b8-45b7-92d5-267d4e186862.jpeg",
                benefits: conversationPlanBenefits,
              }}
              onClick={(plan) => handleChoosePlan(plan)}
              plans={plans}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

VendasMobile.propTypes = {
  handleHideBanner: PropTypes.func.isRequired,
  variant: PropTypes.number.isRequired,
};

export default VendasMobile;
