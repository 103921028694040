import React, { useContext } from "react";

import { UserContext } from "../../../context/UserContext";

export const ProfileImage = () => {
  const { user } = useContext(UserContext);

  return (
    <>
      <div className="profile__hexagono">
        <div className="hexagono__border" />
        <img
          className="hexagono__image"
          src={user?.profilePictureUrl ?? process.env.REACT_APP_PROFILE_AVATAR_URL}
          alt="profile"
        />
      </div>
    </>
  );
};

export default ProfileImage;
