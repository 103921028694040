import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import { AiOutlineClose as CloseIcon } from "react-icons/ai";
import { HiShare } from "react-icons/hi";
import { IoLogoWhatsapp } from "react-icons/io";
import { logAnalyticsEvent } from "utils/firebase-analytics";

import BannerImage from "../../../../assets/imgs/banners/BannerRecommendation.png";
import useOnClickOutside from "../../../../hooks/useOnClickOutside";
import * as S from "./styles.module.css";

const Recommendation = ({ handleHideBanner }) => {
  const ref = useRef();
  const [copyUrlText, setCopyUrlText] = useState("Copiar Link");

  useOnClickOutside(ref, () => handleHideBanner());

  const image = BannerImage;

  const handleClick = () => {
    setCopyUrlText("Link copiado com sucesso!");
    logAnalyticsEvent("CLICK_BANNER_RECOMMENDATION_URL");

    navigator.clipboard.writeText("https://api.englishbay.com.br/redirect/open-platform");
  };

  const handleWhatsappClick = () => {
    handleHideBanner();
    logAnalyticsEvent("CLICK_BANNER_RECOMMENDATION_WPP");

    window.open(
      "https://api.whatsapp.com/send/?text=Conhe%C3%A7a%20o%20aplicativo%20GRATUITO%20da%20EnglishBay%20e%20tenha%20acesso%20a%20centenas%20de%20materiais%20e%20exercicios%20para%20praticar%20seu%20ingl%C3%AAs!%20https://englishbay.com.br",
    );
  };

  useEffect(() => {
    logAnalyticsEvent("SHOW_BANNER_RECOMMENDATION");
  }, []);

  return (
    <div className={S.Backdrop}>
      <div ref={ref} className={S.Container}>
        <div className={S.Wrapper}>
          <div className={S.WrapperContent}>
            <div style={{ position: "relative" }}>
              <>
                <p className={S.Title}>
                  Indique a EnglishBay
                  <span> para um amigo!</span>
                </p>
              </>
            </div>

            <p className={S.Description}>
              Se você gosta da nossa plataforma ajude a EnglishBay indicando para os amigos, vamos crescer juntos!
            </p>

            <button onClick={handleClick} className={S.Button}>
              {copyUrlText === "Copiar Link" && <HiShare size={24} />}
              {copyUrlText}
            </button>

            <div onClick={handleWhatsappClick} className={S.LinkedinButton}>
              <IoLogoWhatsapp size={24} color="white" />
              <button className={S.shareText}>Compartilhe no whatsapp</button>
            </div>
          </div>
        </div>

        <div style={{ position: "relative", width: "100%" }}>
          <img className={S.Image} src={image} alt="bannerImage" />

          <div className={S.WrapperButtonClose}>
            <button className={S.ButtonClose} onClick={handleHideBanner}>
              <CloseIcon size={20} color="#6A51B2" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

Recommendation.propTypes = {
  handleHideBanner: PropTypes.func.isRequired,
};

export default Recommendation;
