import PropTypes from "prop-types";
import React from "react";

import * as S from "./styles.module.css";

const ErrorFeedback = ({ isCorrect }) => {
  const errorFeedbackColor = isCorrect ? "green" : "red";
  const errorFeedbackText = isCorrect ? "Certa resposta! :D" : "Ops... Você errou :(";

  return (
    <p className={S.errorFeedback} style={{ color: errorFeedbackColor }}>
      {errorFeedbackText}
    </p>
  );
};

ErrorFeedback.propTypes = {
  isCorrect: PropTypes.bool.isRequired,
};

export default ErrorFeedback;
