import React, { useState, useEffect, useContext } from "react";
import { useLocation } from "react-router-dom";

import ButtonSvg from "../../../_Shared/ButtonSvg/ButtonSvg";
import Pagination from "../../../_Shared/Pagination/Pagination";
import SearchButton from "../../../_Shared/SearchButton/SearchButton";
import OurTeachers from "../../../_Shared/Teacher/OurTeachers";
import { Configuration, CalendarClock } from "../../../../assets/svgs/";
import { NavbarContext } from "../../../../context/NavbarContext";
import { SlideContext } from "../../../../context/SlideContext";
import useWindowSize from "../../../../hooks/useWindowSize";
import DrawerAvailability from "./DrawerAvailability";
import DrawerFilters from "./DrawerFilters";

import "./MyTeachers.css";

const MyTeachers = () => {
  const { search } = useLocation();
  const { width } = useWindowSize();
  const { setShowSlide, setSlideComponent } = useContext(SlideContext);
  const { setNavigationLinks } = useContext(NavbarContext);

  const [key, setKey] = useState(null);
  const [query, setQuery] = useState("");
  const [totalTeacher, setTotalTeacher] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const [isTestClass, setIsTestClass] = useState(false);
  const [paginate, setPaginate] = useState({});
  const [pageNumber, setPageNumber] = useState(0);
  const [currentFilters, setCurrentFilters] = useState(JSON.parse(sessionStorage.getItem("teacherFilters")) ?? []);

  const availabityFilters = [
    "EARLY_MORNING",
    "MORNING",
    "EVENING",
    "NIGHT",
    "SUNDAY",
    "MONDAY",
    "TUESDAY",
    "WEDNESDAY",
    "THURSDAY",
    "FRIDAY",
    "SATURDAY",
  ];

  const preferencesFilters = [
    "CHILDREN",
    "TEENAGERS",
    "ADULTS",
    "Básico",
    "Intermediário",
    "Avançado",
    "Viagem",
    "Business",
    "Certificações",
    "Português",
    "Inglês",
    "Espanhol",
  ];

  useEffect(() => {
    sessionStorage.setItem("teacherFilters", JSON.stringify(currentFilters));
  }, [currentFilters]);

  useEffect(() => {
    setPageNumber(0);
  }, [key, query]);

  useEffect(() => {
    // Se abriu passando ?aulaTeste=true
    if (new URLSearchParams(search).get("aulaTeste")) {
      setIsTestClass(true);
      setKey(2);
    } else {
      setKey(1);
    }
  }, []);

  useEffect(() => {
    const arrLinks = [
      { title: "Início", path: "/" },
      { title: "Professores", path: "/professores" },
    ];

    setNavigationLinks(arrLinks);
  }, []);

  const handlePageChange = ({ selected }) => setPageNumber(selected);

  const handleClick = (name) => {
    setShowSlide(true);

    if (name === "filter") {
      setSlideComponent(
        <DrawerFilters
          setShowSlide={setShowSlide}
          setCurrentFilters={setCurrentFilters}
          currentFilters={currentFilters}
        />,
      );
    } else {
      setSlideComponent(
        <DrawerAvailability
          setShowSlide={setShowSlide}
          setCurrentFilters={setCurrentFilters}
          currentFilters={currentFilters}
        />,
      );
    }
  };

  return (
    <>
      <div className="text-white">
        <div className="grid grid-cols-1 2xl:grid-cols-4 sm:px-8" style={{ marginBottom: "100px" }}>
          <div className="2xl:col-span-3">
            <div className="p-4 mt-3 bg-white sm:items-center rounded-lg shadow-md text-gray-600 flex flex-col-reverse sm:flex-row sm:h-auto">
              <div className={"flex items-start w-full col-span-3 text-center font-gray text-sm"}>
                {!isTestClass && (
                  <button onClick={() => setKey(1)} className="relative w-52">
                    <p className={`${key === 1 && "font-aqua-green font-semibold"} "p-4"`}>Favoritos</p>
                    {key === 1 && (
                      <div className="inset-x top-9 sm:inset-x sm:top-12 absolute border-t-2 border-aqua-green w-full" />
                    )}
                  </button>
                )}
                <button onClick={() => setKey(2)} className="relative w-52">
                  <p className={`${key === 2 && "font-aqua-green font-semibold"} "p-4"`}>Todos</p>
                  {key === 2 && (
                    <div className="inset-x top-9 sm:inset-x sm:top-12 absolute border-t-2 border-aqua-green w-full" />
                  )}
                </button>
              </div>
              <div className="flex mb-8 sm:mb-0 justify-between items-center">
                {!isOpen && (
                  <button
                    onClick={() => {
                      setQuery("");
                      setCurrentFilters([]);
                    }}>
                    <p className="text-red-700 font-bold cursor-pointer sm:hidden">Limpar Filtros</p>
                  </button>
                )}
                <div className="flex items-center">
                  <SearchButton query={query} setQuery={setQuery} isOpen={isOpen} setIsOpen={setIsOpen} />
                  <ButtonSvg
                    clickHandler={() => handleClick("availabity")}
                    className={`p-3 bg-white border border-gray-300 ${
                      currentFilters.some((item) => availabityFilters.includes(item)) && "filter-active"
                    }`}>
                    <CalendarClock className="w-5" />
                  </ButtonSvg>
                  <ButtonSvg
                    clickHandler={() => handleClick("filter")}
                    className={`p-3 bg-white border border-gray-300 ${
                      currentFilters.some((item) => preferencesFilters.includes(item)) && "filter-active"
                    }`}>
                    <Configuration className="w-5" />
                  </ButtonSvg>
                </div>
              </div>
            </div>
            <div className="mt-2 text-black">
              <div className="flex justify-between items-center sm:py-5 sm:px-0 py-5 px-5">
                <div className="sm:w-full">
                  <h2 className="text-xl lg:text-2xl sm:w-full">Professores de Inglês</h2>
                  <p className="text-gray-500">{totalTeacher} professores encontrados</p>
                </div>

                {width > 468 && (
                  <button
                    className="text-red-700 font-bold cursor-pointer"
                    style={{ width: "114px" }}
                    onClick={() => {
                      setQuery("");
                      setCurrentFilters([]);
                    }}>
                    Limpar Filtros
                  </button>
                )}
              </div>
              <div className="grid grid-cols-1 md:grid-cols-3 gap-5 relative sm:px-0 px-4">
                {key && (
                  <OurTeachers
                    limit={9}
                    isSchedule={true}
                    setTotalTeacher={setTotalTeacher}
                    query={query}
                    favorite={key === 1}
                    pageNumber={pageNumber}
                    setPaginate={setPaginate}
                    setPageNumber={setPageNumber}
                    currentFilters={currentFilters}
                  />
                )}
              </div>

              {paginate.totalPages > 1 && (
                <div className="mt-5" style={{ marginBottom: "100px" }}>
                  <Pagination
                    forcePage={pageNumber}
                    totalPages={paginate.totalPages}
                    handlePageChange={handlePageChange}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MyTeachers;
