import PropTypes from "prop-types";
import React from "react";

const Teach = ({ teach }) => {
  return (
    <div className="mt-10">
      <div className="text-sm text-gray-600">Ensina</div>
      <div className="mt-5 text-gray-600">
        <div className="flex flex-col">
          {teach?.map((item, index) => {
            return <p key={index}>{item}</p>;
          })}
        </div>
      </div>
    </div>
  );
};

Teach.propTypes = {
  teach: PropTypes.array,
};

export default Teach;
