import React from "react";
import { Link } from "react-router-dom";

import PersonCalendar from "../../../../assets/imgs/PersonCalendar.png";
import { PurpleButton } from "../../Form/index";

const CardSchedule = () => {
  return (
    <>
      <div className="flex justify-around">
        <div className="flex flex-1 bg-white rounded-xl shadow-md">
          <div>
            <img src={PersonCalendar} alt="person" className="m-5" />
          </div>
          <div className="flex flex-col m-10 ">
            <h2 className="text-sm font-sans text-gray-600 p-2 mr-2">Have you set your teaching schedule yet? :)</h2>
            <p className="text-sm font-sans	text-gray-600 p-2 mr-2">
              Click on &quot;Manage your schedule&quot; to open and manage your work time slots, so that new students
              are able to book lessons with you.
            </p>
            <div className="grid grid-cols-2">
              <Link to="/cronograma">
                <PurpleButton className="mt-5 my-auto btnEvent">Manage your schedule</PurpleButton>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CardSchedule;
