export function pushGTMEvent(payload) {
  if (!window.isProduction) {
    return;
  }

  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push(function () {
    this.reset();
  });
  window.dataLayer.push(payload);
}
