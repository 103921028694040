import Texture2 from "assets/imgs/banner-texture-2.png";
import GirlBanner7 from "assets/imgs/girl-banner-7.png";
import { useIsStudent } from "hooks/useIsStudent";
import React from "react";
import "./BannerPremiumSquare.css";
import { logAnalyticsEvent } from "utils/firebase-analytics";

const BannerPremiumSquare = () => {
  const { handleRedirect } = useIsStudent();

  return (
    <div
      className="banner_premium_square"
      style={{ backgroundImage: `url(${Texture2})` }}
      onClick={() => {
        logAnalyticsEvent("banner_click_premium");
        handleRedirect("premium");
      }}>
      <div>
        <p>Plano</p>
        <p>Premium</p>
        <p style={{ fontSize: "14px", marginTop: "4px" }}>Aulas ao vivo toda semana</p>
      </div>

      <img src={GirlBanner7} alt="girl-banner" />

      <div className="div_premium">
        <p>Seja premium</p>
      </div>
    </div>
  );
};

export default BannerPremiumSquare;
