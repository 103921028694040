/* eslint-disable jsx-a11y/no-onchange */
import * as iso3166 from "iso-3166-2";
import PropTypes from "prop-types";
import React, { useContext, useState, useEffect } from "react";

import { Warning } from "../../../../../assets/svgs/index";
import {
  FormCouponButton,
  InputTwo,
  PurpleButton,
  Button,
  RadioButton,
} from "../../../../../components/_Shared/Form/index";
import { SlideContext } from "../../../../../context/SlideContext";
import { ToastContext } from "../../../../../context/ToastContext";
import { UserContext } from "../../../../../context/UserContext";
import useForm from "../../../../../hooks/useForm";
import useRequest from "../../../../../hooks/useRequest";
import { pushGTMEvent } from "../../../../../utils/GTM";
import CardBankBillPaymentSuccess from "../CardBankBillPaymentSuccess/CardBankBillPaymentSuccess";

const CardBankBillPayment = ({ planUuid, setShowSlide, isReactivatePlan, planType }) => {
  const { request } = useRequest();
  const { setSlideComponent } = useContext(SlideContext);
  const { updateUserContext, getCredits, user } = useContext(UserContext);
  const { showToast } = useContext(ToastContext);
  const couponForm = useForm("optional");
  const [discount, setDiscount] = useState();
  const [loading, setloading] = useState();
  const [terms, setTerms] = useState("");
  const [privacy, setPrivacy] = useState("");

  const cpf = useForm("cpf");
  const name = useForm("");
  const cep = useForm("cep");
  const street = useForm("");
  const number = useForm("");
  const complement = useForm("optional");
  const neighborhood = useForm("");
  const state = useForm("");
  const city = useForm("");
  const acceptedTermsForm = useForm();

  const stateDic = Object.keys(iso3166.country("BR").sub).map((key) => ({
    value: key.toString().replace("BR-", ""),
    label: iso3166.country("BR").sub[key].name,
  }));

  const saveGTMEvents = (purchase) => {
    pushGTMEvent({
      event: "purchase",
      ecommerce: {
        purchase: {
          actionField: {
            id: purchase.billId, // Transaction ID. Required for purchases and refunds. (Vindi Bill Id)
            affiliation: "EnglishBay",
            revenue: purchase.bill.amount, // Total transaction value (incl. tax and shipping)
            tax: null,
            shipping: null,
            coupon: purchase?.coupon?.code ? purchase.coupon.code : null, //Retorna o cupom caso for utilizado na compra
          },
          products: [
            {
              // List of productFieldObjects.
              name: purchase?.plan?.name, // Name or ID is required. Nome da assinatura
              id: purchase?.plan?.uuid,
              price: purchase?.plan?.price,
              brand: null,
              category: purchase?.plan?.lessonType,
              variant: "null",
              quantity: 1,
              coupon: purchase?.coupon?.code ? purchase.coupon.code : null,
            },
          ],
        },
      },
    });
  };

  useEffect(() => {
    const fetchTerms = async () => {
      const userType = user.role === "TEACHER" ? "TEACHER" : "USER";

      const [term, privacy] = await Promise.all([
        request("GET", `/terms/read?kind=TERM&userType=${userType}`),
        request("GET", `/terms/read?kind=PRIVACY&userType=${userType}`),
      ]);

      setTerms(term.text);
      setPrivacy(privacy.text);
    };

    fetchTerms();
  }, []);

  const validateForm = () => {
    cpf.validate();
    name.validate();
    cep.validate();
    street.validate();
    number.validate();
    neighborhood.validate();
    state.validate();
    city.validate();
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (loading) {
      return;
    }

    const paymentRequest = {
      cpf: cpf.value,
      planUuid,
      paymentMethodCode: "pix_bank_slip",
      cep: cep.value,
      street: street.value,
      neighborhood: neighborhood.value,
      complement: complement.value,
      state: state.value,
      city: city.value,
      number: number.value,
    };

    if (discount) {
      paymentRequest.couponCode = discount.code;
    }

    validateForm();

    const formIsValid =
      cpf.validate() &&
      name.validate() &&
      cep.validate() &&
      street.validate() &&
      number.validate() &&
      neighborhood.validate() &&
      state.validate() &&
      city.validate();

    if (formIsValid) {
      setloading(true);
      const response = await request(
        "POST",
        `/subscription/${isReactivatePlan ? "subscribe" : "new"}`,
        paymentRequest,
        true,
        false,
      );

      if (!response.error) {
        await updateUserContext();
        await getCredits();
        saveGTMEvents(response);

        setSlideComponent(
          <CardBankBillPaymentSuccess
            discount={discount}
            uuid={planUuid}
            barCode={
              response.bill.charges[response.bill.charges.length - 1].last_transaction.gateway_response_fields[
                process.env.REACT_APP_NODE_ENV === "prod" ? "typeable_barcode" : "typable_barcode"
              ]
            }
            linkTicket={response.bill.charges[response.bill.charges.length - 1].print_url}
            pixQrCodeUrl={
              response.bill.charges[response.bill.charges.length - 1].last_transaction.gateway_response_fields
                .qrcode_path
            }
            pixCode={
              response.bill.charges[response.bill.charges.length - 1].last_transaction.gateway_response_fields
                .qrcode_original_path
            }
          />,
        );

        window.open(response.bill.charges[response.bill.charges.length - 1].print_url);
        return;
      }

      setloading(false);

      if (response.error?.statusCode !== 201) {
        if (response.error?.message === "INVALID_CPF") {
          return showToast({ message: "CPF Inválido!", type: "error" });
        }

        if (response.error?.message === "ERR_INVALID_PHONE_NUMBER") {
          return showToast({ message: "Número de telefone inválido! Altere seu número em seu perfil", type: "error" });
        }

        if (response.error?.message === "ERR_INVALID_STATE") {
          return showToast({ message: "Estado inválido!", type: "error" });
        }

        if (response.error?.message === "ERR_INVALID_CEP") {
          return showToast({ message: "CEP inválido!", type: "error" });
        }

        if (response.error?.statusCode === 500) {
          return showToast({ message: "Erro interno ao processar compra!", type: "error" });
        }

        return showToast({ message: "Preencha todos os dados corretamente!", type: "error" });
      }
    }

    setloading(false);
  };

  return (
    <form onSubmit={handleSubmit}>
      <h1 className="text-xl font-gray">Insira os dados da cobrança</h1>

      <div className="flex items-center gap-2 py-3">
        <Warning style={{ fill: "#F59300", width: "23px", height: "23px" }} className="w-10 md:w-5 mr-3 md:mr-1" />
        <p style={{ color: "#F59300" }}>
          Lembre-se: A cobrança é mensal! {planType !== "group" && <span>Suas aulas tem validade de 45 dias.</span>}
        </p>
      </div>

      <div className="grid grid-cols-2 gap-5">
        <InputTwo label="Nome Completo" placeholder="Nome Completo" {...name} />
        <InputTwo label="CPF" placeholder="CPF" mask="999.999.999-99" {...cpf} />
      </div>
      <div className="grid grid-cols-2 gap-5">
        <InputTwo label="cep" placeholder="CEP" {...cep} type="string" mask="99999999" />
        <InputTwo label="endereco" placeholder="Endereço" {...street} />
      </div>
      <div className="grid grid-cols-2 gap-5">
        <InputTwo label="Número" placeholder="Número" {...number} />
        <InputTwo label="Complemento" placeholder="Complemento" {...complement} />
      </div>
      <div className="grid grid-cols-3 gap-5">
        <InputTwo label="Bairro" placeholder="Bairro" {...neighborhood} />

        <div className={"flex flex-col p-1 my-3 "} style={{ borderBottom: "1px solid #CCCBD2", maxHeight: "57px" }}>
          <label className="uppercase text-xs tracking-wider" style={{ color: "#66647A" }} htmlFor="states">
            Estado
          </label>
          <div className="flex flex-row justify-between">
            <select
              className="mb-2 w-full cursor-pointer"
              style={{ backgroundColor: "transparent", marginTop: "0.4rem" }}
              onChange={({ target }) => state.setValue(target.value)}
              name="states"
              required
              id="states">
              <option selected disabled value="">
                Selecione
              </option>
              {stateDic.map(({ label, value }, index) => (
                <option key={index} value={value}>
                  {label}
                </option>
              ))}
            </select>
          </div>
        </div>

        <InputTwo label="Cidade" placeholder="Cidade" {...city} />
      </div>
      <div>
        <FormCouponButton couponForm={couponForm} planUuid={planUuid} setDiscount={setDiscount} discount={discount} />
      </div>
      <div style={{ fontSize: "0.8em" }} className="grid md:grid-cols-2 mt-3">
        <RadioButton terms={terms} privacy={privacy} {...acceptedTermsForm} />
      </div>
      <div className="grid md:grid-cols-2 mt-4">
        <div className="col-span-1 grid grid-cols-2 gap-5">
          <PurpleButton type="submit" isLoading={loading}>
            Finalizar compra
          </PurpleButton>
          <Button
            type="button"
            className="border border-purple-800 text-purple-800"
            onClick={() => setShowSlide(false)}>
            Cancelar
          </Button>
          <hr className="mt-2 invisible" />
        </div>
      </div>
    </form>
  );
};

CardBankBillPayment.propTypes = {
  planUuid: PropTypes.string,
  setShowSlide: PropTypes.func,
  isReactivatePlan: PropTypes.bool,
  planType: PropTypes.string,
};

export default CardBankBillPayment;
