import PropTypes from "prop-types";
import React, { useContext } from "react";
import "./Slide.css";

import { SlideContext } from "../../../context/SlideContext";

const Slide = ({ children, isCloseSlide }) => {
  const { ref } = useContext(SlideContext);

  return (
    <div
      ref={ref}
      className={`${
        !isCloseSlide ? "slide-close" : ""
      } slide-component w-full md:w-auto fixed right-0 flex bg-white rounded-lg p-8 md:p-12 z-20 shadow-xl overflow-auto`}>
      {children}
    </div>
  );
};

Slide.propTypes = {
  children: PropTypes.node,
  isCloseSlide: PropTypes.bool,
};

export default Slide;
