import PropTypes from "prop-types";
import React, { useContext } from "react";

import Photos from "../../../_Shared/Photos/Photos";
import { BasicClock } from "../../../../assets/svgs/index";
import { UserContext } from "../../../../context/UserContext";
import { getDay, getMonthName, getYear, isDateNear } from "../../../../utils/dates";
import { formatDate } from "../../../../utils/dates";

const Event = ({
  title,
  message,
  startDate,
  endDate,
  participants,
  participantsCount,
  isNext,
  isConfirmed,
  handleClick,
}) => {
  const { user } = useContext(UserContext);

  return (
    <div
      className={`${
        isNext ? "border border-yellow-400" : ""
      } bg-white p-4 rounded-xl flex flex-col sm:items-center sm:flex-row sm:justify-between mt-2 shadow-sm py-8`}>
      <div className="flex flex-row items-center px-8">
        <div className={`${isNext ? "text-yellow-600" : "text-gray-500"} mr-9 justify-self-center`}>
          <p className="text-xl sm:text-sm uppercase">{getMonthName(startDate)}</p>
          <p className={`${isNext ? "text-yellow-300" : "text-black"} font-bold sm:text-2xl text-3xl`}>
            {getDay(startDate)}
          </p>
          <p>{getYear(startDate)}</p>
        </div>
        <div className="col-span-6 border-l border-gray-200 px-4 text-sm text-gray-600">
          <h2 className="text-lg font-medium line-clamp-1">{title}</h2>
          <p className="text-xs font-gray line-clamp-2">{message}</p>
          <div className="flex mt-3 justify-between">
            <div>
              {participantsCount <= 0 ? (
                <p className="text-xs sm:text-base">Nenhum participante</p>
              ) : (
                <Photos participants={participants} participantsCount={participantsCount} />
              )}
            </div>
            <div className="flex items-center text-xs sm:text-base">
              <BasicClock fill="#625E66" className="w-5 ml-2 mr-1" />
              Início {formatDate(startDate, user?.timezone).hour}
              {endDate && ` / Término ${formatDate(endDate, user?.timezone).hour}`}
            </div>
          </div>
        </div>
      </div>

      <div className="col-start-9 col-span-2 flex flex-row sm:flex-col mt-8 sm:mt-0 items-center ">
        {isNext ? (
          <button
            onClick={handleClick}
            className="text-sm font-semibold border-yellow-300 text-yellow-400 border w-full sm:w-52 py-3 rounded-lg">
            Ir para o evento
          </button>
        ) : (
          <button
            onClick={handleClick}
            className={`${
              isConfirmed ? "font-aqua-green" : "aqua-green text-white"
            } text-sm border-aqua-green border w-full sm:w-52 py-3 rounded-lg`}>
            {isConfirmed ? "Ver Detalhes" : "Tenho interesse"}
          </button>
        )}
        {isConfirmed && !isNext && (
          <div className="px-2">
            <p className="light-aqua-green font-aqua-green text-xs text-center sm:mt-2 sm:w-44 mx-auto p-1 rounded-xl">
              participação confirmada
            </p>
          </div>
        )}
        {isDateNear(startDate, 3) && (
          <div className="px-2">
            <p className="bg-yellow-200 text-yellow-700 text-xs text-center sm:mt-2 sm:w-44 mx-auto p-1 rounded-xl">
              começará em breve
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

Event.propTypes = {
  title: PropTypes.string,
  message: PropTypes.string,
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  participants: PropTypes.array,
  participantsCount: PropTypes.number,
  isNext: PropTypes.bool,
  isConfirmed: PropTypes.bool,
  handleClick: PropTypes.func,
};

export default Event;
