/* eslint-disable */
import React from "react";

import { SpinnerPurple } from "../../../../assets/svgs";

function RedirectToApp() {
  return (
    <section className="min-h-screen flex flex-col justify-center items-center">
      <span className="section__title text-center" style={{ fontSize: 20 }}>
        Redirecionando para o app, aguarde...
      </span>
      <SpinnerPurple className="w-12 m-auto mt-6 mb-8 animate-spin" />{" "}
    </section>
  );
}

export default RedirectToApp;
