import PropTypes from "prop-types";
import React from "react";

import ModalTerms from "../_Shared/Modal/ModalTerms";

const Terms = ({ show, setShowModal, termo, title }) => {
  return show ? <ModalTerms title={title} setShowModal={setShowModal} termo={termo} /> : null;
};

Terms.propTypes = {
  show: PropTypes.bool.isRequired,
  setShowModal: PropTypes.func.isRequired,
  termo: PropTypes.string.isRequired,
  title: PropTypes.string,
};

export default Terms;
