import PropTypes from "prop-types";
import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";

import {
  Input,
  InputPhone,
  PurpleButton,
  Button,
  CheckboxLabel,
  SelectTimezone,
  TextArea,
} from "../../../../_Shared/Form/index";
import LanguageCheckbox from "../../../../_Shared/Teacher/LanguageCheckbox/LanguageCheckbox";
import SkypeIcon from "../../../../../assets/imgs/skypeIcon.jpg";
import { SpinnerPurple } from "../../../../../assets/svgs/index";
import { YoutubeColorfull } from "../../../../../assets/svgs/index";
import { ToastContext } from "../../../../../context/ToastContext";
import { UserContext } from "../../../../../context/UserContext";
import useForm from "../../../../../hooks/useForm";
import useRequest from "../../../../../hooks/useRequest";

const InformationTeacher = ({ loading, setLoading }) => {
  const { request } = useRequest();
  const { showToast } = useContext(ToastContext);
  const { setUserContext } = useContext(UserContext);

  const [ageRangeForm, setAgeRangeForm] = useState([]);
  const [rateForm, setRateForm] = useState([]);
  const [languagesForm, setLanguagesForm] = useState([]);

  const setUserFormInfo = (user) => {
    nameForm.setValue(user.name);
    emailForm.setValue(user.email);
    birthDateForm.setValue(user.birthDate);
    skypeIdForm.setValue(user.skypeId);
    phoneNumberForm.setValue(user.phoneNumber);
    timezone.setValue(user.timezone);
    miniBioForm.setValue(user.teacher?.description);
    hobbiesForm.setValue(user.teacher?.hobbies);
    youtubeForm.setValue(user.teacher?.videoUrl);
    setLanguagesForm(user.teacher?.languages);
    setAgeRangeForm(user.teacher?.ageRange);
  };

  const nameForm = useForm();
  const miniBioForm = useForm("optional");
  const hobbiesForm = useForm("optional");
  const emailForm = useForm("email");
  const phoneNumberForm = useForm("phoneNumber");
  const skypeIdForm = useForm("optional");
  const birthDateForm = useForm("optional");
  const timezone = useForm("optional");
  const youtubeForm = useForm("optional");

  const [userForm, setUserForm] = useState();

  const validateForm = () => {
    nameForm.validate();
    emailForm.validate();
    phoneNumberForm.validate();
  };

  const getUserInfos = async () => {
    setLoading(true);
    const user = await request("GET", "/user/read");
    setRateForm(user.teacher.teach);
    setUserFormInfo(user);
    setUserForm(user);
    setLoading(false);
    return user;
  };

  const handleResetClick = () => {
    setUserFormInfo(userForm);
    validateForm();
  };

  const formatVideoUrl = (videoUrl) => {
    if (!videoUrl) {
      return "";
    }

    if (videoUrl.includes("embed")) {
      return videoUrl;
    }

    const videoCode = videoUrl.split("watch?v=").pop();
    return `https://www.youtube.com/embed/${videoCode}`;
  };

  useEffect(() => {
    getUserInfos();
  }, []);

  const handleSubmit = async (event) => {
    setLoading(true);
    event.preventDefault();
    validateForm();

    const formIsValid = nameForm.validate() && emailForm.validate() && phoneNumberForm.validate();
    if (formIsValid) {
      const userReq = {
        description: miniBioForm.value,
        user: {
          name: nameForm.value,
          email: emailForm.value,
          birthDate: birthDateForm.value,
          skypeId: skypeIdForm.value,
          timezone: timezone.value,
          phoneNumber: phoneNumberForm.value,
        },
        videoUrl: formatVideoUrl(youtubeForm.value),
        hobbies: hobbiesForm.value,
        ageRange: ageRangeForm,
        languages: languagesForm,
        teach: rateForm,
      };

      const user = await request("PATCH", "/teacher/update", userReq);

      if (!user.error) {
        showToast({ type: "success", message: "Informações atualizadas com sucesso!" });
        const updatedUser = await getUserInfos();
        setUserContext(updatedUser);
      }
    }

    setLoading(false);
  };
  return (
    <div className="bg-white p-4 px-7 mt-5 mb-10">
      <h2 className="font-medium">Sobre você</h2>

      {loading ? (
        <SpinnerPurple className="w-10 m-auto mt-5 animate-spin" />
      ) : (
        <form onSubmit={handleSubmit} className="2xl">
          <div className="flex flex-col">
            <div className="h-16 mt-5 mb-5">
              <TextArea name="Mini Bio" label="Mini Bio" rows={3} {...miniBioForm} />
            </div>

            <div className="grid sm:grid-cols-2 gap-5 mt-5">
              <Input name="Nome completo" label="Nome Completo" {...nameForm} />
              <Input
                name="Data de nascimento"
                label="Data de nascimento"
                type="date"
                required={false}
                {...birthDateForm}
              />
            </div>

            <div className="grid sm:grid-cols-2" style={{ columnGap: "1.25rem" }}>
              <div>
                <SelectTimezone name="Fuso horário" label="Fuso horário" {...timezone} />
              </div>
              <InputPhone
                errorClass="col-start-2"
                name="Telefone"
                label="Telefone"
                placeholder="Telefone"
                rounded="rounded-lg"
                {...phoneNumberForm}
              />
            </div>
            <div className="grid sm:grid-cols-2 gap-5">
              <Input name="Email" label="email" {...emailForm} />
            </div>
            <h2 className="mt-4 font-medium">Seus Hobbies</h2>
            <div className="grid grid-cols-1 gap-5">
              <Input name="Escreva sobre seus Hobbies" label="Escreva sobre seus Hobbies" {...hobbiesForm} />
            </div>
            <h2 className="mt-4 font-medium">Conectar Rede Social</h2>
            <div className="grid sm:grid-cols-2 gap-5">
              <div className="flex items-center gap-2">
                <img src={SkypeIcon} className="w-7 h-7" alt="Skype icone" />
                <Input className="w-full" name="ID SKYPE" label="ID SKYPE" {...skypeIdForm} />
              </div>
              <div className="flex items-center gap-2">
                <YoutubeColorfull className="w-7 h-7" />
                <Input className="w-full" name="Youtube" label="Youtube" {...youtubeForm} />
              </div>
            </div>
            <div className="grid sm:grid-cols-2 gap-5 mt-4">
              <div className="flex flex-col">
                <h2 className="font-medium">Selecione a faixa etária</h2>
                <div className="grid grid-rows-4 gap-3 mt-5">
                  <CheckboxLabel
                    name="ageRange"
                    value="CHILDREN"
                    text="Crianças (4-11)"
                    elements={ageRangeForm}
                    setElements={setAgeRangeForm}
                  />
                  <CheckboxLabel
                    name="ageRange"
                    value="TEENAGERS"
                    text="Adolescentes (12-18)"
                    elements={ageRangeForm}
                    setElements={setAgeRangeForm}
                  />
                  <CheckboxLabel
                    name="ageRange"
                    value="ADULTS"
                    text="Adutos (19+)"
                    elements={ageRangeForm}
                    setElements={setAgeRangeForm}
                  />
                </div>
              </div>
              <div className="flex flex-col">
                <h2 className="font-medium">Selecione suas preferências</h2>
                <div className="grid sm:grid-cols-2 grid-rows-4 gap-3 mt-5">
                  <CheckboxLabel text="Básico" value="Básico" elements={rateForm} setElements={setRateForm} />
                  <CheckboxLabel
                    text="Intermediário"
                    value="Intermediário"
                    elements={rateForm}
                    setElements={setRateForm}
                  />

                  <CheckboxLabel text="Avançado" value="Avançado" elements={rateForm} setElements={setRateForm} />
                  <CheckboxLabel text="Business" value="Business" elements={rateForm} setElements={setRateForm} />

                  <CheckboxLabel text="Viagem" value="Viagem" elements={rateForm} setElements={setRateForm} />
                  <CheckboxLabel
                    text="Certificações"
                    value="Certificações"
                    elements={rateForm}
                    setElements={setRateForm}
                  />
                </div>
              </div>
            </div>

            <h2 className="font-medium mt-8 sm:mt-0">Línguas faladas</h2>
            <div className="grid grid-cols-3 sm:grid-cols-6 gap-3 mt-5">
              <LanguageCheckbox
                flag="brasil"
                text="Português"
                value="Português"
                elements={languagesForm}
                setElements={setLanguagesForm}
              />
              <LanguageCheckbox
                flag="eua"
                value="Inglês"
                text="English"
                elements={languagesForm}
                setElements={setLanguagesForm}
              />
              <LanguageCheckbox
                flag="spain"
                value="Espanhol"
                text="Español"
                elements={languagesForm}
                setElements={setLanguagesForm}
              />
              <LanguageCheckbox
                flag="russian"
                value="Russo"
                text="Russian"
                elements={languagesForm}
                setElements={setLanguagesForm}
              />
              <LanguageCheckbox
                flag="serbian"
                value="Sérvio"
                text="Serbian"
                elements={languagesForm}
                setElements={setLanguagesForm}
              />
            </div>
            <div className="grid grid-cols-2 sm:grid-cols-3 gap-3 mt-5">
              <PurpleButton onClick={handleSubmit} type="submit" className="dark-purple text-white">
                Salvar alterações
              </PurpleButton>

              <Button onClick={handleResetClick} type="reset" className="border border-dark-purple font-dark-purple">
                Descartar alterações
              </Button>
            </div>
            <div className="grid sm:grid-cols-2 gap-5">
              <div className="flex mt-5 ">
                <Link to="/termodeuso">
                  <p className="mr-5 text-gray-400 text-sm">Termos de Uso</p>
                </Link>
                <Link to="/politicaPrivacidade">
                  <p className="text-gray-400 text-sm">Politicas de privacidade</p>
                </Link>
              </div>
            </div>
          </div>
        </form>
      )}
    </div>
  );
};

InformationTeacher.propTypes = {
  loading: PropTypes.bool,
  setLoading: PropTypes.func,
};

export default InformationTeacher;
