import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import { HiOutlineDotsHorizontal } from "react-icons/hi";
import { logAnalyticsEvent } from "utils/firebase-analytics";

import Message from "../Message/Message";
import * as S from "./styles.module.css";

const QuickReplies = ({ message, handleRepliesClick, replies }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [response, setResponse] = useState("");

  useEffect(() => {
    setTimeout(() => {
      setResponse(message);
      setIsLoading(false);
    }, 1000);
  }, [message]);

  return (
    <>
      <Message>
        <div
          className={S.BotResponse}
          style={{
            borderTopLeftRadius: 5,
            borderBottomLeftRadius: 20,
          }}>
          {isLoading ? <HiOutlineDotsHorizontal /> : response}
        </div>
      </Message>

      {!isLoading && (
        <>
          <Message>
            <div className={S.QuickReplies}>
              {replies &&
                replies.map(({ text, value }, index) => (
                  <button
                    key={index} // Adicionando a key aqui
                    onClick={() => {
                      logAnalyticsEvent("BAY_opt_revisao");
                      handleRepliesClick(value);
                    }}>
                    {text}
                  </button>
                ))}
            </div>
          </Message>
        </>
      )}
    </>
  );
};

QuickReplies.propTypes = {
  message: PropTypes.string.isRequired,
  handleRepliesClick: PropTypes.func,
  replies: PropTypes.array,
};

export default QuickReplies;
