import propTypes from "prop-types";
import React, { useContext, Fragment } from "react";
import { Link, useHistory } from "react-router-dom";

import BackButton from "../../_Shared/BackButton/BackButton";
import ProfileDropdown from "../../_Shared/ProfileDropdown/ProfileDropdown";
import ProfileImage from "../../_Shared/ProfileImage/ProfileImage";
import { ChevronRight, Hamburger, Logout } from "../../../assets/svgs";
import { NavbarContext } from "../../../context/NavbarContext";
import { UserContext } from "../../../context/UserContext";
import { getLevelIcon } from "../../../utils/level";
import { getFirstName } from "../../../utils/utils";

import "./Navbar.css";

const Navbar = ({ isMenuVisible, setMenuVisible, toggleMenu }) => {
  const { navigationLinks } = useContext(NavbarContext);
  const { user, logout } = useContext(UserContext);

  const userLevel = user?.levelTest;
  const history = useHistory();

  return (
    <header className="header">
      <div className="header__profile">
        <ProfileImage />

        <div>
          <span className="profile__welcome">Welcome {"👋"}.</span>
          <span className="profile__name">{getFirstName(user?.name)}</span>

          {user?.role !== "TEACHER" && (
            <div className="profile__level">
              {getLevelIcon(userLevel)}
              <span>{userLevel}</span>
            </div>
          )}
        </div>
      </div>

      <div className={`header__actions ${toggleMenu ? "header__actions--expand" : "header__actions--contract"}`}>
        <div className="navigation-bar__container">
          {history.location.pathname != "/" && (
            <>
              <BackButton />

              <div className="navigation-bar__links">
                {navigationLinks.map(({ title, path }, index) => (
                  <Fragment key={title}>
                    <Link to={path}>{title}</Link>
                    {index !== navigationLinks.length - 1 && <ChevronRight />}
                  </Fragment>
                ))}
              </div>
            </>
          )}
        </div>

        <div className="flex">
          <button className="actions__button button--menu" onClick={() => setMenuVisible(!isMenuVisible)}>
            <Hamburger />
          </button>

          <button className="button--profile-dropdown">
            <ProfileDropdown />
          </button>

          <button className="actions__button button--logout" onClick={logout}>
            <Logout />
          </button>
        </div>
      </div>
    </header>
  );
};

export default Navbar;

Navbar.propTypes = {
  setMenuVisible: propTypes.func,
  isMenuVisible: propTypes.bool,
  toggleMenu: propTypes.bool,
}.isRequired;
