import PropTypes from "prop-types";
import React from "react";

const ButtonSvg = ({ children, className, clickHandler }) => {
  return (
    <button onClick={clickHandler} className={`${className} p-2 ml-3 rounded-md`}>
      {children}
    </button>
  );
};

ButtonSvg.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  clickHandler: PropTypes.func,
};

export default ButtonSvg;
