import PropTypes from "prop-types";
import React from "react";

const Filler = ({ percentage, progressBarColor }) => {
  return <div className="filler" style={{ width: `${percentage}%`, background: `#${progressBarColor}` }}></div>;
};

Filler.propTypes = {
  percentage: PropTypes.number,
  progressBarColor: PropTypes.string,
};

export default Filler;
