import PropTypes from "prop-types";
import React from "react";
import { useHistory } from "react-router-dom";

import BannerWhatsappImageMobile from "../../../../../assets/imgs/banners/banner_privateclasses_mobile.png";
import BannerWhatsappImage from "../../../../../assets/imgs/banners/banner_privateclasses.png";
import { ChatFavourite } from "../../../../../assets/svgs";
import ModalBanner from "../../ModalBanner";

import "./ModalPrivateClasses.css";

const ModalBannerPrivateClasses = ({ handleHideBanner }) => {
  const history = useHistory();

  const handleRedirect = () => {
    handleHideBanner();
    history.push("/planos");
  };

  return (
    <ModalBanner imageDesktop={BannerWhatsappImage} imageMobile={BannerWhatsappImageMobile}>
      <div className="modal-banner-whatsapp-wrapper">
        <div className="modal-banner-text-wrapper">
          <ChatFavourite />

          <p className="modal-banner-title">
            Aulas <span>particulares</span> com professores
          </p>

          <p className="modal-banner-description">
            Tenha aulas particulares com professores estrangeiros certificados. Escolha um plano que se adequa a sua
            necessidade
          </p>
        </div>

        <div className="modal-banner-button-wrapper">
          <button onClick={handleRedirect} className="modal-banner-button-primary">
            Ver planos
          </button>
        </div>
      </div>
    </ModalBanner>
  );
};

ModalBannerPrivateClasses.propTypes = {
  handleHideBanner: PropTypes.func.isRequired,
};

export default ModalBannerPrivateClasses;
