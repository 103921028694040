export const timezones = [
  { name: "(GMT-12:00) International Date Line West", value: "Etc/GMT+12" },
  { name: "(GMT-11:00) Midway Island, Samoa", value: "Pacific/Midway" },
  { name: "(GMT-10:00) Hawaii", value: "Pacific/Honolulu" },
  { name: "(GMT-09:00) Alaska", value: "US/Alaska" },
  { name: "(GMT-08:00) Pacific Time (US & Canada)", value: "America/Los_Angeles" },
  { name: "(GMT-08:00) Tijuana, Baja California", value: "America/Tijuana" },
  { name: "(GMT-07:00) Arizona", value: "US/Arizona" },
  { name: "(GMT-07:00) Chihuahua, La Paz, Mazatlan", value: "America/Chihuahua" },
  { name: "(GMT-07:00) Mountain Time (US & Canada)", value: "US/Mountain" },
  { name: "(GMT-06:00) Central America", value: "America/Managua" },
  { name: "(GMT-06:00) Central Time (US & Canada)", value: "US/Central" },
  { name: "(GMT-06:00) Guadalajara, Mexico City, Monterrey", value: "America/Mexico_City" },
  { name: "(GMT-06:00) Saskatchewan", value: "Canada/Saskatchewan" },
  { name: "(GMT-05:00) Bogota, Lima, Quito, Rio Branco", value: "America/Bogota" },
  { name: "(GMT-05:00) Eastern Time (US & Canada)", value: "US/Eastern" },
  { name: "(GMT-05:00) Indiana (East)", value: "US/East-Indiana" },
  { name: "(GMT-04:00) Atlantic Time (Canada)", value: "Canada/Atlantic" },
  { name: "(GMT-04:00) Caracas, La Paz", value: "America/Caracas" },
  { name: "(GMT-04:00) Manaus", value: "America/Manaus" },
  { name: "(GMT-04:00) Santiago", value: "America/Santiago" },
  { name: "(GMT-03:30) Newfoundland", value: "Canada/Newfoundland" },
  { name: "(GMT-03:00) Brasilia", value: "America/Sao_Paulo" },
  { name: "(GMT-03:00) Buenos Aires, Georgetown", value: "America/Argentina/Buenos_Aires" },
  { name: "(GMT-03:00) Greenland", value: "America/Godthab" },
  { name: "(GMT-03:00) Montevideo", value: "America/Montevideo" },
  { name: "(GMT-02:00) Mid-Atlantic", value: "America/Noronha" },
  { name: "(GMT-01:00) Cape Verde Is.", value: "Atlantic/Cape_Verde" },
  { name: "(GMT-01:00) Azores", value: "Atlantic/Azores" },
  { name: "(GMT+00:00) Casablanca, Monrovia, Reykjavik", value: "Africa/Casablanca" },
  { name: "(GMT+00:00) Greenwich Mean Time : Dublin, Edinburgh, Lisbon, London", value: "Etc/Greenwich" },
  { name: "(GMT+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna", value: "Europe/Amsterdam" },
  { name: "(GMT+01:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague", value: "Europe/Belgrade" },
  { name: "(GMT+01:00) Brussels, Copenhagen, Madrid, Paris", value: "Europe/Brussels" },
  { name: "(GMT+01:00) Sarajevo, Skopje, Warsaw, Zagreb", value: "Europe/Sarajevo" },
  { name: "(GMT+01:00) West Central Africa", value: "Africa/Lagos" },
  { name: "(GMT+02:00) Amman", value: "Asia/Amman" },
  { name: "(GMT+02:00) Athens, Bucharest, Istanbul", value: "Europe/Athens" },
  { name: "(GMT+02:00) Beirut", value: "Asia/Beirut" },
  { name: "(GMT+02:00) Cairo", value: "Africa/Cairo" },
  { name: "(GMT+02:00) Harare, Pretoria", value: "Africa/Harare" },
  { name: "(GMT+02:00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius", value: "Europe/Helsinki" },
  { name: "(GMT+02:00) Jerusalem", value: "Asia/Jerusalem" },
  { name: "(GMT+02:00) Minsk", value: "Europe/Minsk" },
  { name: "(GMT+02:00) Windhoek", value: "Africa/Windhoek" },
  { name: "(GMT+03:00) Kuwait, Riyadh, Baghdad", value: "Asia/Kuwait" },
  { name: "(GMT+03:00) Moscow, St. Petersburg, Volgograd", value: "Europe/Moscow" },
  { name: "(GMT+03:00) Nairobi", value: "Africa/Nairobi" },
  { name: "(GMT+03:00) Tbilisi", value: "Asia/Tbilisi" },
  { name: "(GMT+03:30) Tehran", value: "Asia/Tehran" },
  { name: "(GMT+04:00) Abu Dhabi, Muscat", value: "Asia/Muscat" },
  { name: "(GMT+04:00) Baku", value: "Asia/Baku" },
  { name: "(GMT+04:00) Yerevan", value: "Asia/Yerevan" },
  { name: "(GMT+04:30) Kabul", value: "Asia/Kabul" },
  { name: "(GMT+05:00) Yekaterinburg", value: "Asia/Yekaterinburg" },
  { name: "(GMT+05:00) Islamabad, Karachi, Tashkent", value: "Asia/Karachi" },
  { name: "(GMT+05:30) Chennai, Kolkata, Mumbai, New Delhi", value: "Asia/Calcutta" },
  { name: "(GMT+05:30) Sri Jayawardenapura", value: "Asia/Calcutta" },
  { name: "(GMT+05:45) Kathmandu", value: "Asia/Katmandu" },
  { name: "(GMT+06:00) Almaty, Novosibirsk", value: "Asia/Almaty" },
  { name: "(GMT+06:00) Astana, Dhaka", value: "Asia/Dhaka" },
  { name: "(GMT+06:30) Yangon (Rangoon)", value: "Asia/Rangoon" },
  { name: "(GMT+07:00) Bangkok, Hanoi, Jakarta", value: "Asia/Bangkok" },
  { name: "(GMT+07:00) Krasnoyarsk", value: "Asia/Krasnoyarsk" },
  { name: "(GMT+08:00) Beijing, Chongqing, Hong Kong, Urumqi", value: "Asia/Hong_Kong" },
  { name: "(GMT+08:00) Kuala Lumpur, Singapore", value: "Asia/Kuala_Lumpur" },
  { name: "(GMT+08:00) Irkutsk, Ulaan Bataar", value: "Asia/Irkutsk" },
  { name: "(GMT+08:00) Perth", value: "Australia/Perth" },
  { name: "(GMT+08:00) Taipei", value: "Asia/Taipei" },
  { name: "(GMT+09:00) Osaka, Sapporo, Tokyo", value: "Asia/Tokyo" },
  { name: "(GMT+09:00) Seoul", value: "Asia/Seoul" },
  { name: "(GMT+09:00) Yakutsk", value: "Asia/Yakutsk" },
  { name: "(GMT+09:30) Adelaide", value: "Australia/Adelaide" },
  { name: "(GMT+09:30) Darwin", value: "Australia/Darwin" },
  { name: "(GMT+10:00) Brisbane", value: "Australia/Brisbane" },
  { name: "(GMT+10:00) Canberra, Melbourne, Sydney", value: "Australia/Canberra" },
  { name: "(GMT+10:00) Hobart", value: "Australia/Hobart" },
  { name: "(GMT+10:00) Guam, Port Moresby", value: "Pacific/Guam" },
  { name: "(GMT+10:00) Vladivostok", value: "Asia/Vladivostok" },
  { name: "(GMT+11:00) Magadan, Solomon Is., New Caledonia", value: "Asia/Magadan" },
  { name: "(GMT+12:00) Auckland, Wellington", value: "Pacific/Auckland" },
  { name: "(GMT+12:00) Fiji, Kamchatka, Marshall Is.", value: "Pacific/Fiji" },
  { name: "(GMT+13:00) Nuku'alofa", value: "Pacific/Tongatapu" },
];
