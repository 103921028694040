export const banner07 = `<div
  style="
    cursor: pointer;
    margin: 0 auto;
    background-image: url('https://media.englishbay.com.br/assets-banners/An&uacute;ncios.png');
    background-size: cover;
    background-position: center;
    color: #333;
    text-align: center;
    font-size: 14px;
    font-family: 'Urbanist', sans-serif;
    display: flex;
    flex-direction: column;
    max-width: 330px;
    border-radius: 10px;
    position: relative;
  "
>
  <img
    style="position: absolute; top: 25px; left: 9%; width: 278px; height: 188px"
    src="https://media.englishbay.com.br/assets-banners/banner-3.png"
    alt="Juicy Online Conference"
  />
  <div
    style="
      background-color: white;
      border-radius: 60px 60px 0 0;
      margin-top: 150px;
      padding: 10px;
    "
  >
    <p
      style="
        font-family: 'Urbanist', sans-serif;
        margin: 0;
        font-weight: 600;
        margin-top: 50px;
        font-size: 22px;
        text-align: left;
      "
    >
      Pratique conversação com a Bay, nossa Inteligência Artificial.
    </p>
    <div
      style="
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 10px 0 20px 0;
      "
    >
      <div>
        <p
          style="
            font-family: 'Urbanist', sans-serif;
            margin: 0;
            font-weight: 600;
            text-decoration: line-through;
            text-align: left;
          "
        >
          R$ 599,88
        </p>
        <p
          style="
            font-family: 'Urbanist', sans-serif;
            margin: 0;
            font-weight: 800;
            font-size: 24px;
          "
        >
          R$ 179,90
        </p>
        <p
          style="
            font-family: 'Urbanist', sans-serif;
            margin: 0;
            font-weight: 600;
            text-align: left;
          "
        >
          Cobran&ccedil;a anual
        </p>
      </div>
      <div style="background: #edeaf6; padding: 8px; border-radius: 10px">
        <p
          style="
            font-family: 'Urbanist', sans-serif;
            margin: 0;
            font-weight: bold;
            color: #733eb1;
          "
        >
          economia de 67%
        </p>
      </div>
    </div>
    <a style="text-decoration: none">
    <button
      style="
        color: white;
        font-size: 16px;
        background-color: black;
        text-decoration: none;
        border-radius: 10px;
        height: 47px;
        width: 100%;
        text-align: center;
      "
    >
      Assinar
    </button>
    </a>
  </div>
</div>
`;
