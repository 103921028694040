import propTypes from "prop-types";
import React from "react";

import "./CertificateCard.css";

const CertificateCard = ({ data, disabled, handleClick, showColor }) => {
  const { image, imageDisabled, title, description, color } = data;

  const disabledStyle = {
    background: "#F2F2F2",
    color: "#1B1B1C",
    cursor: "default",
  };

  return (
    <section className="certificate-card">
      <img src={!showColor ? imageDisabled : image} alt="icon" className="certificate__image" />

      <span className="certificate__title" style={{ color: !showColor ? "#1B1B1C" : color }}>
        {title}
      </span>

      <span className="certificate__description">{description}</span>

      <button
        style={(!showColor && disabledStyle) || { background: color }}
        onClick={() => {
          if (!disabled) {
            handleClick();
            return;
          }
        }}>
        Baixar certificado
      </button>
    </section>
  );
};

export default CertificateCard;

CertificateCard.propTypes = {
  data: propTypes.object.isRequired,
  disabled: propTypes.bool,
  showColor: propTypes.bool,
  handleClick: propTypes.func,
  description: propTypes.string,
};
