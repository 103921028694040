import PropTypes from "prop-types";
import React from "react";

const ModalTermos = ({ setShowModal, termo, buttonMessage, onAccepted, title }) => {
  const handleClick = () => {
    setShowModal(false);
  };

  return (
    <div className="fixed z-10 inset-0">
      <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <div className="fixed inset-0 transition-opacity" aria-hidden="true">
          <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
        </div>

        <div
          className="inline-block align-bottom bg-white rounded-lg text-left shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline">
          <div className="p-[20px] md:p-[25px]">
            <h1 style={{ fontSize: "1.50em", fontWeight: "bold", textAlign: "center" }}>{title}</h1>
            <br />
            <div>
              <iframe
                style={{ height: "55vh", overflowY: "auto", width: "100%" }}
                title="terms"
                srcDoc={termo}></iframe>
            </div>
          </div>
          <div className="bg-gray-50 rounded-lg px-4 md:py-3 sm:px-6 sm:flex sm:flex-row-reverse">
            <button
              type="button"
              onClick={onAccepted ? onAccepted : handleClick}
              className="md:mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm">
              {buttonMessage ? "Aceitar" : "Fechar"}
            </button>
            <hr className="invisible mt-3" />
          </div>
        </div>
      </div>
    </div>
  );
};

ModalTermos.propTypes = {
  setShowModal: PropTypes.func.isRequired,
  termo: PropTypes.string.isRequired,
  buttonMessage: PropTypes.string,
  onAccepted: PropTypes.func,
  title: PropTypes.string,
};

export default ModalTermos;
