import React from "react";
import { useHistory } from "react-router-dom";

import { HutPerson } from "../../../assets/svgs/index";
import "./AccountCreatedSuccess.css";

const AccountCreatedSuccess = () => {
  const history = useHistory();

  const handleClick = () => {
    if (location.search.includes("?returnUrl=")) {
      const returnUrl = location.search.split("=")[1];

      history.push(returnUrl);
    } else {
      history.push("/");
    }
  };

  return (
    <div className="accountCreatedSuccess">
      <h6>
        Sua conta foi criada com sucesso! Bons estudos{" "}
        <span role="img" aria-label="emoji">
          &#129299;
        </span>
      </h6>

      <HutPerson className="w-full" />

      <button onClick={handleClick}>Acessar minha conta</button>
    </div>
  );
};

export default AccountCreatedSuccess;
