import PropTypes from "prop-types";
import React, { useContext, useState } from "react";
import { useHistory } from "react-router-dom";

import { CloseX, SpinnerWhite, Warning } from "../../../../assets/svgs/index";
import { SlideContext } from "../../../../context/SlideContext";
import { UserContext } from "../../../../context/UserContext";
import useRequest from "../../../../hooks/useRequest";
import InfoPlan from "./InfoPlan";
import SuccessDeletePlan from "./SuccessDeletePlan";

const ConfirmDeletePlan = ({ planName }) => {
  const { setShowSlide, setSlideComponent, setEnableClickAway } = useContext(SlideContext);
  const { updateUserContext } = useContext(UserContext);
  const { request } = useRequest();
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();

  const handleCancelPlan = async () => {
    try {
      setIsLoading(true);
      await request("PATCH", "/subscription/cancel");
      await updateUserContext();

      setEnableClickAway(false);
      setSlideComponent(
        <SuccessDeletePlan
          handleClick={() => {
            setShowSlide(false);
            history.push("/");
          }}
          setEnableClickAway={setEnableClickAway}
        />,
      );
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="flex h-full flex-col flex-1 w-full">
      <header className="flex justify-between items-center">
        <h2
          className="text-2xl 31.5rem w-96"
          style={{
            color: "#383743",
          }}>
          Você tem certeza que deseja cancelar este plano?
        </h2>
        <CloseX className="cursor-pointer" onClick={() => setSlideComponent(<InfoPlan />)} />
      </header>

      <div
        className="mt-12 pb-6"
        style={{
          borderBottom: "0.6px solid #9D96A3",
        }}>
        <h1
          className="text-xs tracking-wider uppercase font-bold"
          style={{
            color: "#625E66",
          }}>
          Plano adquirido
        </h1>
        <h2
          className="mt-2"
          style={{
            color: "#252426",
          }}>
          {planName}
        </h2>
      </div>

      <div className="flex mt-8 items-center">
        <Warning fill="#F59300" />

        <p
          className="ml-4 flex-grow-0 text-sm"
          style={{
            width: "29rem",
            color: "#F59300",
          }}>
          Ao cancelar o seu plano você poderá marcar suas aulas até sua validade. Você ainda terá acesso as suas aulas
          anteriores e todos materiais
        </p>
      </div>

      <div className="grid md:grid-cols-3 gap-5 p-2 flex-1 md:h-1/2"></div>
      <footer className="col-span-2 overflow-auto flex flex-row h-12">
        <button
          className="rounded-lg text-sm font-semibold tracking-wider mb:pt-4 mb:pb-4 pl-12 pr-12 text-white"
          style={{
            background: "#B81212",
            width: "290px",
          }}
          onClick={() => handleCancelPlan()}>
          {isLoading ? <SpinnerWhite className="w-6 m-auto animate-spin" /> : "Sim, quero cancelar"}
        </button>
        <button
          className="ml-4 rounded-lg text-sm font-semibold tracking-wider mb:pt-4 mb:pb-4 pl-12 pr-12 bg-white"
          style={{
            color: "#B81212",
            border: "1px solid #B81212",
          }}
          onClick={() => setSlideComponent(<InfoPlan />)}>
          Não
        </button>
      </footer>
    </div>
  );
};

ConfirmDeletePlan.propTypes = {
  planName: PropTypes.string,
};

export default ConfirmDeletePlan;
