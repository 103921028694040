import PropTypes from "prop-types";
import React from "react";

import { Button } from "../../_Shared/Form/index";
import { PurpleArrow } from "../../../assets/svgs";

const NoAccount = ({ onStateChange }) => {
  const handleClick = () => {
    onStateChange(false);
  };
  return (
    <>
      <div className="py-7">
        <div className="flex items-center">
          <PurpleArrow onClick={handleClick} className="mr-2 cursor-pointer" />
          <button onClick={handleClick} className="cursor-pointer">
            Voltar
          </button>
        </div>

        <div className="text-2xl font-low text-gray-900 mt-2 mb-2 text-left">Nenhuma conta encontrada</div>
        <div className="text-left font-low text-sm mb-9">
          Não encontramos nenhuma conta registrada com essa combinação de email e senha. Por favor verifique sua senha e
          tente novamente.
        </div>
      </div>
      <div>
        <Button
          onClick={handleClick}
          className="w-full py-4 px-4 bg-purple-700 hover:bg-purple-900 rounded-md text-white text-sm">
          Tentar novamente
        </Button>
      </div>
    </>
  );
};

NoAccount.propTypes = {
  onStateChange: PropTypes.func.isRequired,
};

export default NoAccount;
