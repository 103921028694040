import PropTypes from "prop-types";
import React from "react";

import { getAgeName } from "../ages";

const Age = ({ ageRange }) => {
  return (
    <div className="mt-10">
      <div className="text-sm text-gray-600">Faixa etária</div>
      <div className="mt-5 text-gray-600">
        <div>
          {ageRange?.map((item) => {
            return <p key={item.uuid}>{getAgeName(item)}</p>;
          })}
        </div>
      </div>
    </div>
  );
};

Age.propTypes = {
  ageRange: PropTypes.array,
};

export default Age;
