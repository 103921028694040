import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";

import Terms from "../../../Terms/Terms";

import "./CardAuth.css";

const { tawkTo } = require("../../../../utils/tawkto");

const CardAuth = ({ children, terms, privacy }) => {
  const [showModal, setShowModal] = useState(false);
  const [targetText, setTargetText] = useState(terms);
  const [targetTitle, setTargetTitle] = useState("Termos de Uso - EnglishBay");

  const handleTawkTo = () => {
    const config = window.Tawk_API;
    config.toggle();
  };

  const openTerm = () => {
    setTargetTitle("Termos de Uso - EnglishBay");
    setTargetText(terms);
    setShowModal(true);
  };

  const openPrivacy = () => {
    setTargetTitle("Política de Privacidade - EnglishBay");
    setTargetText(privacy);
    setShowModal(true);
  };

  useEffect(() => {
    tawkTo(null);
  }, []);

  return (
    <div className="my-auto mb-1">
      <div className="mt-6">
        <div className="max-w-lg w-full mx-auto bg-white p-9 shadow-2xl borderTopRight">{children}</div>
        <div
          className="max-w-lg w-full grid lg:grid-cols-2 lg:grid-rows-1 sm:grid-cols-1 sm:grid-rows-2 text-center"
          style={{ margin: "0 auto", marginTop: "20px" }}>
          <div className="font-extralight text-sm" style={{ marginRight: "7px" }}>
            Copyright © {new Date().getFullYear()} EnglishBay
          </div>
          <div>
            <button onClick={handleTawkTo} className="text-sm text-gray-400 font-bold mr-2">
              Ajuda
            </button>
            <button onClick={openPrivacy} className="text-sm text-gray-400 font-bold mr-2">
              Privacidade
            </button>
            <button onClick={openTerm} className="text-sm text-gray-400 font-bold mr-2">
              Termos
            </button>
          </div>
        </div>
      </div>
      <Terms title={targetTitle} show={showModal} setShowModal={setShowModal} termo={targetText} />
    </div>
  );
};

CardAuth.propTypes = {
  children: PropTypes.node,
  terms: PropTypes.string,
  privacy: PropTypes.string,
};

export default CardAuth;
