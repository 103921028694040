import PropTypes from "prop-types";
import React from "react";

import "./CardDashed.css";

const CardDashed = ({ children, backgroundActive, style }) => {
  return (
    <div
      style={style}
      className={`${backgroundActive ? "borderGradient" : "borderGradient-no-bg"} bg-white rounded-xl mr-2`}>
      {children}
    </div>
  );
};

CardDashed.propTypes = {
  children: PropTypes.node.isRequired,
  backgroundActive: PropTypes.bool,
  style: PropTypes.object,
};

export default CardDashed;
