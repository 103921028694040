import Button from "components/MaterialCards/Button/Button";
import { useMaterialCards } from "context/MaterialCardsContext";
import PropTypes from "prop-types";
import React from "react";

import * as S from "./styles.module.css";

const RenderHTML = ({ nextCard }) => {
  const { question } = useMaterialCards();

  const handleClick = () => {
    nextCard({ answer: "_html", isCorrect: true });
  };

  return (
    <div className={S.wrapper}>
      <div>
        <div className={S.title}>{question?.title}</div>
        <div className={S.html} dangerouslySetInnerHTML={{ __html: question?.html }} />
      </div>

      <div className="flex justify-center" style={{ marginTop: "20px" }}>
        <Button onClick={handleClick} type="callToAction" variant={"checking"} text="Continuar" />
      </div>
    </div>
  );
};

RenderHTML.propTypes = {
  nextCard: PropTypes.func.isRequired,
};

export default RenderHTML;
