import PropTypes from "prop-types";
import React from "react";
import { useHistory } from "react-router-dom";

import BannerWhatsappImageMobile from "../../../../../assets/imgs/banners/banner_conversation_mobile.png";
import BannerWhatsappImage from "../../../../../assets/imgs/banners/banner_conversation.png";
import { ChatFavourite } from "../../../../../assets/svgs";
import ModalBanner from "../../ModalBanner";

import "./ModalBannerConversation.css";

const ModalBannerConversation = ({ handleHideBanner }) => {
  const history = useHistory();

  const handleRedirect = () => {
    handleHideBanner();
    history.push("/planos");
  };

  return (
    <ModalBanner imageDesktop={BannerWhatsappImage} imageMobile={BannerWhatsappImageMobile}>
      <div className="modal-banner-conversation">
        <div className="modal-banner-conversation-text-wrapper">
          <ChatFavourite />

          <p className="modal-banner-conversation-title">
            Pratique <span>conversação</span> com outros alunos e professores
          </p>

          <p className="modal-banner-conversation-description">
            Participe dos nossos grupos de conversação de segunda à sexta. Assine o plano premium por apenas{" "}
            <span style={{ fontWeight: "bold" }}>R$49,87 ao mês.</span>
          </p>
        </div>

        <div className="modal-banner-conversation-button-wrapper">
          <button className="modal-banner-conversation-button-primary" onClick={handleRedirect}>
            Assine agora
          </button>
        </div>
      </div>
    </ModalBanner>
  );
};

ModalBannerConversation.propTypes = {
  handleHideBanner: PropTypes.func.isRequired,
};

export default ModalBannerConversation;
