import propTypes from "prop-types";
import React from "react";
import { FaCheck } from "react-icons/fa";
import { MdClose } from "react-icons/md";

import "./PlanCard.css";

function PlanCard({ data, activePlan, onClick, plans }) {
  const { benefits } = data;

  const handlePlan = () => {
    onClick(plans.find((p) => p.intervalCount === 12 && p.lessonType === "CONVERSATION"));
  };

  return (
    <section className="plan-card shadow-sm">
      <section className="plan-card__header">
        <div className="flex gap-5">
          <div className="flex items-center">
            <span className="plan-card__title">{data?.name}</span>
          </div>
        </div>

        <div>
          <div>
            <p className="plan_price--label">A partir de</p>
            <p className="plan-card__price">
              <span className="plan-card__sifra">R$</span>

              <span>{data?.price}</span>

              <span
                style={{
                  fontSize: 18,
                }}>
                /mês
              </span>
            </p>
          </div>
        </div>
      </section>

      <ul>
        {benefits.map(({ benefit, isIncluded }) => (
          <li key={benefit}>
            {isIncluded ? <FaCheck color="#623cea" /> : <MdClose color="#33303e" size={24} />}
            {benefit}
          </li>
        ))}
      </ul>

      <button
        className="btn-choose-plan mt-8"
        style={{ background: `${activePlan && "#EDEAF6"}`, color: `${activePlan && "#733EB1"}` }}
        onClick={handlePlan}>
        <p className="my-1">{activePlan ? "Plano atual" : "Escolher plano"}</p>
      </button>
    </section>
  );
}

export default PlanCard;

PlanCard.propTypes = {
  data: propTypes.object.isRequired,
  activePlan: propTypes.bool,
  fromPrice: propTypes.bool,
  onClick: propTypes.func,
  plans: propTypes.array,
};
