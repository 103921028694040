import React, { useState, useContext } from "react";

import { Input, Button, PurpleButton } from "../../../../_Shared/Form/index";
import { ToastContext } from "../../../../../context/ToastContext";
import useForm from "../../../../../hooks/useForm";
import useRequest from "../../../../../hooks/useRequest";

const ChangePassword = () => {
  const { request } = useRequest();
  const { showToast } = useContext(ToastContext);
  const [passwordError, setPasswordError] = useState();
  const currentPassword = useForm("password");
  const { error: passwordFormError, ...password } = useForm("password");
  const { error: confirmPasswordFormError, ...confirmPassword } = useForm("password");

  const validateForm = () => {
    currentPassword.validate();
    password.validate();
    confirmPassword.validate();
    passwordValidate();
  };
  const passwordValidate = () => {
    if (password.value === confirmPassword.value) {
      return true;
    }

    setPasswordError("As senhas não são iguais");
    return false;
  };
  const handleSubmit = async (event) => {
    event.preventDefault();

    validateForm();

    if (!passwordValidate()) {
      return;
    }

    const dataIsValid = currentPassword.validate() && password.validate() && confirmPassword.validate();
    if (dataIsValid) {
      const recovery = {
        currentPassword: currentPassword.value,
        password: password.value,
      };

      const { error } = await request("POST", "/user/changePassword", recovery);

      if (!error) {
        return showToast({ type: "success", message: "Senha atualizada com sucesso!!" });
      }
    }
  };

  return (
    <div className="bg-white p-5 rounded-lg">
      <p className="font-medium text-lg">Escolha uma nova senha</p>
      <form onSubmit={handleSubmit}>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-5">
          <Input label="Senha atual" type="password" {...currentPassword} />
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 md:gap-5">
          <Input label="Nova senha" type="password" {...password} error={passwordError || passwordFormError} />
          <Input
            label="Confirmar nova senha"
            type="password"
            {...confirmPassword}
            error={passwordError || confirmPasswordFormError}
          />
        </div>

        <div className="grid grid-cols-2 md:grid-cols-3 gap-3 mt-5">
          <PurpleButton onClick={handleSubmit} type="submit" className="dark-purple text-white">
            Salvar alterações
          </PurpleButton>
          <Button className="border border-dark-purple font-dark-purple">Descartar alterações</Button>
        </div>
      </form>
    </div>
  );
};

export default ChangePassword;
