import React from "react";
import SmartBannerComponenent from "react-smartbanner";
import "./styles.css";

const SmartBanner = () => {
  if (["/checkout", "/redirectToApp", "/onBoarding"].includes(window.location.pathname)) {
    return null;
  }

  return (
    <SmartBannerComponenent
      title="Aplicativo da EnglishBay!"
      storeText={{
        android: "na Google Play",
        ios: "na App Store",
      }}
      price={{
        android: "De graça",
        ios: "De graça",
      }}
      // Dias para esconder o banner depois de clicar em fechar
      daysHidden={1}
      // Dias para esconder o banner depois de clicar em "View"
      daysReminder={5}
      button="Baixar"
    />
  );
};

export default SmartBanner;
