import Banner from "components/_Shared/Banners/Banner/Banner";
import Quiz from "quizenglishbay";
import React, { useState, useEffect, useContext } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Popover } from "react-text-selection-popover";

import LessonCoverNoVideo from "../../../_Shared/LessonCoverNoVideo/LessonCoverNoVideo";
import { Download, Next, SpinnerPurple, SpinnerWhite, Sound } from "../../../../assets/svgs";
import { UserContext } from "../../../../context/UserContext";
import useRequest from "../../../../hooks/useRequest";
import "./Lesson.css";
import { downloadFile } from "../../../../utils/utils";

const Lesson = () => {
  const { request } = useRequest();
  const { updateUserContext, showAds } = useContext(UserContext);

  const { uuid } = useParams();
  const history = useHistory();

  const [videoUrl, setVideoUrl] = useState("");
  const [pdfUrl, setPdfUrl] = useState("");
  const [html, setHtml] = useState("");
  const [title, setTitle] = useState("");
  const [viewed, setViewed] = useState(false);
  const [nextLesson, setNextLesson] = useState({});
  const [quiz, setQuiz] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingPdf, setIsLoadingPdf] = useState(false);
  const [isLoadingTTS, setIsLoadingTTS] = useState(false);
  const [showPopover, setShowPopover] = useState(false);

  const isMobile = window.innerWidth < 1024;

  const fetchLesson = async () => {
    setIsLoading(true);
    const response = await request("GET", `/material/read/${uuid}`);

    if (!response.error) {
      setVideoUrl(response.videoUrl);
      setHtml(response.htmlContent);
      setViewed(response.viewed);
      setQuiz(response.quiz);
      setNextLesson(response.nextMaterial);
      setPdfUrl(response.pdfUrl);
      setTitle(response.title);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchLesson();
  }, [uuid]);

  const handleCompleteLessson = async () => {
    if (viewed) {
      history.push(`/lesson/${nextLesson.uuid}`);
      return;
    }

    setIsLoading(true);
    const { isLevelUp, nextMaterial, error } = await request("POST", "/material/markAsRead/" + uuid, null, true, false);

    setIsLoading(false);

    if (error && error.statusCode === 409) {
      history.push(`/lesson/${nextLesson.uuid}`);
      return;
    }

    if (isLevelUp) {
      await updateUserContext();
    }

    if (!nextMaterial?.uuid) {
      history.push("/material");
    }

    history.push(`/lesson/${nextMaterial.uuid}`);
  };

  const downloadMaterial = async () => {
    if (pdfUrl) {
      window.open(pdfUrl, "_blank");
      return;
    }

    setIsLoadingPdf(true);
    const { pdf } = await request("GET", "/material/pdf/" + uuid);

    setIsLoadingPdf(false);
    downloadFile(pdf, title);
  };

  const handleTTS = async (textContent) => {
    if (isLoadingTTS) {
      return;
    }

    setIsLoadingTTS(true);

    const { result } = await request("POST", "/tts/textToSpeech", { text: textContent });

    new Audio("data:audio/ogg;base64, " + result).play();
    setIsLoadingTTS(false);
  };

  useEffect(() => {
    fetchLesson();
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", () => setShowPopover(false));
    return () => window.removeEventListener("scroll", () => setShowPopover(false));
  }, []);

  useEffect(() => {
    setShowPopover(true);
  });

  return (
    <>
      {/* banner/lesson-1 */}
      {showAds && (
        <div id="eb-ad-slot" style={{ margin: "0 auto", marginTop: "20px" }}>
          <ins
            className="adsbygoogle"
            data-ad-client="ca-pub-9985597315024929"
            data-ad-slot="5870511764"
            data-ad-format="auto"
            data-full-width-responsive="true"></ins>
        </div>
      )}
      <section className="lesson__container">
        {(isLoading && <SpinnerPurple className="w-5 m-auto mt-12 animate-spin" />) || (
          <>
            {showPopover && (
              <Popover
                render={({ clientRect, isCollapsed, textContent }) => {
                  if (clientRect == null || isCollapsed) {
                    return null;
                  }

                  return (
                    <button
                      id="tts-button"
                      style={{
                        position: "fixed",
                        left: `${clientRect.left + clientRect.width / 2}px`,
                        top: `${clientRect.top - 50}px`,
                        marginLeft: "-75px",
                        width: "150px",
                        padding: ".4em",
                        borderRadius: "8px",
                        background: "#5807B8",
                        fontSize: "0.8em",
                        color: "white",
                        textAlign: "center",
                        zIndex: "20",
                      }}
                      onClick={() => handleTTS(textContent)}>
                      <div className="flex items-center px-2 text-sm font-bold">
                        <Sound className="fill-current text-white" />
                        {isLoadingTTS ? "Carregando..." : "Escutar texto"}
                      </div>
                    </button>
                  );
                }}
              />
            )}

            <section className="container__video container__video__wrapper">
              <div className="container__video__wrapper">
                {(videoUrl && (
                  <iframe src={videoUrl} title="video" frameBorder="0" allowFullScreen className="video__iframe" />
                )) || <LessonCoverNoVideo />}

                <div className="video__bottom-bar mb-1">
                  <button className="button--download" onClick={downloadMaterial} disabled={isLoadingPdf}>
                    Baixar materiais {isLoadingPdf ? <SpinnerWhite className="w-4 animate-spin" /> : <Download />}
                  </button>

                  <div className="flex items-center gap-3">
                    <div className="flex flex-col">
                      <span className="legend">Próximo</span>
                    </div>

                    <Next className="next-icon" onClick={handleCompleteLessson} />
                  </div>

                  <button
                    className={`btn-conclude ${viewed ? "btn-conclude--viewed" : ""}`}
                    onClick={handleCompleteLessson}>
                    <span className="conclude__ball" />
                    {viewed ? "Concluído" : "Concluir"}
                  </button>
                </div>

                <div className="container-banner--desktop">{videoUrl && <Banner format="rect" name="teacher" />}</div>
                {/* banner/lesson-2 */}
                {!isMobile && showAds && (
                  <div id="eb-ad-slot">
                    <ins
                      className="adsbygoogle"
                      data-ad-client="ca-pub-9985597315024929"
                      data-ad-slot="8576604140"
                      data-ad-format="auto"
                      data-full-width-responsive="true"></ins>
                  </div>
                )}
              </div>
            </section>

            <div className="lesson__container__material">
              <section className="container__material" dangerouslySetInnerHTML={{ __html: html }}></section>

              {quiz && (
                <div className="p-4 mt-2" style={{ width: "100%" }}>
                  <Quiz quiz={quiz} />
                </div>
              )}
            </div>
            <div className="container-banner--mobile">{videoUrl && <Banner format="rect" name="teacher" />}</div>
          </>
        )}
      </section>
    </>
  );
};

export default Lesson;
