import PropTypes from "prop-types";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { addMinutes, getHoursAndMinutes, getDayOfTheWeekDayMonth } from "../../../../utils/dates";

const FeedbackRow = ({ className, history }) => {
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();

  return (
    <div
      className={"px-4 h-24 relative cursor-pointer w-full w-[350px] md:w-auto " + className}
      style={{
        background: "#F7F7FA",
        borderRadius: "10px",
        boxShadow: "0px 2px 4px -2px rgba(24, 39, 75, 0.12), 0px 4px 4px -2px rgba(24, 39, 75, 0.08)",
      }}
      onClick={() => setOpen(!open)}
      aria-hidden="true">
      <div className="grid grid-cols-2 items-center h-full w-full relative">
        <div className="flex items-center">
          <img
            src={history.schedule.teacher.user.profilePictureUrl ?? process.env.REACT_APP_PROFILE_AVATAR_URL}
            alt="foto do professor(a)"
            className="rounded-full self-center"
            style={{
              width: "2.75rem",
              height: "2.75rem",
            }}
          />

          <div className="ml-4">
            <h1
              style={{
                color: "#1A1738",
              }}>
              {history.schedule.teacher.user.name}
            </h1>
          </div>
        </div>
        <div className="justify-self-end">
          <h1
            className="capitalize"
            style={{
              color: "#1A1738",
            }}>
            {getDayOfTheWeekDayMonth(history.schedule.dateTime)}{" "}
          </h1>

          <h2
            className="mt-1 text-sm font-bold"
            style={{
              color: "#66647A",
            }}>
            {getHoursAndMinutes(history.schedule.dateTime)} ás{" "}
            {getHoursAndMinutes(addMinutes(history.schedule.dateTime, history.type))}
          </h2>
        </div>
      </div>
      {open && (
        <div
          className="absolute h-56 w-full pl-20"
          style={{
            background: "#F7F7FA",
            borderRadius: "10px",
            boxShadow: "0px 2px 4px -2px rgba(24, 39, 75, 0.12), 0px 4px 4px -2px rgba(24, 39, 75, 0.08)",
            top: "86px",
            right: 0,
            zIndex: "13",
          }}>
          <h2
            className="text-lg mt-2"
            style={{
              color: "#383743",
            }}>
            Feedback
          </h2>

          <p
            className="mt-1 text-sm mr-20"
            style={{
              color: "#66647A",
            }}>
            {history?.teacherNote ? history.teacherNote : t("no_notes")}
          </p>
        </div>
      )}
    </div>
  );
};

FeedbackRow.propTypes = {
  className: PropTypes.string,
  history: PropTypes.object,
};

export default FeedbackRow;
