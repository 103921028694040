import React, { useContext, useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";

import CertificateCard from "../../../_Shared/CertificateCard/CertificateCard";
import LevelTest from "../../../_Shared/LevelTest/LevelTest";
import a1Icon from "../../../../assets/imgs/A1.png";
import a2Icon from "../../../../assets/imgs/A2.png";
import b1Icon from "../../../../assets/imgs/B1.png";
import b2Icon from "../../../../assets/imgs/B2.png";
import c1Icon from "../../../../assets/imgs/C1.png";
import c2Icon from "../../../../assets/imgs/C2.png";
import { NavbarContext } from "../../../../context/NavbarContext";
import { UserContext } from "../../../../context/UserContext";
import useRequest from "../../../../hooks/useRequest";
import "./Certificates.css";
import certificates from "../../../../utils/certificates";
import { levelToNumber } from "../../../../utils/level";

function Certificates() {
  const { setNavigationLinks } = useContext(NavbarContext);
  const { user, updateUserContext } = useContext(UserContext);
  const history = useHistory();

  const { request } = useRequest();

  const [showLevelTest, setShowLevelTest] = useState(false);

  useEffect(() => {
    const arrLinks = [
      { title: "Início", path: "/" },
      { title: "Certificados", path: "/certificados" },
    ];

    setNavigationLinks(arrLinks);
  }, []);

  const getLevelCertificateIcon = (currentLevel) =>
    ({
      "A1 - Beginner": a1Icon,
      "A2 - Elementary": a2Icon,
      "B1 - Pre-Intermediate": b1Icon,
      "B2 - Intermediate": b2Icon,
      "C1 - Upper-Intermediate": c1Icon,
      "C2 - Advanced": c2Icon,
    }[currentLevel]);

  const levelIcon = useMemo(() => getLevelCertificateIcon(user?.levelTest), [user?.levelTest]);

  const handleTestFinished = async (result) => {
    const user = await request("PATCH", "/user", { levelTest: result });

    if (!user.error) {
      await updateUserContext();
    }

    setShowLevelTest(false);
  };

  const currentLevelNumber = useMemo(() => levelToNumber(user?.levelTest), [user?.levelTest]);

  const handleDownloadCertificate = () => {
    if (user?.student?.status === "ACTIVE") {
      const config = window.Tawk_API;
      config.toggle();
      return;
    }

    const isCanceledStudent = user?.student?.status === "CANCELED";
    const isLead = user?.role === "LEAD";

    history.push(isLead || isCanceledStudent ? "/planos" : "/assinatura");
  };

  return (
    <>
      {!showLevelTest && (
        <section className="certificates__section">
          <span className="section__title">Certificados</span>

          <div className="take-level-test">
            <div>
              <img src={levelIcon} alt="level icon" />

              <div>
                <span className="current-level--legend">Seu nível atual</span>
                <span className="current-level">{user?.levelTest}</span>
              </div>
            </div>

            <button className="btn-take-test" onClick={() => setShowLevelTest(true)}>
              Fazer teste de nivelamento
            </button>
          </div>

          <section className="certificates__container">
            {certificates.map((certificate, index) => {
              return (
                <CertificateCard
                  key={certificate.title}
                  data={{
                    ...certificate,
                    description:
                      index > currentLevelNumber
                        ? "Conclua o módulo anterior para poder baixar esse certificado"
                        : certificate.description,
                  }}
                  disabled={currentLevelNumber <= index && user?.levelTest !== "C2 - Advanced"}
                  showColor={currentLevelNumber > index || user?.levelTest === "C2 - Advanced"}
                  handleClick={handleDownloadCertificate}
                />
              );
            })}
          </section>
        </section>
      )}

      {showLevelTest && (
        <div style={{ marginBottom: "100px" }}>
          <LevelTest handleTestFinished={handleTestFinished} handleCancel={() => setShowLevelTest(false)} />
        </div>
      )}
    </>
  );
}

export default Certificates;
