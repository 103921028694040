import Button from "components/MaterialCards/Button/Button";
import ButtonFeedback from "components/MaterialCards/ButtonFeedback/ButtonFeedback";
import { HalfScreenScroll } from "components/MaterialCards/HalfScreenScroll/HalfScreenScroll";
import TextArea from "components/MaterialCards/TextArea/TextArea";
import TTSButton from "components/MaterialCards/TTSButton/TTSButton";
import { useChatbot } from "context/ChatbotContext";
import { useMaterialCards } from "context/MaterialCardsContext";
import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import { BiHelpCircle } from "react-icons/bi";

import * as S from "./styles.module.css";

const Essay = ({ nextCard }) => {
  const { question } = useMaterialCards();
  const { handleCheckAnswer } = useChatbot();

  const [value, setValue] = useState("");
  const [isChecking, setIsChecking] = useState(false);
  const [isCorrect, setIsCorrect] = useState(false);
  const [showErrorFeedback, setShowErrorFeedback] = useState(false);
  const [showCorrectAnswer, setShowCorrectAnswer] = useState(false);

  const handleOpenTawkTo = () => {
    const config = window.Tawk_API;
    config.toggle();
  };

  const normalizeText = (text) => {
    return text.toLowerCase().trim();
  };

  const handleSubmit = () => {
    setIsChecking(false);
    setIsCorrect(false);

    const normalizedValue = normalizeText(value);

    if (question?.correctAnswers.map(normalizeText).includes(normalizedValue)) {
      setIsCorrect(true);
      handleCheckAnswer(true);
    } else {
      setIsCorrect(false);
      handleCheckAnswer(false);
    }

    setShowErrorFeedback(true);
  };

  const handleNextQuestion = () => {
    nextCard({ answer: value, isCorrect });

    setIsChecking(false);
    setShowErrorFeedback(false);
    setIsCorrect(false);
  };

  const handleChange = (target) => {
    // Previne que o usuário altere a resposta enquanto a resposta está corrigida
    if (showErrorFeedback) {
      return;
    }

    setValue(target.value);
  };

  useEffect(() => {
    if (value.length > 0) {
      setIsChecking(true);
      return;
    }

    setIsChecking(false);
  }, [value]);

  return (
    <>
      <div className={S.wrapper}>
        <div>
          <h1 className={S.title}>{question?.title}</h1>
        </div>
        <div className={S.question}>
          {question?.showTTSButton ? <TTSButton phrase={question?.question} /> : <p>{question?.question}</p>}

          <div>
            {question?.imageUrl && (
              <div className={S.wrapperImage}>
                <img className={S.image} src={question.imageUrl} alt="card imagem" />
              </div>
            )}

            <TextArea
              value={value}
              onChange={({ target }) => handleChange(target)}
              isCorrect={isCorrect}
              showErrorFeedback={showErrorFeedback}
              disabled={showErrorFeedback}
            />
          </div>
        </div>
        <div className={S.wrapperButton}>
          <ButtonFeedback
            showErrorFeedback={showErrorFeedback}
            isCorrect={isCorrect}
            isChecking={isChecking}
            handleNextQuestion={handleNextQuestion}
            handleSubmit={handleSubmit}
            showCorrectAnswer={() => setShowCorrectAnswer(true)}
          />
        </div>
        {showCorrectAnswer && (
          <HalfScreenScroll>
            <div className={S.correctionBox}>
              <div className="flex justify-between items-center">
                <div />

                <p className={S.title} style={{ textAlign: "center", marginTop: 0 }}>
                  Correção
                </p>

                <div onClick={handleOpenTawkTo} className="cursor-pointer">
                  <BiHelpCircle size={25} />
                </div>
              </div>

              <p className={S.title}>Pergunta:</p>
              <p className={S.question}>{question?.question}</p>

              <p className={S.title}>Sua resposta:</p>
              <p className={S.question}>{value}</p>

              <p className={S.title}>Possíveis respostas corretas:</p>

              {question?.correctAnswers.map((answer, index) => (
                <p key={index} className={S.question}>
                  {answer}
                </p>
              ))}
              <div className={S.correctionButton}>
                <Button onClick={handleNextQuestion} type="callToAction" variant={"incorrect"} text="Próximo" />
              </div>
            </div>
          </HalfScreenScroll>
        )}
      </div>

      {showCorrectAnswer && <div className={S.backdrop} />}
    </>
  );
};

Essay.propTypes = {
  nextCard: PropTypes.func.isRequired,
};

export default Essay;
