import { utcToZonedTime } from "date-fns-tz";
import PropTypes from "prop-types";
import React, { useState, useEffect, useRef, useContext } from "react";
import Slider from "react-slick";

import { ArrowLeft, ArrowRigth } from "../../../../assets/svgs/index";
import { UserContext } from "../../../../context/UserContext";
import { getDay, getMonthName, getDayOfTheWeek, getDayMonthYear } from "../../../../utils/dates";

const HourCard = ({ schedule, date, addDate, removeDate, datesSelected }) => {
  const refCarousel = useRef();
  const { user } = useContext(UserContext);

  const [isToday, setIsToday] = useState(false);

  const [activeSlide, setActiveSlide] = useState(0);
  const [disablePrev, setDisablePrev] = useState(true);
  const [disableNext, setDisableNext] = useState(false);

  const settings = {
    arrows: false,
    infinite: false,
    variableWidth: true,
    slidesToScroll: 1,
    beforeChange: (current, next) => setActiveSlide(next),
  };

  useEffect(() => {
    if (!user?.timezone) {
      return;
    }

    const zonedToday = utcToZonedTime(new Date().toISOString(), user.timezone);
    const isToday = getDayMonthYear(zonedToday) === getDayMonthYear(date);

    setIsToday(isToday);
  }, [date, user?.timezone]);

  useEffect(() => {
    if (activeSlide === 0) {
      setDisablePrev(true);
    } else {
      setDisablePrev(false);
    }

    if (activeSlide >= schedule.length - 1) {
      setDisableNext(true);
    } else {
      setDisableNext(false);
    }
  }, [activeSlide, schedule]);

  const handleClickNext = () => {
    if (schedule.length > 0) {
      refCarousel.current.slickNext();
    }
  };

  const handleClickPrev = () => {
    if (schedule.length > 0) {
      refCarousel?.current?.slickPrev();
    }
  };

  const handleClickPill = (date) => {
    if (datesSelected.find((dateSelected) => dateSelected.uuid === date.uuid)) {
      return removeDate(date);
    }

    addDate(date);
  };

  return (
    <>
      <div className="flex items-center rounded-xl mt-2 hourCard">
        <div
          style={{ minWidth: "81px", backgroundColor: isToday ? "#5807B8" : "#30125C" }}
          className="p-4 px-5 rounded text-white">
          {isToday ? (
            <p className="text-xs text-center uppercase">Hoje</p>
          ) : (
            <p className="text-xs text-center uppercase">{getDayOfTheWeek(date)}</p>
          )}

          <p className="text-3xl font-bold text-center">{getDay(date)}</p>
          <p className="text-xs text-center uppercase">{getMonthName(date)}</p>
        </div>

        <div className="slider-container">
          {schedule.length > 0 ? (
            <Slider ref={refCarousel} {...settings}>
              {schedule
                .filter((schedule) => !schedule.hidden)
                .map((date) => {
                  const hours = date.dateTime.split("T")[1].split(":");
                  return (
                    <button
                      onClick={() => handleClickPill(date)}
                      key={date.uuid}
                      className={`${
                        datesSelected.find((dateSelected) => dateSelected.uuid === date.uuid) ? "phillShape-active" : ""
                      }
                      rounded-full py-1 p-2 text-center phillShape`}>
                      {`${hours[0]}:${hours[1]}`}
                    </button>
                  );
                })}
            </Slider>
          ) : (
            <p className="text-center">Nenhuma aula dísponivel</p>
          )}
        </div>

        <div className="flex ml-3">
          <button onClick={handleClickPrev}>
            <ArrowLeft className={`w-2 fill-current ${disablePrev ? "text-gray-400" : "font-purple"} mr-3`} />
          </button>
          <button onClick={handleClickNext}>
            <ArrowRigth
              id="next"
              className={`w-2 fill-current ${disableNext ? "text-gray-400" : "font-purple"} mr-3`}
            />
          </button>
        </div>
      </div>
    </>
  );
};

HourCard.propTypes = {
  schedule: PropTypes.array,
  datesSelected: PropTypes.array,
  date: PropTypes.object,
  addDate: PropTypes.func,
  removeDate: PropTypes.func,
};

export default HourCard;
