import PropTypes from "prop-types";
import React, { useEffect, useRef } from "react";
import { AiOutlineClose as CloseIcon } from "react-icons/ai";
import { logAnalyticsEvent } from "utils/firebase-analytics";

import BannerImage2 from "../../../../assets/imgs/banners/banner_download_2.png";
import BannerImage from "../../../../assets/imgs/banners/banner_download.png";
import CircleImage from "../../../../assets/imgs/Circle.png";
import { ReactComponent as AppleButton } from "../../../../assets/svgs/apple_button.svg";
import { ReactComponent as CursiveLine } from "../../../../assets/svgs/Cursive_Line.svg";
import { ReactComponent as GoogleButtonSvg } from "../../../../assets/svgs/google_button.svg";
import { ReactComponent as ShineQRCode1 } from "../../../../assets/svgs/Shine_QRCode1.svg";
import { ReactComponent as ShineQRCode2 } from "../../../../assets/svgs/Shine_QRCode2.svg";
import { ReactComponent as StoreQRCoode } from "../../../../assets/svgs/StoreQRCode.svg";
import { ReactComponent as Underline01 } from "../../../../assets/svgs/Underline_01.svg";
import { ReactComponent as Underline02 } from "../../../../assets/svgs/Underline_02.svg";
import useOnClickOutside from "../../../../hooks/useOnClickOutside";
import * as S from "./styles.module.css";

const DownloadApp = ({ handleHideBanner, variant }) => {
  const ref = useRef();

  useOnClickOutside(ref, () => handleHideBanner());

  const image = variant == 0 ? BannerImage : BannerImage2;

  useEffect(() => {
    const eventName = "SHOW_BANNER_DOWNLOAD_APP_" + variant?.toString();
    logAnalyticsEvent(eventName);
  }, []);

  const handleClick = () => {
    const eventName = "CLICK_BANNER_DOWNLOAD_APP_" + variant?.toString();
    logAnalyticsEvent(eventName);
  };

  return (
    <div className={S.Backdrop}>
      <div ref={ref} className={S.Container}>
        <div className={S.Wrapper}>
          <div className={S.WrapperContent}>
            <div style={{ position: "relative" }}>
              {variant == 0 && (
                <>
                  <p className={S.Title}>
                    <p>Aprimore sua</p>
                    Experiência com o App!
                  </p>

                  <div style={{ position: "absolute", left: 0, bottom: -20 }}>
                    <Underline01 />
                  </div>
                </>
              )}

              {variant == 1 && (
                <>
                  <p className={S.Title}>
                    <p>Aprenda</p>
                    Melhor no App!
                  </p>

                  <div style={{ position: "absolute", right: -20, top: 28 }}>
                    <img style={{ width: 108, height: 63, backgroundSize: "contain" }} src={CircleImage} alt="circle" />
                  </div>
                </>
              )}
            </div>

            <p className={S.Description}>
              Nosso aplicativo oferece uma trilha de aprendizado personalizada, lições envolventes e notificações para
              mantê-lo motivado. Faça o download hoje!
            </p>

            {variant == 0 && (
              <>
                <div style={{ width: 150, height: 159, position: "relative" }}>
                  <div style={{ position: "absolute", right: -15, top: -10 }}>
                    <ShineQRCode1 />
                  </div>

                  <StoreQRCoode />

                  <div style={{ position: "absolute", left: -15, bottom: -10 }}>
                    <ShineQRCode2 />
                  </div>
                </div>

                <div className={S.WrapperStoreButtons} onClick={handleClick}>
                  <a href="https://play.google.com/store/apps/details?id=com.englishbay.App&utm_source=modal-plataforma">
                    <GoogleButtonSvg />
                  </a>

                  <a href="https://apps.apple.com/br/app/englishbay-curso-de-inglês/id6450712168?utm_source=modal-plataforma">
                    <AppleButton />
                  </a>
                </div>

                <div className={S.WrapperCursiveLine}>
                  <CursiveLine />
                </div>
              </>
            )}

            {variant == 1 && (
              <>
                <div className="w-full flex justify-around items-center">
                  <div style={{ width: 150, height: 159, position: "relative" }}>
                    <div style={{ position: "absolute", right: -15, top: -10 }}>
                      <ShineQRCode1 />
                    </div>

                    <StoreQRCoode />

                    <div style={{ position: "absolute", left: -15, bottom: -10 }}>
                      <ShineQRCode2 />
                    </div>
                  </div>

                  <div className="flex flex-col gap-3" onClick={handleClick}>
                    <a href="https://play.google.com/store/apps/details?id=com.englishbay.App&utm_source=modal-plataforma">
                      <GoogleButtonSvg />
                    </a>

                    <a href="https://apps.apple.com/br/app/englishbay-curso-de-inglês/id6450712168?utm_source=modal-plataforma">
                      <AppleButton />
                    </a>
                  </div>
                </div>

                <div className={S.WrapperCursiveLine} style={{ bottom: -100 }}>
                  <Underline02 />
                </div>
              </>
            )}
          </div>
        </div>

        <div style={{ position: "relative", width: "100%" }}>
          <img className={S.Image} src={image} alt="bannerImage" />

          <div className={S.WrapperButtonClose}>
            <button className={S.ButtonClose} onClick={handleHideBanner}>
              <CloseIcon size={20} color="#6A51B2" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

DownloadApp.propTypes = {
  handleHideBanner: PropTypes.func.isRequired,
  variant: PropTypes.number.isRequired,
};

export default DownloadApp;
