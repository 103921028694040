import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";

import { CloseX, Warning } from "../../../../assets/svgs/index";
import useRequest from "../../../../hooks/useRequest";
import { addMinutes, getHoursAndMinutes, getDayOfTheWeekDayMonthYear } from "../../../../utils/dates";

const DrawerDetailsCancel = ({ setShowSlide, uuid, userUuid, dateTime, refresh, type }) => {
  const { request } = useRequest();
  const [user, setUser] = useState(null);

  const fetchProfile = async () => {
    const response = await request("GET", `/teacher/readUser/${userUuid}`);
    setUser(response);
  };

  const handleCancelLesson = async () => {
    await request("DELETE", `/appointment/cancel/${uuid}`);

    setShowSlide(false);
    refresh();
  };

  useEffect(() => {
    fetchProfile();
  }, []);

  return (
    <div
      className="h-full flex flex-col"
      style={{
        width: "34.5rem",
      }}>
      <div>
        <header className="flex flex-row justify-between items-center">
          <h1 className="text-xl">Tem certeza que deseja cancelar essa aula?</h1>

          <CloseX className="cursor-pointer" onClick={() => setShowSlide(false)} />
        </header>
        <div className="flex flex-row mt-8 justify-between">
          <div className="flex flex-row">
            <img
              className="w-11 h-11 rounded-3xl text-xs"
              src={user?.profilePictureUrl ?? process.env.REACT_APP_PROFILE_AVATAR_URL}
              alt="foto do aluno"
            />

            <div className="ml-4 items-center justify-center flex">
              <h2
                className="text-base"
                style={{
                  color: "#1A1738",
                }}>
                {user?.name}
              </h2>
            </div>
          </div>
        </div>
        <div className="mt-5">
          <p className="text-sm capitalize">{getDayOfTheWeekDayMonthYear(dateTime)}</p>
          <p className="font-nunito text-xs font-bold tracking-wider uppercase text-gray-600 mt-1">
            das {getHoursAndMinutes(dateTime)} ás {getHoursAndMinutes(addMinutes(dateTime, type))}
          </p>
        </div>
      </div>
      <div className="mt-4 flex-1">
        <div className="flex items-center">
          <div>
            <Warning style={{ fill: "#F59300" }} />
          </div>
          <p className="warningTextColorCancel ml-4 text-sm">
            Lembre-se: Ao cancelar uma aula até 3h antes de seu inicio, o professor irá pagar uma multa de U$$ 3,50.
          </p>
        </div>
      </div>
      <div className="borderButtonsTop pt-6 flex">
        <button
          className="bg-red-700 text-white mr-4 text-center rounded-lg tracking-wider text-sm font-semibold w-60 h-12"
          onClick={handleCancelLesson}>
          Sim, quero cancelar
        </button>
        <button
          className="cancelLessonCancel text-center rounded-lg tracking-wider text-sm font-semibold w-36 h-12"
          onClick={() => setShowSlide(false)}>
          Não
        </button>
      </div>
    </div>
  );
};

DrawerDetailsCancel.propTypes = {
  setShowSlide: PropTypes.func,
  refresh: PropTypes.func,
  uuid: PropTypes.string,
  type: PropTypes.string,
  dateTime: PropTypes.string,
  userUuid: PropTypes.string,
};

export default DrawerDetailsCancel;
