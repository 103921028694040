import GirlBanner5 from "assets/imgs/girl-banner-5.png";
import { useIsStudent } from "hooks/useIsStudent";
import React from "react";
import { HiOutlineArrowNarrowRight } from "react-icons/hi";
import "./BannerCertificateSquare.css";
import { logAnalyticsEvent } from "utils/firebase-analytics";

const BannerCertificateSquare = () => {
  const { handleRedirect } = useIsStudent();

  return (
    <div
      className="banner-certificate-square"
      style={{ backgroundImage: `url(${GirlBanner5})` }}
      onClick={() => {
        logAnalyticsEvent("banner_click_certificados");
        handleRedirect("certificates", "/certificados");
      }}>
      <div>
        <p>
          Solicite o seu <br /> <span>certificado</span>
        </p>

        <button>
          Solicitar
          <HiOutlineArrowNarrowRight />
        </button>
      </div>
    </div>
  );
};

export default BannerCertificateSquare;
