import PropTypes from "prop-types";
import React, { createContext, useState, useRef } from "react";
import { useClickAway } from "react-use";

export const SlideContext = createContext();

export const SlideStorage = ({ children }) => {
  const [showSlide, setShowSlide] = useState(false);
  const [slideComponent, setSlideComponent] = useState(true);
  const [enableClickAway, setEnableClickAway] = useState(true);

  const ref = useRef(null);
  useClickAway(ref, () => {
    enableClickAway && setShowSlide(false);
  });

  return (
    <SlideContext.Provider
      value={{
        showSlide,
        setShowSlide,
        slideComponent,
        setSlideComponent,
        enableClickAway,
        setEnableClickAway,
        ref,
      }}>
      {children}
    </SlideContext.Provider>
  );
};

SlideStorage.propTypes = {
  children: PropTypes.node.isRequired,
};
