import A1DisabledImage from "assets/imgs/A1-Disabled.png";
import A1Image from "assets/imgs/A1.png";
import A2DisabledImage from "assets/imgs/A2-Disabled.png";
import A2Image from "assets/imgs/A2.png";
import B1DisabledImage from "assets/imgs/B1-Disabled.png";
import B1Image from "assets/imgs/B1.png";
import B2DisabledImage from "assets/imgs/B2-Disabled.png";
import B2Image from "assets/imgs/B2.png";
import C1DisabledImage from "assets/imgs/C1-Disabled.png";
import C1Image from "assets/imgs/C1.png";
import C2DisabledImage from "assets/imgs/C2-Disabled.png";
import C2Image from "assets/imgs/C2.png";
import PropTypes from "prop-types";
import React from "react";
import { useHistory } from "react-router-dom";

const levels = [
  {
    title: "A1 - Beginner",
    imageEnabledUrl: A1Image,
    imageDisabledUrl: A1DisabledImage,
  },

  {
    title: "A2 - Elementary",
    imageEnabledUrl: A2Image,
    imageDisabledUrl: A2DisabledImage,
  },

  {
    title: "B1 - Pre-Intermediate",
    imageEnabledUrl: B1Image,
    imageDisabledUrl: B1DisabledImage,
  },
  {
    title: "B2 - Intermediate",
    imageEnabledUrl: B2Image,
    imageDisabledUrl: B2DisabledImage,
  },
  {
    title: "C1 - Upper-Intermediate",
    imageEnabledUrl: C1Image,
    imageDisabledUrl: C1DisabledImage,
  },
  {
    title: "C2 - Advanced",
    imageEnabledUrl: C2Image,
    imageDisabledUrl: C2DisabledImage,
  },
];

const Certificate = ({ description, active, selectedLevel }) => {
  const { title, imageDisabledUrl, imageEnabledUrl } = levels.find((level) => level.title === selectedLevel);

  const history = useHistory();

  const handleClick = () => {
    if (!active) {
      return;
    }

    history.push("/certificados");
  };
  return (
    <div style={{ marginTop: 10, background: "white" }}>
      <div className="flex items-center gap-5" style={{ marginLeft: 30 }}>
        <img src={active ? imageEnabledUrl : imageDisabledUrl} style={{ width: 56, height: 64 }} alt="Certificado" />

        <div>
          <h1>{title}</h1>
          <p>{description}</p>
        </div>
      </div>

      {active ? (
        <button
          onClick={handleClick}
          style={{
            background: "#733eb1",
            borderRadius: "0px 0px 20px 20px",
          }}
          className="w-full text-white font-bold py-2 px-4 mt-4">
          Baixar
        </button>
      ) : (
        <button
          style={{
            background: "gray",
            borderRadius: "0px 0px 20px 20px",
          }}
          className="w-full text-white font-bold py-2 px-4 mt-4">
          Baixar
        </button>
      )}
    </div>
  );
};

Certificate.propTypes = {
  description: PropTypes.string,
  selectedLevel: PropTypes.string,
  active: PropTypes.bool,
};

export default Certificate;
