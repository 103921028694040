import { UserContext } from "context/UserContext";
import { differenceInDays } from "date-fns";
import React, { useContext, useEffect, useState } from "react";
import { HiOutlineArrowNarrowRight } from "react-icons/hi";
import { useHistory } from "react-router-dom";
import { logAnalyticsEvent } from "utils/firebase-analytics";

import * as S from "./styles.module.css";

const Banner7Days = () => {
  const isMobile = window.innerWidth < 768;
  const { user } = useContext(UserContext);
  const [daysRemaining, setDaysRemaining] = useState(7);
  const history = useHistory();

  const getRemainingDays = (targetDate) => {
    const diasRestantes = differenceInDays(new Date(targetDate), new Date());

    if (diasRestantes > 1) {
      return `${diasRestantes} dias`;
    } else if (diasRestantes === 1) {
      return "1 dia";
    } else {
      return "0 dias";
    }
  };

  const handleClick = () => {
    history.push("/planos");
    logAnalyticsEvent("banner_click_7days");
  };

  useEffect(() => {
    if (!user?.expiresAt) {
      return;
    }

    setDaysRemaining(getRemainingDays(user.expiresAt));
  }, [user?.expiresAt]);

  return isMobile ? (
    <div className={S.Wrapper} onClick={handleClick}>
      <div className={S.TextWrapper}>
        <p>
          {daysRemaining === "1 dia" ? "Falta" : "Faltam"} <span className={S.TextGreen}>{daysRemaining}</span> pro
          teste grátis acabar!
        </p>

        <button>
          Escolha seu plano
          <HiOutlineArrowNarrowRight size="14px" />
        </button>
      </div>
    </div>
  ) : (
    <div className={S.WrapperDesktop} onClick={handleClick}>
      <p>
        {daysRemaining === "1 dia" ? "Falta" : "Faltam"}{" "}
        <strong>
          {daysRemaining} pro teste grátis <span className={S.TextGreen}>acabar!</span>
        </strong>
      </p>

      <button>
        Escolha seu plano
        <HiOutlineArrowNarrowRight size="20px" />
      </button>
    </div>
  );
};

export default Banner7Days;
