import propTypes from "prop-types";
import React from "react";

import "./Button.css";

function Button({ color, bg, border, width, height, children, className, style, ...props }) {
  const s = {
    color,
    background: bg,
    border,
    width,
    height,
    ...style,
  };

  return (
    <button {...props} style={s} className={`generic-button ${className}`}>
      {children}
    </button>
  );
}

export default Button;

Button.propTypes = {
  color: propTypes.string,
  bg: propTypes.string,
  border: propTypes.string,
  width: propTypes.string,
  height: propTypes.string,
  style: propTypes.object,
  className: propTypes.string,
  children: propTypes.any,
};
