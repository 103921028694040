import PropTypes from "prop-types";
import React, { useState } from "react";

import ToggleSwitch from "../../../_Shared/ToggleSwitch/ToggleSwitch";
import { CloseX } from "../../../../assets/svgs/index";

const DropdownFilters = ({ setIsOpen, setCurrentFilters, currentFilters }) => {
  const [filter, setFilter] = useState(currentFilters);

  const applyValueToFilter = (value) => {
    if (filter.includes(value)) {
      setFilter((crr) => crr.filter((currentVal) => currentVal !== value));
      return;
    }

    setFilter((crr) => [...crr, value]);
  };

  return (
    <div
      className="absolute bg-white rounded-2xl right-0 top-0 pt-8"
      style={{
        width: "15.375rem",
        height: "30.1rem",
        border: "1px solid #F0F0F0",
        boxShadow: "0px 1.875rem 4.375rem rgba(58, 53, 104, 0.102)",
        zIndex: "13",
      }}>
      <div className="flex justify-between ml-7 mr-9">
        <h1
          className="text-2xl"
          style={{
            color: "#383743",
          }}>
          Filtros
        </h1>

        <CloseX className="cursor-pointer" onClick={() => setIsOpen(false)} />
      </div>

      <h2 className="mt-6 ml-7 mr-16 font-semibold">Duration</h2>

      <div className="mt-4 ml-7 mr-9">
        <div className="flex justify-between">
          <h1
            className="text-base"
            style={{
              color: "#1A1738",
            }}>
            25 min
          </h1>
          <div onClick={() => applyValueToFilter("TWENTY_FIVE")}>
            <ToggleSwitch initialValue={filter.includes("TWENTY_FIVE")} />
          </div>
        </div>

        <div className="flex justify-between mt-4">
          <h1
            className="text-base"
            style={{
              color: "#1A1738",
            }}>
            50 min
          </h1>
          <div onClick={() => applyValueToFilter("FIFTY")}>
            <ToggleSwitch initialValue={filter.includes("FIFTY")} />
          </div>
        </div>
      </div>

      <h2 className="mt-6 ml-7 mr-16 font-semibold">Class type</h2>

      <div className="mt-4 ml-7 mr-9">
        <div className="flex justify-between">
          <h1
            className="text-base"
            style={{
              color: "#1A1738",
            }}>
            Test class
          </h1>
          <div onClick={() => applyValueToFilter("isTestClass")}>
            <ToggleSwitch initialValue={filter.includes("isTestClass")} />
          </div>
        </div>
      </div>

      <div
        className="ml-7 mr-9 mt-6 flex flex-col"
        style={{
          borderTop: "1px solid #DFE1E6",
        }}>
        <button
          onClick={() => {
            setFilter([]);
            setCurrentFilters([]);
            setIsOpen(false);
          }}
          className="w-48 h-12 rounded-lg mt-7"
          style={{
            color: "#5807B8",
            border: "1px solid #5807B8",
          }}>
          Clear
        </button>

        <button
          onClick={() => {
            setIsOpen(false);
            setCurrentFilters(filter);
          }}
          className="w-48 h-12 rounded-lg text-white mt-3"
          style={{
            background: "#5807B8",
          }}>
          Apply
        </button>
      </div>
    </div>
  );
};

DropdownFilters.propTypes = {
  setIsOpen: PropTypes.func,
  setCurrentFilters: PropTypes.func,
  currentFilters: PropTypes.array,
};

export default DropdownFilters;
