import { getAnalytics, logEvent } from "firebase/analytics";
import { getApp, getApps } from "firebase/app";
import { initializeApp } from "firebase/app";

export const loadFirebase = () => {
  if (getApps().length === 0) {
    const firebaseConfig = {
      apiKey: "AIzaSyDXi1qpAsZ5mcB7ynxX67O8wkW8clTgfvw",
      authDomain: "englishbay-app.firebaseapp.com",
      projectId: "englishbay-app",
      storageBucket: "englishbay-app.appspot.com",
      messagingSenderId: "136720518220",
      appId: "1:136720518220:web:227a54842f35f9b646b8e9",
      measurementId: "G-SY94LQ2VHQ",
    };

    // Initialize Firebase
    return initializeApp(firebaseConfig);
  }

  return getApp();
};

// Initialize Analytics and get a reference to the service
const app = loadFirebase();
const analytics = getAnalytics(app);

export const logAnalyticsEvent = (eventName, payload) => {
  logEvent(analytics, eventName, {
    platform: "WEB",
    ...payload,
  });
};
