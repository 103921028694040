import propTypes from "prop-types";
import React, { useState } from "react";

import "./Switcher.css";

function Switcher(props) {
  const [isFirstButtonActive, setIsFirstButtonActive] = useState(true);

  const {
    option1 = "option 1",
    option2 = "option 2",
    onClick1 = () => {},
    onClick2 = () => {},
    className = "",
  } = props;

  return (
    <div className={`switcher ${className}`}>
      <button
        className={`${isFirstButtonActive ? "button--active" : ""}`}
        onClick={() => {
          setIsFirstButtonActive(true);
          onClick1();
        }}>
        {option1}
      </button>

      <button
        className={`${!isFirstButtonActive ? "button--active" : ""}`}
        onClick={() => {
          setIsFirstButtonActive(false);
          onClick2();
        }}>
        {option2}
      </button>
    </div>
  );
}

export default Switcher;

Switcher.propTypes = {
  option1: propTypes.string,
  option2: propTypes.string,
  className: propTypes.string,
  onClick1: propTypes.func,
  onClick2: propTypes.func,
}.isRequired;
