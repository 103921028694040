import PlanCard from "components/Pages/Lead/PlanCard/PlanCard";
import useRequest from "hooks/useRequest";
import PropTypes from "prop-types";
import React, { useEffect, useRef } from "react";
import { useState } from "react";
import { AiOutlineClose as CloseIcon } from "react-icons/ai";
import { useHistory } from "react-router-dom";
import { logAnalyticsEvent } from "utils/firebase-analytics";

import BannerImage from "../../../../assets/imgs/banners/BannerVendas.png";
import useOnClickOutside from "../../../../hooks/useOnClickOutside";
import * as S from "./styles.module.css";

const conversationPlanBenefits = [
  {
    benefit: "Cursos de inglês",
    isIncluded: true,
  },
  {
    benefit: "Aulas ao vivo de segunda a sexta",
    isIncluded: true,
  },
  {
    benefit: "Grupo de conversação diário",
    isIncluded: true,
  },
  {
    benefit: "Suporte com Professores",
    isIncluded: true,
  },
  {
    benefit: "Certificados",
    isIncluded: true,
  },
  {
    benefit: "Sem anúncios",
    isIncluded: true,
  },
];

const conversationPlanBenefitsSmall = [
  {
    benefit: "Cursos de inglês",
    isIncluded: true,
  },
  {
    benefit: "Aulas ao vivo de segunda a sexta",
    isIncluded: true,
  },
  {
    benefit: "Grupo de conversação diário",
    isIncluded: true,
  },
  {
    benefit: "Suporte com Professores",
    isIncluded: true,
  },
];

const Vendas = ({ handleHideBanner }) => {
  const { request } = useRequest();
  const [plans, setPlans] = useState([]);
  const history = useHistory();

  const ref = useRef();

  useOnClickOutside(ref, () => handleHideBanner());

  const fetchPlans = () => request("GET", "/plan/list");

  const handleChoosePlan = () => {
    logAnalyticsEvent("CLICK_BANNER_SALES");
    handleHideBanner();

    history.push("/planos");
  };

  useEffect(() => {
    logAnalyticsEvent("SHOW_BANNER_SALES");
    fetchPlans().then(setPlans);
  }, []);

  return (
    <div className={S.Backdrop}>
      <div className={S.Wrapper}>
        <div className={S.WrapperButtonClose}>
          <button className={S.ButtonClose} onClick={handleHideBanner}>
            <CloseIcon size={20} color="#6A51B2" />
          </button>
        </div>

        <div className={S.WrapperContent}>
          <img src={BannerImage} alt="Banner" className={S.BannerImage} />

          <div style={{ height: "100%" }}>
            <p className={S.Title}>Desbloqueie todo potencial da plataforma</p>

            <p className={S.Description}>
              <PlanCard
                data={{
                  name: "Premium",
                  price: "49.99",
                  price2: "189.99",
                  iconUrl: "https://media.englishbay.com.br/3eec2c7c-b7b8-45b7-92d5-267d4e186862.jpeg",
                  benefits: window.innerWidth < 1424 ? conversationPlanBenefitsSmall : conversationPlanBenefits,
                }}
                onClick={() => handleChoosePlan()}
                plans={plans}
              />
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

Vendas.propTypes = {
  handleHideBanner: PropTypes.func.isRequired,
};

export default Vendas;
