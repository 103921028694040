import React, { useContext } from "react";

import { PersonBad } from "../../../assets/svgs/index";
import { SlideContext } from "../../../context/SlideContext";

const PaymentUnsuccessful = () => {
  const { setShowSlide } = useContext(SlideContext);

  return (
    <div className="flex h-full flex-col flex-1" style={{ width: "690px" }}>
      <h1
        className="text-2xl"
        style={{
          color: "#B81212",
        }}>
        Ops, pareceu que houve algum problema!
      </h1>
      <p
        className="mt-2 text-base"
        style={{
          color: "#66647A",
        }}>
        Por algum motivo, parece que seu pagamento não foi aprovado. Qualquer coisa, entre em contato com a emissora do
        seu cartão.
      </p>
      <p
        className="mt-2 text-base"
        style={{
          color: "#66647A",
        }}>
        Se o problema persistir, entre em contato com nosso{" "}
        <button onClick={() => window.Tawk_API?.toggle()} style={{ color: "lightseagreen" }}>
          suporte
        </button>
      </p>
      <PersonBad className="mt-5 self-center" />
      <div className="grid grid-cols-3 gap-5 p-2 flex-1 h-1/2"></div>
      <footer className="col-span-2">
        <button
          className="ml-4 rounded-lg text-sm font-semibold tracking-wider pt-4 pb-4 pl-12 pr-12 border border-purple-800 text-purple-800"
          onClick={() => setShowSlide(false)}>
          Fechar
        </button>
      </footer>
    </div>
  );
};

export default PaymentUnsuccessful;
