import React, { useContext, useState, useEffect } from "react";

import EventDetails from "../../../_Shared/EventDetails/EventDetails";
import Pagination from "../../../_Shared/Pagination/Pagination";
import SearchButton from "../../../_Shared/SearchButton/SearchButton";
import { SpinnerPurple } from "../../../../assets/svgs/index";
import { NavbarContext } from "../../../../context/NavbarContext";
import { SlideContext } from "../../../../context/SlideContext";
import { UserContext } from "../../../../context/UserContext";
import useRequest from "../../../../hooks/useRequest";
import { getHourDifference } from "../../../../utils/dates";
import Event from "./Event";
import "./MyEvents.css";

const MyEvents = () => {
  const { request } = useRequest();
  const { setShowSlide, setSlideComponent } = useContext(SlideContext);
  const { user } = useContext(UserContext);
  const { setNavigationLinks } = useContext(NavbarContext);

  const [key, setKey] = useState(1);
  const [loading, setLoading] = useState(false);
  const [events, setEvents] = useState([]);
  const [paginate, setPaginate] = useState(0);
  const [pageNumber, setPageNumber] = useState(0);
  const [query, setQuery] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [updatePage, setUpdatePage] = useState(true);

  const getEvents = async (page = 0) => {
    setLoading(true);
    const { results: events, totalPages, totalResults } = await request(
      "GET",
      `/calendarEvent/list?page=${page + 1}&perPage=10${query ? "&searchQuery=" + query : ""}`,
    );

    setEvents(events);
    setPaginate({ totalPages, totalResults });
    setKey(1);
    setLoading(false);
  };

  useEffect(() => {
    getEvents();

    const arrLinks = [
      { title: "Início", path: "/" },
      { title: "Eventos", path: "/eventos" },
    ];

    setNavigationLinks(arrLinks);
  }, []);

  const getMyEvents = async (page = 0) => {
    setLoading(true);
    const { results: events, totalPages, totalResults } = await request(
      "GET",
      `/calendarEvent/listMyEvents?page=${page + 1}&perPage=10${query ? "&searchQuery=" + query : ""}`,
    );

    setEvents(events);
    setPaginate({ totalPages, totalResults });
    setLoading(false);
  };

  useEffect(() => {
    if (updatePage) {
      if (key === 1) {
        getEvents();
      }

      if (key === 2) {
        getMyEvents();
      }

      setPageNumber(0);
      setUpdatePage(false);
    }
  }, [user?.timezone, updatePage]);

  useEffect(() => {
    if (key === 1) {
      getEvents();
    }

    if (key === 2) {
      getMyEvents();
    }
  }, [query]);

  useEffect(() => {
    setUpdatePage(!updatePage);
  }, [key]);

  const renderSlideComponent = (event) => {
    setShowSlide(true);

    setSlideComponent(
      <EventDetails
        data={event}
        closeSlider={() => setShowSlide(false)}
        renderEventDetails={(event) => renderSlideComponent(event)}
        setUpdateCards={setUpdatePage}
      />,
    );
  };

  const handleClick = (event, index) => {
    setShowSlide(true);
    renderSlideComponent(event, index);
  };

  const handlePageChange = ({ selected }) => {
    if (key === 1) {
      getEvents(selected);
    }

    if (key === 2) {
      getMyEvents(selected);
    }

    setPageNumber(selected);
  };

  const calculateEventIsNext = (date) => getHourDifference(date) <= 1;

  return (
    <>
      <div style={{ marginBottom: "100px" }}>
        <div className="grid grid-cols-1 2xl:grid-cols-3 sm:px-8">
          <div className="2xl:col-span-2">
            <div className="p-4 mt-3 bg-white items-center rounded-lg shadow-md text-gray-600 justify-between flex flex-row h-20">
              <div className="flex items-start w-full col-span-3 px-2 sm:px-8 text-center font-gray text-sm">
                <button onClick={() => setKey(1)} className="relative">
                  <p className={`${key === 1 && "font-aqua-green font-semibold"} "p-4"`}>Todos os eventos</p>
                  {key === 1 && (
                    <div className="sm:mt-3 sm:-ml-7 top-[49px] sm:top-[36px] absolute border-t-2 border-aqua-green w-full sm:w-44" />
                  )}
                </button>
                <button onClick={() => setKey(2)} className="relative ml-8 sm:ml-20">
                  <p className={`${key === 2 && "font-aqua-green font-semibold"} "p-4"`}>Meus eventos</p>
                  {key === 2 && (
                    <div className="sm:mt-3 sm:-ml-10 top-[49px] sm:top-[36px] absolute border-t-2 border-aqua-green w-full sm:w-44" />
                  )}
                </button>
              </div>
              <div className="flex justify-end gap-2 md:col-start-4 col-span-2  items-center">
                <SearchButton setQuery={setQuery} isOpen={isOpen} setIsOpen={setIsOpen} query={query} />
              </div>
            </div>
            <div className="mt-5 text-black">
              <div className="px-4">
                {key === 1 && <h2 className="text-2xl font-medium ml-1">Todos os eventos</h2>}
                {key === 2 && <h2 className="text-2xl font-medium">Meus eventos</h2>}

                {loading ? (
                  <SpinnerPurple className="w-10 m-auto mt-5 animate-spin" />
                ) : (
                  events &&
                  events.map((event, index) => {
                    const isLastEvent = index + 1 === events.length;

                    return (
                      <div key={event.id}>
                        <div className={isLastEvent ? "mb-9" : ""}>
                          <Event
                            title={event.title}
                            message={event.message}
                            handleClick={() => {
                              handleClick(event, index);
                            }}
                            isConfirmed={event.participating}
                            isNext={pageNumber === 0 && (calculateEventIsNext(event.startDate) || index === 0)}
                            startDate={event.startDate}
                            endDate={event.endDate}
                            participants={event.participants}
                            participantsCount={event.participantsCount}
                          />
                        </div>

                        {index === 0 && <div className="border-b border-gray-300 my-4" />}
                      </div>
                    );
                  })
                )}

                {paginate.totalPages > 1 && (
                  <Pagination
                    forcePage={pageNumber}
                    totalPages={paginate.totalPages}
                    handlePageChange={handlePageChange}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MyEvents;
