/* eslint-disable react/display-name */
import React from "react";

import BannerCertificate from "../BannerCertificate/BannerCertificate";
import BannerCertificateSquare from "../BannerCertificateSquare/BannerCertificateSquare";
import BannerConversation from "../BannerConversation/BannerConversation";
import BannerConversationRect from "../BannerConversationRect/BannerConversationRect";
import BannerLiveClassesRect from "../BannerLiveClassesRect/BannerLiveClassesRect";
import BannerLiveClassesSquare from "../BannerLiveClassesSquare/BannerLiveClassesSquare";
import BannerPraticeConversationRect from "../BannerPraticeConversationRect/BannerPraticeConversationRect";
import BannerPraticeConversationSquare from "../BannerPraticeConversationSquare/BannerPraticeConversationSquare";
import BannerPremium from "../BannerPremium/BannerPremium";
import BannerPremiumSquare from "../BannerPremiumSquare/BannerPremiumSquare";
import BannerPrivateClassesRect from "../BannerPrivateClassesRect/BannerPrivateClassesRect";
import BannerPrivateClassesSquare from "../BannerPrivateClassesSquare/BannerPrivateClassesSquare";
import BannerTeacher from "../BannerTeacher/BannerTeacher";
import BannerTeacherSquare from "../BannerTeacherSquare/BannerTeacherSquare";

export const squareBanners = [
  {
    name: "teacher",
    component: () => <BannerTeacherSquare />,
  },
  {
    name: "privateClasses",
    component: () => <BannerPrivateClassesSquare />,
  },
  {
    name: "praticeConversation",
    component: () => <BannerPraticeConversationSquare />,
  },
  {
    name: "conversation",
    component: () => <BannerConversation />,
  },
  {
    name: "certificate",
    component: () => <BannerCertificateSquare />,
  },
  {
    name: "premium",
    component: () => <BannerPremiumSquare />,
  },
  {
    name: "liveClasses",
    component: () => <BannerLiveClassesSquare />,
  },
];

const rectBanners = [
  { name: "teacher", component: () => <BannerTeacher /> },
  {
    name: "privateClasses",
    component: () => <BannerPrivateClassesRect />,
  },
  {
    name: "conversation",
    component: () => <BannerConversationRect />,
  },
  { name: "premium", component: () => <BannerPremium /> },
  {
    name: "liveClasses",
    component: () => <BannerLiveClassesRect />,
  },
  {
    name: "praticeConversation",
    component: () => <BannerPraticeConversationRect />,
  },
  { name: "certificate", component: () => <BannerCertificate /> },
];

const Banner = ({ format = "square", name = "teacher" }) => {
  const bannerFormat = format === "square" ? squareBanners : rectBanners;

  const banner = bannerFormat.find((banner) => banner.name === name);

  const BannerComponent = banner.component;

  return <BannerComponent />;
};

export default Banner;
