import MaterialCards from "components/Pages/_SharedPages/MaterialCards/MaterialCards.jsx";
import RedirectToApp from "components/Pages/_SharedPages/RedirectToApp/RedirectToApp";
import { UserContext } from "context/UserContext";
import throttle from "lodash.throttle";
import React, { useContext, useEffect, useCallback } from "react";
import { Switch, Route } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { setAttributes, tawkTo } from "utils/tawkto";

import LevelTest from "../components/_Shared/LevelTest/LevelTest";
import OnBoardingForm from "../components/_Shared/OnBoardingForm/OnBoardingForm";
import SignUpForm from "../components/_Shared/SignUpForm/SignUpForm";
import Auth from "../components/Auth/Auth";
import NotFound from "../components/NotFound/NotFound";
import CheckoutExternal from "../components/Pages/_SharedPages/CheckoutExternal/CheckoutExternal";
import { pushGTMEvent } from "../utils/GTM";
import AuthRoutes from "./AuthRoutes";
import ProtectedRoute from "./ProtectedRoute";

const Routes = () => {
  const history = useHistory();
  const { user } = useContext(UserContext);

  const refreshAd = () => {
    const insElement = document.getElementById("englishbay-google-adbar");

    if (!insElement) {
      return;
    }

    insElement.removeAttribute("data-adsbygoogle-status");
    insElement.removeAttribute("data-ad-status");
    insElement.innerHTML = "";
    window.adsbygoogle.push({});
  };

  const debouncedRefreshAd = useCallback(throttle(refreshAd, 35000));

  useEffect(() => {
    return history.listen(() => {
      const rawUser = localStorage.getItem("user");
      const user = rawUser ? JSON.parse(rawUser) : null;

      if (window.fbq) {
        window.fbq("track", "PageView");
      }

      if (!user?.uuid) {
        return;
      }
      // Seta o user no GTM
      pushGTMEvent({
        userId: user.uuid,
      });

      if (user.role === "LEAD" && window.adsbygoogle) {
        debouncedRefreshAd();
      }
    });
  }, []);

  useEffect(() => {
    if (!(user?.name && user?.email && user?.role)) {
      return;
    }

    if (!window.Tawk_API?.toggle) {
      tawkTo(user);
      return;
    }

    tawkTo(user);
    setAttributes(user);
  }, [user?.role]);

  return (
    <Switch>
      <Route exact path="/onboarding" component={OnBoardingForm} />
      <Route exact path="/onboarding/level" component={LevelTest} />
      <Route exact path="/onboarding/signup" component={SignUpForm} />

      <Route exact path="/checkout/:uuid/:token" component={CheckoutExternal} />
      <Route exact path="/redirectToApp" component={RedirectToApp} />

      <Route exact path="/materialCards/:initialCardPosition" component={MaterialCards} />
      <Route exact path="/materialCards" component={MaterialCards} />

      <AuthRoutes path="/auth/register" component={OnBoardingForm} />
      <AuthRoutes path="/auth/*" component={Auth} />

      <ProtectedRoute path="/" />

      <Route path="*" component={NotFound} />
    </Switch>
  );
};

export default Routes;
