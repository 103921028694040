import GuyBanner4 from "assets/imgs/guy-banner-4.png";
import { useIsStudent } from "hooks/useIsStudent";
import React from "react";
import { HiOutlineArrowNarrowRight } from "react-icons/hi";
import "./BannerTeacherSquare.css";
import { logAnalyticsEvent } from "utils/firebase-analytics";

const BannerTeacherSquare = () => {
  const { handleRedirect } = useIsStudent();

  return (
    <div
      className="banner__teacher__square"
      style={{ backgroundImage: `url(${GuyBanner4})` }}
      onClick={() => {
        logAnalyticsEvent("banner_click_tire_suas_duvidas");
        handleRedirect("whatsapp");
      }}>
      <div>
        <span>
          Tire suas <span>dúvidas</span> pelo WhatsApp
        </span>

        <button>
          Fale com um professor <HiOutlineArrowNarrowRight />
        </button>
      </div>
    </div>
  );
};

export default BannerTeacherSquare;
