/* eslint-disable no-unused-vars */
import Chat from "components/_Shared/Chatbot/components/Chat/Chat";
import ButtonFeedback from "components/MaterialCards/ButtonFeedback/ButtonFeedback";
import { useChatbot } from "context/ChatbotContext";
import { useMaterialCards } from "context/MaterialCardsContext";
import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";

import * as S from "./styles.module.css";

const initialMessages = [
  {
    type: "Caption",
  },
  {
    type: "QuickReplies",
    props: {
      message: "Eu sou a Bay, vamos praticar o que aprendemos no módulo?",
      replies: [
        {
          text: "Sim",
        },
        {
          text: "Não",
        },
      ],
    },
  },
];

const Conversation = ({ nextCard }) => {
  const { question } = useMaterialCards();
  const { handleCheckAnswer } = useChatbot();

  const [answers, setAnswers] = useState([]);
  const [selectedAnswer, setSelectedAnswer] = useState(0);
  const [isChecking, setIsChecking] = useState(false);
  const [isCorrect, setIsCorrect] = useState(false);
  const [showErrorFeedback, setShowErrorFeedback] = useState(false);

  const handleSubmit = () => {
    setShowErrorFeedback(true);

    setAnswers((prevAnswers) => {
      return prevAnswers.map((answer, index) => {
        if (index === selectedAnswer) {
          setIsCorrect(answer.isCorrect);

          handleCheckAnswer(answer.isCorrect);

          return {
            ...answer,
            variant: answer.isCorrect ? "correct" : "incorrect",
          };
        }

        return {
          ...answer,
        };
      });
    });
  };

  useEffect(() => {
    if (question === undefined) {
      return;
    }

    setAnswers(question.choices);
  }, [question]);

  const handleQuestionClick = (indexQuestion) => {
    // Previne que o usuário clique em outra questão enquanto a resposta está corrigida
    if (showErrorFeedback) {
      return;
    }

    setSelectedAnswer(indexQuestion);
    setIsChecking(true);

    setAnswers((prevAnswers) => {
      return prevAnswers.map((answer, index) => {
        if (index === indexQuestion) {
          return {
            ...answer,
            variant: "checking",
          };
        }

        return {
          ...answer,
          variant: null,
        };
      });
    });
  };

  const handleNextQuestion = () => {
    setIsChecking(false);
    setShowErrorFeedback(false);
    setIsCorrect(false);
    setSelectedAnswer(null);
    nextCard({ answer: answers[selectedAnswer].answer, isCorrect });
  };

  const showCorrectAnswer = () => {
    setAnswers((prevAnswers) => {
      return prevAnswers.map((answer) => {
        if (answer.isCorrect) {
          return { ...answer, variant: "correct" };
        }

        return { ...answer };
      });
    });
  };

  return (
    <div className={S.wrapper}>
      <Chat initialMessages={initialMessages} showChat={true} isGif={false} showHeaderAndFooter={false} />
    </div>
  );
};

Conversation.propTypes = {
  nextCard: PropTypes.func.isRequired,
};

export default Conversation;
