import { squareBanners } from "components/_Shared/Banners/Banner/Banner";
import { useModalBanner } from "context/ModalBannerContext";
import { UserContext } from "context/UserContext";
import { useEffect, useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import { getRandomItemsFromArray } from "utils/utils";

export const useIsStudent = () => {
  const { user } = useContext(UserContext);
  const history = useHistory();
  const { handleSetBanner } = useModalBanner();
  const [isActiveStudent, setIsActiveStudent] = useState(false);

  useEffect(() => {
    const isActiveStudent = user?.student?.status === "ACTIVE";

    setIsActiveStudent(isActiveStudent);
  }, [user]);

  const handleRedirect = (banner, redirect) => {
    if (!isActiveStudent) {
      handleSetBanner(banner);
      return;
    }

    if (redirect.startsWith("http")) {
      window.open(
        "https://api.whatsapp.com/send?phone=5511958565055&text=Gostaria%20de%20tirar%20duvida%20com%20professor",
        "_blank",
      );
      return;
    }

    history.push(redirect);
  };

  const getBannersList = (user, quantity = 1) => {
    if (!user) {
      return;
    }

    const bannersName = squareBanners.map((banner) => banner.name);

    if (user?.student?.status === "ACTIVE") {
      const index = bannersName.indexOf("premium");
      bannersName.splice(index, 1);
    }

    return getRandomItemsFromArray(quantity, bannersName);
  };

  return { isActiveStudent, handleRedirect, getBannersList };
};
