import propTypes from "prop-types";
import React, { useState, useEffect, useContext } from "react";
import { useTranslation } from "react-i18next";
import { Link, useHistory } from "react-router-dom";

import SidebarProfile from "../../_Shared/SidebarProfile/SidebarProfile";
import {
  LeftArrow,
  Facebook,
  Instagram,
  Youtube,
  Linkedin,
  Whatsapp,
  Community,
  LogoFooterMenuFlag,
  Chatting,
  TikTok,
  LogoutSidebar,
} from "../../../assets/svgs";
import { UserContext } from "../../../context/UserContext";

import "./Sidebar.css";

const Sidebar = ({ userSidebarLinks, isMenuVisible, setMenuVisible, setToggleMenu, toggleMenu, reference }) => {
  const { t } = useTranslation();
  const { logout } = useContext(UserContext);
  const [windowWidth, setWindowWidth] = useState(0);

  const {
    location: { pathname },
  } = useHistory();

  const handleTawkTo = () => {
    const config = window.Tawk_API;
    config.toggle();
  };

  useEffect(() => {
    window.addEventListener("resize", () => setWindowWidth(window.innerWidth));
  }, []);

  useEffect(() => {
    if (windowWidth === 0) {
      setWindowWidth(window.innerWidth);
      return;
    }

    if (windowWidth < 1024) {
      setMenuVisible(false);
      return;
    }

    setMenuVisible(true);
  }, [windowWidth]);

  return (
    <nav
      className={`sidebar ${!isMenuVisible && "sidebar--hidden"} ${toggleMenu && "toggle-menu"}`}
      ref={reference}
      style={{ zIndex: "999" }}>
      <div>
        <button className="button--toggle-menu" onClick={() => setToggleMenu(!toggleMenu)}>
          <LeftArrow />
        </button>

        <SidebarProfile toggleMenu={toggleMenu} />

        <section className="container-links">
          {userSidebarLinks.map(({ name, path, svg }) => (
            <Link to={path} key={name} className={`sidebar__link ${path === pathname ? "sidebar__link--active" : ""}`}>
              {svg}
              <span className={`${toggleMenu ? "hidden-text" : "fade-in"}`}>{t(name)}</span>
              {toggleMenu && <div className="link__tooltip">{t(name)}</div>}
            </Link>
          ))}

          <button className="sidebar__link" onClick={handleTawkTo}>
            <Chatting />
            <span className={`${toggleMenu ? "hidden-text" : "fade-in"}`}>Suporte</span>
            {toggleMenu && <div className="link__tooltip">Suporte</div>}
          </button>

          <button className="sidebar__link">
            <Community width={32} height={32} />
            <a href="http://comunidade.englishbay.com.br/" className={`${toggleMenu ? "hidden-text" : "fade-in"}`}>
              Comunidade
            </a>
            {toggleMenu && <div className="link__tooltip">Comunidade</div>}
          </button>

          {windowWidth < 1024 && (
            <button className="sidebar__link" onClick={logout}>
              <div style={{ width: "32px", height: "32px", display: "flex", justifyContent: "center" }}>
                <LogoutSidebar />
              </div>
              <span className={`${toggleMenu ? "hidden-text" : "fade-in"}`}>Logout</span>
            </button>
          )}
        </section>
      </div>

      <div className="flex flex-col gap-5 items-center mt-1">
        {toggleMenu && <LogoFooterMenuFlag />}

        {!toggleMenu && (
          <section className={`${toggleMenu ? "" : "fade-in"} medias__links`}>
            <a href="https://facebook.com/SchoolEnglishBay" target="_blank" rel="noreferrer">
              <Facebook />
            </a>

            <a href="https://www.tiktok.com/@english.bay" target="_blank" rel="noreferrer">
              <TikTok />
            </a>

            <a href="https://www.instagram.com/english.bay" target="_blank" rel="noreferrer">
              <Instagram />
            </a>

            <a href="https://youtube.com/englishbay" target="_blank" rel="noreferrer">
              <Youtube />
            </a>

            <a href="https://www.linkedin.com/company/englishbay" target="_blank" rel="noreferrer">
              <Linkedin />
            </a>

            <a href="https://api.whatsapp.com/send?phone=5511958565055" target="_blank" rel="noreferrer">
              <Whatsapp />
            </a>
          </section>
        )}
      </div>
    </nav>
  );
};

export default Sidebar;

Sidebar.propTypes = {
  userSidebarLinks: propTypes.array,
  isMenuVisible: propTypes.bool,
  setMenuVisible: propTypes.func,
  toggleMenu: propTypes.bool,
  setToggleMenu: propTypes.func,
}.isRequired;
