import GirlBanner4 from "assets/imgs/girl-banner-4.png";
import { useIsStudent } from "hooks/useIsStudent";
import React from "react";
import { HiOutlineArrowNarrowRight } from "react-icons/hi";
import "./BannerPrivateClassesSquare.css";
import { logAnalyticsEvent } from "utils/firebase-analytics";

const BannerPrivateClassesSquare = () => {
  const { handleRedirect } = useIsStudent();

  return (
    <div
      className="banner__private__classes__square"
      style={{ backgroundImage: `url(${GirlBanner4})` }}
      onClick={() => {
        logAnalyticsEvent("banner_click_aulas_particulares");
        handleRedirect("privateClasses", "/eventos");
      }}>
      <div>
        <p>
          Faça aulas <span>particulares</span> online
        </p>

        <button>
          Ver professores <HiOutlineArrowNarrowRight />
        </button>
      </div>
    </div>
  );
};

export default BannerPrivateClassesSquare;
