import React from "react";

import { MasterCard, Visa } from "../assets/svgs/index";

export const getCompanySvg = (paymentCompanyId) => {
  const prod = { 1: <MasterCard />, 2: <Visa /> };
  const dev = { 12: <MasterCard />, 13: <Visa /> };

  if (process.env.REACT_APP_NODE_ENV === "prod") {
    return prod[paymentCompanyId] ?? <MasterCard />;
  }

  return dev[paymentCompanyId] ?? <MasterCard />;
};
