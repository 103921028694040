import PropTypes from "prop-types";
import React from "react";
import { useHistory } from "react-router-dom";

import BannerLiveClassMobileImage from "../../../../../assets/imgs/banners/banner_liveClass_mobile.png";
import BannerLiveClassDesktopImage from "../../../../../assets/imgs/banners/banner_liveClass.png";
import { ChatFavourite } from "../../../../../assets/svgs";
import ModalBanner from "../../ModalBanner";

import "./ModalBannerLiveClasses.module.css";

const ModalBannerCertificates = ({ handleHideBanner }) => {
  const history = useHistory();

  const handleClick = () => {
    handleHideBanner();
    history.push("/planos");
  };

  return (
    <ModalBanner imageDesktop={BannerLiveClassDesktopImage} imageMobile={BannerLiveClassMobileImage}>
      <div className="modal-banner-certificate">
        <div className="modal-banner-certificate-text-wrapper">
          <ChatFavourite />

          <p className="modal-banner-certificate-title">
            Participe de <span>aulas ao vivo</span>
          </p>
          <p className="modal-banner-certificate-description">
            Por apenas R$49,90 ao mês, você pode participar das nossas aulas ao vivo às{" "}
            <span style={{ fontWeight: "bold" }}> terças e quintas, às 19:00</span>.
          </p>
        </div>

        <div className="modal-banner-certificate-button-wrapper">
          <button onClick={handleClick} className="modal-banner-certificate-button-primary">
            Assine agora
          </button>
        </div>
      </div>
    </ModalBanner>
  );
};

ModalBannerCertificates.propTypes = {
  handleHideBanner: PropTypes.func.isRequired,
};

export default ModalBannerCertificates;
