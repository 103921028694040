import propTypes from "prop-types";
import React, { useContext } from "react";
import { Link } from "react-router-dom";

import { UserContext } from "../../../context/UserContext";
import { getLevelIcon } from "../../../utils/level";
import { getFirstName } from "../../../utils/utils";

import "./SidebarProfile.css";

function SidebarProfile({ toggleMenu }) {
  const { user } = useContext(UserContext);

  const isMobile = window.innerWidth < 1024;
  const isTeacher = user?.role === "TEACHER";

  return (
    <div className="sidebar-profile">
      {!isMobile && (
        <Link to="/perfil/informacoes" className="profile__image">
          <div className={`${toggleMenu ? "profile__border--small" : "profile__border"}`}></div>
          <img
            className={`${toggleMenu ? "profile__picture--small" : "profile__picture"}`}
            src={user?.profilePictureUrl ?? process.env.REACT_APP_PROFILE_AVATAR_URL}
            alt="profile"
            onError={({ currentTarget }) => {
              currentTarget.src = process.env.REACT_APP_PROFILE_AVATAR_URL;
            }}
          />
        </Link>
      )}

      {isMobile && (
        <div className="flex gap-3 justify-start w-full items-center">
          <Link to="/" className="profile__image">
            <div className="profile__border--small"></div>
            <img
              className="profile__picture--small"
              src={user?.profilePictureUrl ?? process.env.REACT_APP_PROFILE_AVATAR_URL}
              alt="profile"
            />
          </Link>

          <div className="flex flex-col justify-start">
            <span className="profile__username">{getFirstName(user?.name)}</span>
            <Link to="/perfil/informacoes" className="profile__see-profile">
              Ver perfil
            </Link>
          </div>
        </div>
      )}

      {!toggleMenu && !isTeacher && (
        <div className={`sidebar__level ${isMobile && "w-full"} ${!toggleMenu && "fade-in-level"}`}>
          <div className="sidebar__level__icon">{getLevelIcon(user?.levelTest)}</div>
          <span>
            <span>{user?.levelTest}</span>
          </span>
        </div>
      )}
    </div>
  );
}

export default SidebarProfile;

SidebarProfile.propTypes = {
  toggleMenu: propTypes.bool,
};
