import PropTypes from "prop-types";
import React from "react";
import { useHistory } from "react-router-dom";

import BannerWhatsappImageMobile from "../../../../../assets/imgs/banners/banner_premium_mobile.png";
import BannerWhatsappImage from "../../../../../assets/imgs/banners/banner_premium.png";
import { StarBanner } from "../../../../../assets/svgs";
import ModalBanner from "../../ModalBanner";

import "./ModalBannerPremium.css";

const ModalBannerPremium = ({ handleHideBanner }) => {
  const history = useHistory();

  const handleRedirect = () => {
    handleHideBanner();
    history.push("/planos");
  };

  return (
    <ModalBanner imageDesktop={BannerWhatsappImage} imageMobile={BannerWhatsappImageMobile}>
      <div className="modal-banner-premium">
        <div className="modal-banner-premium-text-wrapper">
          <p className="modal-banner-premium-title">Seja premium!</p>

          <p className="modal-banner-premium-description">Por apenas R$49,87 ao mês, você recebe:</p>

          <div className="flex items-center gap-4 mt-2">
            <StarBanner />
            <p>Grupo de conversação de segunda à sexta</p>
          </div>

          <div className="flex items-center gap-4 mt-2">
            <StarBanner />
            <p>Aulas ao vivo às terças e quintas</p>
          </div>

          <div className="flex items-center gap-4 mt-2">
            <StarBanner />
            <p>Baixe seus certificados</p>
          </div>

          <div className="flex items-center gap-4 mt-2">
            <StarBanner />
            <p>Correções dos exercícios</p>
          </div>
        </div>

        <div className="modal-banner-premium-button-wrapper">
          <button onClick={handleRedirect} className="modal-banner-premium-button-primary">
            Assine agora
          </button>

          <button onClick={handleHideBanner} className="w-full mt-2">
            Talvez mais tarde
          </button>
        </div>
      </div>
    </ModalBanner>
  );
};

ModalBannerPremium.propTypes = {
  handleHideBanner: PropTypes.func.isRequired,
};

export default ModalBannerPremium;
