import PropTypes from "prop-types";
import { useState, useEffect } from "react";
import React from "react";
import { FaPlus, FaMinus } from "react-icons/fa";

export const Accordion = ({ title, text }) => {
  const [accordionOpen, setAccordionOpen] = useState(false);

  useEffect(() => {
    setAccordionOpen(false);
  }, []);

  return (
    <div className="my-20 w-full">
      <button
        className={" flex items-center gap-10 w-full text-left font-semibold py-2 bg-[#F0F0F0] shadow-sm "}
        onClick={(e) => {
          e.preventDefault();
          setAccordionOpen(!accordionOpen);
        }}>
        {accordionOpen ? <FaMinus color="#623CEA" /> : <FaPlus color="#623CEA" />}
        <div className="flex gap-4">
          <span className="text-[24px] mb-2">{title}</span>
        </div>
      </button>

      <div className={`px-6 pt-0  ${accordionOpen ? "block" : " hidden"}`}>
        <div className="overflow-hidden flex flex-wrap gap-3 bg-[#F0F0F0] shadow-sm p-4">
          <p className="pb-4 text-left">{text}</p>
        </div>
      </div>
    </div>
  );
};

Accordion.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
};

export default Accordion;
