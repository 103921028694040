import PropTypes from "prop-types";
import React, { useState } from "react";

import "./ToggleSwitch.css";

const ToggleSwitch = ({ value, initialValue }) => {
  const [checked, setChecked] = useState(initialValue ?? false);

  return (
    <>
      <button
        className="relative inline-block w-10 align-middle select-none transition duration-200 ease-in"
        onClick={() => setChecked(!checked)}>
        <input
          type="checkbox"
          name="toggle"
          id="toggle"
          value={value}
          checked={initialValue}
          className="toggle-checkbox absolute block w-6 h-6 rounded-full bg-white border-4 appearance-none cursor-pointer"
        />
        <div className="toggle-label block overflow-hidden h-6 rounded-full bg-gray-300 cursor-pointer"></div>
      </button>
    </>
  );
};

ToggleSwitch.propTypes = {
  value: PropTypes.func,
  initialValue: PropTypes.bool,
};

export default ToggleSwitch;
