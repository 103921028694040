import PropTypes from "prop-types";
import React from "react";

import "./TextArea.css";

const TextArea = ({ error, name, value, onChange, label, onBlur, ...props }) => {
  return (
    <>
      <textarea
        id={name}
        className={`custom-textarea w-full border rounded-lg border-gray-200 rounded-sm ${
          error && "border-red-300 focus:border-red-300"
        }`}
        name={name}
        value={value}
        onChange={({ target }) => {
          onChange(target.value);
        }}
        onBlur={onBlur}
        {...props}
      />
      <label className="inputLabel">{label}</label>
    </>
  );
};

TextArea.propTypes = {
  className: PropTypes.string,
  value: PropTypes.string,
  error: PropTypes.string,
  onBlur: PropTypes.func,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default TextArea;
