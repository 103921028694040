import bannerLiveClassesRectImage from "assets/imgs/bannerLiveClasses.png";
import { useIsStudent } from "hooks/useIsStudent";
import React from "react";
import { HiOutlineArrowNarrowRight } from "react-icons/hi";
import { logAnalyticsEvent } from "utils/firebase-analytics";

import * as S from "./BannerLiveClassesRect.module.css";

const BannerLiveClassesRect = () => {
  const { handleRedirect } = useIsStudent();

  return (
    <div
      onClick={() => {
        logAnalyticsEvent("banner_click_liveclass");
        handleRedirect("liveClasses", "/eventos");
      }}
      className={S.wrapperRect}
      style={{ backgroundImage: `url(${bannerLiveClassesRectImage})` }}>
      <div className={S.textWrapper}>
        <p className={S.title}>Aulas ao vivo</p>
        <p className={S.title}>Segunda a sexta</p>

        <button className={S.button}>
          Quero participar
          <HiOutlineArrowNarrowRight />
        </button>
      </div>
    </div>
  );
};

export default BannerLiveClassesRect;
