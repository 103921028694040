import React, { useEffect, useContext, useState } from "react";

import { UserContext } from "../../../../context/UserContext";
import useRequest from "../../../../hooks/useRequest";

const TermsOfUse = () => {
  const { user } = useContext(UserContext);
  const { request } = useRequest();

  const [terms, setTerms] = useState("");

  useEffect(() => {
    if (!user?.role) {
      return;
    }

    const fetchTerms = async () => {
      const userType = user.role === "TEACHER" ? "TEACHER" : "USER";

      const { text } = await request("GET", `/terms/read?kind=TERM&userType=${userType}`, null, false, false);

      if (text) {
        setTerms(text);
      }
    };

    fetchTerms();
  }, [user?.role]);

  return (
    <>
      <h2 className="text-3xl font-medium m-5">Termos de Uso</h2>

      <div className="p-5">
        <iframe className="w-full h-[70vh]" title="Terms" srcDoc={terms}></iframe>
      </div>
    </>
  );
};

export default TermsOfUse;
