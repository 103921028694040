import Button from "components/MaterialCards/Button/Button";
import CardHeaderTransparent from "components/MaterialCards/CardHeaderTransparent/CardHeaderTransparent";
import ProgressBar from "components/MaterialCards/ProgressBar/ProgressBar";
import { useMaterialCards } from "context/MaterialCardsContext";
import { useModalBanner } from "context/ModalBannerContext";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import YouTube from "react-youtube";

import * as S from "./styles.module.css";

function youTubeGetID(url) {
  url = url.split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/);
  return url[2] !== undefined ? url[2].split(/[^0-9a-z_-]/i)[0] : url[0];
}

const PROGRESS_INTERVAL = 1000;
const PROGRESS_STEP = 10;
const PROGRESS_TOTAL = 100;

const ShortsVideo = ({ nextCard }) => {
  const { setShowBanner } = useModalBanner();
  const { question } = useMaterialCards();

  const [progress, setProgress] = useState(0);
  const [videoId, setVideoId] = useState();
  const [stop, setStop] = useState(false);

  const [showNextQuestion, setShowNextQuestion] = useState(true);

  const opts = {
    playerVars: {
      // https://developers.google.com/youtube/player_parameters?hl=pt-br
      // autoplay: 1,
      disablekb: 1,
      controls: 0,
      fs: 0,
      iv_load_policy: 3,
      modestbranding: 1,
      loop: 1,
      playlist: videoId,
      rel: 0,
      autoplay: question?.isAd ? 1 : 0,
    },
  };

  const onPlayerReady = (event) => {
    // access to player in all event handlers via event.target
    event.target.setVolume(50);
  };

  const playVideo = () => {
    if (stop) {
      setStop(false);
      setProgress((prev) => prev);
      return;
    }

    setProgress(1);
  };

  const handleClick = async () => {
    if (question?.isAd) {
      setShowBanner("Premium");
    }

    setProgress(0);
    await nextCard({ answer: "_shorts", isCorrect: true });
    setShowNextQuestion(false);
  };

  useEffect(() => {
    const interval = setInterval(
      () => {
        if (progress === 0) {
          return;
        }

        if (progress === 100) {
          setShowNextQuestion(true);
          return;
        }

        // Se stop for true pare a contagem
        if (stop) {
          setProgress((progress) => progress);
          return;
        }

        // Se o progresso for maior que 100, sete o progresso para 100 para não estourar o width da barra
        if (progress + PROGRESS_STEP > PROGRESS_TOTAL) {
          setProgress(100);
          return;
        }

        setProgress((progress) => progress + PROGRESS_STEP);
      },

      PROGRESS_INTERVAL,
    );

    return () => clearInterval(interval);
  }, [progress, stop]);

  useEffect(() => {
    if (question?.url) {
      setVideoId(youTubeGetID(question.url));
    }

    if (question?.isAd) {
      setShowNextQuestion(false);
      return;
    }

    setShowNextQuestion(true);
  }, [question?.url, question?.isAd]);

  return (
    <div style={{ position: "relative" }}>
      <div className={S.cardWrapper}>
        <CardHeaderTransparent />
      </div>

      <YouTube
        onReady={onPlayerReady}
        onPlay={playVideo}
        onPause={() => setStop(true)}
        className={S.wrapper}
        iframeClassName={S.iframe}
        videoId={videoId}
        opts={opts}
      />

      <div className={S.cardFooter}>
        {question?.title && <h1>{question?.title}</h1>}

        {!showNextQuestion && <ProgressBar width={progress} />}

        {showNextQuestion && <Button onClick={handleClick} type="callToAction" text="Continuar" variant="checking" />}
      </div>
    </div>
  );
};

ShortsVideo.propTypes = {
  nextCard: PropTypes.func.isRequired,
};

export default ShortsVideo;
