/* eslint-disable */
import React, { useEffect, useContext, useState } from "react";
import Turnstile from "react-turnstile";

import Button from "../../../_Shared/Button/Button";
import { InputTwo } from "../../../_Shared/Form";
import Input from "../../../_Shared/Input/Input";
import Select from "../../../_Shared/Select/Select";
import CreditCardPaymentSuccess from "../../../_Shared/Slide/PaymentsCards/CreditCardPaymentSuccess/CreditCardPaymentSuccess";
import Warning from "../../../_Shared/Warning/Warning";
import { SpinnerWhite } from "../../../../assets/svgs";
import { SlideContext } from "../../../../context/SlideContext";
import { ToastContext } from "../../../../context/ToastContext";
import { UserContext } from "../../../../context/UserContext";
import useForm from "../../../../hooks/useForm";
import useRequest from "../../../../hooks/useRequest";
import { pushGTMEvent } from "../../../../utils/GTM";
import { useHistory } from "react-router-dom";
import { BiChevronLeft } from "react-icons/bi";

import "react-credit-cards/es/styles-compiled.css";
import "../Checkout/Checkout.css";

import { useParams } from "react-router-dom/cjs/react-router-dom.min";

const getInstallments = (planValue, maxInstallments) => {
  let installments = [];
  for (let i = 1; i <= Math.min(planValue, maxInstallments); i++) {
    let installmentValue = (planValue / i).toFixed(2);
    installments.push(i + "x de R$ " + installmentValue + " sem juros");
  }
  return installments;
};

function CheckoutExternal() {
  const { request } = useRequest();
  const { user, setCaptchaToken } = useContext(UserContext);
  const history = useHistory();

  const { showToast } = useContext(ToastContext);
  const [cData, setCData] = useState(Date.now().toString());
  const { setSlideComponent, setShowSlide } = useContext(SlideContext);

  const [isLoading, setIsLoading] = useState(false);
  const [installmentsOptions, setInstallmentsOptions] = useState();
  const [installments, setInstallments] = useState(1);
  const [plan, setPlan] = useState({});
  const [couponFormValue, setCouponFormValue] = useState("");
  const [isCouponLoading, setIsCouponLoading] = useState(false);
  const [showReturnButton, setShowReturnButton] = useState(false);
  const [discount, setDiscount] = useState(0);
  const [coupon, setCoupon] = useState(null);
  const [price, setPrice] = useState(0);
  const [response, setResponse] = useState({});

  const cpfForm = useForm("cpf");
  const cardNumberForm = useForm("cardNumber");
  const cardholderNameForm = useForm();
  const cardExpirationForm = useForm();
  const cardCVVForm = useForm();

  const { uuid, token } = useParams();

  const fetchPlans = async (planUuid) => await request("GET", "/plan/read/" + planUuid);

  const handleCoupon = async (event) => {
    event.preventDefault();

    setIsCouponLoading(true);

    const cuponReq = {
      code: couponFormValue,
    };

    const response = await request("POST", "/coupon/check", cuponReq, true, false);

    if (response.error) {
      handleInvalidCoupon();
      return;
    }

    setCoupon(response);
    setIsCouponLoading(false);
  };

  const fetchPlan = async () => {
    const response = await fetchPlans(uuid);

    if (!response.error) {
      setPlan(response);
      return;
    }
  };

  const toFixed = (value, precision) => {
    const regex = /\./gi;

    if (!regex.test(value)) {
      return value.toFixed(2);
    }

    var decimalPlaces = Math.pow(10, precision);
    return Math.floor(value * decimalPlaces) / decimalPlaces;
  };

  const saveGTMEvent = (purchase) => {
    pushGTMEvent({
      event: "purchase",
      ecommerce: {
        purchase: {
          actionField: {
            id: purchase?.billId, // Transaction ID. Required for purchases and refunds. (Vindi Bill Id)
            affiliation: "EnglishBay",
            revenue: purchase?.bill?.amount, // Total transaction value (incl. tax and shipping)
            tax: null,
            shipping: null,
            coupon: purchase?.coupon?.code ? purchase?.coupon.code : null, //Retorna o cupom caso for utilizado na compra
          },
          products: [
            {
              // List of productFieldObjects.
              name: purchase?.plan?.name, // Name or ID is required. Nome da assinatura
              id: purchase?.plan?.uuid,
              price: purchase?.plan?.price,
              brand: null,
              category: purchase?.plan?.lessonType,
              variant: "null",
              quantity: 1,
              coupon: purchase?.coupon?.code ? purchase?.coupon.code : null,
            },
          ],
        },
      },
    });
  };

  const handleInvalidCoupon = () => {
    showToast({ type: "error", message: "Cupom inválido" });
    setPrice(plan.price);
    setDiscount(0);
    setCouponFormValue("");
    setIsCouponLoading(false);
  };

  const handleCreditCardCheckout = async () => {
    const isValidForm =
      cpfForm.validate() &&
      cardholderNameForm.validate() &&
      cardExpirationForm.validate() &&
      cardNumberForm.validate() &&
      cardCVVForm.validate();

    const paymentRequest = {
      token,
      cpf: cpfForm.value,
      planUuid: uuid,
      paymentMethodCode: "credit_card",
      card: {
        holderName: cardholderNameForm.value,
        cardExpiration: cardExpirationForm.value,
        cardNumber: cardNumberForm.value.split(" ").join(""),
        cardCVV: cardCVVForm.value,
      },
      installments,
    };

    if (coupon) {
      paymentRequest.couponCode = coupon.code;
    }

    if (isValidForm) {
      setIsLoading(true);

      const response = await request("POST", `/subscription/externalCheckout`, paymentRequest, true, false, true);

      setCData(Date.now().toString());

      setResponse(response);

      if (!response.error) {
        saveGTMEvent(response);
        setIsLoading(false);
        setShowReturnButton(true);

        return;
      }

      setIsLoading(false);
      if (response.error?.statusCode !== 201) {
        if (response.error?.message === "INVALID_CPF") {
          return showToast({ message: "CPF Inválido!", type: "error" });
        }

        if (response.error?.message === "CARD_REJECTED") {
          setSlideComponent(<PaymentUnsuccessful />);
          setShowSlide(true);
          return;
        }

        if (response.error?.statusCode === 500) {
          return showToast({ message: "Erro interno ao processar compra!", type: "error" });
        }

        return showToast({ message: "Preencha todos os dados corretamente!", type: "error" });
      }
    }
  };

  useEffect(() => {
    if (coupon?.discountPercentage) {
      setDiscount(toFixed(plan.price * (coupon.discountPercentage / 100), 2));
    }
  }, [coupon]);

  useEffect(() => {
    if (discount > 0) {
      setPrice(toFixed(plan.price - discount, 2));
    }
  }, [discount]);

  useEffect(() => {
    fetchPlan();
  }, []);

  useEffect(() => {
    if (plan.price) {
      if (discount > 0) {
        setPrice(toFixed(plan.price - discount, 2));
        return;
      }

      setInstallmentsOptions(getInstallments(plan.price, 12));
      setPrice(plan.price);
    }
  }, [plan]);

  useEffect(() => {
    const preventGestureStart = (event) => {
      event.preventDefault();
    };

    document.addEventListener("gesturestart", preventGestureStart);

    return () => {
      document.removeEventListener("gesturestart", preventGestureStart);
    };
  }, []);

  if (showReturnButton) {
    return (
      <section className="checkout__section">
        <CreditCardPaymentSuccess redirectUrl={"/redirectToApp"} response={response} discount={discount} />
      </section>
    );
  }

  return (
    <section className="checkout__section my-4">
      <div
        className="flex items-center cursor-pointer border border-black rounded-full mb-4 px-2"
        style={{ maxWidth: "80px" }}
        onClick={() => history.push("/redirectToApp")}>
        <BiChevronLeft size={16} />
        <span>Voltar</span>
      </div>

      <form className="flex flex-col gap-2">
        <p className="section__title">Contratar plano</p>

        <p>Preencha suas informações para concluir sua mátricula </p>

        <InputTwo
          mask="9999 9999 9999 9999"
          label="Número do cartão"
          placeholder="Insira o numero"
          name="number"
          {...cardNumberForm}
        />

        <div className="flex gap-4 justify-between">
          <div style={{ width: "50%" }}>
            <InputTwo
              mask="99/99"
              placeholder="Insira a validade"
              label="Validade"
              name="expiry"
              {...cardExpirationForm}
            />
          </div>

          <div style={{ width: "50%" }}>
            <InputTwo mask="999" placeholder="Insira o código" label="cvv" name="cvc" {...cardCVVForm} />
          </div>
        </div>

        <InputTwo
          placeholder="Digite o nome do responsável"
          label="Nome impresso no cartão"
          type="text"
          name="name"
          {...cardholderNameForm}
        />

        <InputTwo
          mask="999.999.999-99"
          placeholder="Digite o CPF do responsável"
          label="Digite seu CPF"
          name="cpf"
          {...cpfForm}
        />

        {plan?.intervalCount === 12 && (
          <Select
            label="Parcelas"
            options={installmentsOptions}
            onChange={({ target }) => setInstallments(target.value.split("x")[0])}
          />
        )}

        <div className="discount__container">
          <span className="font-bold mb-2 block">Cupom de desconto</span>

          <form className="flex gap-4 coupon-form" onSubmit={handleCoupon}>
            <Input
              width="70%"
              bg="#F9F9F9"
              border="1px solid #E3E6E8"
              value={couponFormValue}
              required
              onChange={({ target }) => setCouponFormValue(target.value)}
            />

            <Button bg="#733EB1" color="#FFF" width="30%">
              {isCouponLoading ? <SpinnerWhite className="w-7 mx-auto animate-spin" /> : "Aplicar"}
            </Button>
          </form>
        </div>

        <div>
          <Turnstile
            className="mb-3"
            sitekey={process.env.REACT_APP_TURNSTILE_SITE_KEY}
            theme="light"
            onVerify={setCaptchaToken}
            cData={cData}
          />
        </div>
      </form>

      <div className="price__container">
        {discount > 0 && (
          <>
            <div>
              Plano
              <span>R$ {plan.price}</span>
            </div>

            <div>
              Desconto
              <span>R$ {discount}</span>
            </div>
          </>
        )}
        <div>
          Total
          <span>R$ {price}</span>
        </div>
      </div>

      <p className="text-center">
        Confirmando a compra, você aceita os
        <br />
        <a href="#" className="text-purple-500 underline">
          Termos de uso
        </a>{" "}
        e a{" "}
        <a href="#" className="text-purple-500 underline">
          Política de privacidade
        </a>
      </p>

      <Button bg="#733EB1" color="#FFF" className="w-full my-4" onClick={handleCreditCardCheckout} disabled={isLoading}>
        {isLoading ? <SpinnerWhite className="w-7 mx-auto animate-spin" /> : "Finalizar compra"}
      </Button>
    </section>
  );
}

export default CheckoutExternal;
