import axios from "axios";
import React, { useRef, useContext, useState } from "react";

import { Profile, Image } from "../../../../../assets/svgs/index";
import { ToastContext } from "../../../../../context/ToastContext";
import { UserContext } from "../../../../../context/UserContext";
import useRequest from "../../../../../hooks/useRequest";
import InformationTeacher from "./InformationTeacher";
import InformationUser from "./InformationUser";

const Informations = () => {
  const { showToast } = useContext(ToastContext);
  const { setUserContext, user } = useContext(UserContext);
  const [loading, setLoading] = useState(false);
  const { request } = useRequest();
  const imageUpload = useRef();
  const bannerUpload = useRef();

  const getUserInfos = async () => request("GET", "/user/read");

  const onButtonClick = (ref) => ref.current.click();

  const handlePhotoUpload = async ({ target }) => {
    setLoading(true);
    const formDate = new FormData();
    formDate.append("file", target.files[0]);

    const response = await axios.post(process.env.REACT_APP_UPLOAD_URL, formDate);
    const userReq = {
      profilePictureFilename: response.data[0].fileName,
    };

    const responsePatch = await request("PATCH", "/user", userReq);

    if (!responsePatch.error) {
      showToast({ type: "success", message: "Sua foto foi alterada com sucesso!!" });
      const user = await getUserInfos();
      setUserContext(user);
    }
    setLoading(false);
  };

  const handleBannerUpload = async ({ target }) => {
    setLoading(true);
    const formDate = new FormData();
    formDate.append("file", target.files[0]);

    const response = await axios.post(process.env.REACT_APP_UPLOAD_URL, formDate);
    const userReq = {
      bannerFilename: response.data[0].fileName,
    };

    const responsePatch = await request("PATCH", "/user", userReq);

    if (!responsePatch.error) {
      showToast({ type: "success", message: "Seu banner foi alterado com sucesso!!" });
      const user = await getUserInfos();
      setUserContext(user);
    }
    setLoading(false);
  };

  return (
    <div>
      {user && (
        <>
          <h1 className="font-medium text-lg">Configurações do perfil</h1>
          <p className="font-medium text-sm text-gray-500 mb-2">
            Você pode alterar as inforamações básicas da sua conta
          </p>
          <div className="grid grid-cols-3 sm:grid-cols-7 gap-5">
            <div className="col-span-3 text-center rounded-2xl shadow-sm bg-white">
              <div>
                <img
                  style={{ height: "81px", borderTopLeftRadius: "1rem", borderTopRightRadius: "1rem" }}
                  src={user && user.bannerUrl}
                  alt="banner"
                  className="w-full"
                />
                <div className="flex flex-1 justify-center">
                  <img
                    style={{ width: "96px", height: "96px" }}
                    onError={({ currentTarget }) => {
                      currentTarget.src = process.env.REACT_APP_PROFILE_AVATAR_URL;
                    }}
                    src={user?.profilePictureUrl ?? process.env.REACT_APP_PROFILE_AVATAR_URL}
                    alt="professora"
                    className="-mt-10 rounded-full border"
                  />
                </div>
                <div className="p-3">
                  <p>{user && user.name}</p>
                  <p className="text-sm">{user && user.email}</p>
                </div>
              </div>
            </div>
            <button
              onClick={() => onButtonClick(imageUpload)}
              className="sm:col-span-2 col-span-3 bg-white rounded-2xl shadow-sm p-4 text-center shadow-sm hover:shadow">
              <div>
                <Profile className="w-10 mx-auto mb-4 fill-current text-gray-400" />
                <p>Alterar imagem de perfil</p>
                <input
                  style={{ display: "none" }}
                  id="select-file-photo"
                  ref={imageUpload}
                  onChange={(event) => handlePhotoUpload(event)}
                  type="file"
                />
                <p className="text-xs">
                  Ao fazer o upload da sua foto está ciente que dá seu consentimento para a englishbay tratar essa
                  informação para personalizar o perfil do Usuário, com base no art 11, I, da LGPD
                </p>
              </div>
            </button>
            <button
              onClick={() => onButtonClick(bannerUpload)}
              className="sm:col-span-2 col-span-3 bg-white rounded-2xl shadow-sm p-4 text-center hover:shadow">
              <div>
                <Image className="w-9 mx-auto mt-1 mb-5 fill-current text-gray-400" />
                <p>Alterar imagem de capa</p>
                <input
                  style={{ display: "none" }}
                  id="select-file-banner"
                  ref={bannerUpload}
                  onChange={(event) => handleBannerUpload(event)}
                  type="file"
                />
                <p className="text-xs">
                  Ao fazer o upload da sua foto está ciente que dá seu consentimento para a englishbay tratar essa
                  informação para personalizar o perfil do Usuário, com base no art 11, I, da LGPD
                </p>
              </div>
            </button>
          </div>
          {user.role === "TEACHER" && <InformationTeacher loading={loading} setLoading={setLoading} />}
          {user.role !== "TEACHER" && <InformationUser loading={loading} setLoading={setLoading} />}
        </>
      )}
    </div>
  );
};

export default Informations;
