import { format, formatDistance, differenceInHours, add, sub, startOfWeek, differenceInMinutes } from "date-fns";
import { ptBR } from "date-fns/locale";

const lessonTime = {
  TWENTY_FIVE: 25, // 25 minutos
  FIFTY: 50, // 50 minutos
};

const getLessonTime = (type) => {
  return lessonTime[type];
};

export const getFormat = (date, options) => format(date, options);

export const formatIso = (date) => format(date, "yyyy-MM-dd'T'HH:mm");

export const getDay = (utc) => format(new Date(utc), "dd", { locale: ptBR });

export const getDayOfWeek = (utc) => format(new Date(utc), "EEEE", { locale: ptBR });

export const getDayOfWeekNumber = (utc) => format(new Date(utc), "i", { locale: ptBR });

export const getNameOfDay = (utc) => format(new Date(utc), "E", { locale: ptBR });

export const getMonthName = (utc) => format(new Date(utc), "MMM", { locale: ptBR });

export const getYear = (utc) => format(new Date(utc), "yyyy", { locale: ptBR });

export const getMonthYear = (utc) => format(new Date(utc), "MMMM'/'yyyy", { locale: ptBR });

export const getDayMonthYear = (utc) => format(new Date(utc), "dd'/'MM'/'yyyy", { locale: ptBR });

export const getYearMonthDay = (utc) => format(new Date(utc), "yyyy'-'MM'-'dd", { locale: ptBR });

export const getDayMonth = (utc) => format(new Date(utc), "dd'/'MMMM'", { locale: ptBR });

export const getDayMonthName = (utc) => format(new Date(utc), "dd' de 'MMMM'", { locale: ptBR });

export const getDayOfTheWeek = (utc) => format(new Date(utc), "EEE", { locale: ptBR });

export const getDayOfTheWeekDayMonthYear = (utc) => format(new Date(utc), "EEE', 'd', 'MMM', 'yyyy", { locale: ptBR });

export const getDayOfTheWeekCompletedDayMonthYear = (utc) =>
  format(new Date(utc), "EEEE', 'd', 'MMM', 'yyyy", { locale: ptBR });

export const getDayOfTheWeekDayMonth = (utc) => format(new Date(utc), "EEEE', 'd', 'MMMM', 'yyyy", { locale: ptBR });

export const getTimeBetweemToday = (utc) => formatDistance(new Date(), new Date(utc), { locale: ptBR });

export const getHourDifference = (utc) => differenceInHours(new Date(utc), new Date());

export const getMinuteDifference = (utc) => differenceInMinutes(new Date(utc), new Date());

export const getHoursAndMinutes = (utc) => format(new Date(utc), "HH':'mm", { locale: ptBR });

// TODO mudar o nome da função para algo mais descritivo como: addMinutesByLessonType
export const addMinutes = (utc, type) => add(new Date(utc), { minutes: getLessonTime(type) }, { locale: ptBR });

export const addDays = (utc, days) => add(new Date(utc), { days }, { locale: ptBR });

export const subDays = (utc, days) => sub(new Date(utc), { days }, { locale: ptBR });

export const addHours = (utc, hours) => add(new Date(utc), { hours }, { locale: ptBR });

export const subHours = (utc, hours) => sub(new Date(utc), { hours }, { locale: ptBR });

export const add30Minutes = (utc) => add(new Date(utc), { minutes: 30 }, { locale: ptBR });

export const getDayMonthNameYear = (utc) => format(new Date(utc), "d 'de' MMMM 'de' y", { locale: ptBR });

export const getDayAbrvMonthYear = (utc) => format(new Date(utc), "d', 'MMM', 'yyyy", { locale: ptBR });

export const getStartOfWeek = () => startOfWeek(new Date(), { locale: ptBR });

// Verifica se a data está X horas próxima
export const isDateNear = (date, hours) => getHourDifference(date) <= hours;

// Verifica se a data está X minutos próximo
export const isDateNearInMinutes = (date, minutes) => getMinuteDifference(date) <= minutes;

// Recebe uma string da data em utc e converte para pt-br retornando um objeto com a data e a hora separadas
export const formatDate = (dateString, timeZone) => {
  const dateUTC = new Date(dateString);

  const date = dateUTC.toLocaleDateString("pt-br", { weekday: "long", month: "long", day: "numeric", timeZone });
  const hour = dateUTC.toLocaleTimeString("pt-br", { timeStyle: "short", timeZone });

  return { date, hour };
};
