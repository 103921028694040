import Button from "components/MaterialCards/Button/Button";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min.js";
import { logAnalyticsEvent } from "utils/firebase-analytics.js";

import { banner02 } from "./banners/banner02.js";
import { banner04 } from "./banners/banner04.js";
import { banner07 } from "./banners/banner07.js";
import * as S from "./styles.module.css";

const getDownloadLink = () => {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;

  if (/iPad|iPhone|iPod/.test(userAgent)) {
    return "https://apps.apple.com/br/app/englishbay-curso-de-ingl%C3%AAs/id6450712168";
  }

  return "https://play.google.com/store/apps/details?id=com.englishbay.App&hl=pt_BR";
};

const RenderAD = ({ nextCard }) => {
  const [banner, setBanner] = useState();
  const history = useHistory();

  const banners = [
    {
      html: banner07,
      analyticsEvent: "SHOW_AD_CARD_BANNER_07_WEB",
      onClick: () => {
        logAnalyticsEvent("CLICK_AD_CARD_BANNER_07_WEB");
        window.open(getDownloadLink());
      },
    },
    {
      html: banner04,
      analyticsEvent: "SHOW_AD_CARD_BANNER_04_WEB",
      onClick: () => {
        logAnalyticsEvent("CLICK_AD_CARD_BANNER_04_WEB");
        history.push("/planos");
      },
    },
    {
      html: banner02,
      analyticsEvent: "SHOW_AD_CARD_BANNER_02_WEB",
      onClick: () => {
        logAnalyticsEvent("CLICK_AD_CARD_BANNER_02_WEB");
        history.push("/planos");
      },
    },
  ];

  const handleClick = () => {
    nextCard({ answer: "_html", isCorrect: true });
  };

  useEffect(() => {
    const bannerRandom = banners[Math.floor(Math.random() * banners.length)];

    logAnalyticsEvent(bannerRandom.analyticsEvent);
    setBanner(bannerRandom);
  }, []);

  return (
    <div className={S.wrapper}>
      <div style={{ marginTop: 10 }}>
        <div className={S.html} onClick={() => banner?.onClick()} dangerouslySetInnerHTML={{ __html: banner?.html }} />
      </div>

      <div className="flex justify-center">
        <Button onClick={handleClick} type="callToAction" variant={"checking"} text="Talves mais tarde" />
      </div>
    </div>
  );
};

RenderAD.propTypes = {
  nextCard: PropTypes.func.isRequired,
};

export default RenderAD;
