import PropTypes from "prop-types";
import React, { useContext, useState, useEffect } from "react";
import { Link } from "react-router-dom";

import ProgressBar from "../../../../_Shared/ProgressBar/ProgressBar";
import { Warning } from "../../../../../assets/svgs/index";
import { UserContext } from "../../../../../context/UserContext";
import { getTimeBetweemToday, getDayMonthYear } from "../../../../../utils/dates";
import "./Scheduling.css";
import ScheduleRow from "./ScheduleRow";

const SchedulingSuccess = ({ appointmentsSuccesseArray }) => {
  const [appointmentsSuccesseArraySorted, setAppointmentsSuccesseArraySorted] = useState([]);

  useEffect(() => {
    const sortedArray = appointmentsSuccesseArray.sort(
      (a, b) => new Date(a.schedule.dateTime) - new Date(b.schedule.dateTime),
    );

    setAppointmentsSuccesseArraySorted(() => [...sortedArray]);
  }, [appointmentsSuccesseArray]);

  const { credits, user } = useContext(UserContext);

  return (
    <div>
      <header className="header-bg">
        <div className="px-4">
          <div className="py-4 text-white">
            <p className="text-gray-50 text-xs">Início / Minhas aulas / Agendamento</p>
            <h1 className="text-3xl mt-5">
              <span role="img" aria-label="dsadsa" className="mr-2">
                👏
              </span>
              Você agendou sua aula, {appointmentsSuccesseArraySorted[0]?.user?.name}!
            </h1>
            <p className="mt-5 text-sm">Você receberá um e-mail com as instruções do agendamento.</p>
          </div>
        </div>
      </header>
      <main className="md:grid xl:grid-cols-3 2xl:grid-cols-4 gap-5 mt-2 px-4 md:px-5">
        <div className="col-span-2 bg-white rounded-2xl -mt-6 p-7 shadow-lg flex flex-col">
          <h1 className="text-xl">Resumo</h1>
          <p className="mt-2 text-base">
            Sua próxima aula começará daqui{" "}
            {appointmentsSuccesseArraySorted.length > 0 &&
              getTimeBetweemToday(appointmentsSuccesseArraySorted[0].schedule.dateTime)}
          </p>
          {appointmentsSuccesseArraySorted.map((appointment) => (
            <ScheduleRow key={appointment.uuid} appointment={appointment} planType={appointment.type} />
          ))}

          <div className="flex items-center mt-12 mb-5">
            <div className="flex items-center">
              <Warning style={{ fill: "#F59300" }} />
            </div>

            <p className="ml-4 lg:mt-0 text-sm" style={{ color: "#F59300" }}>
              Lembre-se: É possível cancelar uma aula até 3 horas antes do seu início, caso contrário a aula será
              contabilizada como uma aula ocorrida.
            </p>
          </div>
          <div className="flex items-center mb-5">
            <div className="flex items-center">
              <Warning style={{ fill: "#F59300" }} />
            </div>

            <p className="ml-4 lg:mt-0 text-sm" style={{ color: "#F59300" }}>
              Atenção: Você receberá um email de lembrete 10 minutos antes da aula. Você também pode entrar na aula
              vendo seus agendamentos na seção &quot;Minhas Aulas&quot;
            </p>
          </div>

          <div className="lg:mt-auto justify-center">
            <Link to="/minhasAulas">
              <button
                className="p-4 pl-9 pr-9 w-full lg:w-auto rounded-lg text-sm font-semibold tracking-wider"
                style={{ background: "#14C4BE", color: "white" }}>
                Conferir minhas aulas
              </button>
            </Link>
            <Link to="/">
              <button
                className="md:ml-4 p-4 pl-9 pr-9 w-full lg:w-auto rounded-lg text-sm font-semibold tracking-wider"
                style={{ color: "#14C4BE" }}>
                Ir para o inicio
              </button>
            </Link>
          </div>
        </div>
        {user?.role === "STUDENT" && (
          <div
            className="bg-white flex flex-wrap p-7 mt-4 h-2/3 min-h-[400px] shadow-lg"
            style={{ borderRadius: "16px 16px 0px 0px" }}>
            <div>
              <h1 className="text-xl" style={{ color: "#383743" }}>
                Plano
              </h1>
              <h2 className="mt-1" style={{ color: "#383743" }}>
                Restam {credits?.available} aulas de {credits?.planLessons} mensais
              </h2>
              <ProgressBar
                className="mt-4"
                progressBarColor={credits?.available > 0 ? "5807B8" : "B81212"}
                percentage={(100 / credits?.planLessons) * credits?.used}
              />
              <p className="text-base mt-6" style={{ color: "#625E66" }}>
                O plano será renovado em {getDayMonthYear(credits?.subscriptionRenewDate)}
              </p>
              <div className="md:mt-0 lg:mt-2 pt-6" style={{ borderTop: "1px dashed #DFE1E6" }}>
                <Warning style={{ fill: "#625E66" }} />
                <p className="mt-3 text-xs" style={{ color: "#625E66" }}>
                  Caso seu plano esgote antes da renovação você poderá continuar estudando comprando aulas avulsas ou
                  até mesmo fazendo a renovação antecipada do plano ou adquirindo um plano maior{" "}
                </p>
              </div>
            </div>
          </div>
        )}
      </main>
    </div>
  );
};

SchedulingSuccess.propTypes = {
  appointmentsSuccesseArray: PropTypes.array.isRequired,
};

export default SchedulingSuccess;
