import PropTypes from "prop-types";
import React, { useEffect, useRef } from "react";
import { AiOutlineClose as CloseIcon } from "react-icons/ai";
import { useHistory } from "react-router-dom";
import { logAnalyticsEvent } from "utils/firebase-analytics";

import BannerConversationElipse from "../../../../assets/imgs/banners/BannerConversationElipse.png";
import Person1 from "../../../../assets/imgs/banners/BannerConversationPerson1.png";
import Person2 from "../../../../assets/imgs/banners/BannerConversationPerson2.png";
import Person3 from "../../../../assets/imgs/banners/BannerConversationPerson3.png";
import Person4 from "../../../../assets/imgs/banners/BannerConversationPerson4.png";
import BannerImage from "../../../../assets/imgs/banners/BannerConversationTexture.png";
import useOnClickOutside from "../../../../hooks/useOnClickOutside";
import * as S from "./styles.module.css";

const Conversation = ({ handleHideBanner }) => {
  const history = useHistory();

  const ref = useRef();

  useOnClickOutside(ref, () => handleHideBanner());

  const image = BannerImage;

  const handleClick = () => {
    handleHideBanner();
    logAnalyticsEvent("CLICK_BANNER_CONVERSATION");
    history.push("/planos");
  };

  useEffect(() => {
    logAnalyticsEvent("SHOW_BANNER_CONVERSATION");
  }, []);

  return (
    <div className={S.Backdrop}>
      <div ref={ref} className={S.Container}>
        <div className={S.Wrapper}>
          <div className={S.WrapperContent}>
            <div style={{ position: "relative" }}>
              <>
                <p className={S.Title}>
                  Grupo de conversação de
                  <span> Segunda à Sexta</span>
                </p>
              </>
            </div>

            <p className={S.Description}>
              Participe dos nossos grupo de conversação e pratique diariamente, sempre com um professor e outros alunos
              conversando sobre diversos temas.
            </p>

            <button onClick={handleClick} className={S.Button}>
              Quero entrar
            </button>
          </div>
        </div>

        <div style={{ position: "relative", width: "100%" }}>
          <img className={S.Image} src={image} alt="bannerImage" />

          <div
            style={{
              position: "absolute",
              zIndex: 3,
              bottom: 0,
              right: "23px",
              width: "90%",
              height: "70%",
              background: "rgba(255, 255, 255, 0.05)",
              backdropFilter: "blur(1px)",
              borderRadius: "32px 32px 0 0",
            }}
          />

          <img
            style={{
              position: "absolute",
              zIndex: 3,
              top: 0,
              right: 50,
            }}
            src={BannerConversationElipse}
            alt="bannerElipse"
          />

          <img
            style={{
              position: "absolute",
              zIndex: 5,
              bottom: 0,
              transform: "rotate(180deg)",
              right: 0,
            }}
            src={BannerConversationElipse}
            alt="bannerElipse"
          />

          <img
            style={{
              position: "absolute",
              zIndex: 2,
              bottom: 0,
              right: 50,
            }}
            src={Person1}
            alt="bannerElipse"
          />

          <img
            style={{
              position: "absolute",
              zIndex: 4,
              top: 0,
              left: -100,
              width: 350,
              height: 350,
            }}
            src={Person2}
            alt="bannerElipse"
          />

          <img
            style={{
              position: "absolute",
              zIndex: 4,
              top: 40,
              right: 0,
              width: 320,
              height: 320,
            }}
            src={Person4}
            alt="bannerElipse"
          />

          <img
            style={{
              position: "absolute",
              zIndex: 6,
              bottom: -20,
              left: 20,
              width: 350,
              height: 350,
            }}
            src={Person3}
            alt="bannerElipse"
          />

          <div className={S.WrapperButtonClose}>
            <button className={S.ButtonClose} onClick={handleHideBanner}>
              <CloseIcon size={20} color="#6A51B2" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

Conversation.propTypes = {
  handleHideBanner: PropTypes.func.isRequired,
};

export default Conversation;
