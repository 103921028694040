import PropTypes from "prop-types";
import React from "react";

import Filler from "./Filler";

const ProgressBar = ({ className, percentage, progressBarColor }) => {
  return (
    <div className={`progressBar ${className}`}>
      <Filler percentage={percentage} progressBarColor={progressBarColor} />
    </div>
  );
};

ProgressBar.propTypes = {
  percentage: PropTypes.number,
  progressBarColor: PropTypes.string,
  className: PropTypes.string,
};

export default ProgressBar;
