import PropTypes from "prop-types";
import React, { useState, useEffect, useContext } from "react";

import { PurpleButton } from "../../../_Shared/Form/index";
import { IconV, CloseX } from "../../../../assets/svgs/index";
import { SlideContext } from "../../../../context/SlideContext";
import useRequest from "../../../../hooks/useRequest";
import ConfirmationChangePlan from "../ConfirmationChangePlan/ConfirmationChangePlan";
import CardPayment from "../PaymentsCards/CardPayment/CardPayment";
import CardPlan from "./CardPlan";
import "./SelectPlan.css";

const SelectPlan = ({ changePlan, getPlans, isReactivatePlan }) => {
  const { setShowSlide, setSlideComponent } = useContext(SlideContext);
  const { request } = useRequest();
  const [plans, setPlans] = useState([]);
  const [showPlans, setShowPlans] = useState([]);
  const [togglePlanType, setTogglePlanType] = useState(true);
  const [selectedPlan, setSelectedPlan] = useState("");
  const [planName, setPlanName] = useState("");
  const [planType, setPlanType] = useState("lessons");

  useEffect(() => {
    const handleInfoPlan = () => {
      if (togglePlanType) {
        setShowPlans(plans.filter((plan) => plan.lessonType === "FIFTY"));
        return;
      }

      setShowPlans(plans.filter((plan) => plan.lessonType === "TWENTY_FIVE"));
      return;
    };

    handleInfoPlan();
  }, [togglePlanType]);

  useEffect(() => {
    const fetchPlans = async () => {
      const response = await request("GET", "/plan/list");

      setPlans(response);
      setShowPlans(response.filter((plan) => plan.lessonType === "FIFTY"));
    };

    fetchPlans();
  }, []);

  useEffect(() => {
    if (planType === "group") {
      setShowPlans(plans.filter((plan) => plan.lessonType === "CONVERSATION"));
    } else {
      setShowPlans(plans.filter((plan) => plan.lessonType !== "CONVERSATION"));
    }
  }, [planType]);

  const handleClick = (comp) => {
    if (selectedPlan) {
      setSlideComponent(comp);
    }
  };

  return (
    <div>
      <h2 className="text-xl sm:text-3xl text-center font-semibold mb-5">Você está interessado em:</h2>

      <div className="flex justify-center">
        <div className="text-sm sm:text-lg flex rounded-full p-1 text-white" style={{ background: "#623CEA" }}>
          <button
            style={{ color: `${planType === "group" ? "#623CEA" : "white"}` }}
            className={`${
              planType === "group"
                ? "px-4 bg-white rounded-full font-dark-purple font-bold"
                : "px-4 text-white rounded-full p-2"
            }`}
            onClick={() => {
              setPlanType("group");
            }}>
            Aulas ao vivo
          </button>
          <button
            style={{ color: `${planType === "lessons" ? "#623CEA" : "white"}` }}
            className={`${
              planType === "lessons"
                ? "px-4 bg-white rounded-full font-dark-purple font-bold"
                : "px-4 text-white rounded-full p-2"
            }`}
            onClick={() => {
              setPlanType("lessons");
            }}>
            Aulas particulares
          </button>
        </div>
      </div>

      <div className="flex justify-between items-center mt-10">
        {changePlan ? (
          <h2 className="text-xl sm:text-2xl pl-2">Alterar o Plano</h2>
        ) : (
          <h2 className="text-xl sm:text-2xl pl-2">Escolha seu plano</h2>
        )}

        <CloseX onClick={() => setShowSlide(false)} className="cursor-pointer" />
      </div>

      <div className="flex container__plans">
        <div className="" style={{ width: "310px" }}>
          {planType === "lessons" && (
            <div className="text-sm flex mt-2">
              <div className="flex rounded-full p-1 text-white" style={{ background: "#623CEA" }}>
                <button
                  style={{ color: `${!togglePlanType ? "#623CEA" : "white"}` }}
                  className={`${
                    togglePlanType ? "px-4 rounded-full p-2" : "px-4 bg-white rounded-full font-dark-purple font-bold"
                  }`}
                  onClick={() => {
                    setTogglePlanType(false);
                  }}>
                  Aulas de 25min
                </button>
                <button
                  style={{ color: `${togglePlanType ? "#623CEA" : "white"}` }}
                  className={`${
                    !togglePlanType ? "px-4 rounded-full p-2 " : "px-4 bg-white rounded-full font-dark-purple font-bold"
                  }`}
                  onClick={() => {
                    setTogglePlanType(true);
                  }}>
                  Aulas de 50min
                </button>
              </div>
            </div>
          )}

          {planType === "lessons" && (
            <ul className="mt-4">
              <li className="flex gap-1">
                <IconV />
                <p>Aula individual de {togglePlanType ? 50 : 25} minutos</p>
              </li>
              <li className="flex gap-1">
                <IconV />
                <p>Material gratuito</p>
              </li>
              <li className="flex gap-1">
                <IconV />
                <p>Grupo de conversação</p>
              </li>
              <li className="flex gap-1">
                <IconV />
                <p>Live classes</p>
              </li>
              <li className="flex gap-1">
                <IconV />
                <p>Certificado</p>
              </li>
              <li className="flex gap-1">
                <IconV />
                <p>Cancele sem multa</p>
              </li>
            </ul>
          )}

          {planType === "group" && (
            <ul className="mt-4">
              <li className="flex gap-1">
                <IconV />
                <p>PDF das lições</p>
              </li>
              <li className="flex gap-1">
                <IconV />
                <p>Certificados</p>
              </li>
              <li className="flex gap-1">
                <IconV />
                <p>Tirar dúvidas por Whatsapp</p>
              </li>
              <li className="flex gap-1">
                <IconV />
                <p>Sem multa de cancelamento</p>
              </li>
              <li className="flex gap-1">
                <IconV />
                <p>Aulas ao vivo toda segunda e quinta as 19:00</p>
              </li>
            </ul>
          )}
        </div>

        <div className="bg-white overflow-auto">
          {showPlans &&
            showPlans.map((plan) => (
              <CardPlan
                key={plan.uuid}
                name={plan.name}
                value={plan.uuid}
                setSelectedPlan={setSelectedPlan}
                selectedPlan={selectedPlan}
                setPlanName={setPlanName}
                lessonsCount={plan.lessonsCount}
                price={plan.price}
                recommended={plan.recommended}
                iconUrl={plan.iconUrl}
                lessonType={plan.lessonType}
              />
            ))}
        </div>
      </div>

      <div style={{ width: "300px", margin: "30px auto" }}>
        {changePlan ? (
          <PurpleButton
            onClick={() =>
              handleClick(<ConfirmationChangePlan planUuid={selectedPlan} planName={planName} getPlans={getPlans} />)
            }>
            Alterar
          </PurpleButton>
        ) : (
          <PurpleButton
            onClick={() =>
              handleClick(
                <CardPayment isReactivatePlan={isReactivatePlan} planUuid={selectedPlan} planType={planType} />,
              )
            }>
            Assinar
          </PurpleButton>
        )}
      </div>
    </div>
  );
};

SelectPlan.propTypes = {
  changePlan: PropTypes.bool,
  isReactivatePlan: PropTypes.bool,
  getPlans: PropTypes.func,
};

export default SelectPlan;
