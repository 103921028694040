import PropTypes from "prop-types";
import React from "react";

import { BrasilFlag, UnitedStatesFlag } from "../../../assets/svgs/index";

const LanguageDropdown = ({ className, showHide, setOption, ref }) => {
  const isSmallMonitor = window.innerWidth < 720;

  return (
    <div
      ref={ref}
      className={`${!showHide ? "hidden" : ""} ${className} flex justify-center items-center bg-white`}
      style={{ left: "50%", right: "50%", top: "4em" }}>
      <div
        className="bg-white p-2 listHover rounded-lg"
        style={{
          boxShadow: "0px 6px 14px -6px rgba(24, 39, 75, 0.12), 0px 10px 32px -4px rgba(24, 39, 75, 0.1)",
          marginRight: isSmallMonitor ? "90px" : "0px",
        }}>
        <button className="flex flex-row p-2 w-full" onClick={() => setOption("pt-BR")}>
          <BrasilFlag className="mr-2" />
          Português
        </button>
        <button className="flex flex-row p-2 w-full" onClick={() => setOption("en")}>
          <UnitedStatesFlag className="mr-2" />
          English
        </button>
        {/* <button className="flex flex-row p-2 w-full" onClick={() => setOption("sp-sp")}>
          <SpainFlag className="mr-2" />
          Español
        </button>
        <button className="flex flex-row p-2 w-full" onClick={() => setOption("sb-sb")}>
          <SerbianFlag className="mr-2" />
          Serbian
        </button>
        <button className="flex flex-row p-2 w-full" onClick={() => setOption("ru-ru")}>
          <RussianFlag className="mr-2" />
          Russian
        </button> */}
      </div>
    </div>
  );
};

LanguageDropdown.propTypes = {
  className: PropTypes.string,
  showHide: PropTypes.bool,
  setOption: PropTypes.func,
  ref: PropTypes.any,
};

export default LanguageDropdown;
