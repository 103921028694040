import PropTypes from "prop-types";
import React, { createContext, useState, useContext } from "react";

export const ChatbotContext = createContext();

export const ChatbotProvider = ({ children }) => {
  const [mood, setMood] = useState("neutral");
  const [message, setMessage] = useState();
  const [showTooltip, setShowTooltip] = useState(false);
  const [showChat, setShowChat] = useState(false);
  const [showTutor, setShowTutor] = useState(true);

  const handleChatbot = (mood, message) => {
    setMood(mood);
    setMessage(message);
    setShowTooltip(true);
  };

  const handleWrongAnswer = () => {
    setMood("sad");
  };

  const handleCorrectAnswer = () => {
    setMood("happy");
  };

  const handleCheckAnswer = (isCorrect) => {
    if (isCorrect) {
      handleCorrectAnswer();
    } else {
      handleWrongAnswer();
    }
  };

  const value = {
    handleChatbot,
    handleCheckAnswer,
    showTooltip,
    setShowTooltip,
    setMood,
    mood,
    message,
    showChat,
    setShowChat,
    showTutor,
    setShowTutor,
  };

  return <ChatbotContext.Provider value={value}>{children}</ChatbotContext.Provider>;
};

export const useChatbot = () => useContext(ChatbotContext);

ChatbotProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
