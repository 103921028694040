import PropTypes from "prop-types";
import React from "react";
import { useHistory } from "react-router";

import { ArrowRigth, WarningIcon } from "../../../assets/svgs/index";
import { PurpleButton } from "../Form/index";

import "./PaymentWarningStudent.css";

const PaymentWarningStudent = ({ message, buttonMessage, color, buttonColor = "#8e152e", link }) => {
  const history = useHistory();

  const openPlans = () => {
    history.push(link);
  };

  /**
   * PurgeCSS:
   * bg-red-300
   * bg-green-300
   * bg-yellow-300
   * bg-gray-300
   * bg-purple-300
   * bg-indigo-300
   * bg-blue-300
   * bg-pink-300
   */
  return (
    <div
      className={`flex flex-col sm:flex-row items-start sm:items-center justify-center sm:justify-between h-36 sm:h-20 bg-${color}-300 px-8`}>
      <div className="flex flex-col sm:flex-row">
        {window.innerWidth > 1127 && <WarningIcon />}
        <p className="sm:ml-4 text-base text-red-700">{message}</p>
      </div>
      {buttonMessage && (
        <div className="pt-2 mr-8 md:pt-0 md:mr-0">
          <PurpleButton
            onClick={openPlans}
            className="buttonPaymentStudent w-full"
            style={{ backgroundColor: buttonColor }}>
            {buttonMessage} <ArrowRigth className="w-2 fill-current text-whit ml-16" />
          </PurpleButton>
        </div>
      )}
    </div>
  );
};

PaymentWarningStudent.propTypes = {
  message: PropTypes.string,
  buttonMessage: PropTypes.string,
  color: PropTypes.string,
  link: PropTypes.string,
  buttonColor: PropTypes.string,
};

export default PaymentWarningStudent;
