import PropTypes from "prop-types";
import React, { useEffect, useRef } from "react";
import { AiOutlineClose as CloseIcon } from "react-icons/ai";
import { AiFillYoutube } from "react-icons/ai";
import { logAnalyticsEvent } from "utils/firebase-analytics";

import BannerImage from "../../../../assets/imgs/banners/YoutubeMobile.png";
import useOnClickOutside from "../../../../hooks/useOnClickOutside";
import * as S from "./styles.module.css";

const YoutubeMobile = ({ handleHideBanner }) => {
  const ref = useRef();

  useOnClickOutside(ref, () => handleHideBanner());

  const image = BannerImage;

  const handleClick = () => {
    handleHideBanner();
    logAnalyticsEvent("CLICK_BANNER_YOUTUBE_MOBILE");
    window.open("https://www.youtube.com/c/englishbay?sub_confirmation=1").focus();
  };

  useEffect(() => {
    logAnalyticsEvent("SHOW_BANNER_YOUTUBE_MOBILE");
  }, []);

  return (
    <div className={S.Backdrop}>
      <div className={S.Container}>
        <div ref={ref}>
          <div style={{ position: "relative", width: "100%" }}>
            <img className={S.Image} src={image} alt="bannerImage" />

            <div className={S.WrapperButtonClose}>
              <button className={S.ButtonClose} onClick={handleHideBanner}>
                <CloseIcon size={20} color="#6A51B2" />
              </button>
            </div>
          </div>

          <div className={S.Wrapper}>
            <p className={S.Title}>
              Aulas <span>GRÁTIS</span> no Youtube <AiFillYoutube size={46} color="red" />
            </p>

            <p className={S.Description}>
              Participe das nossas lives, de segunda a quinta às 20HS de graça em nosso canal do Youtube com o professor
              Julio
            </p>

            <button onClick={handleClick} className={S.Button}>
              <AiFillYoutube size={32} color="#fff" />
              Seguir canal
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

YoutubeMobile.propTypes = {
  handleHideBanner: PropTypes.func.isRequired,
  variant: PropTypes.number.isRequired,
};

export default YoutubeMobile;
