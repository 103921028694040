import { useGoogleLogin } from "@react-oauth/google";
import React, { useContext, useState, useEffect } from "react";
import AppleLogin from "react-apple-login";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import { useTranslation } from "react-i18next";
import { Link, useHistory, useLocation } from "react-router-dom";
import Turnstile from "react-turnstile";
import { logAnalyticsEvent } from "utils/firebase-analytics";

import { Input, PurpleButton } from "../../_Shared/Form/index";
import Modal from "../../_Shared/Modal/ModalTerms";
import { Profile } from "../../../assets/svgs/index";
import { ToastContext } from "../../../context/ToastContext";
import { UserContext } from "../../../context/UserContext";
import useForm from "../../../hooks/useForm";
import useRequest from "../../../hooks/useRequest";
import { pushGTMEvent } from "../../../utils/GTM";
import { saveToken, saveUser } from "../../../utils/localStorage";
import NoAccount from "../NoAccount/NoAccount";

import "./Login.css";

const isMacLike = /(Mac|iPhone|iPod|iPad)/i.test(navigator.platform);

const Login = () => {
  const history = useHistory();
  const location = useLocation();
  const { setUserContext, updateUserContext, setCaptchaToken, getOnBoardingPayload } = useContext(UserContext);
  const { showToast } = useContext(ToastContext);
  const { i18n } = useTranslation();

  const [invalidAccount, setInvalidAccount] = useState(false);
  const [showModalTerms, setShowModalTerms] = useState(false);
  const [showModalPrivacy, setShowModalPrivacy] = useState(false);
  const [userState, setUserState] = useState(null);
  const [tokenState, setTokenState] = useState("");
  const [termsVersion, setTermsVersion] = useState("");
  const [privacyVersion, setPrivacyVersion] = useState("");
  const [terms, setTerms] = useState("");
  const [privacy, setPrivacy] = useState("");
  const { request, loading, setLoading } = useRequest();

  const email = useForm("email");
  const password = useForm("password");

  const handleTermsAccepted = async (isTerm) => {
    const term = {
      version: isTerm ? termsVersion : privacyVersion,
      kind: isTerm ? "TERM" : "PRIVACY",
    };

    saveToken(tokenState);
    setUserContext(userState);

    await request("POST", "/terms/accept", term);

    if (isTerm) {
      setShowModalTerms(false);
    } else {
      setShowModalPrivacy(false);
    }

    if ((isTerm && showModalPrivacy) || (!isTerm && showModalTerms)) {
      return;
    }

    history.push("/");
  };

  const validateForm = () => {
    email.validate();
    password.validate();
  };

  const saveGTMEvents = (user) => {
    pushGTMEvent({
      event: "login",
      method: "email", // Método de login (fb, gmail ou email)
      userId: user.uuid, //variável userID
      plano: user.role === "STUDENT" ? user.student?.activeSubscription?.plan?.name : "Free",
    });

    logAnalyticsEvent("af_login", {
      af_login_method: "EMAIL",
    });

    // Seta o user no GTM
    pushGTMEvent({
      userId: user.uuid,
      plano: user.role === "STUDENT" ? user.student?.activeSubscription?.plan?.name : "Free",
    });
  };

  const validateOauth = async (accessToken, origin, onBoarding, givenName) => {
    setLoading(true);
    const body = {
      origin,
      accessToken,
      givenName,
      onBoarding,
      os: "web",
    };

    if (onBoarding?.phoneNumber) {
      body.phoneNumber = onBoarding.phoneNumber;
    }

    const originCode = localStorage.getItem("originCode");
    if (originCode) {
      body.originCode = originCode;
    }

    const { token, user, isSignUp, error } = await request("POST", "/auth/externalLogin", body, true, false, true);

    if (error?.message === "MISSING_ONBOARDING") {
      sessionStorage.setItem("externalLogin", JSON.stringify({ accessToken, origin, givenName }));
      history.push("/onBoarding");
      return;
    }

    if (user && token) {
      if (isSignUp) {
        logAnalyticsEvent("af_complete_registration", {
          af_registration_method: origin,
        });
      } else {
        logAnalyticsEvent("af_login", {
          af_login_method: origin,
        });
      }

      localStorage.removeItem("timesAlreadyShowedFreeClasses");
      saveToken(token);
      saveUser(user);
      setUserContext(user);
      history.push("/");

      return true;
    }
  };

  const loginGoogle = useGoogleLogin({
    onSuccess: async ({ access_token }) => {
      await validateOauth(access_token, "GOOGLE", getOnBoardingPayload());
    },
  });

  const loginFacebook = async (response) => {
    await validateOauth(response.accessToken, "FACEBOOK", getOnBoardingPayload(), response.name);
  };

  const loginApple = async (response) => {
    await validateOauth(
      response.authorization.id_token,
      "APPLE",
      getOnBoardingPayload(),
      response.user?.name?.firstName,
    );
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    validateForm();

    if (email.validate() && password.validate()) {
      const userForm = {
        email: email.value,
        password: password.value,
      };

      const { token, user, hasNewTerms, hasNewPrivacy, error } = await request(
        "POST",
        "/auth/login",
        userForm,
        false,
        false,
        true,
      );

      if (error && error.statusCode === 404) {
        setInvalidAccount(true);
        return;
      }

      if (error) {
        console.error(error.message);
        showToast({ message: error.message, type: "error" });
        return;
      }

      if (!["STUDENT", "LEAD", "TEACHER"].includes(user?.role)) {
        setInvalidAccount(true);
        return;
      }

      setUserState(user);
      setTokenState(token);

      if (user.role !== "TEACHER") {
        i18n.changeLanguage("pt-BR");
      }

      const userType = user.role === "TEACHER" ? "TEACHER" : "USER";

      if (hasNewTerms) {
        const { text, version } = await request("GET", `/terms/read?kind=TERM&userType=${userType}`, null, true, false);
        setTerms(text ?? "Vazio");
        setTermsVersion(version ?? 0);
        setShowModalTerms(true);
      }

      if (hasNewPrivacy) {
        const { text, version } = await request(
          "GET",
          `/terms/read?kind=PRIVACY&userType=${userType}`,
          null,
          true,
          false,
        );
        setPrivacy(text ?? "Vazio");
        setPrivacyVersion(version ?? 0);
        setShowModalPrivacy(true);
      }

      if (!hasNewTerms && !hasNewPrivacy) {
        saveGTMEvents(user);
        saveToken(token);
        saveUser(user);
        setUserContext(user);

        if (location.search.includes("?returnUrl=")) {
          const returnUrl = location.search.split("=")[1];

          history.push(returnUrl);
        } else {
          history.push("/");
        }
      }
    }
  };

  useEffect(() => {
    const verifyToken = async () => {
      if (location.search.includes("?token=")) {
        const token = location.search.split("=")[1];

        saveToken(token);
        await updateUserContext();
        history.push("/");
      }
    };

    verifyToken();
  }, []);

  return (
    <>
      {invalidAccount && (
        <div>
          <NoAccount onStateChange={setInvalidAccount} />
        </div>
      )}
      {!invalidAccount && (
        <div>
          <div>
            {showModalTerms && (
              <Modal
                setShowModal={setShowModalTerms}
                termo={terms}
                buttonMessage="Aceitar"
                onAccepted={() => handleTermsAccepted(true)}
                title="Termos de Uso - EnglishBay"
              />
            )}

            {showModalPrivacy && (
              <Modal
                setShowModal={setShowModalPrivacy}
                termo={privacy}
                buttonMessage="Aceitar"
                onAccepted={() => handleTermsAccepted(false)}
                title="Política de Privacidade - EnglishBay"
              />
            )}
            <Profile className="w-8 fill-current font-aqua-green" />
            <div className="text-left font-extralight text-xs mb-2">LOGIN</div>
            <div className="text-2xl font-low text-gray-900 mt-2 mb-2 text-left">Acesse sua conta</div>
            <div className="text-left font-low text-sm mb-4">Faça login na sua conta ou cadastre-se</div>
          </div>
          {/* Deixar comentado enquanto não for implementado */}
          <div className="flex items-center justify-between mb-4">
            <button
              onClick={loginGoogle}
              className="cursor-pointer border borderRadius font-semibold border-gray-100 hover:bg-purple-700 hover:text-white py-2 px-4 inline-flex items-center mr-2">
              <img className="w-5 mr-2" alt="google" src="https://img.icons8.com/fluent/48/000000/google-logo.png" />
              Entrar com Google
            </button>

            {isMacLike && (
              <AppleLogin
                clientId="com.br.englishbay.web"
                callback={loginApple}
                redirectURI="https://app.englishbay.com.br"
                scope="name email"
                responseType="id_token"
                usePopup={true}
                render={(renderProps) => (
                  <button
                    onClick={renderProps.onClick}
                    className="cursor-pointer border borderRadius font-semibold border-gray-100 hover:bg-purple-700 hover:text-white py-2 px-4 inline-flex items-center mr-2">
                    <img
                      style={{ width: "22px", height: "22px" }}
                      className="mr-2"
                      alt="apple"
                      src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjwhRE9DVFlQRSBzdmcgIFBVQkxJQyAnLS8vVzNDLy9EVEQgU1ZHIDEuMS8vRU4nICAnaHR0cDovL3d3dy53My5vcmcvR3JhcGhpY3MvU1ZHLzEuMS9EVEQvc3ZnMTEuZHRkJz48c3ZnIGVuYWJsZS1iYWNrZ3JvdW5kPSJuZXcgMCAwIDU2LjY5MyA1Ni42OTMiIGhlaWdodD0iNTYuNjkzcHgiIGlkPSJMYXllcl8xIiB2ZXJzaW9uPSIxLjEiIHZpZXdCb3g9IjAgMCA1Ni42OTMgNTYuNjkzIiB3aWR0aD0iNTYuNjkzcHgiIHhtbDpzcGFjZT0icHJlc2VydmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPjxnPjxwYXRoIGQ9Ik00MS43NzcsMzAuNTE3Yy0wLjA2Mi02LjIzMiw1LjA4Mi05LjIyMSw1LjMxMi05LjM3MmMtMi44OTEtNC4yMjctNy4zOTUtNC44MDctOC45OTgtNC44NzMgICBjLTMuODMtMC4zODktNy40NzcsMi4yNTYtOS40MiwyLjI1NmMtMS45MzksMC00Ljk0MS0yLjE5OS04LjExNy0yLjE0M2MtNC4xNzgsMC4wNjItOC4wMjksMi40My0xMC4xNzksNi4xNyAgIGMtNC4zMzksNy41MjctMS4xMSwxOC42ODIsMy4xMTgsMjQuNzkxYzIuMDY3LDIuOTg2LDQuNTMyLDYuMzQ2LDcuNzY2LDYuMjIzYzMuMTE3LTAuMTIzLDQuMjkzLTIuMDE2LDguMDYxLTIuMDE2ICAgczQuODI2LDIuMDE2LDguMTIzLDEuOTUzYzMuMzUyLTAuMDYxLDUuNDc3LTMuMDQzLDcuNTI3LTYuMDQxYzIuMzczLTMuNDY5LDMuMzUtNi44MjgsMy40MDgtNi45OTggICBDNDguMzA1LDQwLjQzMyw0MS44NDQsMzcuOTU4LDQxLjc3NywzMC41MTd6Ii8+PHBhdGggZD0iTTM1LjU4MiwxMi4yMjljMS43MTUtMi4wODIsMi44NzctNC45NzUsMi41NjEtNy44NTVjLTIuNDc1LDAuMS01LjQ3MSwxLjY0NS03LjI0OCwzLjcyNSAgIGMtMS41OTIsMS44NDYtMi45ODQsNC43ODUtMi42MTEsNy42MTNDMzEuMDQ1LDE1LjkyNiwzMy44NjEsMTQuMzA3LDM1LjU4MiwxMi4yMjl6Ii8+PC9nPjwvc3ZnPg=="
                    />
                    Entrar com Apple
                  </button>
                )}
              />
            )}
            {!isMacLike && (
              <FacebookLogin
                appId="223054333877393"
                callback={loginFacebook}
                render={(renderProps) => (
                  <button
                    onClick={renderProps.onClick}
                    className="cursor-pointer border borderRadius font-semibold border-gray-100 hover:bg-purple-700 hover:text-white py-2 px-4 inline-flex items-center">
                    <img
                      className="w-5 mr-2"
                      alt="facebook"
                      src="https://img.icons8.com/fluent/48/000000/facebook-new.png"
                    />
                    Entrar com Facebook
                  </button>
                )}
              />
            )}
          </div>
          <div className="text-center font-low text-sm mb-4 mt-2">
            <div>ou entre por e-mail</div>
          </div>
          <form className="sapce-y-6" onSubmit={handleSubmit}>
            <div>
              <Input className="my-4" label="E-mail" name="email" {...email} />
            </div>
            <div>
              <Input className="my-4" label="Senha" name="senha" type="password" {...password} />
            </div>
            <div className="flex items-center justify-end mb-5 font-color">
              <div>
                <Link to="/auth/passwordRecovery" className="font-medium text-sm font-color">
                  Esqueceu sua senha?
                </Link>
              </div>
            </div>
            <div>
              <div className="d-flex" style={{ display: "flex", justifyContent: "right", marginBottom: "15px" }}>
                <Turnstile
                  className="mb-3"
                  sitekey={process.env.REACT_APP_TURNSTILE_SITE_KEY}
                  theme="light"
                  onVerify={setCaptchaToken}
                />
              </div>
              <PurpleButton isLoading={loading}>Entrar</PurpleButton>
            </div>
            <div>
              <div className="text-center font-extralight text-sm mt-8 mb-4">
                Não possui uma conta?
                <Link to="/onBoarding" className="text-purple-600 font-bold ml-2">
                  Criar conta
                </Link>
              </div>
            </div>
          </form>
        </div>
      )}
    </>
  );
};

export default Login;
