import React, { useState, useEffect, useContext, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { useClickAway } from "react-use";

import { UserContext } from "../../../context/UserContext";
import ButtonSwitch from "./ButtonSwitch";
import LanguageDropdown from "./LanguageDropdown";

import "./SwitchLanguageButton.css";

const SwitchLanguageButton = () => {
  const [showHide, setShowHide] = useState(false);
  const [option, setOption] = useState("pt-BR");
  const { i18n } = useTranslation();
  const { user } = useContext(UserContext);
  const location = useLocation();

  const toggleShowHide = () => {
    setShowHide(!showHide);
  };

  // Quando for professor, deixa inglês por padrão
  useEffect(() => {
    if (user?.role === "TEACHER" || location?.pathname.includes("/teacher/")) {
      i18n.changeLanguage("en");
      setOption("en");
    }
  }, [user?.role]);

  const ref = useRef(null);
  useClickAway(ref, () => {
    console.log("aaaaaaa");
    setShowHide(false);
  });

  return (
    <div className="relative btnHover">
      <ButtonSwitch option={option} toggleShowHide={toggleShowHide} />
      <LanguageDropdown
        ref={ref}
        className="absolute"
        showHide={showHide}
        setOption={(option) => {
          i18n.changeLanguage(option);
          setOption(option);
        }}
      />
    </div>
  );
};

export default SwitchLanguageButton;
