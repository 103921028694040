import PropTypes from "prop-types";
import React, { useState } from "react";

import ToggleSwitch from "../../../_Shared/ToggleSwitch/ToggleSwitch";
import { CloseX } from "../../../../assets/svgs/index";

const DrawerAvailability = ({ setShowSlide, setCurrentFilters, currentFilters }) => {
  const [filter, setFilter] = useState(currentFilters);

  const applyValueToFilter = (value) => {
    if (filter.includes(value)) {
      setFilter((crr) => crr.filter((currentVal) => currentVal !== value));
      return;
    }

    setFilter((crr) => [...crr, value]);
  };
  return (
    <div className="flex flex-col px-1 h-full w-full md:w-[23em]">
      <div className="flex justify-between items-center">
        <h1
          className="text-xl font-medium"
          style={{
            color: "#383743",
          }}>
          Disponibilidade
        </h1>
        <CloseX className="cursor-pointer" onClick={() => setShowSlide(false)} />
      </div>
      <div className="flex-1 overflow-auto">
        <div className="mt-2 border-b border-gray-200">
          <div className="flex justify-between items-center text-sm">
            <h2
              className="text-sm"
              style={{
                color: "#676482",
              }}>
              Hora do dia
            </h2>
            <button
              style={{
                color: "#14C4BE",
              }}
              onClick={() => {
                setFilter([]);
              }}>
              Limpar filtros
            </button>
          </div>

          <div className="py-1 text-sm font-medium">
            <div className="flex justify-between items-center py-1">
              <h2
                style={{
                  color: "#1A1738",
                }}>
                Início da Manhã (00h-09h)
              </h2>
              <div onClick={() => applyValueToFilter("EARLY_MORNING")}>
                <ToggleSwitch initialValue={filter.includes("EARLY_MORNING")} />
              </div>
            </div>
            <div className="flex justify-between items-center py-1">
              <h2
                style={{
                  color: "#1A1738",
                }}>
                Manhã (09h-12h)
              </h2>
              <div onClick={() => applyValueToFilter("MORNING")}>
                <ToggleSwitch initialValue={filter.includes("MORNING")} />
              </div>
            </div>
            <div className="flex justify-between items-center py-1">
              <h2
                style={{
                  color: "#1A1738",
                }}>
                Tarde (12h-18h)
              </h2>
              <div onClick={() => applyValueToFilter("EVENING")}>
                <ToggleSwitch initialValue={filter.includes("EVENING")} />
              </div>
            </div>
            <div className="flex justify-between items-center py-1">
              <h2
                style={{
                  color: "#1A1738",
                }}>
                Noite (18h-00h)
              </h2>
              <div onClick={() => applyValueToFilter("NIGHT")}>
                <ToggleSwitch initialValue={filter.includes("NIGHT")} />
              </div>
            </div>
          </div>
        </div>
        <div className="mt-6 pb-6">
          <div className="flex justify-between items-center text-sm">
            <h2
              style={{
                color: "#676482",
              }}>
              Disponibilidade
            </h2>
            <button
              style={{
                color: "#14C4BE",
              }}
              onClick={() => {
                setFilter([]);
              }}>
              Limpar filtros
            </button>
          </div>
          <div className="mt-2 text-sm font-medium">
            <div className="flex justify-between items-center">
              <h2
                style={{
                  color: "#1A1738",
                }}>
                Domingo
              </h2>
              <div onClick={() => applyValueToFilter("SUNDAY")}>
                <ToggleSwitch initialValue={filter.includes("SUNDAY")} />
              </div>
            </div>
            <div className="flex justify-between items-center py-2">
              <h2
                style={{
                  color: "#1A1738",
                }}>
                Segunda-feira
              </h2>
              <div onClick={() => applyValueToFilter("MONDAY")}>
                <ToggleSwitch initialValue={filter.includes("MONDAY")} />
              </div>
            </div>
            <div className="flex justify-between items-center py-2">
              <h2
                style={{
                  color: "#1A1738",
                }}>
                Terça-feira
              </h2>
              <div onClick={() => applyValueToFilter("TUESDAY")}>
                <ToggleSwitch initialValue={filter.includes("TUESDAY")} />
              </div>
            </div>
            <div className="flex justify-between items-center py-2">
              <h2
                style={{
                  color: "#1A1738",
                }}>
                Quarta-feira
              </h2>
              <div onClick={() => applyValueToFilter("WEDNESDAY")}>
                <ToggleSwitch initialValue={filter.includes("WEDNESDAY")} />
              </div>
            </div>
            <div className="flex justify-between items-center py-2">
              <h2
                style={{
                  color: "#1A1738",
                }}>
                Quinta-feira
              </h2>
              <div onClick={() => applyValueToFilter("THURSDAY")}>
                <ToggleSwitch initialValue={filter.includes("THURSDAY")} />
              </div>
            </div>
            <div className="flex justify-between items-center py-2">
              <h2
                style={{
                  color: "#1A1738",
                }}>
                Sexta-feira
              </h2>
              <div onClick={() => applyValueToFilter("FRIDAY")}>
                <ToggleSwitch initialValue={filter.includes("FRIDAY")} />
              </div>
            </div>
            <div className="flex justify-between items-center py-2">
              <h2
                style={{
                  color: "#1A1738",
                }}>
                Sábado
              </h2>
              <div onClick={() => applyValueToFilter("SATURDAY")}>
                <ToggleSwitch initialValue={filter.includes("SATURDAY")} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-6 flex pt-6">
        <button
          className="w-44 btnFilter rounded-lg h-12 font-semibold text-sm tracking-wider"
          onClick={() => {
            setShowSlide(false);
            setCurrentFilters(filter);
          }}
          style={{
            width: "11rem !important",
          }}>
          Filtrar
        </button>
        <button
          onClick={() => setShowSlide(false)}
          className="w-36 h-12 rounded-lg ml-5 font-semibold text-sm tracking-wider"
          style={{
            border: "1px solid #5807B8",
            color: "#5807B8",
          }}>
          Voltar
        </button>
      </div>
    </div>
  );
};

DrawerAvailability.propTypes = {
  setShowSlide: PropTypes.any,
  setCurrentFilters: PropTypes.func,
  currentFilters: PropTypes.array,
};

export default DrawerAvailability;
