export const banner02 = `<div
  style="
    cursor: pointer;
    margin: 0 auto;
    color: #333;
    padding: 10px;
    text-align: center;
    font-size: 14px;
    font-family: 'Urbanist', sans-serif;
    display: flex;
    flex-direction: column;
    max-width: 330px;
    gap: 10px;
  "
>
  <img
    style="width: 100%; height: 210px; margin: 0 auto"
    src="https://media.englishbay.com.br/assets-banners/conference.png"
    alt="Juicy Online Conference"
  />
  <p style="font-size: 18px; font-weight: 600; text-align: left">
  Desbloqueie todo potencial. Aulas todos os dias com o plano Premium
  </p>
  <div
    style="
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 10px;
    "
  >
    <img
      style="width: 24px; height: 24px"
      src="https://media.englishbay.com.br/assets-banners/diploma.png"
      alt="Group"
    />
    <p style="font-size: 18px">Grupos de conversação</p>
  </div>
  <div
    style="
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 10px;
    "
  >
    <img
      style="width: 24px; height: 24px"
      src="https://media.englishbay.com.br/assets-banners/Group.png"
      alt="Group"
    />
    <p style="font-size: 18px">Aulas ao vivo</p>
  </div>
  <a
    style="
      color: white;
      padding: 15px;
      font-size: 16px;
      background-color: #723fb1;
      text-decoration: none;
      border-radius: 12px;
      margin-top: 10px;
    "
    >Assine com 70% de desconto
  </a>
  <p style="font-size: 12px; color: #767676">Cancele quando quiser</p>
</div>
`;
