/* eslint-disable jsx-a11y/interactive-supports-focus */

import { isAfter } from "date-fns";
import { zonedTimeToUtc } from "date-fns-tz";
import PropTypes from "prop-types";
import React, { useContext, useState } from "react";

import { PurpleButton } from "../../../_Shared/Form";
import { Calendar, Watch, X, SpinnerPurple } from "../../../../assets/svgs/index";
import { SlideContext } from "../../../../context/SlideContext";
import { UserContext } from "../../../../context/UserContext";
import useRequest from "../../../../hooks/useRequest";
import { getDayMonthNameYear, getHoursAndMinutes, getMinuteDifference, addMinutes } from "../../../../utils/dates";
import DrawerProfile from "../Classes/DrawerProfile";

const SchedulePill = ({ schedule, refresh }) => {
  const [scheduleReq, setScheduleReq] = useState(schedule);
  const [hidden, setHidden] = useState(true);
  const [hiddenCancel, setHiddenCancel] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const { request } = useRequest();
  const { user } = useContext(UserContext);
  const { setShowSlide, setSlideComponent } = useContext(SlideContext);

  const isPast = (dateString) => {
    return isAfter(new Date(), zonedTimeToUtc(dateString, user.timezone));
  };

  const handleClick = async () => {
    if (isLoading) {
      return;
    }

    // Se a pill está no futuro faz a request
    const targetDateTime = scheduleReq._dateTime || schedule._dateTime;
    if (isPast(targetDateTime)) {
      return;
    }

    setIsLoading(true);
    const dateUTC = zonedTimeToUtc(targetDateTime, user.timezone).toISOString(false);

    const reqBody = {
      dateTime: dateUTC,
    };

    const response = await request("POST", "teacherSchedule/create", reqBody);

    if (!response.error) {
      setScheduleReq(response);
    }

    setIsLoading(false);
  };

  const handleDisableSchedule = async () => {
    if (isLoading) {
      return;
    }

    // Se a pill está no passado não faz a request
    if (isPast(schedule.dateTime)) {
      return;
    }

    setIsLoading(true);
    const response = await request("DELETE", `teacherSchedule/delete/${scheduleReq.uuid}`);
    if (!response.error) {
      setScheduleReq({ _dateTime: schedule.dateTime });
    }

    setIsLoading(false);
  };

  const openCancelAppoitmentModal = () => {
    setHidden(true);
    setHiddenCancel(false);
  };

  const handleCancelAppointment = async () => {
    const response = await request("DELETE", `/appointment/cancel/${scheduleReq.appointment.uuid}`);

    if (!response.error) {
      setHiddenCancel(true);
      refresh();
    }
  };

  const isButtonDisabled = () => {
    const utc = zonedTimeToUtc(schedule.dateTime, user.timezone).toISOString();
    return getMinuteDifference(utc) >= 9 || isAfter(new Date(), addMinutes(utc, 50));
  };

  const handleComponent = (component) => {
    setSlideComponent(component);
    setShowSlide(true);
  };

  const formatName = (name) => {
    if (!name) {
      return "[DELETED]";
    }

    return name.split(" ")[0].slice(0, 8);
  };

  return (
    <>
      {scheduleReq?.status === "AVAILABLE" && (
        <button
          onClick={() => handleDisableSchedule()}
          className="pill-shape-available pill-shape"
          style={{ cursor: isPast(schedule.dateTime) ? "not-allowed" : "pointer" }}>
          {isLoading && <SpinnerPurple className="m-auto animate-spin" style={{ width: "25px", height: "30px" }} />}
        </button>
      )}

      {scheduleReq?.status === "UNAVAILABLE" && (
        <>
          <div className="relative">
            <div role="button" onClick={() => setHidden(false)} className="pill-shape pill-shape-schedule">
              <p style={{ marginTop: isLoading ? "2px" : "0.25rem" }}>
                {isLoading ? (
                  <SpinnerPurple className="m-auto animate-spin" style={{ width: "25px", height: "30px" }} />
                ) : (
                  formatName(schedule.appointment.user?.name)
                )}
              </p>
            </div>

            <div
              style={{ width: "374px", height: "410px", zIndex: "8", right: "-5px", top: "-5px" }}
              className={`${hidden && "hidden"} absolute shadow-xl borderBehind text-white p-5`}>
              <div className="flex justify-end">
                <X onClick={() => setHidden(true)} className="w-7 fill-current text-white cursor-pointer mb-1" />
              </div>
              <div style={{ borderRadius: "0.625rem" }} className="bg-white p-5">
                <div className="flex">
                  <img
                    className="w-11 h-11 rounded-full"
                    src={scheduleReq.appointment.user?.profilePictureUrl ?? process.env.REACT_APP_PROFILE_AVATAR_URL}
                    alt="foto do aluno"
                  />

                  <div className="ml-4 flex items-center">
                    <h1
                      style={{
                        color: "#1A1738",
                      }}>
                      {formatName(scheduleReq.appointment.user?.name)}{" "}
                    </h1>
                  </div>
                </div>
                <div
                  style={{
                    marginLeft: "3.8rem",
                  }}>
                  <button
                    className="text-sm"
                    style={{
                      color: "#4AD2CD",
                    }}
                    onClick={() => {
                      if (!schedule.appointment.user?.uuid) {
                        return;
                      }

                      handleComponent(
                        <DrawerProfile setShowSlide={setShowSlide} uuid={schedule.appointment.user.uuid} />,
                      );
                    }}>
                    Ver perfil do aluno
                  </button>
                </div>
              </div>
              <div className="flex mt-2">
                <Watch className="mt-3" />
                <div className="ml-5 mb-1">
                  <p className="text-white">Duração</p>
                  <div className="text-white font-semibold text-xl">
                    {scheduleReq.appointment.type === "TWENTY_FIVE" ? "25 min" : "50 min"}{" "}
                    {scheduleReq.appointment.isTestClass && <span className="text-base">(Test class)</span>}
                  </div>
                </div>
              </div>
              <div className="flex mt-2">
                <Calendar className="mt-3" />
                <div className="ml-5 mb-1 w-full">
                  <p>Data</p>
                  <ul className="w-full px-4 overflow-auto list-disc">
                    <li>
                      <span>
                        {`${getDayMonthNameYear(scheduleReq.dateTime)} ás ${getHoursAndMinutes(scheduleReq.dateTime)}`}
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
              <div
                className="flex items-center justify-center"
                style={{ marginTop: `${isPast(schedule.dateTime) ? "30px" : "0px"}` }}>
                <PurpleButton
                  className="w-full h-14 bg-purple-700 text-white p-2 px-5 rounded-lg my-2"
                  disabled={isButtonDisabled()}
                  onClick={() => {
                    window.open(scheduleReq.appointment?.teacherMeetingUrl, "_blank");
                  }}>
                  <div className="w-full flex justify-between items-center">
                    <span>Ir para aula</span>
                    <div className="rounded-full bg-purple-900 h-10 w-10 flex justify-center items-center">
                      <p className="mb-1">&gt;</p>
                    </div>
                  </div>
                </PurpleButton>
              </div>
              {!isPast(schedule.dateTime) && (
                <div className="flex items-center justify-center">
                  <button onClick={openCancelAppoitmentModal}>Cancelar aula</button>
                </div>
              )}
            </div>

            <div
              style={{ width: "374px", height: "401px", zIndex: "8", right: "-5px", top: "-5px" }}
              className={`${hiddenCancel && "hidden"} absolute shadow-xl borderBehind text-white p-5`}>
              <div className="flex justify-end">
                <X onClick={() => setHiddenCancel(true)} className="w-7 fill-current text-white cursor-pointer mb-1" />
              </div>
              <div className="mt-10">
                <h1 className="text-lg font-bold my-2">Deseja cancelar a aula?</h1>
                <p className="my-2">
                  Ao cancelar a aula 3h antes do agendado pelo aluno, o professor sofrerá uma multa de U$$ 3,50.
                </p>
              </div>
              <div className="flex items-center justify-center my-2">
                <button
                  onClick={() => setHiddenCancel(true)}
                  className="text-center w-full h-14 bg-purple-700 text-white p-2 px-5 rounded-lg my-2">
                  <span>Não cancelar</span>
                </button>
              </div>
              <div className="flex items-center justify-center">
                <button onClick={handleCancelAppointment}>Continuar cancelamento</button>
              </div>
            </div>
          </div>
        </>
      )}

      {scheduleReq?.status !== "UNAVAILABLE" && scheduleReq?.status !== "AVAILABLE" && (
        <button
          onClick={() => handleClick()}
          className="pill-shape pill-shape"
          style={{
            cursor: isPast(scheduleReq._dateTime || schedule._dateTime) ? "not-allowed" : "pointer",
            backgroundColor: isPast(scheduleReq._dateTime || schedule._dateTime) ? "#bebebe63" : "white",
          }}>
          {isLoading && <SpinnerPurple className="m-auto animate-spin" style={{ width: "25px", height: "30px" }} />}
        </button>
      )}
    </>
  );
};

SchedulePill.propTypes = {
  refresh: PropTypes.func,
  schedule: PropTypes.any,
};

export default SchedulePill;
