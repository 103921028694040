import propTypes from "prop-types";
import React from "react";
import { HiOutlineArrowNarrowRight } from "react-icons/hi";
import { useHistory } from "react-router-dom";

import CourseProgressBar from "../CourseProgressBar/CourseProgressBar";

import "./TertiaryCategoryCard.css";

const TertiaryCategoryCard = ({ data, color }) => {
  const { uuid, title, description, iconUrl, level, totalMaterials, viwedMaterials, children } = data;

  const history = useHistory();

  const barColor = color.replace(/rgb/gi, "rgba").replace(")", ", 0.4)");
  const subCategoriesLink = `material?parentUuid=${uuid}`;
  const hasSubCategories = children?.length > 0;

  return (
    <section
      onClick={() => history.push(hasSubCategories ? subCategoriesLink : `material/${uuid}`)}
      className="tertiary-category-card cursor-pointer">
      <div className="tertiary__main">
        <img src={iconUrl} alt="category" draggable="false" className="tertiary-category__image" />

        <div>
          <div className="tertiary-category__header">
            <span className="tertiary-category__title" style={{ color, height: "50px" }}>
              {title}
            </span>
            <span className="tertiary-category__level">{level}</span>
          </div>

          <span className="tertiary-category__description" style={{ height: "100px" }}>
            {description}
          </span>
        </div>
      </div>

      <CourseProgressBar
        barHeight="8px"
        totalSteps={totalMaterials}
        doneSteps={viwedMaterials}
        barColor={barColor}
        detailsColor={color}
        showDetails
        showPercentage
      />

      <button type="button" style={{ color }}>
        {viwedMaterials === 0
          ? "Conhecer módulo"
          : viwedMaterials === totalMaterials
          ? "Módulo concluído"
          : "Continuar módulo"}

        <HiOutlineArrowNarrowRight />
      </button>
    </section>
  );
};

export default TertiaryCategoryCard;

TertiaryCategoryCard.propTypes = {
  data: propTypes.object,
  color: propTypes.string,
}.isRequired;
