/* eslint-disable react/prop-types */
import React from "react";

import SchedulePill from "./SchedulePill";

import "./TeacherSchedule.css";

const Schedule = ({ time, schedules, refresh }) => {
  return (
    <div className="schedule-grid mt-4">
      <div className="pill-shape border">
        <p className="mt-1 ml-3">{time}</p>
      </div>

      {schedules.map((schedule, index) => (
        <SchedulePill key={index} schedule={schedule} refresh={refresh} />
      ))}
    </div>
  );
};

export default Schedule;
