import PropTypes from "prop-types";
import React, { useEffect, useRef } from "react";
import { AiOutlineClose as CloseIcon } from "react-icons/ai";
import { useHistory } from "react-router-dom";
import { logAnalyticsEvent } from "utils/firebase-analytics";

import BannerImage from "../../../../assets/imgs/banners/BannerConversationMobile.png";
import useOnClickOutside from "../../../../hooks/useOnClickOutside";
import * as S from "./styles.module.css";

const ConversationMobile = ({ handleHideBanner }) => {
  const history = useHistory();

  const ref = useRef();

  useOnClickOutside(ref, () => handleHideBanner());

  const image = BannerImage;

  const handleClick = () => {
    handleHideBanner();
    logAnalyticsEvent("CLICK_BANNER_CONVERSATION_MOBILE");
    history.push("/planos");
  };

  useEffect(() => {
    logAnalyticsEvent("SHOW_BANNER_CONVERSATION_MOBILE");
  }, []);

  return (
    <div className={S.Backdrop}>
      <div className={S.Container}>
        <div ref={ref}>
          <div style={{ position: "relative", width: "100%" }}>
            <img className={S.Image} src={image} alt="bannerImage" />

            <div className={S.WrapperButtonClose}>
              <button className={S.ButtonClose} onClick={handleHideBanner}>
                <CloseIcon size={20} color="#6A51B2" />
              </button>
            </div>
          </div>

          <div className={S.Wrapper}>
            <div>
              <div>
                <p className={S.Title}>
                  Grupo de conversação de
                  <p>Segunda à Sexta</p>
                </p>
              </div>

              <p className={S.Description}>
                Participe dos nossos grupo de conversação e pratique diariamente, sempre com um professor e outros
                alunos conversar sobre diversos temas.
              </p>
            </div>

            <div>
              <button onClick={handleClick} className={S.ButtonPrimary} style={{ background: "#58A700", fontSize: 18 }}>
                <span>QUERO ENTRAR</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

ConversationMobile.propTypes = {
  handleHideBanner: PropTypes.func.isRequired,
  variant: PropTypes.number.isRequired,
};

export default ConversationMobile;
