import PropTypes from "prop-types";
import React from "react";

import Message from "../Message/Message";
import * as S from "./styles.module.css";

const Caption = ({ isUser }) => {
  return (
    <>
      {isUser ? (
        <Message
          style={{
            alignItems: "flex-end",
            paddingBottom: 4,
          }}>
          <div className={S.Caption}>
            <h3>Você</h3>
          </div>
        </Message>
      ) : (
        <Message
          style={{
            paddingBottom: 4,
          }}>
          <div className={S.Caption}>
            <img src="https://media.englishbay.com.br/Frame%2040169.png" alt="Chatbot" />
            <h3>Bay</h3>
          </div>
        </Message>
      )}
    </>
  );
};

Caption.propTypes = {
  isUser: PropTypes.bool,
};
export default Caption;
