import PropTypes from "prop-types";
import React, { createContext, useContext, useState } from "react";

const StateContext = createContext();

export const MaterialCardsProvider = ({ children }) => {
  const [question, setQuestion] = useState();
  const [progress, setProgress] = useState(0);
  const [percentage, setPercentage] = useState(0);
  const [unityTitle, setUnityTitle] = useState("");

  const [categoryProgress, setCategoryProgress] = useState(0);

  const value = {
    setQuestion,
    question,
    progress,
    setProgress,
    percentage,
    setPercentage,
    unityTitle,
    setUnityTitle,
    categoryProgress,
    setCategoryProgress,
  };

  return <StateContext.Provider value={value}>{children}</StateContext.Provider>;
};

MaterialCardsProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const useMaterialCards = () => useContext(StateContext);
