export const banner04 = `<div
  style="
    cursor: pointer;
    margin: 0 auto;
    color: #333;
    text-align: center;
    font-size: 14px;
    font-family: 'Urbanist', sans-serif;
    display: flex;
    flex-direction: column;
    max-width: 330px;
    border-radius: 10px;
    position: relative;
  "
>
  <p
    style="
      position: absolute;
      font-size: 22px;
      font-weight: 600;
      color: white;
      padding: 10px;
      text-shadow: 1px 1px 1px #333;
      width: 100%;
    "
  >
    Grupos de conversação de segunda a sexta!
  </p>
  <img
    style="width: 100%; height: auto; margin: 0 auto"
    src="https://media.englishbay.com.br/assets-banners/banner04.png"
    alt="Juicy Online Conference"
  />
  <a
    style="
      color: white;
      padding: 15px;
      font-size: 16px;
      background-color: #723fb1;
      text-decoration: none;
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
      width: 100%;
    "
    >Assine com 70% de desconto
  </a>
</div>`;
