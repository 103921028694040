import useForm from "hooks/useForm";
import propTypes from "prop-types";
import React from "react";
import { useHistory } from "react-router-dom";
import "./QuestionCard.css";
import { logAnalyticsEvent } from "utils/firebase-analytics";

import { InputPhone, PurpleButton } from "../../_Shared/Form/index";

const QuestionCardPhoneNumber = ({ setter, title }) => {
  const phoneNumberForm = useForm("phoneNumber");
  const history = useHistory();

  const handleSubmit = () => {
    if (!phoneNumberForm.value) {
      history.push("/onboarding/signup");

      logAnalyticsEvent("OB_phoneNumber");

      return;
    }

    if (phoneNumberForm.validate()) {
      logAnalyticsEvent("OB_phoneNumber");

      setter(phoneNumberForm.value);
    }
  };

  return (
    <div className="question-card">
      <span className="question-title" style={{ marginBottom: "8px" }}>
        {title}
      </span>

      <div className="answers">
        <div className="">
          <InputPhone
            label="Telefone"
            placeholder="Número do whatsapp"
            style={{ width: "270px", fontSize: "1em", paddingLeft: "20px" }}
            {...phoneNumberForm}
          />
        </div>
      </div>

      <PurpleButton
        className="btn-phone-next"
        disabled={!!phoneNumberForm.value && !!phoneNumberForm.error}
        onClick={handleSubmit}>
        {phoneNumberForm.value?.length ? "Continuar" : "Pular"}
      </PurpleButton>
    </div>
  );
};

export default QuestionCardPhoneNumber;

QuestionCardPhoneNumber.propTypes = {
  setter: propTypes.func,
  title: propTypes.string,
}.isRequired;
