import React from "react";

import { Home, Calendar, Lessons, Notebook, Users, Trophy, Certificate } from "../../../assets/svgs/index";

export const StudentSidebarLinks = [
  { name: "sidebar.home", path: "/", svg: <Home /> },
  { name: "sidebar.material", path: "/material", svg: <Notebook />, isNotExact: true },
  { name: "sidebar.classes", path: "/minhasAulas", svg: <Lessons /> },
  { name: "sidebar.teachers", path: "/professores", svg: <Users />, isNotExact: true },
  { name: "sidebar.events", path: "/eventos", svg: <Calendar /> },
  { name: "Certificados", path: "/certificados", svg: <Certificate /> },
  { name: "sidebar.plans", path: "/assinatura", svg: <Trophy /> },
];
