import PropTypes from "prop-types";
import React, { useContext, useEffect, useState } from "react";

import { ArrowRigth, CloseX, SpinnerPurple, WarningIcon } from "../../../../assets/svgs/index";
import { SlideContext } from "../../../../context/SlideContext";
import useForm from "../../../../hooks/useForm";
import useRequest from "../../../../hooks/useRequest";
import { getDayMonthYear } from "../../../../utils/dates";
import { PurpleButton, Button, FormCouponButton } from "../../Form/index";
import ChangePlanSuccess from "../ChangePlanSuccess/ChangePlanSuccess";
import "./ConfirmationChangePlan.css";

const ConfirmationChangePlan = ({ planUuid, planName, getPlans }) => {
  const { request, loading } = useRequest();
  const couponForm = useForm("optional");

  const { setShowSlide, setSlideComponent } = useContext(SlideContext);
  const [activePlan, setActivePlan] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [plan, setPlan] = useState();
  const [discount, setDiscount] = useState();

  useEffect(() => {
    const getPlans = async () => {
      const activePlan = await request("GET", "/subscription/activeSubscription");
      const plan = await request("GET", `/plan/read/${planUuid}`);

      setActivePlan(activePlan);
      setPlan(plan);
      setIsLoading(false);
    };

    getPlans();
  }, []);

  const handleRequest = async () => {
    const planForm = {
      paymentMethodCode: "credit_card",
      planUuid,
    };

    if (discount) {
      planForm.couponCode = discount.code;
    }

    const { error } = await request("POST", "/subscription/subscribe", planForm);

    if (!error) {
      setSlideComponent(<ChangePlanSuccess />);
      getPlans();
    }
  };

  return (
    <div className="flex flex-col" style={{ width: "550px" }}>
      <div className="flex justify-between items-center gap-2">
        <h1 className="text-xl font-gray font-medium">Confirmação de plano</h1>
        <CloseX className="cursor-pointer" onClick={() => setShowSlide(false)} />
      </div>
      <p className="font-light-gray font-medium py-2">Confira as informações abaixo para antes de trocar o seu plano</p>
      {isLoading ? (
        <SpinnerPurple className="w-8 m-auto animate-spin" />
      ) : (
        <>
          <div className="flex-1">
            <p className="py-2 text-sm" style={{ color: "#F59300" }}>
              As cobranças por cartão de crédito são automáticas, você pode cancelar quando quiser através do painel
              “Planos”.
            </p>
            <div className="flex gap-5 py-5 border-b border-gray-300">
              <div>
                <p className="font-light-gray text-xs font-bold uppercase">Plano Atual</p>
                <p>{activePlan && activePlan.plan.name}</p>
              </div>
              <ArrowRigth className="w-2 fill-current text-purple-600" />
              <div>
                <p className="font-light-gray text-xs font-bold uppercase">Novo Plano</p>
                <p>{planName}</p>
              </div>
            </div>
            <div className="py-5 border-b border-gray-300">
              <p className="font-light-gray text-xs font-bold uppercase">Cupom de desconto</p>
              <div>
                <FormCouponButton
                  couponForm={couponForm}
                  planUuid={planUuid}
                  setDiscount={setDiscount}
                  discount={discount}
                />
              </div>
            </div>
            <div className="flex gap-3 py-5">
              <div>
                <p className="font-light-gray text-xs font-bold uppercase">Próxima cobrança</p>
                <p>{getDayMonthYear(activePlan.nextBillingAt)}</p>
              </div>
              <div>
                <p className="font-light-gray text-xs font-bold uppercase">aulas disponíveis</p>
                <p>
                  {plan.lessonsCount} {plan.lessonsCount === 1 ? "aula" : "aulas"} mensais
                </p>
              </div>
            </div>
            <p
              className="py-2 mb-3 inline-flex text-sm items-center justify-center px-2 font-bold leading-none rounded-full w-full"
              style={{ backgroundColor: "#FFEEDD", color: "#F59300" }}>
              <WarningIcon stroke="orange" className="mr-2 w-5" />
              Lembre-se: O plano passará a valer na próxima cobrança
            </p>
          </div>
          <div className="grid grid-cols-3 gap-5 mt-10">
            <PurpleButton onClick={handleRequest} isLoading={loading}>
              Confirmar
            </PurpleButton>
            <Button onClick={() => setShowSlide(false)} className="border border-purple-800 text-purple-800">
              Cancelar
            </Button>
          </div>
        </>
      )}
    </div>
  );
};

ConfirmationChangePlan.propTypes = {
  planUuid: PropTypes.string,
  planName: PropTypes.string,
  getPlans: PropTypes.func,
};

export default ConfirmationChangePlan;
