import PropTypes from "prop-types";
import React from "react";
import { BiCheckCircle, BiChevronRight, BiLock } from "react-icons/bi";

import CourseProgressBar from "../CourseProgressBar/CourseProgressBar";
import * as S from "./styles.module.css";

const CTA = {
  COMPLETED: <BiCheckCircle size={24} color="#58CC02" />,
  DOING: <BiChevronRight size={24} color="#BBBBBB" />,
  BLOCKED: <BiLock size={24} color="#BBBBBB" />,
};

const LevelProgress = ({ onClick, state, totalUnity, doneUnity, title, imageEnabledUrl, imageDisabledUrl }) => {
  const image = state !== "BLOCKED" ? imageEnabledUrl : imageDisabledUrl;

  const handleClick = () => {
    if (state === "BLOCKED") {
      return;
    }

    onClick();
  };

  return (
    <div
      onClick={handleClick}
      className={`${state === "COMPLETED" && S.Done}
      ${state === "DOING" && S.InProgress}
      flex items-center gap-2 p-4 mb-2`}>
      <img src={image} alt="imagelevle" style={{ width: 64, height: 64 }} />

      <div className="w-full font-bold">
        <h2 className={S.Title}>{title}</h2>

        {state === "COMPLETED" && (
          <p
            className={S.Description}
            style={{
              color: "#58CC02",
            }}>
            Todas as unidades concluídas
          </p>
        )}

        {state === "DOING" && <p>{totalUnity} unidades neste módulos </p>}

        {state === "BLOCKED" && <p>Conclua as unidades anteriores </p>}

        {state === "DOING" && (
          <CourseProgressBar
            barColor="#F1F1F1"
            fillColor="#58CC02"
            barHeight="8px"
            totalSteps={totalUnity}
            doneSteps={doneUnity}
          />
        )}

        {state === "BLOCKED" && (
          <CourseProgressBar barColor="#F1F1F1" fillColor="#58CC02" barHeight="8px" totalSteps={10} doneSteps={0} />
        )}
      </div>

      <div>{CTA[state]}</div>
    </div>
  );
};

LevelProgress.propTypes = {
  onClick: PropTypes.func,
  state: PropTypes.string.isRequired,
  totalUnity: PropTypes.number.isRequired,
  doneUnity: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  imageEnabledUrl: PropTypes.string,
  imageDisabledUrl: PropTypes.string,
};

export default LevelProgress;
