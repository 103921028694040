import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";

import { BasicClock, ShoppingService, Warning } from "../../../../assets/svgs/index";
import VideoIcon from "../../../../assets/svgs/VideoIcon.svg";
import useRequest from "../../../../hooks/useRequest";
import { getDayOfTheWeekDayMonthYear, getHoursAndMinutes } from "../../../../utils/dates";
import AnnotationRow from "./AnnotationRow";
import "./InformationAboutClass.css";

const translateAppointmentStatus = (status) => {
  const dic = {
    COMPLETED: "Presente",
    MISSED: "Faltou",
    PENDING: "Aguardando confirmação",
  };

  return dic[status] ?? status;
};

const InformationAboutClass = ({ uuid }) => {
  const { request } = useRequest();

  const [classTeacher, setClass] = useState({});

  const getRecordLink = () =>
    `https://class.englishbay.com.br/playback/presentation/2.3/${classTeacher.internalMeetingID}`;

  const fetchTeacher = async () => {
    if (uuid === undefined) {
      return;
    }

    const response = await request("GET", "/appointment/read/" + uuid);
    setClass(response);
  };

  useEffect(() => {
    fetchTeacher();
  }, [uuid]);

  return (
    <div
      className="min-h-screen bg-white pt-14 md:pr-16 pr-8 pl-8 rounded-2xl col-span-2"
      style={{
        boxShadow: "0px 6px 8px rgba(24, 39, 75, 0.12), 0px 8px 16px rgba(24, 39, 75, 0.08)",
      }}>
      <div className="flex items-center justify-between">
        <div className="flex items-center">
          <img
            src={classTeacher?.schedule?.teacher?.user?.profilePictureUrl ?? process.env.REACT_APP_PROFILE_AVATAR_URL}
            alt="professora"
            className="w-16 h-16 rounded-full"
          />

          <h1
            className="ml-5 md:text-xl text-base"
            style={{
              color: "#383743",
            }}>
            {classTeacher?.schedule?.teacher?.user?.name}
          </h1>
        </div>

        <div className="items-center">
          <NavLink
            className="col-span-2 buttonColored w-[90px] h-[2em] md:w-[200px] md:h-[3.25em]"
            to={"/professores/perfil/" + classTeacher?.schedule?.teacher?.uuid}>
            <p className="text-xs">Ver Perfil</p>
          </NavLink>
        </div>
      </div>

      <div className="mt-6 sm:ml-24 flex items-center">
        <div className="flex items-center">
          <BasicClock fill="#625E66" className="mr-3" />
          <div>
            <div>
              <p
                className="uppercase tracking-wider text-xs font-bold"
                style={{
                  color: "#676482",
                }}>
                Duração
              </p>
              <p
                className="md:text-base text-xs"
                style={{
                  color: "#1A1738",
                }}>
                {classTeacher?.type === "TWENTY_FIVE" ? "25 min" : "50 min"}
              </p>
            </div>
          </div>
        </div>
        <div className="flex items-center ml-9">
          <ShoppingService className="mr-3" />
          <div>
            <div>
              <p
                className="uppercase tracking-wider text-xs font-bold"
                style={{
                  color: "#676482",
                }}>
                Chamada
              </p>
              <p
                className="md:text-base text-xs"
                style={{
                  color: "#1A1738",
                }}>
                {classTeacher?.status && translateAppointmentStatus(classTeacher.status)}
              </p>
            </div>
          </div>
        </div>
      </div>
      <h2
        className="mt-8"
        style={{
          color: "#383743",
        }}>
        Resumo da aula
      </h2>

      <div className="mt-2">
        <AnnotationRow
          title="Anotações do Professor"
          note={classTeacher?.teacherNote ? classTeacher?.teacherNote : "Não há anotações"}
        />
        <AnnotationRow
          className="mt-6"
          title="Anotações do Aluno"
          note={classTeacher?.studentNote ? classTeacher?.studentNote : "Não há anotações"}
        />
      </div>

      {classTeacher.internalMeetingID && (
        <div>
          <h2
            className="mt-8 mb-2 ml-1"
            style={{
              color: "#383743",
            }}>
            Arquivos de apoio
          </h2>

          <div className="ml-1">
            <div
              className="flex space-x-2 cursor-pointer"
              onClick={() => {
                window.open(getRecordLink(), "_blank");
              }}>
              <img src={VideoIcon} alt="Icone do PDF" />
              <div>
                <h5 className="text-sm font-medium">Vídeo da aula</h5>
                <h5 className="text-sm">
                  {classTeacher.schedule?.dateTime && getDayOfTheWeekDayMonthYear(classTeacher.schedule?.dateTime)} às{" "}
                  {classTeacher.schedule?.dateTime && getHoursAndMinutes(classTeacher.schedule?.dateTime)}
                </h5>
              </div>
            </div>
          </div>

          <div className="flex items-center py-3 mt-3">
            <Warning style={{ fill: "#F59300", width: "18px", height: "18px" }} className="w-10 md:w-5 mr-3 md:mr-1" />
            <p style={{ color: "#F59300", fontSize: "0.87em" }}>
              A gravação pode demorar até algumas horas para ficar disponível.
            </p>
          </div>
        </div>
      )}
    </div>
  );
};

InformationAboutClass.propTypes = {
  uuid: PropTypes.string,
};

export default InformationAboutClass;
