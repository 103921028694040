import React, { useState, useEffect, useContext } from "react";

import LessonsCardsCarousel from "../../../_Shared/LessonsCardsCarousel/LessonsCardsCarousel";
import RemainingClasses from "../../../_Shared/RemainingClasses/RemainingClasses";
import { NavbarContext } from "../../../../context/NavbarContext";
import useRequest from "../../../../hooks/useRequest.js";
import HistoryClassRow from "./HistoryClassRow";
import HistoryClass from "./InformationAboutClass";

import "./MyLessons.css";

const MyLessons = () => {
  const [historyLessons, setHistoryLessons] = useState([]);
  const [uuid, setUuid] = useState();
  const { setNavigationLinks } = useContext(NavbarContext);

  const { request } = useRequest();

  const fetchHistory = async () => {
    const response = await request("GET", "appointment/listHistory");

    if (response.length) {
      setUuid(response[0].uuid);
      setHistoryLessons(response);
    }
  };

  useEffect(() => {
    fetchHistory();

    const arrLinks = [
      { title: "Início", path: "/" },
      { title: "Minhas Aulas", path: "minhasAulas" },
    ];

    setNavigationLinks(arrLinks);
  }, []);

  return (
    <>
      <div className="mt-5 px-5">
        <div className="flex justify-end items-center my-4">
          <RemainingClasses />
        </div>
        <LessonsCardsCarousel />
        <div className="grid grid-cols-1 md:grid-cols-3 gap-3 mt-20" style={{ marginBottom: "100px" }}>
          <div>
            <div className="flex justify-between">
              <div className="mb-3">
                <h2
                  className="text-2xl"
                  style={{
                    color: "#252426",
                  }}>
                  Histórico de aulas
                </h2>
                {historyLessons.length === 0 ? (
                  <p>Você não tem histórico de aulas</p>
                ) : (
                  <p
                    className="text-sm"
                    style={{
                      color: "#625E66",
                    }}>
                    <span className="font-green font-bold">{historyLessons.length}</span> aulas concluídas
                  </p>
                )}
              </div>
              {/* {historyLessons.length === 0 ? (
                ""
              ) : (
                <div className="flex justify-start 2xl:justify-end">
                  <ButtonSvg className="p-3 mr-1 bg-white rounded-lg max-h-12">
                    <Configuration className="w-6" />
                  </ButtonSvg>
                </div>
              )} */}
            </div>
            {historyLessons.map((item) => {
              return (
                <HistoryClassRow
                  key={item.uuid}
                  onClick={() => setUuid(item.uuid)}
                  nameTeacher={item.schedule.teacher.user.name}
                  imageTeacher={item.schedule.teacher.user.profilePictureUrl}
                  date={item.schedule.dateTime}
                  uuid={uuid}
                  itemUuid={item.uuid}
                />
              );
            })}
          </div>
          {historyLessons.length === 0 ? "" : <HistoryClass uuid={uuid} />}
        </div>
      </div>
    </>
  );
};

export default MyLessons;
