import Button from "components/MaterialCards/Button/Button";
import ButtonFeedback from "components/MaterialCards/ButtonFeedback/ButtonFeedback";
import TTSButton from "components/MaterialCards/TTSButton/TTSButton";
import { useChatbot } from "context/ChatbotContext";
import { useMaterialCards } from "context/MaterialCardsContext";
import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";

import * as S from "./styles.module.css";

const MultipleAnswer = ({ nextCard }) => {
  const { question } = useMaterialCards();
  const { handleCheckAnswer } = useChatbot();

  const [answers, setAnswers] = useState([]);
  const [selectedAnswer, setSelectedAnswer] = useState(0);
  const [isChecking, setIsChecking] = useState(false);
  const [isCorrect, setIsCorrect] = useState(false);
  const [showErrorFeedback, setShowErrorFeedback] = useState(false);

  const handleSubmit = () => {
    setShowErrorFeedback(true);

    setAnswers((prevAnswers) => {
      return prevAnswers.map((answer, index) => {
        if (index === selectedAnswer) {
          setIsCorrect(answer.isCorrect);

          handleCheckAnswer(answer.isCorrect);

          return {
            ...answer,
            variant: answer.isCorrect ? "correct" : "incorrect",
          };
        }

        return {
          ...answer,
        };
      });
    });
  };

  useEffect(() => {
    if (question === undefined) {
      return;
    }

    setAnswers(question.choices);
  }, [question]);

  const handleQuestionClick = (indexQuestion) => {
    // Previne que o usuário clique em outra questão enquanto a resposta está corrigida
    if (showErrorFeedback) {
      return;
    }

    setSelectedAnswer(indexQuestion);
    setIsChecking(true);

    setAnswers((prevAnswers) => {
      return prevAnswers.map((answer, index) => {
        if (index === indexQuestion) {
          return {
            ...answer,
            variant: "checking",
          };
        }

        return {
          ...answer,
          variant: null,
        };
      });
    });
  };

  const handleNextQuestion = () => {
    setIsChecking(false);
    setShowErrorFeedback(false);
    setIsCorrect(false);
    setSelectedAnswer(null);
    nextCard({ answer: answers[selectedAnswer].answer, isCorrect });
  };

  const showCorrectAnswer = () => {
    setAnswers((prevAnswers) => {
      return prevAnswers.map((answer) => {
        if (answer.isCorrect) {
          return { ...answer, variant: "correct" };
        }

        return { ...answer };
      });
    });
  };

  return (
    <div className={S.wrapper}>
      <div className={S.wrapperQuestions}>
        <div className={S.question}>
          {question?.showTTSButton ? <TTSButton phrase={question?.question} /> : <p>{question?.question}</p>}
        </div>

        {question?.imageUrl && (
          <div className={S.wrapperImage}>
            <img className={S.image} src={question.imageUrl} alt="card imagem" />
          </div>
        )}

        <div className={S.questions}>
          {answers?.map((answer, index) => (
            <Button
              onClick={() => handleQuestionClick(index)}
              key={index}
              type="answer"
              text={answer.answer}
              variant={answer.variant}
            />
          ))}
        </div>
      </div>

      <div className={S.wrapperButton}>
        <ButtonFeedback
          showErrorFeedback={showErrorFeedback}
          handleNextQuestion={handleNextQuestion}
          handleSubmit={handleSubmit}
          isCorrect={isCorrect}
          isChecking={isChecking}
          showCorrectAnswer={showCorrectAnswer}
        />
      </div>
    </div>
  );
};

MultipleAnswer.propTypes = {
  nextCard: PropTypes.func.isRequired,
};

export default MultipleAnswer;
