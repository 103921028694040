import PropTypes from "prop-types";
import React from "react";
import { DebounceInput } from "react-debounce-input";
import { useClickAway } from "react-use";

import { Search } from "../../../assets/svgs";
import "./SearchButton.css";

const WAIT_TIME = 200; // miliseconds

const SearchButton = ({ query, setQuery, isOpen, setIsOpen }) => {
  const clickRef = React.useRef();

  useClickAway(clickRef, () => {
    setIsOpen(false);
  });

  if (!isOpen && !query?.length) {
    return (
      <button className="p-3 bg-white border border-gray-300 max-h-12 rounded-md" onClick={() => setIsOpen(!isOpen)}>
        <Search width="20" height="20" className="cursor-pointer" />
      </button>
    );
  }

  return (
    <div
      className="flex justify-between items-center p-2 pl-4 rounded-xl"
      style={{
        border: "1px solid #14C4BE",
        height: "2.875rem",
      }}>
      <div className="flex items-center" ref={clickRef}>
        <Search className="cursor-pointer" width="16" height="16" onClick={() => setIsOpen(!isOpen)} />
        <DebounceInput
          minLength={2}
          debounceTimeout={WAIT_TIME}
          onChange={({ target }) => setQuery(target.value)}
          className="ml-4 font-serif text-base search-input"
          style={{
            color: "#A39EB8",
          }}
          placeholder="Busque por nome"
          value={query}
        />
      </div>
    </div>
  );
};

SearchButton.propTypes = {
  setQuery: PropTypes.func,
  query: PropTypes.string,
  isOpen: PropTypes.bool,
  setIsOpen: PropTypes.func,
};

export default SearchButton;
