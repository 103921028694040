import PropTypes from "prop-types";
import React, { createContext, useContext, useState } from "react";

const StateContext = createContext();

export const ModalBannerProvider = ({ children }) => {
  const [showBanner, setShowBanner] = useState(false);
  const [banner, setBanner] = useState(null);

  const handleSetBanner = (bannerName) => {
    setBanner(bannerName);
    setShowBanner(true);
  };

  const handleHideBanner = () => {
    setShowBanner(false);
  };

  const value = {
    handleSetBanner,
    handleHideBanner,
    showBanner,
    setShowBanner,
    banner,
  };

  return <StateContext.Provider value={value}>{children}</StateContext.Provider>;
};

ModalBannerProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const useModalBanner = () => useContext(StateContext);
