import React from "react";
import { Switch, Route, NavLink } from "react-router-dom";

import { Profile as ProfileIcon, RightChevron } from "../../../../assets/svgs/index";
import ChangePassword from "./ChangePassword/ChangePassword";
import Informations from "./Informations/Informations";
import Notifications from "./Notifications/Notifications";

import "./Profile.css";

const Profile = () => {
  return (
    <>
      <p className="py-2 px-8 text-sm mt-8 sm:mt-4">Início / Perfil</p>
      <div className="grid grid-cols-1 lg:grid-cols-4 2xl:grid-cols-5 gap-5 px-4">
        <div className="col-span-3 sm:col-span-1 bg-white p-4 rounded-3xl flex h-56">
          <div>
            <div className="flex items-center gap-2 ml-2">
              <ProfileIcon className="w-8 fill-current text-purple-800" />
              <p>Minha Conta</p>
            </div>
            <div className="px-3">
              <p className="text-sm py-2">Altere as configurações e confira suas notificações </p>
              <NavLink activeClassName="profile-item-active" exact to="/perfil/informacoes">
                <div className="flex justify-between items-center">
                  <p className="font-medium	hover-dark-purple text-sm">Configurações do Perfil</p>
                  <RightChevron className="fill-current color-black" />
                </div>
              </NavLink>

              <NavLink activeClassName="profile-item-active" exact to="/perfil/alterarSenha">
                <div className="flex justify-between items-center">
                  <p className="font-medium hover-dark-purple text-sm">Alterar senha</p>
                  <RightChevron className="fill-current color-black" />
                </div>
              </NavLink>

              <NavLink activeClassName="profile-item-active" exact to="/perfil/notificacoes">
                <div className="flex justify-between items-center">
                  <p className="font-medium hover-dark-purple text-sm">Notificações</p>
                  <RightChevron className="fill-current color-black" />
                </div>
              </NavLink>
            </div>
          </div>
        </div>
        <div className="col-span-3">
          <Switch>
            <Route path="*/informacoes" component={Informations} />
            <Route path="*/alterarSenha" component={ChangePassword} />
            <Route path="*/notificacoes" component={Notifications} />
          </Switch>
        </div>
      </div>
    </>
  );
};

export default Profile;
