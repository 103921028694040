import propTypes from "prop-types";
import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import { Calendar, CloseX, EnglishBay, SpinnerWhite, Users, World } from "../../../assets/svgs";
import { UserContext } from "../../../context/UserContext";
import useRequest from "../../../hooks/useRequest";
import { formatDate } from "../../../utils/dates";
import { capitalize } from "../../../utils/utils";
import EventParticipants from "../EventParticipants/EventParticipants";

import "./EventDetails.css";

const EventDetails = ({ data, closeSlider, renderEventDetails, setUpdateCards }) => {
  const [formattedDate, setFormatedDate] = useState({});
  const [formatedEndDate, setFormatedEndDate] = useState({});
  const [hourDifference, setHourDifference] = useState(10);
  const [isLoading, setIsLoading] = useState(false);
  const { user } = useContext(UserContext);
  const { request } = useRequest();
  const history = useHistory();

  const {
    uuid,
    title,
    accessRoles,
    message,
    eventUrl,
    startDate,
    endDate,
    participants,
    participating,
    participantsCount,
  } = data;

  const haveAccessToEvent = accessRoles.includes(user?.role) && user?.student?.status === "ACTIVE";

  const getHourDifference = (date) => {
    const currentTime = new Date().getTime() / 1000;
    const eventTime = new Date(date).getTime() / 1000;

    return (eventTime - currentTime) / 3600;
  };

  useEffect(() => {
    if (!user?.timezone) {
      return;
    }

    setFormatedDate(formatDate(startDate, user.timezone));
    setHourDifference(getHourDifference(startDate));

    if (endDate) {
      setFormatedEndDate(formatDate(endDate, user.timezone));
    }
  }, [startDate, endDate, user?.timezone]);

  const getEventRolesMessage = (roles) => {
    if (roles.includes("LEAD")) {
      return "Todos os públicos";
    }

    if (!roles.includes("LEAD") && roles.includes("STUDENT")) {
      return "Para Estudantes";
    }
  };

  const updateSlider = async () => {
    const event = await request("GET", `/calendarEvent/read/${uuid}`);
    renderEventDetails(event);
    setUpdateCards(true);
    setIsLoading(false);
  };

  const cancelParticipation = async () => {
    await request("DELETE", `/calendarEvent/participate/${uuid}`);
    updateSlider();
  };

  const confirmParticipation = async () => {
    if (!haveAccessToEvent) {
      history.push(user?.role === "LEAD" ? "/planos" : "/assinatura");
      closeSlider();
      return;
    }

    setIsLoading(true);
    await request("POST", `/calendarEvent/participate/${uuid}`);
    updateSlider();
  };

  return (
    <section style={{ width: "500px" }} className="flex flex-col justify-between">
      <div>
        <div className="flex items-center justify-between mb-10">
          <span className="text-lg text-gray-500">Detalhes do evento</span>
          <CloseX className="cursor-pointer" onClick={closeSlider} />
        </div>

        <h1 className="text-2xl font-bold text-gray-700">{title}</h1>
        <span className="text-md font-medium text-gray-500 mb-5 block">{message}</span>

        <div className="flex items-center gap-3">
          <Calendar />

          <div>
            <span className="block text-lg font-semibold text-gray-700">{capitalize(formattedDate.date)}</span>
            <span className="text-md font-medium text-gray-500 block">
              Início às {formattedDate.hour}
              {endDate && ` / Término às ${formatedEndDate.hour}`}
            </span>
          </div>
        </div>

        <div className="flex items-center gap-3 mt-5">
          <World style={{ width: "32px" }} />

          <div>
            <span className="block text-lg font-semibold text-gray-700">Público destinado</span>
            <span className="text-md font-medium text-gray-500 block">{getEventRolesMessage(accessRoles)}</span>
          </div>
        </div>

        <div className="flex items-center gap-3 mt-5">
          <Users />

          <div>
            <span className="block text-lg font-semibold text-gray-700">Participantes</span>

            <span className="text-md font-medium text-gray-500 block">
              {participantsCount === 1
                ? `${participantsCount} confirmou presença`
                : participantsCount > 1
                ? `${participantsCount} confirmaram presença`
                : "Ninguém confirmou presença"}
            </span>
          </div>
        </div>

        {participantsCount > 0 && (
          <div className="flex items-start pl-12 mt-2">
            <EventParticipants participants={participants} participantsCount={participantsCount} />
          </div>
        )}

        <span className="block text-xl font-semibold text-gray-700 mt-12">Organizador</span>

        <div className="flex items-center gap-4 mt-3 mb-10">
          <EnglishBay />

          <div>
            <span className="block text-lg font-semibold text-gray-700">EnglishBay</span>
            <span className="text-md font-medium text-gray-500 block">Educação</span>
          </div>
        </div>
      </div>

      <div>
        {!participating && (
          <button
            className={"px-5 py-3 rounded-lg text-white font-bold w-full "}
            style={{
              background: "#623CEA",
            }}
            onClick={confirmParticipation}>
            {(isLoading && <SpinnerWhite className="w-6 m-auto animate-spin" />) || "Quero Participar"}
          </button>
        )}

        {participating && (
          <>
            <button
              className="px-5 py-3 rounded-lg text-white font-bold w-full mb-8 button__go"
              disabled={hourDifference > 1}
              onClick={() => window.open(eventUrl, "_blank")}>
              Ir para o evento
              <span>Você poderá acessar o evento em breve!</span>
            </button>

            <button
              className="font-bold text-red-500 w-full block"
              onClick={cancelParticipation}
              style={{ marginBottom: "10px" }}>
              Cancelar participação
            </button>
          </>
        )}
      </div>
    </section>
  );
};

export default EventDetails;

EventDetails.propTypes = {
  data: propTypes.object,
  closeSlider: propTypes.func,
  renderEventDetails: propTypes.func,
  setUpdateCards: propTypes.func,
}.isRequired;
