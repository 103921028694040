import Button from "components/MaterialCards/Button/Button";
import ProgressCircular from "components/MaterialCards/ProgressCircular/ProgressCircular";
import { useChatbot } from "context/ChatbotContext";
import { useMaterialCards } from "context/MaterialCardsContext";
import { UserContext } from "context/UserContext";
import PropTypes from "prop-types";
import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import * as S from "./styles.module.css";

const PontuationProps = {
  success: {
    image: "https://user-images.githubusercontent.com/46573685/216672752-7b17b40a-0bf0-4103-8fb2-a3f3b48171a2.png",
    color: "#88EB40",
    buttonText: "Continuar",
  },

  failure: {
    image: "https://user-images.githubusercontent.com/46573685/216673267-38a5cea7-5f71-4821-874a-5af97bf7990b.png", // TOOD: Trocar essa url
    percentage: 30,
    color: "#F50041",
    buttonText: "Tentar novamente",
  },
};

const Pontuation = ({ resetProgress }) => {
  const history = useHistory();
  const { percentage } = useMaterialCards();
  const { user } = useContext(UserContext);

  const success = percentage >= 70;
  const failure = percentage < 70;

  const [pontuationProps, setPontuationProps] = useState();

  const { handleChatbot } = useChatbot();

  useEffect(() => {
    if (percentage) {
      const variant = success ? "success" : "failure";

      PontuationProps[variant].userName = user.name;

      setPontuationProps(PontuationProps[variant]);

      if (variant === "failure") {
        resetProgress();

        handleChatbot("sad", "Não desanime! Vamos tentar novamente? Você consegue!");
        return;
      }

      handleChatbot("happy", "Parabéns você completou a unidade! Vamos praticar o que aprendeu?");
    }
  }, [percentage]);

  if (percentage === null) {
    return <div></div>;
  }

  return (
    <div className={S.wrapper}>
      <div className={S.wrapperContent}>
        <div>
          <ProgressCircular percentage={percentage} image={pontuationProps?.image} color={pontuationProps?.color} />
          <p className={S.percentage} style={{ color: pontuationProps?.color }}>
            Você acertou {percentage}%
          </p>
        </div>
        {success && <p className={S.title}>Parabéns!</p>}

        {failure && <p className={S.title}>{user.name}, não desanime!</p>}

        {success && (
          <p className={S.textSuccess}>
            Você terminou essa unidade sem grandes dificuldades. Você está pronto para enfrentar os próximos desafios
          </p>
        )}

        {failure && (
          <p className={S.text}>Dá uma revisada no material e tenta novamente, seguindo um novo caminho! Que tal?</p>
        )}
      </div>

      <div className={S.wrapperButton}>
        <Button
          onClick={() => history.push("/")}
          type="callToAction"
          variant="checking"
          text={pontuationProps?.buttonText}
        />
      </div>
    </div>
  );
};

Pontuation.propTypes = {
  resetProgress: PropTypes.func.isRequired,
  getActualCard: PropTypes.func.isRequired,
};

export default Pontuation;
